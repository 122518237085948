import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    bgVideo: {
        position: 'fixed',
        top: '0%',
        left: '0%',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        zIndex: '-100',

        background: "#309def url('../images/video-poster.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transition: '1s opacity',
        transform: 'translateX(-50%) translateY(-50%)',
        [theme.breakpoints.up('md')]: {
            top: '50%',
            left: '50%',

        },
    }
});
class InstallationVideo extends React.Component {
    
    render() {
        const { classes } = this.props;
        return (
            <video playsInline muted loop autoPlay className={classes.bgVideo} poster={require("../../assets/images/video-poster.jpg")}>
                <source src={require("../../assets/video/video.mp4")} type="video/mp4" />
                <source src={require("../../assets/video/video.ogv")} type="video/ogg" />
                <source src={require("../../assets/video/video.webm")} type="video/webm" />
            </video>    
        );
    }
}

export default withStyles(styles)(InstallationVideo);