import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import theme from '../theme';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import Hidden from '@material-ui/core/Hidden'; 
const styles = {
    root: {
        flexGrow: 1,    
        marginLeft: theme.spacing(2),
    },
    vanishIndicator: {
        height:0,
    },
    tabRoot: {
        textTransform: 'uppercase',
        marginRight: theme.spacing(1) * 0.5,
        minWidth: "auto",
        maxWidth: "auto",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        color: theme.palette.grey[300],
        transition:"0.2s ease",
        '&:hover': {
            color: theme.palette.grey[300],
            opacity: 1,
        },
        '&$tabSelected': {
            color: theme.palette.common.white,
        },
   
    },
    tabSelected: {},
};
class TopNavigation extends React.Component {
    state = {
        value: 0,
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    render() {
        const { classes } = this.props;
        return (
            <Hidden only="sm">
            <div className={classes.root}>          
                    <Tabs
                    value={this.state.value}              
                    onChange={this.handleChange}
                    textColor="primary"
                    scrollButtons="off"
                    classes={{ root: classes.tabsRoot, indicator: classes.vanishIndicator }}
                >
                    <Tab component={Link} to="/Dashboard" label={<ListItemText disableTypography primary="Dashboard" />
                    } classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                    <Tab component={Link} to="/productline" label={<ListItemText disableTypography primary="Product Line" />
                    } classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                    <Tab component={Link} to="/flash" label={<ListItemText disableTypography primary="Flash Data" />
                    } classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                    <Tab component={Link} to="/Staff" label={<ListItemText disableTypography primary="Staff" />
                    } classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                    <Tab component={Link} to="/settings" label={<ListItemText disableTypography primary="Settings" />
                    } classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                    <Tab component={Link} to="/support" label={<ListItemText disableTypography primary="Support" />
                    } classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                    </Tabs>

                </div>
            </Hidden>
        );
    }
}

export default withStyles(styles)(TopNavigation);
