import { Box, Button, Card, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ReportIcon from '@material-ui/icons/AssessmentOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DownloadIcon from '@material-ui/icons/SystemUpdateAltSharp';
import { green500, orange500, red500 } from 'material-ui/styles/colors';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Slide
    
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useUploadHistory } from './UploadHistoryContextProvider';
import _ from 'lodash';



const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        margin: '10px',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold'

    },
    cardContent: {
        padding:'0px'
    },
    button: {
        margin: theme.spacing(1),
        color: theme.palette.grey[700],
        background: 'none',
        border: 'none',
        textShadow: 'none',
        boxShadow: 'none',
        '&:hover': {
            color: orange500,
            margin: theme.spacing(1),
            background: 'none',
            border: 'none',
            textShadow: 'none',
            boxShadow: 'none',
        },
        padding: "6px 4px",
        paddingLeft:"6px",
        fontSize: "10 px",
        "& label":{
            fontSize: "10 px"
        }
    },
    buttonIcon: {
        marginRight:'3px'
    },

    pos: {
        marginBottom: 12,
    },
    MuiCardHeader: {
        '&.content': {
            backgroundColor: 'Red'
        }
        
    },
    avatar: {
        backgroundColor: green500,
        '&.restored': {
            backgroundColor: theme.palette.green500,
        },
        '&.deleted': {
            backgroundColor: red500
        },
        '&.resolved': {
            backgroundColor: orange500
        }
    },
    cardHeaderContent: {
        width: '80%',
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        marginTop: 8,
        marginBottom: 0,
        wordWrap: 'break-word',
        maxWidth: '80%'
    },
    subheader: {
        fontSize: 14,
        color: theme.palette.grey[500],
        marginBottom: '0.875em',
    },
    
    subheader2: {
        fontSize: 18,
        color: theme.palette.grey[500],
        marginBottom: '0.875em',
        marginRight: 18,
        fontWeight: 'bold',

        '&.restored': {
            color: theme.palette.green500,
        },
        '&.deleted': {
            color: red500
        },
        '&.resolved': {
            color: orange500
        }

    },
    statLabel: {
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: 500,
        fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        margin: 0,
        textAlign: "center"
    },
    statValue: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 4,
        letterSpacing: '1px',
        textAlign: "center",
        '&.deleted': {
            color: red500
        },
        '&.resolved': {
            color: orange500
        }
    },
    statBoxAction: {
        justifyContent: 'flex-start',
        display: 'flex',
        marginTop: '10px',
        marginLeft: '10px'
    },
    statBoxIconLabel: {
        fontSize: 14
    },
    statBoxText: {
        justifyContent: 'flex-end',
        display: 'contents'
    },
    circularProgress: {
        margin: "0 10px"
    },
    downBtn: {
        padding: 0,
        marginLeft: "4px",
        "&:hover": {
            background: "none",
        }
    },
    statContainer: {
        display: 'flex',
        width:'100%'

    },
    statActionContainer: {
        flex: '2 1' ,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft:'10px'
    },

    statValueContainer: {
        flex: '1 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const IsolatedUploadHistoryItem = props => {
    const classes = useStyles();
    const {
        handleDelete,
        handleRestore,
        downloadOriginal,
        downloadHistoric,
        isDownloadOriginalInProgress,
        isDownloadHistoricInProgress,
        activeUploadId,
        isAdmin,
        dialogState,
        handleDialogAction,
        handleDialogClose,
        dialogContent,
        dialogProgress,
        hasError
    } = useUploadHistory();
  
    
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const upload=props.upload;
    
    const open = Boolean(anchorEl);

    

    const handleMenuOpen = (event, id) => {

        setAnchorEl(event.currentTarget);
        //const addToState = { [id]: true }
        //setMenuActive({ addToState })
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const sizes = { xs: 12, sm: props.single ? 12 : 6, md: props.single ? 12: 4 };

    return (

        <Grid item key={upload.uploadId} {...sizes}>
            <Card className={classes.root} {...sizes}>
                <CardHeader classes={{
                    content: classes.cardHeaderContent, // class name, e.g. `classes-nesting-label-x`
                }} 
                    avatar={
                        <Avatar aria-label="recipe" className={`${classes.avatar} ${upload.isDeleted ? "deleted" : (!_.isNil(upload.restoredFrom) ? "restored" : (upload.type === 2 ? "resolved" : ""))}`}>
                            {`${upload.type != null && upload.isDeleted ? "D" : (!_.isNil(upload.restoredFrom) ? "RC" : (upload.type === 2 ? "R" : "F"))}`}
                         {/*   {upload.uploadId}*/}
                        </Avatar>
                    }
                    action={
                        <Fragment >
                            {isAdmin && upload.totalRecords !== upload.failed && (<IconButton aria-label="settings" onClick={(e) => { handleMenuOpen(e, "upload-" + upload.uploadId) }}>
                                <MoreVertIcon />
                            </IconButton>)
                            }
                            {isAdmin && upload.totalRecords !== upload.failed && (<Menu id={"upload-" + upload.uploadId} key={"upload-" + upload.uploadId}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => { handleMenuClose("upload-" + upload.uploadId) }}
                            >
                                { upload.totalRecords!==upload.failed &&   upload.isDeleted !== null && (
                                    <MenuItem key="restore" onClick={() => {
                                        handleMenuClose("upload-" + upload.uploadId);
                                        handleRestore(upload.uploadId, upload.sourceFileName);
                                    }}>
                                        Restore

                                    </MenuItem>
                                )}

                                {upload.totalRecords !== upload.failed && upload.isDeleted === null && (
                                    <MenuItem key="delete" onClick={() => {
                                        handleMenuClose("upload-" + upload.uploadId);
                                        handleDelete(upload.uploadId, upload.sourceFileName);
                                    }}>
                                        Delete
                                    </MenuItem>
                                )}

                            </Menu>)}
                        </Fragment>
                    }
                    title={
                        <h3 className={classes.heading}>{upload.sourceFileName} </h3>
                    }
                    subheader={<div>
                        <span className={classes.subheaderLarge}>Id:<strong>{upload.uploadId}</strong></span>
                        <br />
                        <span className={classes.subheaderLarge}>Date:{upload.uploadedOn}</span>
                        <br />
                        <span className={classes.subheader}>User:{upload.user.email}</span>
                        <br />
                        <span className={classes.subheader}>Type:{upload.type === 2 ? "Resolved" : "Fresh"}</span>
                        
                        
                    </div>}
                />
                <CardContent p={0} className={classes.cardContent}>
                    <div style={{ "display": "flex", justifyContent: 'flex-end' }}>
                        <span className={`${classes.subheader2} ${upload.isDeleted ? "deleted" : (!_.isNil(upload.restoredFrom) ? "restored" : (upload.type === 2 ? "resolved" : ""))}` }>
                            {`${upload.isDeleted ? "Deleted" : (!_.isNil(upload.restoredFrom) ? "Restored" : "Original")}`}
                        </span>
                    </div>
                    <Divider p={0} light />

                </CardContent>
                <CardActions p={0} disableSpacing>
                    <Box display={'flex'} className={classes.statContainer}>
                        <div className={classes.statActionContainer}>
                            <Button disabled={activeUploadId === upload.uploadId && isDownloadOriginalInProgress}
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<DownloadIcon className={classes.buttonIcon} />}
                                onClick={() => {
                                    downloadOriginal(upload.batchId, upload.sourceFileName, upload.manufacturerId, upload.uploadId);
                                }}
                            >
                                Original {isDownloadOriginalInProgress && activeUploadId === upload.uploadId && <CircularProgress className={classes.circularProgress} size={16} />}
                            </Button>
                            <Button 
                                disabled={activeUploadId === upload.uploadId && isDownloadHistoricInProgress}
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<ReportIcon className={classes.buttonIcon} />}
                                onClick={() => {
                                    downloadHistoric(upload.batchId, upload.reportFileName ? upload.reportFileName : upload.sourceFileName, upload.manufacturerId, upload.uploadId);
                                }}
                            >
                                Report {isDownloadHistoricInProgress && activeUploadId === upload.uploadId && <CircularProgress className={classes.circularProgress} size={16} />}
                            </Button>
                        </div>
                        <div className={classes.statValueContainer}>

                            <Box p={1} flex={'auto'} >
                                <p className={classes.statLabel}>Total</p>
                                <p className={classes.statValue}>{upload.totalRecords}</p>
                            </Box>
                            <Box p={1} flex={'auto'} >
                                <p className={classes.statLabel}>{upload.isDeleted ? "Deleted" : "Saved"}</p>
                                <p className={classes.statValue} style={{ "color": "green" }}>{upload.success}</p>
                            </Box>
                            <Box p={1} flex={'auto'}  >
                                <p className={classes.statLabel}>{upload.isDeleted ? "Undeleted" : "Failed"}</p>
                                <p className={classes.statValue} style={{ "color": "red" }}>{upload.failed}</p>
                            </Box>
                        </div>


                    </Box>


                    
                </CardActions>
            </Card>
            <Dialog
                open={dialogState}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{dialogContent.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText component="div" id="alert-dialog-slide-description" >
                        {dialogContent.body}
                        <div>
                            <span className={`${classes.message} ${hasError ? classes.errorMessage : ""}`}>{dialogContent.message}</span>
                        </div>
                    </DialogContentText>
                    
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleDialogClose} className={classes.cancelButton} disabled={dialogProgress}>
                        Cancel
                    </Button>
                    <Button onClick={handleDialogAction} disabled={dialogProgress || hasError} color="primary">
                        {dialogContent.actionButtonTitle} {dialogProgress && <CircularProgress className={classes.circularProgress} size={16} />}
                    </Button>
                </DialogActions>
            </Dialog>
            
        </Grid>

    );
}


export default IsolatedUploadHistoryItem;
