import { Button, Card, CardHeader, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core/';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles,alpha } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MailIcon from '@material-ui/icons/Mail';
import PropTypes from "prop-types";
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { LINKS_PER_PAGE } from '../../constants';
import { handleActivateDeactivate, handleVerificationDataDownload } from '../../Helper';
import QueryHelper from '../../libs/QueryHelper';
import theme from '../../theme';
import PageTitles from '../admin/PageTitles';
import ProgressButton from '../buttons/ProgressButton';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import ModelSearch from "../ModelSearch";
import _ from 'lodash';

const fontprimary = "1rem";
const fontsecondary = "0.9rem";
const drawerWidth = 360;


const styles = {
    root: {
        padding: 0,
        width:'100%'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        background: theme.palette.grey[50],
    },
    cardinfo: {
        boxShadow: "none",
        background: "none",
        width: "auto",
        whiteSpace: "normal",
    },
    card: {
        boxShadow: "none",
        background: "none",
    },
    titleroot: {
        fontSize: "1.3rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    titleupload: {
        fontSize: "1rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    lightGray: {
        color: theme.palette.grey[900],
        marginLeft: "4px",
    },
    contentPaper: {
        margin: theme.spacing(2),
        background: theme.palette.common.white,
        border: "0px",
        //borderColor: theme.palette.common.borderColor, overflowX: 'auto',
        padding: '0px'
    },
    gridBox: {
        background: theme.palette.common.lightGrey,
        padding: "6px",
    },
    formLabel: {
        textTransform: "uppercase",
        letterSpacing: "1px",
        color: "#cfcfcf",
        margin: "0px",
        padding: "0px",
        fontSize: "1rem",
        marginRight: "10px",
    },
    AccessTime: {
        verticalAlign: "middle",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1) / 2,
    },
    rootCard: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: "1rem",
    },
    uploadFreq: {
        padding: theme.spacing(1),
        background: alpha(red[50], 1),
    },
    subCard: {
        fontSize: '14px',
        fontWeight: 'normal'
    },
    subTitle: {
        fontSize: fontsecondary,
    },
    span: {
        position: "absolute",
        top: 0,
        color: theme.palette.common.white,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        left: theme.spacing(1),
        lineHeight: "26px",
        fontSize: "1rem",
    },
    link: {
        color: theme.palette.primary.main,
        marginTop: "4px",
        fontSize: "0.8rem",
        display: "block",
    },
    timeupload: {
        fontSize: fontsecondary,
        marginLeft: "4px",
        color: theme.palette.grey[600],
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    exclamation: {
        background: red[500],
        width: 34,
        height: 34,
    },
    scannedbg: {
        background: alpha(blue[50], 0.3),
    },
    titlescan: {
        fontSize: "1.3rem",
    },
    primarytext: {
        fontSize: fontprimary,
    },
    secondarytext: {
        fontSize: fontsecondary,
    },
    inline: {
        display: 'inline',
    },
    alert: {
        background: theme.palette.primary.main,
        fontSize: "1rem",

    },
    paperwhite: {
        background: "#fff", width: drawerWidth,
        flexShrink: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    space: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    lbl: {
        display: "inline-block",
        minWidth: "62px",
        marginRight: 4,
        color: "#454647",
        lineHeight: "1.9em",
        fontFamily: 'NHaasGroteskDSPro-65Md'
    },
    error: {
        color: "red",
    },
    errorValue: {
        color: "red",
        marginLeft: "4px"
    },
    progress: {
        position: "relative",
        minWidth: 200,
    },
    linearColorPrimary: {
        height: 26,
        background: theme.palette.common.borderColor,
    },
    linearBarColorPrimary: {
        background: theme.palette.common.red,

    },
    secondary: {
        display: "inline",
        float: "left",
        marginRight: theme.spacing(1),
    },
    rootBlock: {
        display: "inline",
        padding: 0,
    },
    index: {
        paddingTop: 15,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: "1.3rem",
        verticalAlign: "top"
    },
    userId: {
        color: theme.palette.common.lightext,
        fontSize: "0.9rem",
    },
    button: {
        color: theme.palette.grey[500],
    },
    listsimple: {
        listStyle: "none",
        display: "inline-block",
        marginRight: "8px",
        marginTop: "8px",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: "1rem",

    },
    paperModel: {
        position: 'absolute',
        width: 300,
        minHeight: 100,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '15px 15px',
    },
    paperModelSupplier: {
        position: 'absolute',
        minWidth: 100,
        minHeight: 100,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '15px 15px',
    },
    chip: {
        margin: '20px 10px',
        textAlign: 'center',
        padding: '10px'
    },
    button2: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1) * 1,
        paddingRight: theme.spacing(1) * 1,

        '&:disabled': {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
        '&:hover': {
            '&:disabled': {
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    circleWhite: {
        color: theme.palette.common.white,
    },
    successRow: {
        borderLeft: '4px solid',
        borderLeftColor: theme.palette.success.main
    }
    ,
    failedRow: {
        borderLeft: '4px solid',
        borderLeftColor: theme.palette.error.main
    },
    row: {
        marginBottom: '5px'
    },

    nonVerified: {
        backgroundColor: '#f44336',
        border: '1px solid',
        borderColor: theme.palette.error.main,
        color: theme.palette.common.white,                
    },
    verified: {
        backgroundColor: '#6BBC6E',
        border: '1px solid #4CAF50',
        color: theme.palette.common.white,
    },    
    
    buttonGroup: {
        justifyContent:"flex-start",
    },

    appProviderlabel: {
        padding: '5px', backgroundColor: 'lightGray', 
    }
}
const CustomTableCell = withStyles(theme => ({
    body: {
        borderBottomColor: theme.palette.grey[200],
        fontSize: 16,
        whiteSpace: " nowrap",
        padding: "4px 16px 4px 16px"
    },


}))(TableCell);
const EmptyRowCustomTableCell = withStyles(theme => ({
    body: {  
        whiteSpace: " nowrap",        
    },


}))(TableCell);
class LogsHeader extends React.Component {


    render() {
        const { classes, } = this.props;
        return (
            <TableRow>
                {/*  {props.cols.map(col =>
                    <CustomTableCell key={col.name}>{col.header}</CustomTableCell>
                )} */}
                <CustomTableCell>#</CustomTableCell>
                <CustomTableCell>Brand Name</CustomTableCell>
                <CustomTableCell>Requester Info
                   {/* <br />
                    <span className={classes.subtext}>Address / ABN / Email / Phone</span>  */}
                </CustomTableCell>
                <CustomTableCell>
                    <span className={classes.subtext}>Additional Info</span>
                </CustomTableCell>
                <CustomTableCell>
                    Actions
                </CustomTableCell>            
            
            </TableRow>
        );
    }


}
const LHeader = withStyles(styles)(LogsHeader);


export { LHeader };


class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
        this.state = {
            pageNumber: 1, pageSize: LINKS_PER_PAGE,
            rows: [],
            page: 0,
            rowsPerPage: 4, open: false, filter: '', modelOpen: false, modelOpenSupplier: false, activeManufacturerId: 0, activeManufacturerDisplayName: '', requestType: "0",
            isLoading:false,loadingBatchId:null
        };

    
      
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onDownloadRequest = this.onDownloadRequest.bind(this);
        this.handleModelOpen = this.handleModelOpen.bind(this);
        this.handleModelOpenSupplier = this.handleModelOpenSupplier.bind(this);
        this.handleModelClose = this.handleModelClose.bind(this);
     
        this.getModalStyle = this.getModalStyle.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleModelOpen = (manufacturerId, name) => {
        this.setState({ "modelOpen": true, activeManufacturerId: manufacturerId, activeManufacturerDisplayName:name });
    };
    onDownloadRequest(data,onComplete) {

        
        let parentThis = this;
        handleVerificationDataDownload((data) => {
            onComplete();
            parentThis.handleModelClose();
        }, (error) => { onComplete();  parentThis.setState(error); parentThis.handleModelClose(); }, data.requestBatchId,data.type );
    }
    handleModelOpenSupplier = (manufacturerId, name) => {
        this.setState({ "modelOpenSupplier": true, activeManufacturerId: manufacturerId, activeManufacturerDisplayName: name });
    };
    onPageChange(newPage) {
        this.setState({
            pageNumber: newPage
        })
    }    

    getModalStyle() {
        const top = 50 -5;
        const left = 50 -5;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }
    handleModelClose = () => {
        this.setState({ "modelOpen": false, activeManufacturerId: null, activeManufacturerDisplayName: null });
    };
    handleModelCloseSupplier = () => {
        this.setState({ "modelOpenSupplier": false, activeManufacturerId: null, activeManufacturerDisplayName: null });
    };


    handleClick = (self, manufacturerid, userId, status, refetch) => {
        
        const newStatus = !status;
        this.buttonRef.current.innerText = "Loading";
        handleActivateDeactivate(() => { refetch(); this.buttonRef.current.innerText = newStatus ? "Active" : "De-active";  }, () => { refetch() }, manufacturerid, userId, newStatus );
    };
    handleClose = () => {
        this.setState({ open: false });
    };

 
    handleSearch(data) {
        this.setState({ filter: data });

    }

    _getQueryVariables() {
        const filter = this.state.filter;
        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const requestType = this.state.requestType;
        const skip = (page - 1) * page_size;
       
        const first = page_size;

        return { filter, skip, first, requestType:parseInt(requestType)}


    }

     handleChange(event) {
        //setValue(event.target.value);

         this.setState({ requestType: event.target.value });
    }


    render() {
        const { classes, } = this.props;

        const { requestType} = this.state;

        const logsQuery = QueryHelper().AllLogs();

        return (
            <Grid containers className={classes.root}>


                <PageTitles titles={"Verification request logs"} />
                <Paper elevation={0} className={classes.contentPaper}>                    
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            className={classes.gridBox}
                        >
                        <FormLabel className={classes.formLabel} component="label" >Request Type</FormLabel>
                        <FormControl component="fieldset">
                            <RadioGroup row aria-label="requestType" name="requestType" value={requestType} onChange={this.handleChange}>
                                <FormControlLabel value="0" control={<Radio />} label="All Requests" labelPlacement="end" />
                                <FormControlLabel value="2" control={<Radio />} label="Product" labelPlacement="end"/>
                                <FormControlLabel value="1" control={<Radio />} label="Installation" labelPlacement="end"/>
                                <FormControlLabel value="3" control={<Radio />} label="Serial" labelPlacement="end" />
                                <FormControlLabel value="4" control={<Radio />} label="Contains Comment" labelPlacement="end" />
                              </RadioGroup>
                            </FormControl>
                            <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} containerRequired={false} />
                            </Grid>
                    <Table>
                        <TableHead>
                            <LHeader />
                        </TableHead>

                        <Query query={logsQuery} variables={this._getQueryVariables()}  fetchPolicy="no-cache">


                            {({ loading, error, data }) => {


                                if (loading) return <FbLoader isTableBodyRequired={true} colSpan={10} loaderStyle={'emptycell'} />
                                if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} />
                                const listLogs = data.admin.allLogs.map((row, index) =>
                                (
                                    

                                    <Fragment>
                                        <TableRow className={classes.row } key={index}>
                                        <CustomTableCell scope="row" className={classes.index + " " + (row.status === 1 ? classes.successRow : classes.failedRow)}>
                                                
                                                <Card className={classes.cardinfo}>
                                                    <CardHeader
                                                        title={""}
                                                    subheader={
                                                            <span>
                                                                <p><label className={classes.lbl}>Log Id :</label><span className={classes.userId}>{row.productLogId}</span></p>
                                                                <p><label className={classes.lbl}>Serial :</label><span className={classes.userId}>{row.failedSerial}</span></p>
                                                                <p><label className={classes.lbl}>Batch Id :</label><span>{row.requestBatchId}</span></p>
                                                                <p>
                                                                <label className={classes.lbl}>Status :</label>                                                                
                                                                {row.status === 1 && (<Chip label="Verified" variant="outlined" size="small" className={classes.verified} />)}
                                                                {row.status !== 1 && (<Chip label="Not Verified" variant="outlined" size="small" className={classes.nonVerified} />)}
                                                                 
                                                                </p>
                                                            </span>
                                                        }
                                                        classes={{ title: classes.titleroot, root: classes.rootCard, subheader: classes.subCard }}
                                                    />
                                                </Card>


                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <Card className={classes.cardinfo}>
                                                    <CardHeader                                                        
                                                        subheader={<span>
                                                            <p><label className={classes.lbl}>Brand :</label><span className={classes.userId}>{row.requestedBrand}</span></p>
                                                            <p><label className={classes.lbl}>Model :</label><span>{row.requestedModel}</span></p>
                                                        </span>
                                                        }
                                                        classes={{ title: classes.titleroot, root: classes.rootCard, subheader: classes.subCard }}
                                                    />                                                    
                                                </Card>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <Card className={classes.cardinfo}>
                                                    <CardHeader
                                                        title={<p><span className={classes.userId}>{row.requesterName}</span></p>}
                                                        subheader={<span>
                                                            <p><label className={classes.lbl}>Abn :</label><span className={classes.userId}>{row.requesterAbn}</span></p>
                                                            <p><label className={classes.lbl}>Type :</label><span>{row.requesterType === 2 ? "Product"
                                                                : row.requesterType === 1 ? "Installation" : "Serial"}</span></p>
                                                            <p><label className={classes.lbl}>Time :</label><span>{row.requestedTime}</span></p>
                                                            <p><label className={classes.lbl}>App Provider :</label>
                                                                <Chip label={row.requesterAppName} size="small" color="primary" />
                                                            </p>
                                                        </span>
                                                        }
                                                        classes={{ title: classes.titleroot, root: classes.rootCard, subheader: classes.subCard }}
                                                    />
                                                </Card>
                                            </CustomTableCell>
                                            <CustomTableCell scope="row" className={classes.index}>


                                                <Card className={classes.cardinfo}>
                                                    <CardHeader                                                        
                                                        subheader={<span>
                                                            <p><label className={classes.lbl}>Latitude :</label><span className={classes.userId}>{row.latitude}</span></p>
                                                            <p><label className={classes.lbl}>Longitude :</label><span className={classes.userId}>{row.longitude}</span></p>
                                                            <p><label className={classes.lbl}>Parallel Import Comment :</label><span>{row.parralelImportComment}</span></p></span>
                                                        }
                                                        classes={{ title: classes.titleroot, root: classes.rootCard, subheader: classes.subCard }}
                                                    />
                                                </Card>

                                            </CustomTableCell>
                                          
                                            <CustomTableCell>
                                                <div>
                                                    
                                                    <ButtonGroup
                                                        orientation="vertical"
                                                        color="primary"
                                                        aria-label="vertical contained primary button group"
                                                        variant="outlined"                                                       
                                                    >
                                                        <ProgressButton
                                                            startIcon={<CloudDownloadIcon />}
                                                            title={"Download Request"}
                                                            data={{ "requestBatchId": row.requestBatchId, "type": 1 }}
                                                            onAction={(data, callback) => { this.onDownloadRequest(data, callback) }}
                                                            onClick={this.handleClose}
                                                            className={classes.buttonGroup}
                                                            classes={classes}
                                                        >
                                                            Request
                                                        </ProgressButton>

                                                        <ProgressButton
                                                        startIcon={<CloudUploadIcon />}
                                                        title={"Download Response"}
                                                        data={{ "requestBatchId": row.requestBatchId, "type": 0 }}
                                                        onAction={(data, callback) => { this.onDownloadRequest(data, callback) }}
                                                        onClick={this.handleClose}
                                                            classes={classes}
                                                            className={classes.buttonGroup}
                                                        >
                                                            Response
                                                        </ProgressButton>

                                                        
                                                        
                                                        <Button
                                                            startIcon={<MailIcon />}
                                                            onClick={this.handleClose}
                                                            className={classes.buttonGroup}
                                                        >
                                                            Response Payload
                                                        </Button>
                                                    </ButtonGroup>                                                    
                                                    {/*<Button classes={classes} title={"Download Request"} data={{ "requestBatchId": row.requestBatchId, "type": 1 }} onAction={(data, callback) => { this.onDownloadRequest(data, callback) }}></Button>
                                                    <Button classes={classes} title={"Download Response"} data={{ "requestBatchId": row.requestBatchId, "type": 0 }} onAction={(data, callback) => { this.onDownloadRequest(data, callback) }} onClick={this.handleClose}></Button>
                                                    <Button onClick={this.handleClose}></Button>*/}
                                                </div>
                                            </CustomTableCell>
                                          
                                        </TableRow>
                                        <TableRow>
                                            <EmptyRowCustomTableCell colSpan={5}> </EmptyRowCustomTableCell>
                                        </TableRow>
                                    </Fragment>
                                    ));
                                const result = (!_.isNil(data.admin.allLogs) && data.admin.allLogs.length > 0) ? listLogs : <FbEmptyResult isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} />;


                                //  const pagination_content = (data.productfeed.products !== 'undefined' && data.productfeed.products.length > 0 && data.productfeed.count > this.state.pageSize) ? <TableFooter ><TableRow ><FooterCell colSpan="10">{paging}</FooterCell ></TableRow ></TableFooter > : null;
                                return (<Fragment><TableBody>{result}</TableBody></Fragment>);


                            }}
                        </Query>
                    </Table>
                </Paper>

            
            </Grid>
        )
    }
}
Logs.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Logs);

