import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import theme from '../../theme';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';

const drawerWidth = 360;

const styles = {
    root: {
        display: "inline"
    },
    button: {
        boxShadow: "none",
        marginRight: theme.spacing(1),
    },
    btnCancel: {
        color: theme.palette.grey[700]
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    content: {
        paddingBottom: 0,
        overflowY: "initial"

    },
    space: {
        //paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    inputroot: {
        background: theme.palette.grey[100],
        "&:hover": {
            background: theme.palette.grey[200], borderBottom: 'none',
        },
        '&:after': {
            borderBottom: 'none',
        },
        '&:before': {
            borderBottom: 'none',
        },
        '&:focus': {
            borderBottom: 'none',
        },
    },
    paddingNone: {
        paddingBottom: 0,
        overflowY: "initial"
    },

    paperwhite: {
        background: "#fff",
    width: drawerWidth,
        flexShrink: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
}


class EditPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { right: false,}
    }
    toggleDrawer = (side, open) => () => {
        this.setState({ [side]: open });
    };

    render() {
        const { classes } = this.props
        const DialogTitle = withStyles(styles)(props => {
            const { children, classes } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={this.toggleDrawer('right', false)}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                )
        });
        return (
            <div className={classes.root}>
                <Button variant="contained" size="small" color="default" className={classes.button} onClick={this.toggleDrawer('right', true)} disableRipple>
                    Edit
                </Button>
                <Drawer
                    anchor="right"
                  
                    open={this.state.right} onClose={this.toggleDrawer('right', false)}
                    classes={{ paper: classes.paperwhite }}
                >
                    <DialogTitle id="alert-dialog-title">{"Edit details"}</DialogTitle>
                    <DialogContent className={classes.content}>
                        <Grid container spacing={16}>
                            <Grid item sm={12}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="name" >Company</InputLabel>
                                    <FilledInput id="name" type="text" value="SOLAR POLAR" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="phone">Office phone</InputLabel>
                                    <FilledInput id="phone" value="02 9365 2655" type="text" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>

                            </Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="abn">ABN</InputLabel>
                                    <FilledInput id="abn" value="xxx-xxxx" type="text" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="email">Email</InputLabel>
                                    <FilledInput id="email" value="solarpolar@sample.com" type="email" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="Address">Address</InputLabel>
                                    <FilledInput id="Address" value="BONDI BEACH 70 HALL STREET, BONDI" type="text" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <DialogActions className={classes.space}>
                            <Button variant="contained" onClick={this.toggleDrawer('right', false)} color="primary" >
                                Save
                        </Button>
                            <Button onClick={this.toggleDrawer('right', false)} color="default" className={classes.btnCancel} autoFocus>
                                Cancel
                        </Button>
                        </DialogActions>
                    </DialogContent>
                  
                </Drawer>
            </div>

        )
    }

}

export default withStyles(styles)(EditPage);
