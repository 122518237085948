import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    GET_FILTERED_INSTALLATIONS,
    GET_MANUFACTURERS,
    GET_STATES,
    GET_CITIES,
    GET_RETAILERS,
    GET_INSTALLERS
} from '../../../libs/stats_queries';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import Loader from './Loader';
import Error from './Error';
import { parse } from 'json2csv';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    chartContainer: {
        marginTop: theme.spacing(2),
    },
}));

const DetailView = () => {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [toDate, setToDate] = useState(new Date());
    const [manufacturerId, setManufacturerId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [retailerId, setRetailerId] = useState('');
    const [installerId, setInstallerId] = useState(null);

    const handleFromDateChange = date => {
        setFromDate(date);
    };

    const handleToDateChange = date => {
        setToDate(date);
    };

    const { loading: loadingManufacturers, data: dataManufacturers } = useQuery(GET_MANUFACTURERS);
    const { loading: loadingStates, data: dataStates } = useQuery(GET_STATES);
    const { loading: loadingCities, data: dataCities } = useQuery(GET_CITIES);
    const { loading: loadingRetailers, data: dataRetailers } = useQuery(GET_RETAILERS);
    const { loading: loadingInstallers, data: dataInstallers } = useQuery(GET_INSTALLERS);
    const { loading: loadingInstallations, error: errorInstallations, data: dataInstallations } = useQuery(GET_FILTERED_INSTALLATIONS, {
        variables: { fromDate, toDate, manufacturerId, stateId, cityId, retailerId, installerId }
    });

    if (loadingManufacturers || loadingStates || loadingCities || loadingRetailers || loadingInstallers || loadingInstallations) return <Loader />;
    if (errorInstallations) return <Error />;

    const exportToCSV = (data, filename) => {
        const csv = parse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container justify="flex-start" spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="from-date-picker"
                            label="From Date"
                            format="DD/MM/YYYY"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            className={classes.datePicker}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="to-date-picker"
                            label="To Date"
                            format="DD/MM/YYYY"
                            value={toDate}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            className={classes.datePicker}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="manufacturer-label">Manufacturer</InputLabel>
                            <Select
                                labelId="manufacturer-label"
                                id="manufacturer-select"
                                value={manufacturerId}
                                onChange={e => setManufacturerId(e.target.value)}
                            >
                                {dataManufacturers.admin.manufacturers.map(manufacturer => (
                                    <MenuItem key={manufacturer.manufacturerId} value={manufacturer.manufacturerId}>
                                        {manufacturer.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="state-label">State</InputLabel>
                            <Select
                                labelId="state-label"
                                id="state-select"
                                value={stateId}
                                onChange={e => setStateId(e.target.value)}
                            >
                                {dataStates.common.states.map(state => (
                                    <MenuItem key={state.stateId} value={state.stateId}>
                                        {state.stateName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="city-label">City</InputLabel>
                            <Select
                                labelId="city-label"
                                id="city-select"
                                value={cityId}
                                onChange={e => setCityId(e.target.value)}
                            >
                                {dataCities.admin.cityList.map(city => (
                                    <MenuItem key={city.cityId} value={city.cityId}>
                                        {city.cityName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="retailer-label">Retailer</InputLabel>
                            <Select
                                labelId="retailer-label"
                                id="retailer-select"
                                value={retailerId}
                                onChange={e => setRetailerId(e.target.value)}
                            >
                                { 
                                   
                                    dataRetailers.admin.reports.retailers.map(retailer => (
                                    <MenuItem key={retailer.retailerId} value={retailer.retailerId}>
                                        {retailer.retailerName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="installer-label">Installer</InputLabel>
                            <Select
                                labelId="installer-label"
                                id="installer-select"
                                value={installerId}
                                onChange={e => setInstallerId(e.target.value)}
                            >
                                {dataInstallers.admin.installers.map(installer => (
                                    <MenuItem key={installer.installerId} value={installer.installerId}>
                                        {installer.firstName} {installer.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.chartContainer}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" gutterBottom>
                                Installations Data
                            </Typography>
                            <BarChart
                                width={600}
                                height={300}
                                data={dataInstallations.admin.reports.filteredInstallations}
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="installationDate" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="totalRatedPower" fill="#8884d8" />
                            </BarChart>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className={classes.chartContainer}>
                        <Button variant="contained" color="primary" onClick={() => exportToCSV(dataInstallations.admin.reports.filteredInstallations, 'installations.csv')}>
                            Export CSV
                        </Button>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default DetailView;
