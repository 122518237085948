import React from 'react';
import styled from 'styled-components';
const FbLi = styled.li`
    margin: 1px;
    padding: 8px;
    color:#232323ba;
    background-color:#f7f7f7;
    cursor:pointer;
`;
const Suggestions = (props) => {
    const options = props.results.map((r, index) => (
        <FbLi key={index} onClick={()=>props.onSelect(r.Brand)}>
          {r.Brand}
      </FbLi>
  ))
  return <ul>{options}</ul>
}

export default Suggestions