import { withStyles } from '@material-ui/core/styles';
import React, { Component, Fragment } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { CompanyApi } from '../../constants';
import Suggestions from './Suggestions';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1) * 3,
    },
    rootCaption: {
        flexGrow: 1,
        marginTop: theme.spacing(1) * 1,
    },
    formControl: {
        width: "100%"
    },
    dense: {
        marginTop: theme.spacing(1) * 0,
        marginBottom: theme.spacing(1) * 0,
        paddingTop: theme.spacing(1) * 0,
    },
    notchedOutline: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    container: {
        height: '100%',
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(1) * 8,
        paddingRight: theme.spacing(1) * 8,
        width: 300,
        height: 50,
    },
    validText: {
        borderColor: "#41A500"
    }

});
class Search extends Component {
   
    constructor() {
        super();
        this.state = {
            query: '',
            results: [],complete:false
        }
        this.search = React.createRef();
        this.searchComponent = React.createRef();
        this.getInfo = this.getInfo.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }
    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        this.props.ValidatorForm.addValidationRule('isManufacturerAvaiable', (value) => {
            
            return this.props.isloading ? true : this.props.isManufacturerAvaiable(this.search.value);
        });
        this.props.ValidatorForm.addValidationRule('isManufacturerValid', (value) => {
            
            return this.props.isloading ? true : this.props.isManufacturerValid(this.search.value);
        });
    }
    getInfo() {
        this.setState({ complete: false});
        fetch(`${CompanyApi}?search=${this.state.query}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(res => {
            return res.json();
        }).then(res => {

            this.setState({
                results: res
            });
        })
            .catch(err => {
            });
    }

    handleInputChange(e){
      
        this.setState({
            query: this.search.value
        }, () => {
            if (this.state.query && this.state.query.length > 1) {
               // if (this.state.query.length % 2 === 0) {
                    this.getInfo()
                //}
            } else if (!this.state.query) {
            }
        })
    }
    onSelect(data) {
        
        this.setState({
            query: data
        });
        this.search.value = data;
        this.handleBlur();
        ////if (this.search.isValid) {
            this.props.updateCompanyName(this.search.value);
      ///  }
     
       
    }
    handleBlur() {
       
        this.setState({ complete: true, query: this.search.value });
        this.searchComponent.validate(this.search.value);
      
    }
    render() {
       // const { classes } = this.props;
        const requiredMessage = "";
        const suggestions = this.state.complete !== true ? <Suggestions results={this.state.results} onSelect={this.onSelect} /> : null;
        return (
                <Fragment>
                <TextValidator 
                    id="CompanyName" name="CompanyName" onChange={this.handleInputChange} 
                    label="Company Name*" autoComplete="off"
                    placeholder="Company"
                    fullWidth={true}
                    margin="normal"
                    variant="outlined"
                    value={this.state.query}
                    ref={scomp => (this.searchComponent = scomp)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    //className={classNames(classes.dense)}
                    inputRef={input => (this.search = input)}
                    validators={['isManufacturerAvaiable','isManufacturerValid','required']}
                    errorMessages={['Manufacturer is already registered, Please use forgot password to reset your password','Manufacturer name does not match with CEC list',requiredMessage]}
                />

                {suggestions}
            </Fragment>
          
        )
    }
}
export default withStyles(styles)(Search);

