import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { green } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import classNames from 'classnames';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Mutation } from 'react-apollo';
import theme from '../../theme';
import { ResolveModeConstant, Role_Admin, Role_DISTRIBUTOR, Role_MANUFACTURER, Role_STAFF, Role_DISTRIBUTOR_STAFF } from '../../constants';
import UserProfile from '../auth/UserProfile';
export const SAVE_SPREADSHEET = gql`
mutation product($product: SpreadSheetInput!,$manufacturerId: Int) {
  importProduct(product: $product,manufacturerId:$manufacturerId)
}
`;

const styles = {
    root: {
        flexGrow: 1,
    },
    processContainer: {
        width: "75%",
        marginRight: "auto",
        marginTop: "36px",
        marginLeft: "auto",
        textAlign:"center",
    },
    typoColor: {
        color: theme.palette.common.white,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize:"1rem",
    },
    typoColorSuccess: {      
        marginTop: theme.spacing(1) * 3,

    },
    buttonLarge: {
        minHeight: 52,
        minWidth: 200,
        marginTop: theme.spacing(1) * 1,    
    },
    cssGreen: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    cssTextGreen: {
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    linearColorPrimary: {
        backgroundColor: green[50],
       height:6
    },
    linearBarColorPrimary: {
        backgroundColor: green[500],
    },
};


class SaveSheetContainer extends React.Component {

    constructor(props) {
        super(props);
        this.prepareMutationVariable = this.prepareMutationVariable.bind(this);
        this.startSavingData = this.startSavingData.bind(this);
        this.saveDataComplete = this.saveDataComplete.bind(this);
        this.state = { isSaving: false, isComplete: false, buffer: 10, title:0 }
        this.interval = null;
    }
    prepareMutationVariable() {
        
        var data = this.props.data;
        const { requiredFields, mode } = this.props;
        let type = 1;
        if (mode !== undefined && mode === ResolveModeConstant) {
            type = 2;
        }
        if (data == null) { return null; }
        var firstSpreadSheet = data[0];

        const role = UserProfile.getRole();

        const manufacturerId = UserProfile.getManufacturerId();

        let product = {
            "product": {
                "originalName": firstSpreadSheet.originalName, "systemName": firstSpreadSheet.systemName , "index": firstSpreadSheet.index, "sheets": [], "batchId": firstSpreadSheet.batchId, "type": type
            }
        };
        
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: product["manufacturerId"] = parseInt(manufacturerId); break;
            case Role_Admin:
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: break;
        }

        const sheets = firstSpreadSheet.sheets.map((sheet) => {
            if (sheet.header !== null && sheet.header.columns !== null && sheet.header.columns.length > 0) {
                sheet.header.columns.forEach((cell)=>{
                    var found = requiredFields.find((f) => {
                        return f.index === cell.columnIndex ;
                    });
                    if (found) {
                        cell.columnType = found.columnType;
                    }
                });
            }
          

            return { "name": sheet.name, "header": sheet.header,"enabled":sheet.enabled }
        });;
        product.product.sheets = sheets;
        return product;
    }

    startSavingData() {
        this.setState({ isSaving: true });
        var data = this.props.data;
        if (data == null) { return null; }
        this.props.startSavingData();
        return true;
    }
    saveDataComplete(data) {
      
        this.props.saveDataComplete(data);
  
      //  this.setState({ isSaving: false,isComplete:true });
    }
  
    render() {
       
       const { isAllRequiredFieldsMapped, classes, totalRows, isSaving,
            isComplete, isReading, isWriting, writeProgress, readProgress, idx,mode
             } = this.props;
        const percentage = isReading ? (readProgress / totalRows) * 100
            : (writeProgress / totalRows) * 100;
        const completed1 = isWriting || isComplete ? 100 : (isSaving ? percentage : 0);
        const completed2 = isComplete ? 100 : isSaving && isWriting ? percentage : 0;//     isSaving && isReading && !isWriting && !isComplete ? 0 : (isWriting && !isComplete) ? percentage : 100;
        const completed3 = isComplete ? 100 : 0;

       
        const containerClasses = classNames({
            [classes.processContainer]: true,
            "process-btn": true
        });
        const upload_success_message = mode !== undefined && mode === ResolveModeConstant ? "Serials resolved succesfully" : "Flash data imported succesfully.";
        const title = isSaving ? <Fragment></Fragment> : !isSaving && isComplete ? <Typography className={classes.typoColorSuccess} variant="subtitle1" >{upload_success_message}</Typography> : <Typography className={classes.typoColor}>Process Now</Typography>;
        const disabledProcessNowButton = (<Button variant="contained" size="large" component="button" color="primary" classes={{ root: classNames(classes.buttonLarge) }} {...{ disabled: true }} >{title} </Button>);

        if (!isAllRequiredFieldsMapped) {
            return (<div className={containerClasses} >
                {disabledProcessNowButton}
            </div>); }
        //const { isSaving, isComplete } = this.state;
     
     
        const steps =
            [{ label: "Preparing", progress: completed1, isOptional: false },
                { label: "Processing", progress: completed2, isOptional: false },
                { label: "Persisting", progress: completed3, isOptional: false },
                { label: "Done", progress: null, isOptional: true }];
        const stepper = <Grid container direction="row" className={classes.root} justify="space-around" alignItems="center" >
            {steps.map((label, index) => {
                //const props = {};
                
                const cssGreenClasses = classNames({
                    [classes.cssGreen]: (label.progress === 100 || (label.isOptional===true && isComplete))
                }); 

                const cssTextGreenClasses = classNames({
                    [classes.cssTextGreen]: (label.progress === 100 || (label.isOptional === true && isComplete))
                });
                return (<Fragment key={"savesheet" + idx}  >
                    <Grid item  key={label.label} >
                        {/*<Chip icon={<DoneIcon className={label.progress === 100 ? classes.cssTextGreen: null} />} variant="outlined"
                            label={<span>{label.label}</span>} /> */}
                        <Chip avatar={<Avatar className={cssGreenClasses}><DoneIcon className={cssTextGreenClasses} /></Avatar>} variant="outlined"
                            label={<span>{label.label}</span>} /> 
                    </Grid>
                    {label.progress == null  ? null : <Grid item key={index}  className={classes.root} >
                            <div >
                            <LinearProgress variant="determinate" value={label.progress} classes={{
                                colorPrimary: classNames(classes.linearColorPrimary),
                                barColorPrimary: classNames(classes.linearBarColorPrimary),
                                }} />
                            </div>
                        </Grid> }
                    
                    </Fragment>
                );
            })}
        </Grid>
        const mutation = <Mutation mutation={SAVE_SPREADSHEET} onCompleted={(data) => this.saveDataComplete(data)} >
            {(saveData, { data, loading }) => {
                return (
                    <Fragment>
                        <Button variant="contained" size="large" component="button" color="primary" onClick={(e) => this.startSavingData() && saveData({ variables: this.prepareMutationVariable() })} classes={{ root: classNames(classes.buttonLarge) }} >
                            {title}
                        </Button>
                    </Fragment>)
            }}
        </Mutation>;
        if (!isSaving && !isComplete) {
            return (<div className={containerClasses} >
                {mutation} 
            </div>);
        }
        else if (isSaving && !isComplete) {    
   
            return (<div className={containerClasses}>    
                { stepper }
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        {title}
                    </Grid>
                 </Grid>
            </div>);
        }
        else if (!isSaving && isComplete) {
            return (<div className={containerClasses} >
                {stepper}
            </div>);
        }

       
    }
}
SaveSheetContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SaveSheetContainer); 