import { LogoPath, ProfilePath, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF } from '../../constants';
import _ from'lodash';
var UserProfile = (function () {


    var getFirstName = function () {
        var fname = localStorage.getItem('first_name');
        return _.isNil(fname) ? '':  fname ;
    };
    
    var setFirstName = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('first_name', value);
    };


    var getFullName = function () {
        return getFirstName() + ' ' + getLastName();
    };
    var getLastName = function () {
        var lname = localStorage.getItem('last_name');
        return !_.isNil(lname) ? lname : '';
    };

    var setLastName = function (value) {
         if(_.isNil(value)) return;
         localStorage.setItem('last_name',value);
    };

    var getDisplayName = function () {
        return  localStorage.getItem('display_name');
    };

    var setDisplayName = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('display_name',value);
    };

    var getDistributor = function () {
        return  localStorage.getItem('is_distributor');
 
    };

    var setDistributor = function (value) {
        if(_.isNil(value)) return;
         localStorage.setItem('is_distributor',value);
    };

    var getManufacturerId = function () {
        return localStorage.getItem('manufacturer_id');
    };
    var isDistributorStaffWithoutManufacturer = function () {
        return localStorage.getItem('dstaff_unmapped') === "true";
    };

    var setManufacturerId = function (value) {
        if(_.isNil(value)) return;
         localStorage.setItem('manufacturer_id',value);
    };
    var setIsDistributorStaffWithoutManufacturer = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('dstaff_unmapped', value);
    };
    var getUploadFrequency = function () {
        var freq = localStorage.getItem('upload_frequency');
        return !_.isNil(freq) ? freq : "";
    };

    var setUploadFrequency = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('upload_frequency',value);
    };
    var getAddress = function () {
      return  localStorage.getItem('address');
    };

    var setAddress = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('address', value);
    };

    var getGooglePlaceId = function () {
       return localStorage.getItem('google_place_id');
    };

    var setGooglePlaceId = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('google_place_id', value);
    };

    var getLogo = function () {
        return localStorage.getItem('logo');
    };

    var setLogo = function (value) {
        if(_.isNil(value)) return;
         localStorage.setItem('logo',value);
    };

    var getProfilePhoto = function () {
        return localStorage.getItem('profile_photo');
    };

    var setProfilePhoto = function (value) {
        if(_.isNil(value)) return;
         localStorage.setItem('profile_photo',value);
    };

    var getPhone = function () {
        return localStorage.getItem('phone');
    };

    var setPhone = function (value) {
        
        localStorage.setItem('phone', !_.isNil(value) ? value :'');
        // Also set this in cookie/localStorage
    };

    var getUserId = function () {
     //   return userid;    // Or pull this from cookie/localStorage
        return localStorage.getItem('userid');
    };

    var setUserId = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('userid',value);
        // Also set this in cookie/localStorage
    };
    var getEmail = function () {
        return localStorage.getItem('email');
    };

    var setEmail = function (value) {
        if(_.isNil(value)) return;
         localStorage.setItem('email',value);
        // Also set this in cookie/localStorage
    };



    var getProfileImageUrl = function () {
        return ProfilePath +( _.isNil(getProfilePhoto())  ? "user.png" : getProfilePhoto()) ;
    };

    var getLogoImageUrl = function () {
        return LogoPath + getLogo();
    };
    var getDefaultLogoImageUrl = function () {
        return LogoPath + "formbay-logo.png";
    };
    var isStaff = function () {
        var item = localStorage.getItem('staff');
        return !_.isNil(item) && JSON.parse(item);
    };

    var setIsStaff = function (value) {
        if(_.isNil(value)) return;
        localStorage.setItem('staff', value);
        // Also set this in cookie/localStorage
    };

    var getRole = function () {
        return localStorage.getItem('role');
        
    };


    var isAdmin = function () {
        return localStorage.getItem('role') ===Role_Admin;

    };

    var isDistributor = function () {
        return localStorage.getItem('role') === Role_DISTRIBUTOR || localStorage.getItem('role') === Role_DISTRIBUTOR_STAFF; //TODO : affirm this change
    };

    var setRole = function (value) {
        localStorage.setItem('role', value);
    };
    var clearProfile = function () {

        localStorage.removeItem('email');
        localStorage.removeItem('userid');
        localStorage.removeItem('phone');
        localStorage.removeItem('profile_photo');
        localStorage.removeItem('logo');
        localStorage.removeItem('google_place_id');
        localStorage.removeItem('address');
        localStorage.removeItem('upload_frequency');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('display_name');
        localStorage.removeItem('is_distributor');
        localStorage.removeItem('manufacturer_id');
        localStorage.removeItem('staff');
        localStorage.removeItem('admin');
        localStorage.removeItem('role');
        localStorage.removeItem('dstaff_unmapped');

    }

    return {
        setRole: setRole, getRole: getRole, isAdmin: isAdmin, isDistributor: isDistributor,
        getDefaultLogoImageUrl: getDefaultLogoImageUrl,
        isStaff: isStaff, setIsStaff: setIsStaff,
        clearProfile:clearProfile,
        getProfileImageUrl: getProfileImageUrl, getLogoImageUrl: getLogoImageUrl,
        getFullName: getFullName,
        getFirstName: getFirstName,
        setFirstName: setFirstName,
        getLastName: getLastName,
        setLastName: setLastName, getUserId: getUserId, setUserId: setUserId, getEmail: getEmail, setEmail: setEmail,
        getPhone: getPhone, getDisplayName: getDisplayName, getDistributor: getDistributor, getManufacturerId: getManufacturerId, isDistributorStaffWithoutManufacturer: isDistributorStaffWithoutManufacturer,  getUploadFrequency: getUploadFrequency, getAddress: getAddress, getGooglePlaceId: getGooglePlaceId, getLogo: getLogo, getProfilePhoto: getProfilePhoto,
        setPhone: setPhone, setDisplayName: setDisplayName, setDistributor: setDistributor, setManufacturerId: setManufacturerId, setIsDistributorStaffWithoutManufacturer: setIsDistributorStaffWithoutManufacturer, setUploadFrequency: setUploadFrequency, setAddress: setAddress, setGooglePlaceId: setGooglePlaceId, setLogo: setLogo, setProfilePhoto: setProfilePhoto
    }

})();

export default UserProfile;