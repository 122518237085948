import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, FormControl, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams } from 'react-router-dom';
import { AddSupplier as SupplierSaveEndpoint , CheckUsername } from '../../constants';
import { withStyles } from '@material-ui/core/styles';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthService from '../AuthService';
import QueryHelper from '../../libs/QueryHelper';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useRef } from 'react';
const styles = {
    formControl: {
        width: "100%",
    }
}
const SupplierSearch = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState([]);
    const [open, setOpen] = React.useState(false);
    
    const query = QueryHelper().AdminWeeklySupplierList(searchValue);
    const [supplierQuery, { loading, error, data }] = useLazyQuery(query);
 
 
 
    
    const handleSearchChange = (event, value) => {
        props.onAutoCompleteChange(value);
    };
    const handleInputChange = (event) => {
        if (event?.target??false) {
            setSearchValue(event.target.value);
            supplierQuery({ variables: { filter: searchValue } });
        }
        
    }
 
    return (
        <Autocomplete
            name="suppliers"
            options={data?.admin?.weeklySuppliers ?? []}
            loading={loading}
            open={open}
            onOpen={() => {

                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option.importerName}
            onInputChange={handleInputChange}
            onChange={handleSearchChange}
            renderInput={(params) => (
                <TextField {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    label="Supplier"
                    required />
            )}
        />
    );
}
const isUserNameAvaiable = async (filter) => {

    if (filter !== '') {
        const data = await (await fetch(CheckUsername + filter)).json();
        return !data.status;
    }
    else {
        return true;
    }
}

const AddSupplier = (props) => {
    const { classes } = props;
    const formRef = useRef();
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState([]);
    const [formdata, setFormdata] = useState({});
    ValidatorForm.addValidationRule('isUserNameAvailable', async (value) => {
      
        if (value !== '') {
            const data = await (await fetch(CheckUsername + value)).json();
            return !data.status;
        }
        else {
            return true;
        }
    });
    
    const onAutoCompleteChange = (value) => {
      
        setSearchResult(value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        saveSupplier();
    };
    const handleChange=(e)=> {

        setFormdata({ ...formdata, [e.target.name]: e.target.value });
    }
    const saveSupplier = () => {
        setLoading(true);
        const token = new AuthService().getToken();
        fetch(SupplierSaveEndpoint, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Username: formdata.username,
                Password: formdata.password,
                FirstName: formdata.firstName,
                LastName: formdata.lastName,
                Email: formdata.email,
                AbnNo: searchResult.importerABN,
                Name: searchResult.importerName
            })
        }).then(res => {
            if (!res.ok) {
                if (res.status !== 400) {
                    throw res;
                }
            }
            return res.json();

        }).then(res => {
            if (!res.status) {
                const mappedErrors = res.map(item => { return `${item.code},${item.description}` });
                setErrors(mappedErrors);
                setTimeout(() => { setErrors([]); }, 10000);
              
            }
            else {

             setMessage("Supplier added successfully");
                setTimeout(() => { setMessage(""); }, 10000);
                formRef.current.resetValidations();
                setFormdata({});
                setSearchResult([]);
            }
            setLoading(false);

        }).catch(err => {
            setErrors([err.message]);
            setTimeout(() => { setErrors([]); }, 10000);
            setLoading(false);
        });

    }


    return (
        <ValidatorForm ref={formRef} instantValidate={true} autoComplete="off" onError={errors => console.log(errors)}
            onSubmit={handleSubmit}>

      
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SupplierSearch onAutoCompleteChange={onAutoCompleteChange} />
                    <TextField
                        label="Importer Name"
                        value={searchResult?.importerName ?? ''}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        label="Importer ABN"
                        value={searchResult?.importerABN ?? ''}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item xs={6}>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextValidator autoComplete="off" onChange={(e) => handleChange(e)}
                            name="firstName"
                            label="*First Name"
                            placeholder="First Name"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            value={formdata?.firstName??''}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextValidator autoComplete="off" onChange={(e) => handleChange(e)}
                            name="lastName"
                            label="*Last Name"
                            placeholder="Last Name"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            value={formdata?.lastName ?? ''}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextValidator autoComplete="off" onChange={(e) => handleChange(e)}
                            name="email"
                            label="*Email"
                            placeholder="Email"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            value={formdata?.email ?? ''}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextValidator autoComplete="off" onChange={(e) => handleChange(e)}
                            name="username"
                            label="*UserName"
                            placeholder="UserName"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            value={formdata?.username ?? ''}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <TextValidator autoComplete="off" onChange={(e) => handleChange(e)}
                            name="password"
                            label="*Password"
                            placeholder="Password"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            value={formdata?.password ?? ''}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                    </FormControl>
                    <Button type="submit" variant="contained" color="primary">
                        Register &nbsp;&nbsp; {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    </Button>
                    {message && (
                        <p>{message}</p>
                    )}
                    {errors && (
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

export default withStyles(styles)(AddSupplier);