import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { LINKS_PER_PAGE, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF, SERIAL_TYPE } from '../../../constants';
import QueryHelper from '../../../libs/QueryHelper';
import theme from '../../../theme';
import UserProfile from '../../auth/UserProfile';
import FbEmptyResult from '../../common/FbEmptyResult';
import FbError from '../../common/FbError';
import FbLoader from '../../common/FbLoader';
import Pagination from "../../common/Pagination";
import ModelSearch from "../../ModelSearch";
import SerialHeader from '../serials/AvailableSerialHeader';
import SerialRow from "../serials/AvailableSerialRow";

const styles = {

    table: {
        //borderTopWidth: "1px",
        //borderLeftWidth: "1px",
        //borderRightWidth: "1px",
        //borderColor: theme.palette.grey[300],  
    },
    title: {
        textTransform: "uppercase"
    },
    Grid: {
        backgroundColor: theme.palette.grey[50],
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.grey[200],
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        // borderBottom: '1px solid #e8e8e8',
        minHeight: 49,
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    tableroot: {
        width: "100%",
        overflowX: "auto",
    },
    paperTable: {
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,
        minHeight: "calc(100vh - 470px)",
        width: "100%",
        overflowX: 'auto',
        background: theme.palette.common.white,
    }
};
const FooterCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);
class DetailAvailableTab extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = { pageNumber: 1, pageSize: LINKS_PER_PAGE };
        this.onPageChange = this.onPageChange.bind(this);
    }
    onPageChange(newPage) {
        this.setState({
            pageNumber: newPage
        })
    }
    //shouldComponentUpdate(nextProps, nextState) {
    //    if (this.state.input == nextState.input) {
    //        return false;
    //    }
    //}
    handleSearch(data) {
        this.setState({
            filter: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }
    _getQueryVariables(role, manufacturerId) {

        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const skip = (page - 1) * page_size;
        const id = this.props.Model.productId;
        const first = page_size;
        const filter = this.state.filter;
        const type = SERIAL_TYPE.PENDING;
        // const orderBy = isNewPage ? 'createdAt_DESC' : null
         
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { id, manufacturerId, type, filter, first, skip };
            case Role_Admin:
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { id, type, filter, first, skip };
        }

    }
    _getData(data, role) {
        //adminView ? data.admin.products : data.productfeed.products
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { productSerialCount: data.supplier.product.serialCount, productSerials: data.supplier.product.productSerials };
            case Role_Admin:
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { productSerialCount: data.productfeed.product.serialCount, productSerials: data.productfeed.product.productSerials };
        }
    }
    render() {
        const { classes } = this.props;
        const role = UserProfile.getRole();
        const query = QueryHelper().ProductAvailableSerialsByProductId();
        const manufacturerId = UserProfile.getManufacturerId();
        
        return (
            <Fragment>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.Grid}
                >
                    <Grid item></Grid>
                    <Grid item>
                        <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} />
                    </Grid>
                </Grid>
                <div className={classes.table.root}>
                    <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                        <Table className={classes.table}>
                            <TableHead>
                                <SerialHeader />
                            </TableHead>
                            <Query query={query} variables={this._getQueryVariables(role, manufacturerId)}  >
                                {({ loading, error, data }) => {
                                    if (loading) return <FbLoader isTableBodyRequired={true} colSpan={3} loaderStyle={'emptycell'} />
                                    if (error) return <FbError isTableBodyRequired={true} colSpan={3} errorStyle={'emptycell'} />

                                    const { productSerialCount, productSerials } = this._getData(data, role);

                                    const paging = (<Pagination Prefix="serialFailed" TotalRecords={productSerialCount} PageSize={this.state.pageSize}
                                        PageNumber={this.state.pageNumber} ShowPreviousNext={true} onPageChange={this.onPageChange} ></Pagination>);
                                    const listSerials = productSerials.map((serial, index) =>
                                        (
                                            <SerialRow key={index} serial={serial} index={index} />

                                        ));
                                    const result = (productSerials !== 'undefined' && productSerials.length > 0) ? listSerials : <FbEmptyResult isTableBodyRequired={false} colSpan={3} errorStyle={'emptycell'} />;

                                    const pagination_content = (productSerials !== 'undefined' && productSerials.length > 0
                                        && productSerialCount > this.state.pageSize) ? <TableFooter><TableRow><FooterCell colSpan="10">{paging}</FooterCell></TableRow></TableFooter> : null;

                                    return (<Fragment><TableBody>{result}</TableBody>{pagination_content}</Fragment>);
                                }}
                            </Query>
                        </Table>
                    </Paper>
                </div>
            </Fragment>


        );

    }
}

export default withStyles(styles)(DetailAvailableTab); 
