import React, { useState, useCallback, useImperativeHandle, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Paper,
    Slide,
} from '@material-ui/core';
import ModelSearch from "../ModelSearch";
import classNames from 'classnames';
import QueryHelper from '../../libs/QueryHelper';
import { LINKS_PER_PAGE, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../constants';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from '../common/Pagination';
import IsolatedUploadHistoryItem from './UploadHistoryNew';
import UserProfile from '../auth/UserProfile';
import theme from '../../theme';


import { UploadHistoryProvider } from './UploadHistoryContextProvider';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
    root: {
        height: "100%"
    },
    tableroot: {
        width: "100%"
    },
    paperBorder: {
        borderColor: theme.palette.common.borderColor,
        border: "1px solid",
        background: theme.palette.common.white,
        lineHeight: 1.5,
        minHeight: "calc(100vh - 260px)",
        [theme.breakpoints.down('sm')]: {
            border: "none",
        },
    },
    dialogFileName: {
        color: theme.palette.common.blue,
        fontSize:'18px'
    },
    dialogContentContainer: {
        color: theme.palette.common.black
    },
    message: {
        fontSize: '16px',
        color: theme.palette.common.green
    },
    errorMessage: {
        fontSize: '16px',
        color: theme.palette.common.red
    },
    subheaderLarge: {
        fontSize: 18,
        color: theme.palette.grey[900],
        marginBottom: '0.875em',
    },
    dialogAction: {
        marginRight: '15px',
        padding:'18px'
    },
    cancelButton: {
        color: theme.palette.grey[600]
    },
    circularProgress: {
        margin: "0 10px"
    },
    dialogFileInfo: {
        margin:'15px'
    },
    legends: {
        padding: '15px',
        fontSize: '1.3em',
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'flex-end',
        justifyContent:'flex-end'
    }
};
const UploadHistoryDataContainer = React.forwardRef(({ classes, filter, manufacturerId: mid }, ref) => {

    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = LINKS_PER_PAGE;
    const getManufacturerId = () => (
        Role_Admin === UserProfile.getRole() ? mid : UserProfile.getManufacturerId()
    );
    const onPageChange = useCallback((newPage) => setPageNumber(newPage), []);

    useImperativeHandle(ref, () => ({
        setOnPage: onPageChange,
    }));
    const getQueryVariables = () => {
        const role = UserProfile.getRole();
        const manufacturerId = getManufacturerId();

        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR:
            case Role_Admin:
                return { manufacturerId: parseInt(manufacturerId), filter, skip: (pageNumber - 1) * pageSize, first: pageSize };
            default:
                return { filter, skip: (pageNumber - 1) * pageSize, first: pageSize };
        }
    };

    const getData = (data) => {
        const role = UserProfile.getRole();

        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR:
                return {
                    uploadHistoryCount: data.supplier.historyCount || 0,
                    uploadHistory: data.supplier.history,
                };
            case Role_Admin:
                return {
                    uploadHistoryCount: data.admin.historyCount || 0,
                    uploadHistory: data.admin.history,
                };
            default:
                return {
                    uploadHistoryCount: data.manufacturer.historyCount || 0,
                    uploadHistory: data.manufacturer.history,
                };
        }
    };


    const { loading, error, data, refetch } = useQuery(QueryHelper().History(), {
        variables: getQueryVariables(),
        fetchPolicy: "no-cache"
    });

    if (loading) return <FbLoader loaderStyle="center" />;
    if (error) return <FbError errorStyle="default" message="No upload history available" />;

    const { uploadHistoryCount, uploadHistory } = getData(data);;
    return ( uploadHistoryCount > 0 ? (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
               <UploadHistoryProvider refetch={refetch} classes={classes} manufacturerId={mid}>
                {uploadHistory.map((upload) => (

                    <IsolatedUploadHistoryItem key={upload.uploadId} upload={upload} />

                ))}
                    </UploadHistoryProvider>
                </Grid >
            </Grid >
            <Grid item xs={ 12}>
            <Pagination
                Prefix="products"
                TotalRecords={uploadHistoryCount}
                PageSize={pageSize}
                PageNumber={pageNumber}
                ShowPreviousNext={true}
                onPageChange={onPageChange}
            />
              </Grid >
        </Grid>
    ) : <FbEmptyResult />);


});
const UploadHistoryContainer = ({ classes, manufacturerId }) => {
    
    const [filter, setFilter] = useState(null);
    const childRef = useRef();
    const handleSearch = useCallback((data) => {
        setFilter(data);
        if (childRef.current) {
            childRef.current.setOnPage(1);
        }
        
    }, []);
    

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SPV | History</title>
            </Helmet>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h1" gutterBottom>
                        History
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.root}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.Grid}>
                    <Grid item>
                        <ModelSearch filterText={filter} searchHandler={handleSearch} />
                    </Grid>
                </Grid>
                <div>
                    <Paper square elevation={0} className={classNames(classes.paperBorder, classes.tableroot)}>
                        <UploadHistoryDataContainer classes={classes } manufacturerId={manufacturerId} ref={childRef} filter={filter} />
                        
                    </Paper>
                </div>
            </div>
        </>
    );
};

export default withStyles(styles)(withRouter(UploadHistoryContainer));