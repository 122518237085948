import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AddProductLineHeaderTitle} from '../StringConstants'
const styles = {
    root: {
        flexGrow: 1,
    },
}
class Addwarranty extends React.Component {
    state = {
        value: 'left',
        background: '#fff',
    };
    handleChange = event => {
        this.setState({ value: event.target.value });
    };
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" >
                            {AddProductLineHeaderTitle}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withStyles(styles)(Addwarranty);
