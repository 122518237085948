import { orange } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import gql from 'graphql-tag';
import React, { Fragment } from 'react';
import { Mutation } from 'react-apollo';
import { Helmet } from "react-helmet";
import { MANUFACTURER_ID_CanadianSolarInc, MANUFACTURER_ID_SunPowerCorporation, ResolveModeConstant, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../constants';
import Customicon from '../../Customicon';
import theme from '../../theme';
import UserProfile from '../auth/UserProfile';
import FbError from "../common/FbError";
import FbLoader from '../common/FbLoader';
export const UPLOAD_FILE = gql`
mutation uploadFile($type: Int,$manufacturerId: Int, $file: String) {
uploadFile(file: $file,manufacturerId:$manufacturerId, type: $type) {
originalName
systemName
index
batchId
mappings(type: $type,manufacturerId:$manufacturerId){
index
fieldName
knownNames
optional
columnType
dataType
}
sheets {
index
name
enabled
emptyRowCount
totalRowCount
header {
index
columns {
columnIndex
columnName
rowIndex
}
}
rows {
cells {
index
rowIndex
value
}
index
}
}
}
}
`;
export const UPLOAD_FILE2 = gql`
mutation uploadFile($type: Int,$manufacturerId: Int, $file: String) {
uploadFile(file: $file, type: $type,manufacturerId:$manufacturerId) {
originalName
systemName
index
batchId
mappings(type: $type,manufacturerId:$manufacturerId){
index
fieldName
knownNames
optional
columnType
dataType
}
sheets {
index
name
enabled
emptyRowCount
totalRowCount
header {
index
columns {
columnIndex
columnName
rowIndex
}
}
rows {
cells {
index
rowIndex
value
}
index
}
}
}
}
`;
const styles = {
    paper: {
        // ...theme.mixins.gutters(),
        backgroundColor: "transparent",
        border: "1px solid",
        borderColor: theme.palette.text.divider,
        padding: theme.spacing(1) * 4,
        minHeight: 290,
        position: 'relative',
        //marginTop: theme.spacing(1) * 14,
        //background: 'linear-gradient(to top, #ffefba, #ffffff)',
    },
    button: {
        padding: "12px 24px",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        height: 42,
        borderRadius: "2px",
    },
    input: {
        display: 'none'
    },
    center: {
        minHeight: "calc(100vh - 172px)",
        marginTop: "-21px", textAlign: "left"
    },
    customicon: {
        // marginTop: theme.spacing(1) * 4,
    },
    gutters: {
        marginBottom: "20px",
        marginTop: "25px",
        textTransform: 'inherit',
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    supportText: {
        fontSize: "14px",
        marginBottom: "20px",
    },
    btnColor: {
        backgroundColor: orange[500],
        color: "#fff",
        '&:hover': {
            backgroundColor: orange[700],
        },
    }
};



class UploadFlash extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileName: "", file: null, target: null, uploading: false
        }

        this.handleFileChange = this.handleFileChange.bind(this);
        this.validate = this.validate.bind(this);
        this.complete = this.complete.bind(this);
        this.uploadVariables = this.uploadVariables.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.getVariables = this.getVariables.bind(this);
    }
    handleFileChange(e) {

        if (e.target.validity.valid) {
            this.setState({ fileName: e.target.files[0].name, file: e.target.files[0], target: e.target })
        }
    }
    setLoading(status) {
        this.setState({ "uploading": status });
        return true;
    }
    validate() {
        return this.state.target != null && this.state.target.validity != null && this.state.target.validity.valid;
    }

    complete(data, mode) {
        this.props.OnComplete(data, null, null, mode);
        this.setLoading(false);
    }

    uploadVariables(overrideMode) {

        if (overrideMode !== null) {
            let type = overrideMode === ResolveModeConstant ? 2 : 1;
            return { type };
        }
        const { mode } = this.props;
        let type = mode != null && mode === ResolveModeConstant ? 2 : 1;
        return { type };
    }
    getVariables(role,e,type,manufacturerId) {
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { variables: { "file": e.target.files[0], "type": type, "manufacturerId": parseInt(manufacturerId) } };
            case Role_Admin:
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return {
                
                    variables: { "file": e.target.files[0], "type": type }
                }
            }
        }
       

    
render() {

    const { filename: name, } = this.state;
    const { classes } = this.props;
    const role = UserProfile.getRole();

    const manufacturerId = UserProfile.getManufacturerId();
    const hideFreshUploadLink = MANUFACTURER_ID_SunPowerCorporation === parseInt(manufacturerId) || (MANUFACTURER_ID_CanadianSolarInc === parseInt(manufacturerId) && [Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF].includes(role));

    const output = (
        <div className={classes.root} key={this.props.dummyKey} >
            <Helmet>
                <meta charSet="utf-8" />
                <title>SPV | Flash Data</title>
            </Helmet>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.center}>

                {!hideFreshUploadLink && <Grid item xs={12} sm={12} lg={3} >
                    <Paper className={classes.paper} elevation={0}>
                        <Mutation key="StandardUpload" mutation={UPLOAD_FILE} onCompleted={(data) => this.complete(data)} >
                            {(standardUpload, { loading, error }) => {
                                if (loading) {
                                    return (<div style={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}><FbLoader loaderStyle={'default'} /> </div>)
                                }
                                let errorResult = null;
                                if (error) {
                                    if (error.networkError !== null && error.networkError.result !== null && error.networkError.result.errors !== null) {
                                        errorResult = error.networkError.result.errors.map(obj => {
                                            return <FbError message={obj.message} />
                                        });
                                    }
                                }
                                return (<Fragment>
                                    <input
                                        className={classes.input}
                                        id="contained-button-file"
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        required
                                        onChange={(e) => e.target.validity.valid && this.setLoading(true) && standardUpload(this.getVariables(role, e, 1, manufacturerId))}
                                    />
                                    <Customicon name="flash" width={42} className={classes.customicon} fill="#9e9e9e" />
                                    <Typography variant="h3" className={classes.gutters} color="textSecondary">
                                        Fresh upload
                                                </Typography>

                                    <Typography className={classes.supportText} color="textSecondary">
                                        supported files (csv,xls & xlsx)
                                                </Typography>
                                    <label htmlFor="contained-button-file">
                                        <Fab variant="extended" component="span" size="large" color="primary" className={classNames(classes.button)}>
                                            Upload file
                                                 </Fab>
                                    </label>
                                    {errorResult}
                                    <div className="attach-file">
                                        {name}
                                    </div>

                                </Fragment>)

                            }}

                        </Mutation>
                    </Paper>
                </Grid> }

                <Grid item xs={12} sm={12} lg={3}>
                    <Paper className={classes.paper} elevation={0}>
                        <Mutation key="ErrorUpload" mutation={UPLOAD_FILE2} onCompleted={(data) => this.complete(data, ResolveModeConstant)}  >
                            {(errorUpload, { loading, error }) => {
                                if (loading) {
                                    return (<div style={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}><FbLoader loaderStyle={'default'} /> </div>)
                                }
                                let errorResult = null;
                                if (error) {
                                    if (error.networkError !== null && error.networkError.result !== null && error.networkError.result.errors !== null) {
                                        errorResult = error.networkError.result.errors.map(obj => {
                                            return <FbError message={obj.message} />
                                        });
                                    }
                                }
                                return (<Fragment>
                                    <input
                                        className={classes.input}
                                        id="contained-button-file1"
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        required
                                        onChange={(e) => e.target.validity.valid && this.setLoading(true) && errorUpload(this.getVariables(role, e, 2, manufacturerId))}
                                    />
                                    <Customicon name="flash" width={42} className={classes.customicon} fill="#ffa726" />
                                    <Typography variant="h3" className={classes.gutters} color="textSecondary">
                                        Resolve failed serials
                                                 </Typography>

                                    <Typography className={classes.supportText} color="textSecondary">
                                        supported files (csv,xls & xlsx)
                                                </Typography>
                                    <label htmlFor="contained-button-file1">
                                        <Fab variant="extended" component="span" size="large" color="secondary" className={classNames(classes.button, classes.btnColor)}>
                                            Upload file
                                                </Fab>
                                    </label>
                                    {errorResult}
                                    <div className="attach-file">
                                        {name}
                                    </div>
                                </Fragment>)
                            }}
                        </Mutation>
                    </Paper>
                </Grid>
            </Grid>
        </div >
    );
    return output;

    }
}

export default withStyles(styles)(UploadFlash);
