import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React from 'react';


const styles = theme => ({
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    Leftmargin: {
        marginRight: theme.spacing(2),
    },
    supplier: {
        color: "#333",
        padding: "0",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: "transparent",
        },
    },
    avatar: {
        borderRadius: 0,
        width:42,
        height: 'auto',
        color: 'tranparent'
    },
    inline: {
        display: 'inline',
        marginLeft: theme.spacing(1) * 0.5,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        color: theme.palette.grey[700],
    },
    tag: {
        color: theme.palette.grey[900],
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    listClass: {
        display: "flex",
        flexDirection: 'row',
    }

});
const InstallTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);




class InstalledSerialRow extends React.Component {


    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_header_row');

    }

    render() {
        const serial = this.props.serial;
        const { classes } = this.props;
        return (

            <TableRow className={classes.row} key={this._id}>
                <InstallTableCell >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item className={classes.Leftmargin}>
                            {/*  <MyLoader /> */}
                            <Avatar alt="Solar Panel" src={require("../../../assets/images/solar-panel.jpg")} className={classes.avatar} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" gutterBottom>
                                {serial.serial}
                            </Typography>
                        </Grid>
                    </Grid>
                </InstallTableCell>
                <InstallTableCell>{serial.uploadedOn}</InstallTableCell>
                <InstallTableCell >      <Typography component="span" className={classes.supplier} color="textPrimary">
                    {serial.supplier ? serial.supplier : "Self supplier"}
                </Typography></InstallTableCell>
                <InstallTableCell>
                    <List className={classes.root} disablePadding classes={{
                        root: classes.listClass
                    }}>
                        <ListItem alignItems="flex-start" disableGutters={true} >
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Pmax:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.pmax}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>    {"FF:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.ff}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Voc:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.voc}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Isc:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.isc}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Vpm:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.vpm}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}> {"Ipm:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.ipm}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                    {/* <span>Pmax:{serial.pmax} </span><span>FF: {serial.ff}</span> <span> Voc:{serial.voc}</span> <span>Isc:{serial.isc}</span> <span> Vpm:{serial.vpm}</span><span>Ipm:{serial.ipm}</span> */}
               
                </InstallTableCell>
             
                
            </TableRow>
           

        );

       


    }
}

export default withStyles(styles)(InstalledSerialRow);
