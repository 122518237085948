// src/components/ManufacturerStatSearch.js
import React from 'react';
import { TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const ManufacturerStatSearch = ({ search, onSearchChange, fromDate, onFromDateChange, toDate, onToDateChange }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <TextField
                label="Search Manufacturer"
                variant="outlined"
                value={search}
                onChange={onSearchChange}
                style={{ marginLeft: '16px', marginRight: '16px' }}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="from-date-picker"
                    label="From Date"
                    format="DD-MM-YYYY"
                    value={fromDate}
                    onChange={onFromDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{ marginRight: '16px' }}
                />
                <KeyboardDatePicker
                    margin="normal"
                    id="to-date-picker"
                    label="To Date"
                    format="DD-MM-YYYY"
                    value={toDate}
                    onChange={onToDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default ManufacturerStatSearch;
