import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';


const styles = theme => ({
    root: {
        flexGrow: 1,
        position: "relative",
    },
    OrderList: {
        paddingLeft: theme.spacing(2),
        paddingtop: theme.spacing(1) * 0.5,
        paddingbottom: theme.spacing(1) * 0.5,
    },
    heading: {
        fontSize: "22px",
        marginBottom: "18px",
    },
    toolbar: theme.mixins.toolbar,
});

const StyledOrderList = styled.ol`
        line-height:21px;
        padding:0 0 0 20px;
        color:#4e4e4e;

`
const StyledList = styled.li`
        line-height:21px;
        padding:4px;
        color:#4e4e4e;
        font-size:16px;

        strong{
            color: #222;
        }
        ol{ 
            padding-left:20px; 
        }

`
const Paragraph = styled.p`
        line-height:21px;
        padding:4px;
        color:#4e4e4e;
        font-size:16px;
`
class Privacy extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
              
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">              
                    <Grid item lg={12}>
                   
                            <Typography component="h3" variant="h3" gutterBottom className={classes.heading}>
                                Privacy
                            </Typography>
                            <Paragraph>
                              FormBay Pty Ltd ABN 31 146 464 995 (FormBay) is subject to the Privacy Act 1988 (Cth)
                                       and complies with the
                                       National Privacy Principles for handling your personal information. This Privacy Policy
                                       relates to personal information collected by FormBay
                                        any means and by any technology.
                             </Paragraph>
                            <Paragraph>
                                Your privacy and personal information is important to us and we will do each of the
                                    following:
                            </Paragraph>          
                            <StyledOrderList>
                                <StyledList>Only collect, use and disclose personal information about you that is required in
                                    the provision of
                                    information about or the promotion or delivery
                                    of our services or such services ancillary to or necessary for those services;
                                    administration of our business;
                                    business analysis; or to meet any
                                        legal obligations imposed on us (Purpose).</StyledList>
                                <StyledList>Only disclose your personal information to third parties for a Purpose and with
                                    whom we have entered into
                                    an
                                    agreement that gives you (or that the law requires
                                    to give you) at least the same level of protection to your personal information as
                                        we do.</StyledList>
                                <StyledList>Only use de-identified information for any statistical or other analysis or similar
                                        research purposes.</StyledList>

                                <StyledList>Use only fair and lawful ways to collect personal information. Sometimes we may
                                    need to collect information
                                    from third parties regarding credit worthiness or prior
                                        work history and reference checks.</StyledList>

                                <StyledList>Collect personal information directly from you if it is reasonable and practicable
                                        to do so.</StyledList>

                                <StyledList>Take reasonable steps to ensure the personal information that FormBay collects,
                                    uses or discloses is
                                    accurate, complete and up-to-date. We recommend you notify
                                    us if you change your address or contact details as soon as possible. This will
                                    help us to maintain your
                                    privacy
                                    by ensuring that any communications are sent to
                                        the correct postal address, email address, or telephone number.</StyledList>

                                <StyledList>Take reasonable steps to protect your personal information from misuse, loss and
                                    unauthorised access,
                                    modification or disclosure. However, FormBay will not be
                                        responsible for any damages suffered as the result of any lost information.</StyledList>

                                <StyledList>Take reasonable steps to destroy or permanently de-identify personal information if
                                    we no longer need it
                                    for
                                        any purpose.</StyledList>

                                <StyledList>On request, we will give you access to the personal information we hold about you.
                                    If any personal
                                    information we hold about you is out of date or inaccurate, we
                                    encourage you to let us know, and ask us to correct it. If we cannot deal with your
                                    request, you will receive
                                        our reasons in writing.
                                    <Paragraph>We may be required to disclose your personal information without your consent if
                                            the disclosure is:</Paragraph>
                                </StyledList>

                                <StyledList>required or authorised by law;</StyledList>

                                <StyledList>required by the Office of the Renewable Energy Regulator, the statutory authority
                                    established to oversee
                                    the
                                    implementation of the Small-Scale Renewable
                                        Energy Scheme;</StyledList>

                                <StyledList> required in order to investigate an unlawful activity;</StyledList>

                                <StyledList> required by an enforcement body for investigative activities; or</StyledList>

                                <StyledList>necessary to prevent a serious and imminent threat to a person's life, health or
                                    safety, or to public
                                    health
                                        or safety.</StyledList>
                            </StyledOrderList>
                            <br />
                            <Typography component="h3" variant="h3" gutterBottom>
                             Formbay Website
                            </Typography>                            
                            <StyledOrderList>
                                <StyledList>We use your Internet Protocol (IP) address to help diagnose problems with our
                                    server and to administer our
                                    web
                                    site. We analyse our web site logs to improve the relevance of content featured on
                                    the site. While these logs
                                    are
                                    IP identifiable, no attempt is made to link them with any individuals that browse
                                    the site.
                                    </StyledList>
                                <StyledList>

                                    The site uses cookies simply to ensure a persistent client state. We also send
                                    session numbers and keys as
                                    cookies to ensure that your connection, when using our online services, is kept as
                                    secure as possible.
                                    </StyledList>
                                <StyledList>

                                    Where this site contains links to other sites, we are not responsible for the
                                    information handling practices
                                    or content of these external sites but we do endeavour to monitor these sites to
                                    ensure that they are
                                    appropriate
                                    to our site, its visitors and members.
                                    </StyledList>
                                <StyledList>

                                    We also maintain several email lists to keep subscribers informed about areas of
                                    specific interest.
                                    Individuals must request to join our mailing lists by signing up
                                    through our web site. We do not sell, rent, loan, trade, or lease the addresses on
                                    our lists to anyone.
                                    </StyledList>
                            </StyledOrderList>
                            <br/>
                            <Paragraph>If you want to complain about an interference with your privacy by us, you can visit our
                                office, call us on
                                0290869184, write to
                                FormBay at Level 1, 222 Clarence Street, Sydney NSW 2000 or email us at
                                privacy@formbay.com.au. We will do our
                                best to resolve your
                                complaint as quickly as possible. If you are not satisfied with our response to your
                                complaint, you can refer
                                the
                                    matter to the Federal Privacy Commissioner:</Paragraph>
                            <Paragraph>Director of Complaints, Office of the Federal Privacy Commissioner, GPO Box 5218, Sydney
                                NSW 2001. Telephone:
                                    1300 363 992</Paragraph>
                     
                    </Grid>

                </Grid>
            
               
            </div>
            
        );
    }
}

export default withStyles(styles)(Privacy);
