import React, { Fragment, useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { useMutation } from '@apollo/react-hooks';
import Customicon from '../../Customicon';
import theme from '../../theme';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
const styles = {
    paper: {
        ...theme.mixins.gutters(),
        background: theme.palette.common.white,
        height: "100%",
    },
    button: {
        minWidth: 200,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        marginBottom: theme.spacing(1) * 4,
    },
    input: {
        display: 'none'
    },
    showClass: {
        display: "block",
    },
    center: {
        textAlign: 'center'
    },
    customicon: {
        marginTop: theme.spacing(1) * 4,
    },
    gutters: {
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(1) * 3,
        textTransform: 'inherit'
    },
    message: {
        textAlign: 'center',
        padding: '10px',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -16,
        marginLeft: -12,
    },
    leftAlign: {
        textAlign: 'left',
    },
    buttonSuccess: {
        margin: "0 auto",
        backgroundColor: green[500],
        width: 60,
        height: 60,
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        margin: "0 auto",
        backgroundColor: red[500],
        width: 60,
        height: 60,
        '&:hover': {
            backgroundColor: red[700],
        },
    },
};
export const UPLOAD_FILE = gql`
mutation installerList($file: String) {
migratedInstallerList(file: $file) {
message
code
success
data
}
}
`;

const ImportInstallerData = ({ classes }) => {
    const [state, setState] = useState({
        loading: false,
        process: false,
        showComponent: false,
        success: false,
        message:''
    });
    const { enqueueSnackbar } = useSnackbar();
    const timer = useRef(null);
    const [files, setFiles] = useState([]);
    const [uploadFile] = useMutation(UPLOAD_FILE);

    useEffect(() => {
        // ComponentWillUnmount equivalent
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handlefileUpload = (e) => {

        try {
            setState(state => ({
                ...state,
                 process: false, loading: true
            }));

            uploadFile({ variables: { "file": e.target.files[0] } })
                .then((response) => {
                    // Handle success
                    console.log('File uploaded successfully!', response);
                    if (!response || !response.data || !response.data.migratedInstallerList) {
                        setState(state => ({
                            ...state,
                            loading: false, showComponent: true, process: true, success: false, message: "some unknown error has occured"
                        }));

                    }
                    else {

                        const res = response.data.migratedInstallerList;
                        const data = JSON.parse(res.data);

                        const messages = res.success ? data?.map(item => (<div style={{ display: 'flex' }}>{item.Action} : <b> {item.Count} </b> </div>)) : data?.map(item => (<div>Error: {item.Action} </div>));
                       

                        setState(state => ({
                            ...state,
                            loading: false, showComponent: true, process: true, success: res.success, message: messages
                        }));
                    }
                        
                })
                .catch((error) => {
                    // Handle error
                    console.error('Error uploading  file', error);
                    setState({ ...state, loading: false, showComponent: true, process: true, success: false });
                });

            //const response = await uploadWeeklySupplierList(files[0]);
            //if (response.status === 200) {
            //    enqueueSnackbar('Upload Successful', { variant: 'success' });
            //}
        } catch (error) {
            enqueueSnackbar('Upload Failed', { variant: 'error' });
        }
    };

    const handleProcessData = () => {
        if (!state.loading) {
            setState({ ...state, loading: true, success: false });
            timer.current = setTimeout(() => {
                setState({ ...state, loading: false, success: true });
            }, 4000);
        }
    };

    const { loading, process, success,message } = state;

    return (
        <div className={classes.root}>
            {process && success == false ? (<Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.center}

            >
                <Grid item xs={12} sm={12} >
                    <Paper className={classes.paper} elevation={0} >
                        {<Typography variant="h2" className={classNames(classes.gutters, classes.message)} gutterBottom>
                            <Avatar className={classes.buttonError} >
                                <ErrorIcon />
                            </Avatar>
                            <div className={classes.message}>
                                Some error occured while processing the request
                                <br />
                                {message}
                            </div>
                            
                        </Typography>
                        }

                    </Paper>
                </Grid>
            </Grid>)
                : process && success == true ? (<Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.center}

                >
                    <Grid item xs={12} sm={12} >
                        <Paper className={classes.paper} elevation={0} >
                            {success && <Typography variant="h2" className={classNames(classes.gutters, classes.message)} gutterBottom>
                                <Avatar className={classes.buttonSuccess} >
                                    <CheckIcon />
                                </Avatar> <br />Installer data imported successfully

                                <Typography variant="subtitle1" className={classNames(classes.gutters, classes.center)} color="textSecondary">
                                    Your file was uploaded to the server and below are the stats
                                    <br />
                                    <div style={{ display: 'flex',justifyContent:'center' }}>
                                        <div style={{  display: 'flex', alignItems: 'self-end', flexDirection: 'column' }}>{message}</div>
                                        
                                    </div>
                                    
                                </Typography>
                            </Typography>
                            }

                        </Paper>
                    </Grid>
                </Grid>) : (
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.center}
                    >
                        <Grid item xs={12} sm={12} >
                            <Paper className={classes.paper} elevation={0} >
                                <input
                                    className={classes.input}
                                    id="contained-button-file"
                                    type="file"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    required
                                    onChange={(e) => { handlefileUpload(e); }}
                                />
                                <Customicon name="flash" width={42} className={classes.customicon} fill="#9e9e9e" />
                                <Typography variant="h2" className={classes.gutters} color="textSecondary">
                                    Upload CSV File Only
                                </Typography>
                                <label htmlFor="contained-button-file">
                                    <Fab variant="extended" component="span" size="large" color="primary" className={classes.button} disabled={loading}>
                                        Upload file
                                        {loading && <CircularProgress size={32} className={classes.buttonProgress} thickness={5} />}
                                    </Fab>
                                </label>
                            </Paper>
                        </Grid>
                    </Grid>
                )
            }
        </div>
    );
};

export default withStyles(styles)(ImportInstallerData);