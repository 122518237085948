import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import UserProfile from '../auth/UserProfile';
import {
    LINKS_PER_PAGE,
    SERIAL_TYPE,
    Role_Admin,
    Role_DISTRIBUTOR,
    Role_MANUFACTURER,
    Role_STAFF,
    Role_DISTRIBUTOR_STAFF,
} from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import { handleFailDownload } from '../../Helper';
const SerialDataContext = createContext();

function SerialDataProvider({ manufacturerId,globalView, children }) {

    const role = UserProfile.getRole();
    const isDistributor = UserProfile.isDistributor();
    if (isDistributor) {
        manufacturerId = UserProfile.getManufacturerId();
    }

    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = LINKS_PER_PAGE;
    const [activeTab, setActiveTab] = useState(SERIAL_TYPE.FAILED);
    const [serial, setSerial] = useState(null);
    const [productSerialId, setProductSerialId] = useState(null);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState(null);

    const onPageChange = useCallback((newPage) => {
        setPageNumber(newPage);
    }, []);

    const handleChange = useCallback((event,newValue) => {
        changeTab(newValue);
    }, []);

    

    const roleMapping = {
        [Role_Admin]: 'admin',
        [Role_DISTRIBUTOR]: 'supplier',
        [Role_DISTRIBUTOR_STAFF]: 'supplier',
        [Role_MANUFACTURER]: 'productfeed',
        [Role_STAFF]: 'productfeed',
    };
    

    const getQuery = () => {
        if (activeTab == SERIAL_TYPE.FAILED) {
            return QueryHelper().FailedProductSerialListing(globalView);
        }
        else if (activeTab == SERIAL_TYPE.PARALLEL) {
            return QueryHelper().AdminParallelImport(globalView);
        }
        return QueryHelper().ProductSerialListing(globalView);
    };

    const getQueryVariables = () => {
        const skip = (pageNumber - 1) * pageSize;
        const first = pageSize;
        const baseVariables = { first, skip, filter, type: activeTab };

        const addManufacturerId = (variables) => ({
            ...variables,
            manufacturerId: parseInt(manufacturerId),
        });

        switch (role) {
            case Role_Admin:
                return globalView ? baseVariables : addManufacturerId(baseVariables);
            case Role_DISTRIBUTOR:
            case Role_DISTRIBUTOR_STAFF:
                return addManufacturerId(baseVariables);
            case Role_MANUFACTURER:
            case Role_STAFF:
            default:
                return baseVariables;
        }
    };
  

    const getData = (data, globalView) => {
        if (!data) return null;
        const key = roleMapping[role] || 'productfeed';
        if (activeTab == SERIAL_TYPE.PARALLEL) {
            return {
                totalSerials: data[key].parallelImportCount,
                serials: data[key].parallelImportSerials,
            };
        }
        else if (activeTab == SERIAL_TYPE.FAILED) {
            return {
                totalSerials: data[key].failedSerialCount,
                serials: data[key].failedSerials,
            };
        }
        return {
            totalSerials: data[key].productSerialCount,
            serials: data[key].productSerials,
        };
    };

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const changeTab = (tab) => {
        setActiveTab(tab);
        setPageNumber(1);
    };

    const handleSearch = useCallback((data) => {

        setFilter(data);
        setPageNumber(1);

    }, [filter]);

    const onFailDownload = useCallback((manufacturerId, name) => {
        handleFailDownload(
            (data) => {
                // setFailedSerialsCount(data);
            },
            (error) => {
                console.error(error);
            },
            manufacturerId, name
        );
    }, []);

    const showSerialImagesCallback = useCallback((serial, productSerialId) => {
        setOpen(true);
        setSerial(serial);
        setProductSerialId(productSerialId);
    }, []);

    const isTabActive = (tab) => {
        if (tab === activeTab) {
            return true;
        }
        return false;
    };

    const hideSerialImagesCallback = useCallback(() => {
        setOpen(false);
    }, []);

    const { loading, error, data } = useQuery(getQuery(), {
        variables: getQueryVariables(activeTab),
    });

  
    

    return (
        <SerialDataContext.Provider value={
            {
                open, serial, productSerialId, activeTab, filter, pageNumber, pageSize, loading, error, data, changeTab, isTabActive,
                handleSearch, showSerialImagesCallback, onFailDownload, getData, onPageChange,
                handleChange, hideSerialImagesCallback, handleClose
            }} >
            {children}
        </SerialDataContext.Provider>
    );
}

export { SerialDataProvider, SerialDataContext };
