import React, { Fragment } from 'react';
import Cell from './ClientDataCell';
import EmptyCell from './EmptyCell';


class ClientDataRow extends React.Component {


    render() {
        const minimum_rows = 15;
        const { rows, headerIndex,header } = this.props;
        let maxCellCount = 0;
        const dataRows = rows.map((row, index) => {
            maxCellCount = row.cells != null && row.cells.length > 0 &&
                row.cells.length > maxCellCount ? row.cells.length : maxCellCount;
            return (
                <tr key={"cl_row" + index}>
                    <td className={"counterCell"} style={{width:'12px',minWidth:'12px'}}>{(index+1)}</td>
                    <Cell isHeader={index === headerIndex} cells={row.cells} header={header} />
                </tr>
            );
        });
        let blankRows = [];
        if (rows.length < minimum_rows) {
            const additional_row_required = minimum_rows - rows.length;
            for (var row = 0; row < additional_row_required; row++) {
                let cells = [];
                for (var i = 0; i < maxCellCount; i++) {
                    cells.push(<EmptyCell key={"dummycell" + i} />);
                }
                blankRows.push((<tr key={"clientdataRow" + row}>{cells}</tr>));
            }
        }
        return <Fragment>{dataRows}{blankRows}</Fragment>;

    }
}

export default ClientDataRow;
