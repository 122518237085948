import React from "react";
import Chart from "react-google-charts";

function GeoApp(props) {
    const data = props.data;


   

    const stateData = [["State", "Installations"]];
    

    for (var item in data) {
        var val = data[item];
      //  statsData[item.stateName] = statsData[item.stateName] + item.totalInstallation;
        stateData.push(['AU-' + item, val])
    }
    const options = {
        colorAxis: { colors: ['#305bf4'] },
        backgroundColor: '#81d4fa',
        datalessRegionColor: '#dddddd',
        defaultColor: '#dddddd',
        displayMode: 'regions',
        region: 'AU',
        resolution: 'provinces'
    };

  return (
    <React.Fragment>     
          <Chart chartType="GeoChart" width="100%" height="300px" data={stateData} options={options} />
    </React.Fragment>
  );
}

export default GeoApp;