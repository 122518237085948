import theme from '../../theme';
export const SerialContainerStyles = {
    SerialsTabHidden: {
        display: 'none'
    },
    root: {
        height: "100%"
    },
    Grid: {
        backgroundColor: theme.palette.grey[100],
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "1px",
        borderColor: theme.palette.common.borderColor,
    },
    tabsIndicator: {},
    tabsIndicatorError: { backgroundColor: '#FFFFFF' },
    tabsRoot: {
        color: theme.palette.grey[700],
        paddingTop: theme.spacing(1),
    },
    tabsErrorIndicator: {
        backgroundColor: theme.palette.error.main
    },
    tabRoot: {
        textTransform: 'capitalize',
        fontSize: "14px",
        textAlign: "center",
        color: theme.palette.grey[800],
        minHeight: "40px",
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.common.tabHover,
            color: theme.palette.grey[900],
        },
        '&$tabSelected': {
            color: theme.palette.grey[900],
        },
        '&:focus': {
            color: theme.palette.grey[900],
        },
    },
    tabRootWarning: {
        backgroundColor: theme.palette.error.alert,
        textTransform: 'capitalize',
        fontSize: "14px",
        textAlign: "center",
        color: theme.palette.grey[800],
        minHeight: "40px",
        color: '#ffffff',
        marginRight: theme.spacing(1),
        '&$tabSelected': {
            backgroundColor: theme.palette.error.main
        },
        '&$tabDisabled': {
            backgroundColor: theme.palette.error.main,
            color: 'yellow',
        },
    },
    tabSelected: {},
    tabDisabled: {},
    typography: {
        padding: theme.spacing(1) * 3,
    },
    badge: {
        verticalAlign: "top",
        position: "inherit",
        borderRadius: theme.shape.borderRadius,
        transform: "initial",
        marginLeft: theme.spacing(2),
        fontFamily: "Roboto",
    },
    grow: {
        flexGrow: 1,
        fontSize: "15px",
    },
    padding: {
        padding: 0,
        minHeight: "auto",
    }
};