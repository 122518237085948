import { Badge, Grid, IconButton, Paper, Tab, Tabs, Toolbar, Typography } from '@material-ui/core/';
import { blue, green, orange, red } from '@material-ui/core/colors';
import { withStyles,alpha } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { transparent } from 'material-ui/styles/colors';
import PropTypes from "prop-types";
import React from 'react';
import { SerialsTitle } from '../../StringConstants';
import theme from '../../theme';
import PageTitles from '../admin/PageTitles';
import SerialContainerWithProvider from '../serial/SerialContainer';

const fontprimary = "1rem";
const fontsecondary = "0.9rem";
const styles = {
    SerialsTabHidden: {
        display: 'none'
    },
    closeIcon: {
        background: transparent,
        boxShadow: 'none',
    },
    viewLogs: {
        border: "1px solid #ddd",
    },
    modelTitle: {
        border: '1px solid #ddd',
        marginBottom: 25,
    },
    logs: {
        border: '1px solid #ddd',
    },
    tablerow: {
        alignItems: "baseline",
    },
    seialNum: {
        color: "#333",
        padding: "0",
        fontSize: "18px",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: transparent,
        },
    },
    chip: {
        backgroundColor: alpha(green[50], 0.45),
        fontFamily: 'NHaasGroteskDSPro-65Md',
        color: green[500],
        margin: theme.spacing(1) * 1,
        fontSize: 14,
        borderRadius: theme.shape.borderRadius,
    },
    errorStatus: {
        backgroundColor: alpha(red[50], 0.45),
        fontFamily: 'NHaasGroteskDSPro-65Md',
        color: red[500],
        margin: theme.spacing(1) * 1,
        fontSize: 14,
        borderRadius: theme.shape.borderRadius,
    },
    actionBtn: {
        boxShadow: "none"
    },
    avatar: {
        backgroundColor: orange[500],
        fontSize: "11px"
    },
    root: {
        width: "100%",
        height: "100%"
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1), background: theme.palette.grey[50],
    },
    card: {
        boxShadow: "none",
        background: "none",
        lineHeight: "2.3",
        width: 200,
    },
    rootCard: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: fontprimary,
    },
    subCard: {
        fontSize: fontprimary,
    },
    titleroot: {
        fontSize: "1.3rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    subtext: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "14px",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    userName: {
        color: "rgba(0, 0, 0, 0.87)"
    },
    contentPaper: {
        //margin: theme.spacing(2),
        //padding: theme.spacing(2),
        background: theme.palette.common.white,
        border: "1px solid",
        borderColor: theme.palette.common.borderColor, overflowX: 'auto',
    },
    fieldsection: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    progress: {
        position: "relative",
    },
    linearColorPrimary: {
        height: 26,
        background: theme.palette.common.borderColor,
    },
    linearBarColorPrimary: {
        background: theme.palette.common.red,

    },
    span: {
        position: "absolute",
        top: 0,
        color: theme.palette.common.white,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        left: theme.spacing(1),
        lineHeight: "26px",
        fontSize: "1rem",
    },
    btn: {
        marginBottom: 24,
        marginLeft: 8,
    },
    action: {
        paddingRight: 20,
    },
    contentModalText: {
        fontSize: "1.2rem",
    },
    removed: {
        color: red[400],
    },
    primarytext: {
        fontSize: fontprimary,
    },
    secondarytext: {
        fontSize: fontsecondary,
    },
    scannedbg: {
        background: alpha(blue[50], 0.5),
    },
    titlescan: {
        fontSize: "1.3rem",
    },
    button: {
        boxShadow: "none",
        borderRadius: "30px",
        height: "20px",
        width: "20px",
    },
    tabsRoot: {
        color: theme.palette.grey[700],
        paddingTop: theme.spacing(1),

    },
    tabRoot: {
        textTransform: 'capitalize',
        fontSize: "14px",
        textAlign: "center",
        color: theme.palette.grey[800],
        minHeight: "40px",
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.common.tabHover,
            color: theme.palette.grey[900],
        },
        '&$tabSelected': {
            color: theme.palette.grey[900]
        },
        '&:focus': {
            color: theme.palette.grey[900],
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing(1) * 3,
    },
    badge: {
        verticalALign: "top",
        position: "inherit",
        borderRadius: theme.shape.borderRadius,
        transform: "initial",
        //  marginTop: "-22px",
        marginLeft: theme.spacing(2),
        fontFamily: "Roboto",
        // The border color match the background color.

    },
    grow: {
        flexGrow: 1,
        fontSize: "15px",
    },
    padding: {
        padding: 0,
        minHeight: "auto",
    }
}
const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -12,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);



class AdminSerials extends React.Component {
    
    constructor(props) {
        super(props);
    }

    
    render() {
        const { classes } = this.props;
      
        return (
            <div className={classes.root}>
                <PageTitles titles={SerialsTitle} />
                <Paper elevation={0} className={classes.paper}>
                    <SerialContainerWithProvider adminView={true} globalView={true} />
                </Paper>
            </div>
        )

    }
}

AdminSerials.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(AdminSerials);

