import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { ResetApi } from '../../constants';
import theme from '../../theme';
import FormFooter from './FormFooter';
import InstallationVideo from './installationVideo';
const styles = theme => ({
    root: {
        flexGrow: 1,
        //height: "100%",
    },
    formControl: {
        width: "100%"
    },
    Paper: {
        padding: theme.spacing(1) * 1,
        background: theme.palette.common.logIn,
    },
    cssOutlinedInput: {
        // color: theme.palette.text.light
    },
    cssLabel: {
        // color: '#ffffff !important',
    },
    notchedOutline: {
        // borderColor: '#FFFFFF !important',
    },
    container: {
        height: '100%',
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(1) * 8,
        paddingRight: theme.spacing(1) * 8,
        width: 275,
        height: 50,
        '&:disabled': {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
        '&:hover': {
            '&:disabled': {
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    formContent: {
        height: "calc(100vh - 130px)",
        minHeight: "500px",
    },
    successMessage: {
        color: green[500],
    },

    logText: {
        marginBottom: "30px",
        textAlign: "center",
        textTransform: 'inherit',
        fontWeight: 'normal',
    },
    setPass: {
        textAlign:"center"
    },
    center: {
        //marginTop: theme.spacing(1),
        textAlign: "center",
    },
    headFrame: {
        width: "100%",
        height: '180px',
    },
    formOuterFrame: {
        width: "100%",
        height: '300px',
    },
});


const ForgotLink = styled.div`
   color: #ffffff; 
   text-align: center;
`;

const ForgotAnchor = styled.a`
    font-family: 'NHaasGroteskDSPro-55Rg';
    text-align: center;
    font-size: 18px;
    text-decoration: underline;
    color: #ffffff; 
    padding:10px 20px;
    background-color:#0d4add;
    &:hover {
        text-decoration: none;
        color: #fff;
    }
`;
const LeftWrapper = styled.div`
    float: left;
    width:25%;
    position: relative;
    height: 100%;
     background-color: #fff;
    display:table;
    padding:0 60px 0 60px;
    @media(max-width:1600px){
      width:30%;
    }
    @media(max-width:1400px){
      width:40%;
    }
    @media(max-width:1280px){
      padding:0 40px 0 40px;
    }
    @media(max-width:992px){
      width:100%;
      float:none;
    }
    @media(max-width:767px){
      padding:0 30px 0 30px;
    }
`;

const RightWrapper = styled.div`
    float: left;
    width: 75%;
    position: relative;  
    display:block;
    @media(max-width:1600px){
      width:70%;
    }
    @media(max-width:1400px){
      width:60%;
    }
    @media(max-width:992px){
         display:none;
    }
    &::before {
        content: "";
        position: fixed;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: -1;
    }
    .header {
        position: fixed;
        top: 0;
        bottom: 0;
        padding: 50px 50px 50px 50px;
        h1 {
            color: #fff;
            margin-bottom: 10px;
            font-weight: normal;
        }

        p {
            color: #fff;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .footer {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 50px 50px 30px 50px;

        .address-box {
            padding: 30px 0 0 0;
            border-top: 1px solid rgba(255, 255, 255, 0.3);

            h3 {
                color: #fff;
                margin-bottom: 5px;
            }

            p {
                color: #fff;
                font-size: $font-size-base;
            }
        }
    }
`;
const Logo = styled.div`
    padding: 30px 0 30px 0;
    text-align: center;
`;
//const Footer = styled.footer`
//    width: 100%;
//    float: left;
//    height: 100px;
//    margin: 30px 0 0 0;
//    padding: 20px 0 0;
//    border-top: 1px solid #e5e5e5;
//    background: none;
//    ul {

//        text-align: center;
//    }
//    li {
//        display: inline-block;
//        margin: 0 15px 0 15px;
//        a {
//            font-family: 'NHaasGroteskDSPro-55Rg';
//            font-size: 15px;
//            color:#0d4add;
//            text-decoration: none;
//            &:hover{
//                color: #0d4add;
//                text-decoration: underline;
//            }
//        }
//    }
//    p{
//        text-align: center;
//        font-size: 14px;
//        color:#333;
//        font-family: 'NHaasGroteskDSPro-55Rg';
//        padding: 15px 0 10px 0;
//    }   
//`;
class ResetPassword extends React.Component {

    constructor() {
        super();

        this.state = {
            failed: false, resolved: false, showPassword: false, Password: '', ConfirmPassword: '', isloading: false, errormessage: '', expired:false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.notifyMessage = this.notifyMessage.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.matchPassword = this.matchPassword.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }
    componentDidMount() {

        ValidatorForm.addValidationRule('isPassword', (value) => {
            return this.matchPassword(value);
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.Password) {
                return false;
            }
            return true;
        });
        const values = queryString.parse(this.props.location.search);
        this.user = {
            UserId: values.uid,
            Email: values.email,
            Token: values.token, Password: ''
        }

    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    handleSubmit = () => {
        this.user.Password = this.state.Password;
        this.setState({ isloading: true })
        fetch(ResetApi, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.user)
        }).then(res => {
            return res.json();
            }).then(res => {
                var expired = false;
                if (res.message.includes('expired')) {
                    expired = true;
            }
                this.setState({ failed: !res.status, resolved: res.status, isloading: false, errormessage: res.message, expired: expired });
        })
            .catch(err => {

                this.setState({ failed: true, resolved: false, isloading: false, errormessage: err });

            });
    }
    matchPassword(value) {
        
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$%*?&]{6,}$/.test(value)) {

            return true;
        }
        return false;
    }
    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value });
    }
    resetForm(classes) {

        const { ConfirmPassword, Password, isloading } = this.state;
        const requiredMessage = "* Password is required";
        return <ValidatorForm ref="form" instantValidate={true} onError={errors => console.log(errors)} onSubmit={this.handleSubmit}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SPV | Reset Password</title>
            </Helmet>
            <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <div className="loginForm">
                            <FormControl className={classes.formControl} error>
                                <TextValidator
                                    autoComplete='off'
                                    id="Password"
                                    name="Password"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Password*"
                                    value={Password}
                                    onChange={this.handleChange}
                                    className={classNames(classes.dense)}
                                    validators={['isPassword', 'required']}
                                    errorMessages={['Password must be combination of upper case,digit and special characters and should have minimum 6 characters', requiredMessage]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            </FormControl>
                        </div>
                        <div className="loginForm">
                            <FormControl className={classes.formControl} error>
                                <TextValidator
                                    autoComplete='off'
                                    id="ConfirmPassword"
                                    name="ConfirmPassword"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Confirm Password*"
                                    value={ConfirmPassword}
                                    onChange={this.handleChange}
                                    className={classNames(classes.dense)}
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['Confirm password did not match', requiredMessage]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.setPass}>        
                        <Button className={classes.button} variant="contained" color="primary" type="submit" alignitem='center'>
                            {isloading ?
                                <CircularProgress size={19} color={theme.palette.common.white} /> :
                                <span>Set new password</span>
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    }

    notifyMessage(classes, status, title, message) {
        if (status) {
            return (<Fragment><Typography variant="h2" className={classes.root}>
                {title}
            </Typography>
                <Typography variant="caption" className={classes.rootCaption} >
                    {message}
                </Typography></Fragment>);
        }
        else {
            return (<Fragment><Typography variant="h2" className={classes.root}>
                {title}
            </Typography>
                <Typography variant="caption" className={classes.rootCaption} >
                    {message} 
                </Typography></Fragment>);
        }
    }
    render() {
        const { classes } = this.props;
        const { failed, resolved, errormessage, expired } = this.state;
        const title = "Reset your password?";     
        const output = this.resetForm(classes);
        const output_message = failed ? (expired ? (<Fragment><Paper align="center" square={true} className={classes.Paper} elevation={0}><Typography variant="title" className={classes.errorText}>
            {errormessage}</Typography></Paper> <ForgotLink><ForgotAnchor as="a" href="/forgot-password" title="Forgot password">Forgot</ForgotAnchor></ForgotLink></Fragment>) : errormessage) : (resolved) ? <Paper align="center" square={true} className={classes.Paper} elevation={0}>Password changed succesfully.</Paper> : null;
        const output_result_class = failed ? classes.errorMessage : resolved ? classes.successMessage : classes.root;
        //var links = [
        //    { name: 'Terms of use', path: '/legal' },
        //    { name: 'Privacy', path: '/legal' },
        //    { name: 'About', path: '/legal' }
        //];
        //const listItems = links.map((link, index) =>
        //    <li key={index}>
        //        <Link component={RouterLink} title={link.name} to={link.path}>{link.name}</Link>
        //    </li>
        //);


        return (<div className={classes.root}>
            <LeftWrapper>
                <Grid container direction="row" alignItems="center" justify="center" className={classes.formContent} >
                    <Grid item xs={12}>
                        <Grid item className={classes.headFrame}>
                            <Logo><img src={require("../../assets/images/solar-logo.svg")} width="250" alt="Formbay Logo" /></Logo>
                            <Typography variant="h1" className={classes.logText}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.formOuterFrame}>
                            {output}
                            <Typography className={output_result_class}>
                                {output_message}
                            </Typography>
                            <Grid item xs={12} className={classes.center}>
                                Just Remembered?&nbsp;<Link component={RouterLink} to="/login" title="Log In">Log In</Link>
                            </Grid>  
                        </Grid>
                    </Grid>
                </Grid>
                <FormFooter />
            </LeftWrapper>
              <Hidden>
                <InstallationVideo />
              </Hidden>
            <RightWrapper>
                <div className="header">
                    <h1>Solar Panel Validation (Formbay)</h1>
                    <p>The Solar Panel Validation Project is intending to establish a voluntary mechanism that allows businesses in the supply chain to validate the authenticity of solar panels.</p>
                </div>
                <div className="footer">
                    <div className="address-box">
                        <h3>Formbay Pty. Ltd.</h3>
                        <p>Level 1, 222 Clarence Street , NSW 2000</p>
                    </div>
                </div>
            </RightWrapper>
        </div>
          
        );
    }
}

//export default Forgot;
ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(ResetPassword));

