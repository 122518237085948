import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Background from '../assets/images/user.png';
import { LogoApi, LogoPath, ProfileApi, ProfilePath, ProfilePhotoApi, UploadFrequencyData } from '../constants';
import theme from '../theme';
import UserProfile from './auth/UserProfile';
import AuthService from './AuthService';

const styles = {
    root: {
        flexGrow: 1,
        height:"100%"

    },
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: theme.palette.common.white,
    },
    formControl: {
        width: "100%",
    },
    avatar: {
        borderRadius: 0,
        width: "100%",
        height: "170px",
        backgroundColor: theme.palette.background.default,
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.background.lightbg,
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "transparent",
    },
    avatarLogo: {
        borderRadius: 0,
        width: "100%",
        height: "172px",
        backgroundColor: theme.palette.background.default,
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.background.lightbg,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "transparent",
        maxWidth: "286px",
        minWidth: "286px",
    },
    iconHover: {
        color: theme.palette.grey[500],
        verticalAlign: "middle",
    },
    gutterBottom: {
        marginBottom: theme.spacing(2),
       
    },
    control: {
        marginTop: theme.spacing(1) * 1,
    },
    update: {
        marginBottom: theme.spacing(2),
    },
    profileImage: {
        width: "100%",
        height: "100%",
        objectFit: " cover",

    },
    uploadLogo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginRight: '8px',
        minWidth: 120,
        height: 42,
    },
    titleBar: {
        fontSize: "13px",
    },
    MuiListItem: {
        paddingBottom: 0,
        paddingLeft: 0,
    },
    icon: {
        marginRight: 0,
        minWidth: '30px',
    }

}

class Myaccount extends React.Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onLogoChange = this.onLogoChange.bind(this);
        this.onProfilePhotoChange = this.onProfilePhotoChange.bind(this);
        this.Auth = new AuthService();
        const profile = UserProfile;

        this.state = {
            isloading: false,
            errorMessage:'',
            isPhotoLoading: false,
            value: 'left',
            background: '#fff',
            age: '',
            name: 'hai',
            labelWidth: 0,
            user: {
                fullName: profile.getFullName(), FirstName: profile.getFirstName(), LastName: profile.getLastName(), Email: profile.getEmail(),
                Phone: profile.getPhone(), Logo: profile.getLogo(), ProfilePhoto: profile.getProfilePhoto(), isDistributor: profile.getDistributor(),
                Company: profile.getDisplayName(), UploadFrequency: profile.getUploadFrequency(), IsStaff: profile.isStaff()
            },
        };
    }

    handleChange = event => {
        this.updateformState({ [event.target.name]: event.target.value })
    };
    handleSubmit() {
        this.setState({ isloading: true });
        const { FirstName, LastName, UploadFrequency, Phone } = this.state.user;
        const token = this.Auth.getToken();
        fetch(ProfileApi, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ FirstName: FirstName, LastName: LastName, UploadFrequency: parseInt(UploadFrequency, 10), Mobile: Phone })
        }).then(res => {
            if (res.status === 200 || res.status === 400) {
                return res.json();
            }
          
            return Promise.reject(res);

        }).then(res => {
            if (res.status === false) {
                this.setState({ isloading: false ,errorMessage:res.message });
                return;
            }
            UserProfile.setFirstName(FirstName);
            UserProfile.setLastName(LastName);
            UserProfile.setPhone(Phone);
            UserProfile.setUploadFrequency(UploadFrequency);
            this.setState({ isloading: false });
        })
            .catch(err => {
             

            });
    };
    onProfilePhotoChange = e => {
        const files = Array.from(e.target.files)
        this.setState({ isloading: true })

        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append("File", file);
        })

        const token = this.Auth.getToken();
        fetch(`${ProfilePhotoApi}`, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`
            },
            body: formData
        })
            .then(res => res.json())
            .then(images => {
                UserProfile.setProfilePhoto(images.fileName);
                this.updateformState({ ProfilePhoto: images.fileName }, { isPhotoLoading: false, isloading:false });


            });
    }
    onLogoChange = e => {
        const files = Array.from(e.target.files)
        this.setState({ isPhotoLoading: true })

        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append("File", file);
        })

        const token = this.Auth.getToken();
        fetch(`${LogoApi}`, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`
            },
            body: formData
        })
            .then(res => res.json())
            .then(images => {
                UserProfile.setLogo(images.fileName);
                this.updateformState({ Logo: images.fileName }, { isPhotoLoading: false, isloading: false });


            });
    }

    componentDidMount() {
        if (this.InputLabelRef !== null) {
            if (ReactDOM.findDOMNode(this.InputLabelRef) != null) {
                this.setState({
                    labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
                });
            }
            
        }

    }

   
    updateformState(obj, extra) {
        
        const { user } = this.state;
        let userState =
            { ...user, ...obj };
        if (extra !== null) {
            this.setState(state => ({ "user": userState, ...extra }));
        }
        else {
            this.setState(state => ({ "user": userState }));
        }


    }
    getUploadFrequency(classes, options, UploadFrequency) {

        return (<Grid item sm={12} xs={12} className={classes.control}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={ref => {
                        this.InputLabelRef = ref;  }}
                >How often do you think you will upload new flash data?
                 </InputLabel>
                <Select value={UploadFrequency}
                    
                    onChange={(e) => this.handleChange(e)}
                    input={
                        <OutlinedInput
                            name="UploadFrequency"
                            labelWidth={this.state.labelWidth}
                        />
                    }
                > <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {options}
                </Select>
            </FormControl>
        </Grid>);
    }
    getLogoContent(classes, logoPath, logo, isLoading) {
        const image = isLoading ? <CircularProgress color="primary"  /> : < img alt="Company Logo" src={`${logoPath}/${logo === null ? 'formbay-logo.png' : logo}`}
           />;

        return <Grid item lg={6} sm={12} xs={12}  className={classes.uploadLogo}>
            <FormLabel component="legend" className={classes.gutterBottom}>Upload Logo </FormLabel>
             <Avatar className={classes.avatarLogo}>
                {image}
                <GridListTileBar
                    title={<span className={classes.title}>{logo === null ? "Upload Logo" : "Change Logo"}</span>}
                    className={classes.titleBar}
                    actionIcon={
                        <Fragment>
                            <input hidden accept="image/*" className={classes.input} id="single" type="file" onChange={(e) => this.onLogoChange(e)} />
                            <label htmlFor="single">
                                <IconButton component="span" color="secondary" >
                                    <Icon style={{ fontSize: 20 }}>camera_alt</Icon>
                                </IconButton>
                            </label>
                        </Fragment>
                    }
                />
            </Avatar>
        </Grid>;
    }
    render() {
        
        const { classes } = this.props;
        const { isloading, isPhotoLoading, errorMessage } = this.state;
        const { fullName, FirstName='', LastName='', Email='', Phone='', Logo, ProfilePhoto, Company='', UploadFrequency=6, IsStaff=false } = this.state.user;
        //const profileUrl = ProfilePath + ProfilePhoto;
        //const logoUrl = LogoPath + Logo;
        const uploadLogo = IsStaff ? null : this.getLogoContent(classes, LogoPath, Logo, isPhotoLoading);

        const options = IsStaff ? null : UploadFrequencyData.map(fq => (
            <MenuItem value={fq.code} key={UploadFrequencyData}>{fq.name}</MenuItem>
        ));
        const image = isPhotoLoading ? <CircularProgress color="primary" /> : < img alt="profilepic" src={`${ProfilePath}/${ProfilePhoto === null ? 'user.png' : ProfilePhoto}`}
           className={classes.profileImage} />;

        const frequencySection = IsStaff ? null : this.getUploadFrequency(classes, options, UploadFrequency);

        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" >
                            My Account
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.control}
                    spacing={8}>
                    <Grid item lg={4} md={7} sm={12}>
                        <ValidatorForm ref="form" instantValidate={true} onError={errors => console.log(errors)}
                            onSubmit={this.handleSubmit}>
                            <Paper elevation={0} className={classes.paper}>
                                <Grid container
                                    direction="row"
                                    spacing={2}>
                                    <Grid item xs={8} sm={5} className={classes.gutterBottom}>
                                        <Avatar className={classes.avatar}>
                                            {image}
                                            <GridListTileBar
                                                title={<span className={classes.title}>{ProfilePhoto === null ? "Upload profile Picture" : "Update"}</span>}
                                                className={classes.titleBar}
                                                actionIcon={
                                                    <Fragment>
                                                        <input hidden accept="image/*" className={classes.input} id="profile" type="file" onChange={(e) => this.onProfilePhotoChange(e)} />
                                                        <label htmlFor="profile">
                                                            <IconButton component="span" color="secondary">
                                                                <Icon style={{ fontSize: 20 }}>
                                                                    camera_alt
                                                                 </Icon>
                                                            </IconButton>
                                                        </label>
                                                    </Fragment>
                                                }
                                            />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <Typography component="h3" variant="h3">
                                            {Company}
                                        </Typography>                                     
                                            <List >
                                                <ListItem className={classes.MuiListItem}>
                                                    <ListItemIcon className={classes.icon}>
                                                            <PersonIcon />
                                                        </ListItemIcon>
                                                    <ListItemText
                                                        primary={fullName}                                                       
                                                        />
                                                    </ListItem>
                                                <ListItem className={classes.MuiListItem}>
                                                    <ListItemIcon className={classes.icon}>
                                                        <PhoneIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                     primary={Phone !== '' ? Phone : 'NA'}
                                                    />
                                                </ListItem>
                                                <ListItem className={classes.MuiListItem}>
                                                    <ListItemIcon className={classes.icon}>
                                                        <EmailIcon />
                                                    </ListItemIcon>
                                                    <ListItemText 
                                                        primary={Email}
                                                    />
                                                </ListItem> 
                                            </List>                                    
                                        {/*  <Typography variant="subtitle2" gutterBottom>
                                            <Icon className={classes.iconHover} style={{ fontSize: 26 }}>person</Icon> {fullName}
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <Icon className={classes.iconHover} style={{ fontSize: 26 }}>email</Icon>  {Email}
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            <Icon className={classes.iconHover} style={{ fontSize: 26 }}>phone</Icon>  {Phone != '' ? Phone : 'NA'}
                                        </Typography> */}
                                    </Grid>                           
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                name="FirstName"
                                                label="*First Name"
                                                placeholder="*First Name"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={FirstName}

                                                validators={['required']}
                                                errorMessages={['This field is required']} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                name="LastName"
                                                label="*Last Name"
                                                placeholder="*Last Name"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={LastName}

                                                validators={['required']}
                                                errorMessages={['This field is required']}


                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator onChange={(e) => this.handleChange(e)}
                                                name="Phone"
                                                label="Phone"
                                                placeholder="Phone"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={Phone}
                                                validators={['matchRegexp:^0(4|6)[0-9]{8}$']}
                                                errorMessages={['Mobile should be 10 digit and must start from 04 or 06']}

                                            />
                                        </FormControl>
                                    </Grid>

                                    {frequencySection}
                                    {uploadLogo}
                                </Grid>
                            </Paper>
                            <Grid container direction="row" className={classes.update}>
                                <Grid item>
                                    <Button className={classes.button} size="large" variant="contained" type="submit" alignitem='center' color="primary" {...isloading === true ? 'disabled' : ''} tabIndex="3">
                                        {isloading ?
                                            <CircularProgress color={theme.palette.common.white} size={19} /> :
                                            <span>Update</span>
                                        }                                     
                                    </Button>
                                    <label>
                                        {errorMessage}
                                    </label>
                                </Grid>

                            </Grid>
                        </ValidatorForm>
                    </Grid>

                </Grid>

            </div>
        )

    }
}
Myaccount.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Myaccount);