import React from 'react';
class Support extends React.Component {
    render() {
        return (
            <div className="heightb100">
                <h1>How can we help?</h1>
                <div className="support">
                    <article>
                        <h3>Most Popular Articles</h3>
                        <ul>
                            <li>
                                <p class="ques"><a href="full-support.html">How do I upgrade my account?</a></p>
                                <span class="ans">To upgrade (or preview our prising packages), do the following : Click on your profile pic...</span>
                            </li>
                            <li>
                                <p class="ques"><a href="full-support.html">How to find my employees list?</a></p>
                                <span class="ans">To find employees list you need to click on staff icon...</span>
                            </li>
                            <li>
                                <p class="ques"><a href="full-support.html">Where to upoload serials on server?</a></p>
                                <span class="ans">You can click on upload icon from navigation or can also go to product de...</span>
                            </li>
                            <li>
                                <p class="ques"><a href="full-support.html">How do I upgrade my account?</a></p>
                                <span class="ans">To upgrade (or preview our prising packages), do the following : Click on your profile pic...</span>
                            </li>
                            <li>
                                <p class="ques"><a href="full-support.html">How to find my employees list?</a></p>
                                <span class="ans">To find employees list you need to click on staff icon...</span>
                            </li>
                            <li>
                                <p class="ques"><a href="full-support.html">Where to upoload serials on server?</a></p>
                                <span class="ans">You can click on upload icon from navigation or can also go to product de...</span>
                            </li>
                        </ul>
                        <div class="loadmore">
                            <a href="#!">Load more <i class="material-icons">add</i></a>
                        </div>
                    </article>
                </div>
            </div>
        );
    }
}

export default Support;
