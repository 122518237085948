import React from 'react';
import { SHEET_TRIM_LENGTH } from '../../constants';
import WorkSheetFooterItem from './WorkSheetFooterItem';
class SpreadSheetFooter extends React.Component{

    
    render() {
        const { responseData, onSheetChangeComplete, activeSheetIndex, activeFileIndex, commonFormat, onIgnoreSheet } = this.props;
        const worksheetItem = responseData.map((spreadsheet,idx) => { 
            const length = SHEET_TRIM_LENGTH;
            const sheetname = spreadsheet.originalName;
            const trimmedString = sheetname.length > length ?
                sheetname.substring(0, length - 3) + "..." :
                sheetname;
            const batchId = spreadsheet.batchId;
            return (<li key={"ssheet" + idx} className="active-tab" >

                <WorkSheetFooterItem commonFormat={commonFormat} onIgnoreSheet={onIgnoreSheet}
                    activeSheetIndex={activeSheetIndex} activeFileIndex={activeFileIndex} sheets={spreadsheet.sheets}
                    spreadSheetName={sheetname} onSheetChangeComplete={onSheetChangeComplete} batchId={batchId} />
                <span><a  data-tooltip={sheetname} data-tooltip-position="right">{trimmedString}</a></span>
            </li>);
        });
        return (<ul> {worksheetItem} </ul>)
     
    }
}

export default SpreadSheetFooter;
