import React from 'react';
import Chart from "react-google-charts";
import theme from '../../theme';

class GraphReports extends React.Component {

    constructor(props) {
        super(props);
        this.listener_handler = null;
    }

    render() {
        const { data, downloadReportCallback } = this.props;
        return (
            <Chart
                className={"GraphClass"}
                width={"100%"}
                height={"300px"}
                chartType="ColumnChart"
                data={data}
                loader={<div>Loading Chart</div>}
                options={{
                    chartArea: { width: "70%", bottom: 50, left: 50, },

                    isStacked: true,
                    vAxis: {
                        minValue: 0,
                    },
                    animation: {
                        startup: true,
                        easing: 'linear',
                        duration: 300,
                    },
                    backgroundColor: '#fafafa',
                    legend: { textStyle: { color: '#ff684d' } },
                    series: {
                        0: { color: '#a6da00' }

                    },
                    hAxis: {
                        textStyle: { color: theme.palette.primary.main },
                    }

                }}
                // stackoverflow.com/a/60697494
                chartEvents={[
                    {
                        eventName: "ready",
                        callback: ({ chartWrapper, google }) => {

                            if (!document.getElementById('graphReportStyle')) {
                                const head = document.querySelector("head");
                                const styles = 'text[text-anchor="middle"] { cursor: pointer; text-decoration : underline }';
                                let css = document.createElement("style");
                                css.setAttribute("id", "graphReportStyle");
                                if (css.styleSheet) {
                                    css.styleSheet.cssText = styles;
                                } else {
                                    css.appendChild(document.createTextNode(styles));
                                }
                                head.appendChild(css);
                            }

                            var monthClickHandler = function (event) {
                                var parts = event.targetID.split("#");
                                if (parts.indexOf("label") >= 0) {
                                    let monthIndex = parts[parts.indexOf("label") + 1];
                                    monthIndex = parseInt(monthIndex);
                                    downloadReportCallback(monthIndex);
                                }
                            };
                            this.listener_handler && google.visualization.events.removeListener(this.listener_handler);
                            this.listener_handler = google.visualization.events.addListener(chartWrapper.getChart(), "click", monthClickHandler);

                        }
                    }
                ]}

            />

        );
    }
}
export default GraphReports;