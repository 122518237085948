import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_SETTINGS, ADD_SETTING, DELETE_SETTING } from '../../libs/AdminQuery';
import config from "../../config";
import {
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Grid,
    Divider,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Switch,
} from '@material-ui/core';

const AdminSettings = () => {
    const [settingType, setSettingType] = useState('AllowedApps');
    const { loading, error, data } = useQuery(GET_SETTINGS, {
        variables: { settingType },
    });

    const [addSetting] = useMutation(ADD_SETTING, {
        refetchQueries: [{ query: GET_SETTINGS, variables: { settingType } }],
    });

    const [deleteSetting] = useMutation(DELETE_SETTING, {
        refetchQueries: [{ query: GET_SETTINGS, variables: { settingType } }],
    });

    const [apps, setApps] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentApp, setCurrentApp] = useState(null);
    const [action, setAction] = useState(null);

    useEffect(() => {
        fetch(`${config.VSP_URL}/api/endpoint`)
            .then(response => response.json())
            .then(data => {
                setApps(data.apps);
            })
            .catch(error => {
                console.error('Error fetching apps:', error);
            });
    }, []);

    const handleToggle = (app, action) => {
        setCurrentApp(app);
        setAction(action);
        setDialogOpen(true);
    };

    const handleConfirm = () => {
        if (action === 'enable') {
            addSetting({
                variables: {
                    setting: {
                        settingType,
                        settingKey: currentApp.appname,
                        settingValue: currentApp.appname,
                    },
                },
            });
        } else if (action === 'disable') {
            deleteSetting({
                variables: {
                    settingType,
                    settingKey: currentApp.appname,
                },
            });
        }
        setDialogOpen(false);
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;

    const enabledAppNames = data.admin.configuration.settings.map(setting => setting.settingKey);
    const combinedApps = apps.map(app => ({
        ...app,
        enabled: enabledAppNames.includes(app.appname),
    }));

    return (
        <Container>
            <Typography variant="h4" gutterBottom align="center">
                SPV App Providers
            </Typography>
            <Paper elevation={3}>
                <List>
                    {combinedApps.map((app) => (
                        <ListItem key={app.id}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                    <ListItemText primary={`${app.serviceadministrator} - ${app.appname}`} />
                                </Grid>
                                <Grid item xs={4}>
                                    {data.admin.hasConfigAccess && (
                                        <Switch
                                            checked={app.enabled}
                                            onChange={() => handleToggle(app, app.enabled ? 'disable' : 'enable')}
                                            name="appToggle"
                                            color="primary"
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </ListItem>
                    ))}
                </List>
            </Paper>

            <Dialog
                open={dialogOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{action === 'enable' ? "Enable App Provider" : "Disable App Provider"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {action === 'enable' ? "enable" : "disable"} the app provider "{currentApp?.serviceadministrator} - {currentApp?.appname}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default AdminSettings;
