import React, { Fragment } from 'react';
import _ from 'lodash';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
const styles = {
    align: {
        textAlign: 'right',
    },
    icon: {
        fontSize: '22px',
        margin: '0 5px 0 0',
    },
    downloadcsv: {
        marginRight: '10px',
        color: 'white',
        background: red[500],
        '&:hover': {
            background: red[600],
            color: 'white',
        },
    },
    button: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        '&:hover': {
            background: theme.palette.common.green
        }
    },
    progress: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    circleWhite: {
        color: theme.palette.common.white,
    }
};

class FbButton extends React.Component {
    
    constructor(props) {
        super(props);
        this._id = _.uniqueId(this.props.Prefix ? this.props.Prefix : "loader");
        this.loaderStyle = this.props.loaderStyle != null ? this.props.loaderStyle : 'default';
    
    }

   

    render() {
        
        const { classes, loading, size, color, tabIndex, variant, onClick, title, type, className } = this.props;
        return (
            <Button className={className} size={size} variant={variant} type={type} {...loading === false ? { alignitem:'center' } : ''} 
                color={color} {...loading === true ? { disabled: true } : ''} tabIndex={tabIndex} onClick={() => { if (onClick != null) { onClick(); } }} >

                {loading ?
                    <Fragment> <CircularProgress size={19} classes={{ root: classes.progress }} />   {title}</Fragment> : <Fragment>
                        {this.props.renderIcon()}
                        {title}
                    </Fragment>
                }
            </Button>);
    }
}
FbButton.defaultProps = {
    className:'',
    size: "large",
    color: "primary",
    isloading: false,
    tabIndex:0,
    variant: "contained",
    type: "submit",
    onClick: null,
    title: 'Default Button',
    renderIcon: () => { }
 

}

export default withStyles(styles)(FbButton);