import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    GET_TOP_MANUFACTURERS,
    GET_INSTALLATIONS_BY_AREA,
    GET_TOP_INSTALLERS,
    GET_TOP_RETAILERS,
    GET_INSTALLATIONS_BY_APP_PROVIDER
} from '../../../libs/stats_queries';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button, Modal, Backdrop, Fade } from '@material-ui/core';
import Loader from './Loader';
import Error from './Error';
import { parse } from 'json2csv';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    datePicker: {
        margin: theme.spacing(1),
    },
    chartContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    chartItem: {
        flex: '1 1 45%', // This makes the charts take 50% of the width on larger screens
        margin: theme.spacing(2),
        minWidth: 300, // Ensure a minimum width
        maxWidth: '45%', // Ensure a maximum width
        [theme.breakpoints.down('sm')]: {
            flex: '1 1 100%', // This makes the charts take 100% width on smaller screens
            maxWidth: '100%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%',
        height: '80%',
        overflow: 'auto',
    },
}));

const StatDashboard = () => {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [toDate, setToDate] = useState(new Date());
    const [openModal, setOpenModal] = useState(false);
    const [selectedChart, setSelectedChart] = useState(null);

    const handleFromDateChange = date => {
        setFromDate(date);
    };

    const handleToDateChange = date => {
        setToDate(date);
    };

    const handleOpenModal = (chartData) => {
        setSelectedChart(chartData);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedChart(null);
    };

    const { loading: loadingManufacturers, error: errorManufacturers, data: dataManufacturers } = useQuery(GET_TOP_MANUFACTURERS, {
        variables: { fromDate, toDate }
    });
    const { loading: loadingAreas, error: errorAreas, data: dataAreas } = useQuery(GET_INSTALLATIONS_BY_AREA, {
        variables: { fromDate, toDate }
    });
    const { loading: loadingInstallers, error: errorInstallers, data: dataInstallers } = useQuery(GET_TOP_INSTALLERS, {
        variables: { fromDate, toDate }
    });
    const { loading: loadingRetailers, error: errorRetailers, data: dataRetailers } = useQuery(GET_TOP_RETAILERS, {
        variables: { fromDate, toDate }
    });
    const { loading: loadingAppProviders, error: errorAppProviders, data: dataAppProviders } = useQuery(GET_INSTALLATIONS_BY_APP_PROVIDER, {
        variables: { fromDate, toDate }
    });

    if (loadingManufacturers || loadingAreas || loadingInstallers || loadingRetailers || loadingAppProviders) return <Loader />;
    if (errorManufacturers || errorAreas || errorInstallers || errorRetailers || errorAppProviders) return <Error />;

    const exportToCSV = (data, filename) => {
        const csv = parse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderChart = (title, data, dataKey, fill, filename) => (
        <Paper className={classes.chartItem}>
            <Typography variant="h6">{title}</Typography>
            <BarChart width={500} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataKey} />
                <YAxis />
                <Tooltip />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
                <Bar dataKey="totalInstallations" fill={fill} />
            </BarChart>
            <Button variant="contained" color="primary" onClick={() => exportToCSV(data, filename)}>
                Export {title}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => handleOpenModal({ title, data, dataKey, fill })}>
                View Fullscreen
            </Button>
        </Paper>
    );

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container justify="flex-start">
                    <KeyboardDatePicker
                        margin="normal"
                        id="from-date-picker"
                        label="From Date"
                        format="DD/MM/YYYY"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        className={classes.datePicker}
                    />
                    <KeyboardDatePicker
                        margin="normal"
                        id="to-date-picker"
                        label="To Date"
                        format="DD/MM/YYYY"
                        value={toDate}
                        onChange={handleToDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        className={classes.datePicker}
                    />
                </Grid>
            </MuiPickersUtilsProvider>

            <div className={classes.chartContainer}>
                {renderChart('Top Manufacturers by Installations', dataManufacturers.admin.reports.topManufacturers, 'displayName', '#8884d8', 'top_manufacturers.csv')}
                {renderChart('Installations by Area', dataAreas.admin.reports.installationsByArea, 'stateName', '#82ca9d', 'installations_by_area.csv')}
                {renderChart('Top Installers by Installations', dataInstallers.admin.reports.topInstallers, 'firstName', '#ffc658', 'top_installers.csv')}
                {renderChart('Top Retailers by Installations', dataRetailers.admin.reports.topRetailers, 'retailerName', '#82ca9d', 'top_retailers.csv')}
                {renderChart('Installations by App Provider', dataAppProviders.admin.reports.installationsByAppProvider, 'iRequesterAppName', '#ffc658', 'installations_by_app_provider.csv')}
            </div>

            <Modal
                className={classes.modal}
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.modalPaper}>
                        {selectedChart && (
                            <>
                                <Typography variant="h4">{selectedChart.title}</Typography>
                                <BarChart width={1024} height={768} data={selectedChart.data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey={selectedChart.dataKey} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend wrapperStyle={{ fontSize: '12px' }} />
                                    <Bar dataKey="totalInstallations" fill={selectedChart.fill} />
                                </BarChart>
                            </>
                        )}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default StatDashboard;
