import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';
import React from 'react';
const styles = theme => ({
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    linkBtn: {
        padding: 0,
        transition: "200ms ease",
        justifyContent: "left",
        '&:hover': {
            background: "transparent",
            textDecoration: "underline",
        }
    },
    avatar1: {
        borderRadius: 2,
        fontSize: " 12px",
        padding: 0,
        width: "24px",
        height: "26px",
        marginLeft: "8px",
        backgroundColor: theme.palette.primary.main,
    },
    button1: {
        padding: "6px 8px",
    }
});
const InstallationTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[300],
        fontSize: 14,
        whiteSpace: " nowrap",
        '&:last-child': {
            paddingRight: 16,
        },
        [theme.breakpoints.down('sm')]: {
            display: "block",
            textAlign: "right",
            '&:before': {
                content: "attr(data-label)",
                float: "left",
                fontWeight: "bold",
                textTransform: "uppercase",
                lineHeight: "24px",
                marginRight: "16px",
            },
        },
    },
}))(TableCell);
class InstallationRow extends React.Component {
    constructor(props) {
        super(props);
        this._id = _.uniqueId('installation_avail');
        this.showPanelDetails = this.showPanelDetails.bind(this);
    }
    showPanelDetails() {
        this.props.showPanelDetails(this.props.installation.productInstallationId);
    }
    render() {
        const { classes } = this.props;
        const installation = this.props.installation;
        //const productInfoRows = installation.products.map((model, index) =>
        // (
        // <ProductInfo key={index} product={model} />
        // ));
        return (
            <TableRow className={classes.row} key={this._id}>
                <InstallationTableCell data-label="Installation Id">{installation.productInstallationId}</InstallationTableCell>
                <InstallationTableCell data-label="Installation Date">{installation.installationDate}</InstallationTableCell>
                <InstallationTableCell data-label="City">{installation.address.city}</InstallationTableCell>
                <InstallationTableCell data-label="State">{installation.address.state}</InstallationTableCell>
                <InstallationTableCell data-label="Total Panels">
                    <Button size="small" variant="outlined" classes={{ root: classes.button1 }} color='primary' onClick={() => this.showPanelDetails()}>
                        View <Avatar className={classes.avatar1} color="primary">{installation.productSerialCount} </Avatar>
                    </Button>
                </InstallationTableCell>
            </TableRow>
        );
    }
}

export default withStyles(styles)(InstallationRow);
