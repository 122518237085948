import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import theme from '../theme';
const styles = {
    appBar: {
        background: theme.palette.background.default,
        borderTop: "1px solid #ececec",
        position: "inherit",
        boxShadow: "none",
        marginTop: theme.spacing(1),

    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        minHeight:"42px",

    },

};
class Footer extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            //<footer>
            //    <p>Copyright &copy; Formbay.com</p>
            //</footer>           
            <AppBar position="fixed" color="default" className={classes.appBar} component="footer">
                    <Toolbar className={classes.toolbar}>
                        <Typography  component="p">
                       
                        Copyright &copy;2020 Formbay.Inc. All rights reserved.
                        </Typography>
                    </Toolbar>
                </AppBar>     
        );
    }
}

export default withStyles(styles)(Footer);
