import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import React from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { ManufacturerAvailable_EndPoint, ManufacturerValid_EndPoint, RegisterApi, UploadFrequencyData, ProfileApi, CheckUsername } from '../../constants';
import Search from '../auth/Search';
import AuthService from '../AuthService';
import LocationSearchInput from '../common/LocationSearchAutocomplete';
import UserProfile from '../auth/UserProfile';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../../theme';
const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
        textTransform: 'inherit',
    },
    labelTxt: {
        fontSize: '15px',
    },
    rootCaption: {
        flexGrow: 1,
        fontSize: '16px',
        lineHeight: '24px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    formControl: {
        width: "100%",
        position:"relative",
    },
    checkboxFrame: {
        paddingLeft: theme.spacing(1),
    },
    dense: {
        //marginTop: theme.spacing(1) * 0,
        //marginBottom: theme.spacing(1) * 0,
        //paddingTop: theme.spacing(1) * 0,
    },
    notchedOutline: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    container: {
        height: '100%',
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        width: 300,
        height: 50,
        '&:disabled': {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
        '&:hover': {
            '&:disabled': {
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
            },
        },
    },
    validText: {
        borderColor: "#41A500"
    },
    logText: {
        marginBottom: "30px",
        textAlign: "center",
        textTransform: 'inherit',
        fontWeight: 'normal',
    },
});
const Logo = styled.div`
    width: 100%;
    float:left;
    padding: 30px 0 30px 0;
    //margin: 0 8px;
    text-align: center;
`;
//const Title = styled.h1`
//    width: 100%;
//    float: left;
//    color: #333;
//    text-transform: uppercase;
//    text-align: center;
//    margin-bottom:30px;
//`;
const RegLink = styled.div`
    width: 100%;
    float: left;
    text-align: center;
    font-size: 14px;
    font-family: 'NHaasGroteskDSPro-55Rg';
    color: #4e4e4e;    
`;

const Anchor = styled.a`
    color: #0d4add;   
    &:hover {
        text-decoration: underline;
    }
`;
class RegisterForm extends React.Component {
    constructor() {
        super();
        this.state = {
            isValidManufacturer: true, isValidated: false, password: '', isAvailable: true, isloading: false,
            showPassword: false, user: {
                Username: '', Password: '', FirstName: '', LastName: '', Email: '', Mobile: '', CompanyName: '', AbnNo: '', Phone: '', UploadFrequency: 0, Supplier: false, Address: null, PlaceId: null, AddressLine1:''
            }
        };
        this.isManufacturerAvaiable = this.isManufacturerAvaiable.bind(this);
        this.isManufacturerValid = this.isManufacturerValid.bind(this);
        this.isUserNameAvaiable = this.isUserNameAvaiable.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.getBanks = this.getBanks.bind(this);
        this.updateformState = this.updateformState.bind(this);
        this.updateformState = this.updateformState.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.checkABN = this.checkABN.bind(this);
        this.matchPassword = this.matchPassword.bind(this);
        this.Auth = new AuthService();
        this.updateCompanyName = this.updateCompanyName.bind(this);
    }
    updateCompanyName(name) {

        this.updateformState({ "CompanyName": name });
    }
    async isUserNameAvaiable(filter) {

        if (filter !== '' && !this.state.isloading) {
            const data = await (await fetch(CheckUsername + filter)).json();
            return !data.status;
        }
        else {
            return true;
        }
    }

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isAbn', (value) => {
            return this.checkABN(value);
        });

        ValidatorForm.addValidationRule('isPassword', (value) => {
            return this.matchPassword(value);
        });
        ValidatorForm.addValidationRule('isUserNameAvaiable', (value) => {
            return this.isUserNameAvaiable(value);
        });
    }
    matchPassword(value) {
        // var patt = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$%*?&]{6,}");
        // var res = patt.test(value);

        // return res;
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$%*?&]{6,}$/.test(value)) { return true }
        return false;
    }

    async isManufacturerAvaiable(filter) {

        if (filter !== '' && !this.state.isloading) {

            const data = await (await fetch(ManufacturerAvailable_EndPoint + encodeURIComponent(filter))).json();
            return data.status;
        }
        else {
            return true;
        }
    }

    async isManufacturerValid(filter) {
        
        if (filter !== '' && !this.state.isloading) {

            const data = await (await fetch(ManufacturerValid_EndPoint + encodeURIComponent(filter))).json();
            return data.status;

        }
        else {
            return true;
        }
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleChange = (event) => {
        this.updateformState({ [event.target.name]: event.target.value });
    }
    handleCheckChange = (event) => {
        this.updateformState({ [event.target.name]: event.target.checked });
    }
    handleAddressChange = (address, placeId) => {
        this.updateformState({ "Address": address, "PlaceId": placeId });
    }
    updateformState(obj) {
        const { user } = this.state;
        let userState =
            { ...user, ...obj };
        this.setState(state => ({ "user": userState }));

    }
    checkABN(str) {

        if (!str) {
            return false;
        }
        str = str.replace(/ +/g, "");
        if (str.length !== 11) {
            return false;
        }
        var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
            checksum = str.split('').map(Number).reduce(
                function (total, digit, index) {
                    if (!index) {
                        digit--;
                    }
                    return total + (digit * weights[index]);
                },
                0
            );

        if (!checksum || checksum % 89 !== 0) {
            return false;
        }

        return true;
    }
    handleBlur(event) {

    }
    handleSubmit = (e) => {
        if (this.state.isloading) return;
        this.setState({ isloading: true, isLoginFailed: false });

        fetch(RegisterApi, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.user)
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            this.setState({ isloading: false});
            return Promise.reject(res);

        }).then(res => {

            this.Auth.setToken(res);
            const token = this.Auth.getToken();
            fetch(ProfileApi, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
                body: null
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    this.setState({ isloading: false });
                    UserProfile.setManufacturerId(res.manufacturerId);
                    UserProfile.setDisplayName(res.displayName);
                    UserProfile.setDistributor(res.isDistributor);
                    UserProfile.setUserId(res.userId);
                    UserProfile.setUploadFrequency(res.uploadFrequency);
                    UserProfile.setAddress(res.address);
                    UserProfile.setGooglePlaceId(res.googlePlaceId);
                    UserProfile.setLogo(res.logo);
                    UserProfile.setProfilePhoto(res.profilePhoto);
                    UserProfile.setFirstName(res.firstName);
                    UserProfile.setLastName(res.lastName);
                    UserProfile.setEmail(res.email);
                    UserProfile.setPhone(res.phoneNumber);
                    UserProfile.setIsStaff(res.isStaff);
                    // this.updateProfile();
                    this.props.history.replace('/dashboard');
                });
         
        })
            .catch(err => {
                //this.setState({ isLoading: false, isLoginFailed: true });
                this.setState({ isloading: false });
            });
    }
    getBanks = () => {
        return UploadFrequencyData.map(bank => (
            <option key={bank.code} value={bank.code}>
                {bank.name}
            </option>
        ));
    };
    render() {
        const { user, isloading } = this.state;

        const { Username, Password, FirstName, LastName, Email, Mobile, AbnNo, Phone, UploadFrequency, Supplier, AddressLine1 } = user;
        const { classes } = this.props;
        const requiredMessage = "";
        const searchOptions = {
            types: ['address'],
            componentRestrictions: { country: ['aus', 'cn'] }
        }
        //const isAvailable = this.state.isAvailable !== true ? { error: true } : {};
        return (
            <ValidatorForm ref="form" instantValidate={true} onError={errors => console.log(errors)}
                onSubmit={this.handleSubmit}>
                <Grid container spacing={1} justify="center">
                    <Grid item sm={12} >
                        <Logo>
                            <img src={require("../../assets/images/solar-logo.svg")} width="250" alt="Formbay Logo" />
                        </Logo>
                        <Typography variant="h1" className={classes.logText}>
                            Register New Account
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant="h2" className={classes.root}>User Details</Typography>
                        <Typography variant="caption" className={classes.rootCaption} >
                            Please create your username and password here. Please ensure your password has a combination of numbers and letters.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="loginForm">
                            <FormControl className={classes.formControl} error>
                                <TextValidator autoComplete="off"
                                    id="Username" name="Username"
                                    label="Username*"
                                    placeholder="Username"
                                   fullWidth
                                    margin="normal"
                                    variant="outlined"
                                  //  className={classNames(classes.dense)}
                                    value={Username}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    validators={['required', 'isUserNameAvaiable']}
                                    errorMessages={['This field is required', 'User name already taken']}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="loginForm">
                            <FormControl className={classes.formControl} error>
                                <TextValidator autoComplete="off"
                                    id="Password"
                                    name="Password"
                                 fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Password*"
                                    value={Password}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                 //   className={classNames(classes.dense)}
                                    validators={['isPassword', 'required']}
                                    errorMessages={['Password must be combination of upper case,digit and special characters and should have minimum 6 characters', requiredMessage]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />



                            </FormControl>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <Typography variant="h2" className={classes.root}>
                            Contact Details
                        </Typography>
                        <Typography variant="caption" className={classes.rootCaption}>
                            Please input your contact information as the registered user for this account. These details will be used in the event we need to contact you or reset your password details.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error>
                            <TextValidator
                                id="FirstName" name="FirstName"
                                label="First Name*"
                                placeholder="First Name"
                             fullWidth
                                margin="normal"
                                variant="outlined"
                               // className={classNames(classes.dense)}
                                value={FirstName}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[requiredMessage]}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error>

                            <TextValidator
                                id="LastName" name="LastName"
                                label="Last Name*"
                                placeholder="Last Name"
                             fullWidth
                                margin="normal"
                                variant="outlined"
                               // className={classNames(classes.dense)}
                                value={LastName}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[requiredMessage]}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error>
                            <TextValidator
                                id="Email" name="Email"
                                label="Email*"
                                placeholder="example@domain.com"
                                type="email"
                             fullWidth
                                margin="normal"
                                variant="outlined"
                              //  className={classNames(classes.dense)}
                                value={Email}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                validators={['required', 'isEmail']}
                                errorMessages={[requiredMessage, 'Please enter valid email address']}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} >
                            <TextValidator
                                id="Mobile" name="Mobile"
                                label="Mobile*"
                                placeholder="Mobile"
                             fullWidth
                                margin="normal"
                                variant="outlined"
                               // className={classNames(classes.dense)}
                                value={Mobile}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}

                                validators={['matchRegexp:^0(2|4)[0-9]{8}$', 'required']}
                                errorMessages={['Mobile should be 10 digit and must start from 02 or 04', requiredMessage]}

                            />

                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant="h2" className={classes.root}>
                            Company Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} error>
                            <Search isloading={isloading} ValidatorForm={ValidatorForm} updateCompanyName={this.updateCompanyName} isManufacturerAvaiable={this.isManufacturerAvaiable} isManufacturerValid={this.isManufacturerValid} />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error>
                            <TextValidator
                                id="Phone" name="Phone"
                                label="Company Phone*"
                                placeholder="Company Phone"
                             fullWidth
                                margin="normal"
                                variant="outlined"
                              //  className={classNames(classes.dense)}
                                value={Phone}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                validators={['matchRegexp:^([+]?61[-]?|[0])?[1-9][0-9]{8}$', 'required']}
                                errorMessages={['Phone should be 10 digit and must start from 04 or 06', requiredMessage]}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error>
                            <SelectValidator
                                label="Upload Frequency*"
                                placeholder="Upload Frequency"
                                name="UploadFrequency"
                                id="UploadFrequency"
                                onChange={this.handleChange}
                                value={UploadFrequency}
                                margin="normal"
                                SelectProps={{
                                    native: true
                                }}
                                variant="outlined"
                                className={classNames([classes.dense, classes.formbay123])}
                                validators={['matchRegexp:^[1-9]$']}
                                errorMessages={[requiredMessage]}
                            >
                                <option value="0" >Select time interval</option>
                                {this.getBanks()}
                            </SelectValidator>


                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error>
                            <TextValidator
                                id="AbnNo" name="AbnNo"
                                label="ABN*"
                                placeholder="ABN"
                                fullwidth="true"
                                margin="normal"
                                variant="outlined"
                                className={classNames(classes.dense)}
                                value={AbnNo}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                validators={['required', 'isAbn']}
                                errorMessages={[requiredMessage, 'Please provide valid ABN no']}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12} >
                        <FormControl className={classes.formControl} error>
                            <TextValidator
                                id="AddressLine1" name="AddressLine1"
                                label="Address Line 1*"
                                placeholder="Apt,suite,unit etc"
                                fullwidth="true"
                                margin="normal"
                                variant="outlined"
                                className={classNames(classes.dense)}
                                value={AddressLine1}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={[requiredMessage]}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} error>
                            <LocationSearchInput searchOptions={searchOptions} handleAddressChange={this.handleAddressChange} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.checkboxFrame}>
                        <FormControlLabel
                            classes={{
                                label: classes.labelTxt,
                            }}
                            control={
                                <Checkbox
                                    name="Supplier"
                                    fullwidth="true"
                                    margin="normal"
                                    variant="outlined"
                                    checked={Supplier}
                                    onChange={this.handleCheckChange}
                                    value={Supplier + ""}
                                    color="primary"
                                />
                            }
                            label="Are you authorised supplier of solar products in Australia?"
                            placeholder="Are you authorised supplier of solar products in Australia?"
                        />

                    </Grid>
                </Grid>

                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <Button className={classes.button} size="large" variant="contained" type="submit" alignitem='center' color="primary" {...isloading === true ? { disabled:true } : ''} {...isloading === true ? 'disableRipple' : ''} tabIndex="19" >
                            {isloading ?
                                <CircularProgress color={theme.palette.common.white} size={19} style={styles.root} /> :
                            <span>Register</span>
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <RegLink>Just Remembered?&nbsp;<Anchor as="a" href="/login" title="Log In">Log In</Anchor></RegLink>
                    </Grid>
                </Grid>
              
            </ValidatorForm>
        );
    }
}
export default withRouter(withStyles(styles)(RegisterForm));
//export default withStyles(styles)(withRouter(RegisterForm));