import { AppBar, Toolbar } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Profile from '../Profile';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        flex: 1,
    },
    appBar: {
        marginLeft: drawerWidth,
        boxShadow: "none",
        background: theme.palette.grey[400],
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    grow: {
        flexGrow: 1,
    },
})
  

class AdminHeader extends React.Component {

    render() {
        const { classes } = this.props;
      
        return (
            <div className={classes.root}>
                <AppBar className={classes.appBar} color="default">
                    <Toolbar>                
                        <div className={classes.grow} />                                       
                            <Profile />                      
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withStyles(styles)(AdminHeader);