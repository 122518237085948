import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});
const TableHeadCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "top",
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "auto",
        whiteSpace: " nowrap",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const TableHeadCell2 = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        fontSize: 13,
        verticalAlign: "top",
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "auto",
        whiteSpace: " nowrap",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class ProductLineHeader extends React.Component {
    constructor(props) {
        super(props);
        this.getDynamicCell = this.getDynamicCell.bind(this);
    }

    getDynamicCell(type, classes, data) {
        switch (type) {
            case 1: {
                return (<TableHeadCell>
                    Model
                </TableHeadCell>)
            }


            case 2: {
                return (<TableHeadCell>
                    
                </TableHeadCell>)
            }
            case 3: {
                return (<TableHeadCell>   
                   Modify
                </TableHeadCell>)
            }
            default: {
                return (<TableHeadCell width="15%">
                 Assigned Models
                </TableHeadCell>)
            }
        }
    }
    render() {
        const { classes, rowType,show_action } = this.props;
        const lastCell = this.getDynamicCell(rowType, null);
        return (<TableHead>
            <TableRow className={classes.tableRow}>
                 <TableHeadCell width="40%">Product Line</TableHeadCell>
                <TableHeadCell width="15%">Warranty</TableHeadCell>
                 <TableHeadCell width="15%">&nbsp;</TableHeadCell>
                 {lastCell}
                {show_action ? <TableHeadCell width="15%">Action</TableHeadCell> : null}
            </TableRow > 
            <TableRow className={classes.tableRow}>
                <TableHeadCell2>&nbsp;</TableHeadCell2>
                <TableHeadCell2>Component</TableHeadCell2>
                <TableHeadCell2>Performance</TableHeadCell2>
              <TableHeadCell2>&nbsp;</TableHeadCell2>      
                
                {show_action ? <TableHeadCell2>&nbsp;</TableHeadCell2>   : null} 
            </TableRow > 
        </TableHead>

        );
    }
}

export default withStyles(styles)(ProductLineHeader);