import React from 'react';
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});
const InstallTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "middle",
        padding: theme.spacing(2),
        height: "auto"
    },

}))(TableCell);

class InstallationHeader extends React.Component {


    constructor(props) { 
        super(props);
        this._id = _.uniqueId('installation_header');

    }
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }
    render() {
        return (

            <TableRow key={this._id}>
           
                <InstallTableCell>Installation Id</InstallTableCell>
                <InstallTableCell>Installation Date</InstallTableCell>
                <InstallTableCell>City</InstallTableCell>
                <InstallTableCell>State</InstallTableCell>
                <InstallTableCell>Total Panels</InstallTableCell>
            </TableRow>


        );




    }
}

export default withStyles(styles)(InstallationHeader);
