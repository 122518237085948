import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    FilledInput,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { CREATE_CITY } from '../../libs/Mutations'; // Adjust the import path accordingly

const CHECK_CITY_EXISTS = gql`
    query CheckCityExists($cityName: String!, $stateId: Int!, $postalCode: String!) {
        admin { cityExists(cityName: $cityName, stateId: $stateId, postalCode: $postalCode) }
    }
`;

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    inputRoot: {
        background: theme.palette.grey[100],
        '&:hover': {
            background: theme.palette.grey[200],
        },
        '&:after': {
            borderBottom: 'none',
        },
        '&:before': {
            borderBottom: 'none',
        },
    },
}));

const statePostcodeRanges = {
    4122: [2600, 2620], // Australian Capital Territory
    4123: [800, 899], // Northern Territory
    4125: [5000, 5999], // South Australia
    4126: [6000, 6999], // Western Australia
    4127: [1000, 2999], // New South Wales
    4128: [4000, 4999], // Queensland
    4129: [3000, 3999], // Victoria
    4130: [7000, 7999], // Tasmania
};

const isValidPostCode = (postCode, stateId) => {
    if (!stateId || !postCode) return false;
    const range = statePostcodeRanges[stateId];
    const pcode = parseInt(postCode);
    return range && pcode >= range[0] && pcode <= range[1];
};

const AddCityDialog = ({ open, addCity, handleClose }) => {
    const classes = useStyles();
    const [city, setCity] = useState('');
    const [stateId, setStateId] = useState('');
    const [postCode, setPostCode] = useState('');
    const [errors, setErrors] = useState({});

    const [checkCityExists, { loading: checkLoading, data: checkData }] = useLazyQuery(CHECK_CITY_EXISTS);
    const [createCity] = useMutation(CREATE_CITY);

    const handleCityChange = (e) => setCity(e.target.value);
    const handleStateChange = (e) => setStateId(e.target.value);
    const handlePostCodeChange = (e) => setPostCode(e.target.value);

    const handleAddCity = async () => {
        const newErrors = {};

        if (!city) {
            newErrors.city = 'City name is required.';
        }
        if (!stateId) {
            newErrors.state = 'State is required.';
        }
        if (!postCode) {
            newErrors.postCode = 'Postcode is required.';
        } else if (!isValidPostCode(postCode, stateId)) {
            newErrors.postCode = 'Invalid postcode for the selected state.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            checkCityExists({
                variables: {
                    cityName: city,
                    stateId: parseInt(stateId),
                    postalCode: postCode
                }
            });
        }
    };

    React.useEffect(() => {
        if (checkData && checkData.admin.cityExists) {
            setErrors({ form: 'This city, state, and postal code combination already exists.' });
        } else if (checkData && !checkData.admin.cityExists) {
            const cityData = {
                cityName: city,
                stateId: parseInt(stateId),
                postalCode: postCode
            };
            createCity({
                variables: {
                    city: cityData
                }
            }).then((data) => {
              //  alert(data.data.createCity);
                addCity(data.data.adminMutation.createCity);
                //handleClose();
            }).catch(error => {
                console.error('Error creating city:', error);
                setErrors({ form: 'Failed to create city. Please try again.' });
            });
        }
    }, [checkData]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New City</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Fill in the details to add a new city.
                </DialogContentText>
                <FormControl
                    className={classes.formControl}
                    variant="filled"
                    error={!!errors.city}
                >
                    <InputLabel htmlFor="cityName">City</InputLabel>
                    <FilledInput
                        id="cityName"
                        value={city}
                        onChange={handleCityChange}
                        classes={{ root: classes.inputRoot }}
                        disableUnderline
                    />
                    {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                </FormControl>
                <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    error={!!errors.state}
                >
                    <InputLabel id="dropdown-label">States</InputLabel>
                    <Select
                        labelId="dropdown-label"
                        value={stateId}
                        onChange={handleStateChange}
                        label="States"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={4122}>Australian Capital Territory</MenuItem>
                        <MenuItem value={4123}>Northern Territory</MenuItem>
                        <MenuItem value={4125}>South Australia</MenuItem>
                        <MenuItem value={4126}>Western Australia</MenuItem>
                        <MenuItem value={4127}>New South Wales</MenuItem>
                        <MenuItem value={4128}>Queensland</MenuItem>
                        <MenuItem value={4129}>Victoria</MenuItem>
                        <MenuItem value={4130}>Tasmania</MenuItem>
                    </Select>
                    {errors.state && <FormHelperText>{errors.state}</FormHelperText>}
                </FormControl>
                <FormControl
                    className={classes.formControl}
                    variant="filled"
                    error={!!errors.postCode}
                >
                    <InputLabel htmlFor="postCode">Post Code</InputLabel>
                    <FilledInput
                        id="postCode"
                        value={postCode}
                        onChange={handlePostCodeChange}
                        classes={{ root: classes.inputRoot }}
                        disableUnderline
                    />
                    {errors.postCode && <FormHelperText>{errors.postCode}</FormHelperText>}
                </FormControl>
                {errors.form && <FormHelperText error>{errors.form}</FormHelperText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddCity} color="primary" disabled={checkLoading}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCityDialog;
