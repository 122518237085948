import React, { useState, Fragment, useCallback } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useQuery } from '@apollo/react-hooks';
import {
    LINKS_PER_PAGE,
    SERIAL_TYPE,
    Role_Admin,
    Role_DISTRIBUTOR,
    Role_MANUFACTURER,
    Role_STAFF,
    Role_DISTRIBUTOR_STAFF,
} from '../../constants';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';

import SerialHeader from './rows/header/HeaderAvailable';
import SerialRow from './rows/RowAvailable';
import QueryHelper from '../../libs/QueryHelper';
import UserProfile from '../auth/UserProfile';

const styles = (theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {},
    paperTable: {
        border: '1px solid',
        borderColor: theme.palette.common.borderColor,
        minHeight: 'calc(100vh - 300px)',
        width: '100%',
        overflowX: 'auto',
        background: theme.palette.common.white,
    },
});

const FooterCell = withStyles((theme) => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const AvailableTab = (props) => {
    const { classes, globalView, availableSerialCountCallback, filter,serials } = props;
    let { manufacturerId } = props;

    

   

    const listSerials = serials != null
        ? serials.map((serial, index) => (
            <SerialRow key={index} serial={serial} manufacturerId={manufacturerId} index={index} />
        ))
        : null;

    const result = serials && serials.length > 0
        ? listSerials
        : <FbEmptyResult isTableBodyRequired={false} colSpan={5} errorStyle={'emptycell'} />;

    

    return (
        <Fragment>
            <div className={classes.root}>
                <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                    <Table className={classes.table}>
                        <Hidden smDown>
                            <TableHead>
                                <SerialHeader />
                            </TableHead>
                        </Hidden>
                        <TableBody>{result}</TableBody>
                        
                    </Table>
                </Paper>
            </div>
        </Fragment>
    );
};

export default withStyles(styles)(AvailableTab);
