import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import Customicon from '../../Customicon';
import TabContainer from "../TabContainer";
import DetailAvailableTab from "./productdetail/DetailAvailabletab";
import DetailFailedTab from "./productdetail/DetailFailedtab";
import DetailInstalledTab from "./productdetail/DetailInstalled";
import ProductHeaderRow from "./ProductHeaderRow";
import ProductRow from "./ProductRow";
import ProductLineDialog from './ProductLineDialog';
import { RandomText } from '../../constants';
import Hidden from '@material-ui/core/Hidden';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    button: {
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        '&:hover': {
            background: theme.palette.common.green,
        }
    },
    table: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.common.borderColor,
        lineHeight:"21px",
    },
    customicon: {
        marginRight: theme.spacing(1) * 1,
    },
    backanim: {
        transition: "400ms ease",
        marginLeft: "-5px"
        //'&:hover': {
        //    marginLeft: "-5px",
        //},
    },
    icon: {
        fontSize: 26,
        verticalAlign: "middle",
        marginTop: "-3px"

    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        color: theme.palette.grey[700],      
    },
    tabRoot: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.grey[100],
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        textTransform: 'capitalize',
        fontSize: "18px",
        textAlign: "center",
        color: theme.palette.grey[800],
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontFamily: 'NHaasGroteskDSPro-65Md',
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[900],
        },
        '&$tabSelected': {
            backgroundColor: theme.palette.grey[100],
            fontFamily: 'NHaasGroteskDSPro-65Md',
            color: theme.palette.grey[900],
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            borderColor: theme.palette.grey[300],
        },
        '&:focus': {
            color: theme.palette.grey[900],
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing(1) * 3,
    },
    badge: {
        verticalALign: "top",
        position: "inherit",
        borderRadius: theme.shape.borderRadius,
        transform: "initial",
        marginLeft: theme.spacing(2),
        fontFamily: "Roboto",
    },
    grow: {
        flexGrow: 1,
    },
    padding: {
        padding: 0,
        minHeight: "auto",
    },
    tableroot: {
        width: "100%",
        overflowX: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 'one', selectedProductId: null, selectedProductLineId: null, random: null, open: false,
        }
        this.onDialogShow = this.onDialogShow.bind(this);
        this.onDialogCLose = this.onDialogCLose.bind(this);
        this.notifyUpdate = this.notifyUpdate.bind(this);
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };
    onDialogCLose() {
        this.setState({ open: false, selectedProductId: null, selectedProductLineId: null, random: RandomText(8) });
    }

    notifyUpdate(mode, newProductLineId) {
        if (mode === true) {
            this.setState({ selectedProductLineId: null });
        }
        else {
            this.setState({ selectedProductLineId: newProductLineId });
        }

    }
    onDialogShow(scroll, model) {
        const productLineId = model.productLine != null ? model.productLine.productLineId : null;
        this.setState({ selectedProductLineId: productLineId, selectedProductId: model.productId, open: true, scroll });
    }
    render() {
        const { classes } = this.props;
        const { model } = this.props.location.state;
        const { open, selectedProductId, selectedProductLineId, value} = this.state;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Link to="/dashboard" title="back to dashboard">
                            <Typography color='primary' variant="h4">
                                <span className={classes.backanim}> <Icon className={classes.icon} >keyboard_arrow_left</Icon>    Back to Dashboard </span>
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" size="large" component={Link} to="/flash" color="primary" className={classes.button}>
                            <Customicon name="flash" width={21} fill="#fff" className={classes.customicon} />Upload Serials
                        </Button>
                    </Grid>
                </Grid>
                <div className="inner-wrapper margin-top15">
                    <div className={classes.tableroot}>                      
                        <Table className={classes.table}>
                            <Hidden xsDown>
                                <TableHead>
                                    <ProductHeaderRow />
                                </TableHead>
                            </Hidden>
                                <TableBody>
                                <ProductRow model={model} hideDetailNavigation={true} shouldOpenDialog={this.onDialogShow}/>
                                </TableBody>
                            </Table>                 
                    </div>
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                    >
                        <Tab
                            value="one"
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={<Toolbar className={classes.padding} variant="dense" disableGutters>
                                <Typography variant="h3" className={classes.grow}>
                                    Serial Failed
                           </Typography>
                                {/* <Badge color="error" badgeContent={0} max={999} classes={{ badge: classes.badge }} /> */}
                            </Toolbar>}

                        />
                        <Tab
                            value="two"
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <Toolbar className={classes.padding} variant="dense" disableGutters>
                                    <Typography variant="h3" className={classes.grow}>
                                        Installed
                                        </Typography>
                                    {/* <Badge color="secondary" badgeContent={0} max={999} classes={{ badge: classes.badge }} /> */}
                                </Toolbar>}
                        />
                        <Tab
                            value="three"
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <Toolbar className={classes.padding} variant="dense" disableGutters>
                                    <Typography variant="h3" className={classes.grow}>
                                        Available
                                        </Typography>
                                    {/* <Badge color="primary" badgeContent={0} max={999} classes={{ badge: classes.badge }} /> */}
                                </Toolbar>}
                        />
                    </Tabs>
                    {value === 'one' && <TabContainer> <DetailFailedTab Model={model} /></TabContainer>}
                    {value === 'two' && <TabContainer><DetailInstalledTab Model={model} /></TabContainer>}
                    {value === 'three' && <TabContainer> <DetailAvailableTab Model={model} /></TabContainer>}

                </div>
                <ProductLineDialog open={open} handleClose={this.onDialogCLose} selectedProductId={selectedProductId} selectedProductLineId={selectedProductLineId} notifyUpdate={this.notifyUpdate} />
            </div>
        );
    }
}

export default withStyles(styles)(ProductDetail);
