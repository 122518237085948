import FilledInput from '@material-ui/core/FilledInput';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import _ from "lodash";
const styles = theme => ({
    selectRoot: {
        padding: theme.spacing(1),
        width: "calc(100% - 16px)",
        fontSize: 14,
        fontWeight: '100',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        borderRadius: theme.shape.borderRadius,
        '&:focus': {
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.primary.main
        },
    },
    icon: {
        width: "100%",
    },
    error: {
        border: 'none !important',
        background: "none !important"
    },
     headerError: {
         border: '1px solid orange !important',
         backgroundColor:'none !important'
    },
    cellError: {
        borderColor: 'red'
    },

    dropDownContainerCompleted: {
        backgroundColor: "#d1edd1",
        position:" relative",
    },
   
    dropDownContainer: {
         padding: "8px",
        width: "100%",
        display: "block"
    },
    postip: {
        position:" absolute",
        right:"-8px",
        padding: 0,
        top: "-10px",
        "&:hover": {
            background: "none"
        }
    }

    });

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.red,
        color: 'rgba(255, 255, 255)',
        boxShadow: theme.shadows[1],
        fontSize: 18,
        marginTop: "-14px",
        padding: 16,
        borderRadius:"4px"

    },
}))(Tooltip);
    
class RequiredHeaderCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = { headerTitle: null }
        this.onDragOver = this.onDragOver.bind(this);
        this.drop = this.drop.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.changeField = this.changeField.bind(this);
    }

    drop(ev, oldField) {
        ev.preventDefault();
        var data = JSON.parse(ev.dataTransfer.getData("dropableField"));
        this.setState({ headerTitle: data.fieldName });
        const { onRequiredFieldUpdate, onRequiredFieldUpdateAndRemove } = this.props;
        if (oldField != null) {
            onRequiredFieldUpdateAndRemove(oldField.index, this.props.index, data);
        }
        else {
            onRequiredFieldUpdate(this.props.index, data);
        }
    }
    changeField(e, index, field) {
        
        e.preventDefault();
        const { onRequiredFieldUpdateAndRemove, onRequiredFieldRemoved, requiredFields } = this.props;
        var fieldName = e.target.value;
        var old_index = index;
        if (fieldName === "") {
            onRequiredFieldRemoved(old_index);
            this.setState({ headerTitle: null });
            return;
        }
        const new_assigned_field = requiredFields.find((item) => { return item.fieldName === fieldName });
       
        onRequiredFieldUpdateAndRemove(old_index, index, new_assigned_field);
        if (new_assigned_field != null) {
            this.setState({ headerTitle: new_assigned_field.fieldName });
        }
        else {
            this.setState({ headerTitle: null });
        }
    }
    onRemove(index) {
        const { onRequiredFieldRemoved } = this.props;
        onRequiredFieldRemoved(this.props.index);
        this.setState({ headerTitle: null });
    }
    onDragOver = (e, index) => {
        e.preventDefault();
    };
    render() {
        const { classes } = this.props;
        const { requiredFields, index } = this.props;
        const field = requiredFields.find((item) => { return item.index === index });
        const isMapped = field != null;
        

        const drpOptionalOption = requiredFields.map((item, idx) => {
            if (item.optional === false || (item.index != null && item.index !== index)) {
                return null;
            }
            let label = item.fieldName;
            return <option key={"opt_map_" + idx} value={label} >{label}</option>
        });

        const drpMandatoryOption = requiredFields.map((item, idx) => {
            if (item.optional === true || (item.index != null && item.index !== index)) {
                return null;
            }
            let label = item.fieldName;
            return <option key={"opt_mapped_" + idx} value={label}  >{label}</option>
        });

        const doesAllCellContainValidValues = !_.isNil(field) && field.hasOwnProperty('isDataValid') && field.isDataValid === false ? false : true;
        const drpIgnoreOption = <Fragment><option value="">Select</option>  </Fragment>
        const classRequired = { [classes.dropDownContainerCompleted]: isMapped, [classes.dropDownContainer]:true, placeholder: !isMapped, [classes.error]: isMapped && !doesAllCellContainValidValues };
        const thClassRequired = { 'mapped': true, [classes.headerError]: isMapped && !doesAllCellContainValidValues };
        const toolTipContent = isMapped && !doesAllCellContainValidValues && (

            <LightTooltip interactive
                title={"Did you assigned the wrong header? We have found that some values are not integers "} aria-label={"did you assigned the wrong header ? we have found that some values are not integers "} >
                <IconButton classes={{ root: classes.postip }} size="small" disableRipple focusRipple>
                    <InfoIcon color="error" fontSize="inherit" />
                </IconButton>
            </LightTooltip>
        );
        return (<th key={"tr_" + index} className={classNames(thClassRequired)} onDragOver={(e) => this.onDragOver(e, index)} onDrop={(e) => { this.drop(e, field); }}>
            <div className={classNames(classRequired)}>
                <div style={{ display: "inline-block", width:"100%" }}>
                <Select native onChange={(e) => { this.changeField(e, index, field); }} value={field != null ? field.fieldName : ""} input={<FilledInput classes={{ input: classes.selectRoot, root: classes.icon }} />}>
                    {drpIgnoreOption}
                    <optgroup label="Required">
                        {drpMandatoryOption}
                    </optgroup >
                    <optgroup label="Optional">
                        {drpOptionalOption}
                    </optgroup >
                    </Select>
                </div>              
                    {toolTipContent}            
            </div>
          
        </th>);
    }
}

export default withStyles(styles)(RequiredHeaderCell);
