import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import yellow from '@material-ui/core/colors/yellow';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import React, { Fragment } from 'react';
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import Jump from 'react-reveal/Jump';
import Slide from 'react-reveal/Slide';
import { Redirect } from 'react-router';
import { NotificationHUB } from '../../constants';
import AuthService from '../AuthService';
import FbLoader from '../common/FbLoader';
import ClientDataRow from './ClientDataRow';
import ClientRowDecoration from './ClientRowDecoration';
import FieldMappingView from './FieldMappingView';
import RequiredSerialTableHeader from './RequiredHeader';
import SaveSheet from './SaveSheetContainer';
import SpreadSheetFooter from './SpreadSheetFooter';


const InfoBackground = yellow[50];
const InfoColor = yellow[700];
const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    tableRoot: {
        overflowX: "scroll",
        width: "100%",
        minHeight: "468px"
    },
    loaderdiv: {
        position: "absolute",
        background: " rgba(0, 0, 0, 0.3)",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    progressLoader: {
        position: "absolute",
        right: 0,
        left: " 50%",
        top: " 50%",
        bottom: 0,
        marginTop: "-37px",
        marginLeft: "-50px",
    },
    prev: {
        position: " absolute",
        left: "50%",
        fontSize: "86px",
        top: "50%",
        marginLeft: "-140px",
        fontWeight: "bold",
        color: " #7b7b7b",
        transform: "rotate(-20deg)",
        opacity: " 0.2",
        marginTop: " -25px",
    },
    paper: {
        padding: theme.spacing(1),
        fontSize: 16,
        fontFamily: "NHaasGroteskDSPro-55Rg",
        background: fade(InfoBackground, 0.5),
        borderBottom: "1px solid #fbc02d"
    },
    InfoIcon: {
        verticalAlign: "middle",
        marginRight: theme.spacing(1),
        color: InfoColor
    },
    emptyTable: {
        fontSize: '40px !important',
        color: 'gray !important',
        verticalAlign: 'middle !important',
        textAlign: 'center !important'
    }
});


class UploadResultContainer extends React.Component {

    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        //this.transport = HttpTransportType.WebSockets;
        // this.logger = new NullLogger(LogLevel.Information);
        this.saveDataComplete = this.saveDataComplete.bind(this);

        this.interval = null;
        //   this.prepareSheetMutationVariable = this.prepareSheetMutationVariable.bind(this);
        this.state = {
            showProgressLoader: false,
            readProgress: 0, readProgressSheetNo: 0, writeProgress: 0, isReading: false, isWriting: false,
            isSaving: false, isComplete: false, uploadProcessStatus: false, pass: 0, fail: 0,
            hubConnection: new HubConnectionBuilder()
                .withUrl(NotificationHUB, { accessTokenFactory: () => this.Auth.getToken() })
                .configureLogging(LogLevel.Information)
                .build(), fileName: "", file: null, target: null
        };
        this.onSheetChangeComplete = this.onSheetChangeComplete.bind(this);
        this.startSavingData = this.startSavingData.bind(this);
        this.onCompleteClose = this.onCompleteClose.bind(this);
        this.toggleErrorContainer = this.toggleErrorContainer.bind(this);
    }

    componentDidMount = () => {
        const hubConnection = this.state.hubConnection;
        this.setState({ hubConnection }, () => {
            this.state.hubConnection.on('UploadComplete', (batchId, status, pass, failed) => {
                //if (this.batchUploadId !== batchId) {
                //    return;
                //}
                // const text = `${status}: ${pass} : ${failed}`;
                this.setState({ isSaving: false, isComplete: true, uploadProcessStatus: status, pass: pass, failed: failed, shouldShowCompleteAlert: true, showProgressLoader: false });
            });

            this.state.hubConnection.on('RowsCopied', (batchId, rows) => {
                if (!this.state.isWriting) {
                    this.setState({ writeProgress: rows, isWriting: true });
                }
                else {
                    this.setState({ writeProgress: rows });
                }

            });

            this.state.hubConnection.on('RowRead', (batchId, sheetNo, rowNo) => {

                if (!this.state.isReading) {
                    this.setState({ readProgress: rowNo, readProgressSheetNo: sheetNo, isReading: true });
                }
                else {
                }

                if (this.state.showProgressLoader) {
                    this.setState({ readProgress: rowNo, readProgressSheetNo: sheetNo, showProgressLoader: false });
                }
                else {
                    this.setState({ readProgress: rowNo, readProgressSheetNo: sheetNo });
                }
            });
            this.state.hubConnection
                .start().then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :-('));

        });
    }
    onCompleteClose() {
        this.setState({ shouldShowCompleteAlert: false });
    }
    saveDataComplete(data) {
        this.props.saveDataComplete(data);

    }
    startSavingData() {
        this.setState({ isSaving: true, isComplete: false, showProgressLoader: true })
    }


    onSheetChangeComplete(data, fileIndex, sheetIndex) {
        this.props.updateData(data, fileIndex, sheetIndex);
    }
    toggleErrorContainer(status) {
        this.setState({ shouldShowCompleteAlert: status });
    }
    render() {

        const { showProgressLoader, isSaving, isComplete, failed, readProgress, readProgressSheetNo, writeProgress, isReading, isWriting, pass, shouldShowCompleteAlert } = this.state;
        const { classes, activeFileIndex, activeSheetIndex, responseData, onRequiredFieldUpdate, onRequiredFieldRemoved,
            saveDataComplete, onRequiredFieldUpdateAndRemove, mode, commonFormat, showOpposite, onIgnoreSheet } = this.props;
        if (responseData == null) { return null; }

        let isAllValid = true;
        let allEmpty = true;
        if (responseData != null) {
            responseData.map((excelFile) => {
                const { sheets } = excelFile;
                sheets.forEach((sheet) => {
                    if (!sheet.valid && sheet.enabled) {
                        isAllValid = false;
                    }
                    if (sheet.rows.length > 0) {
                        allEmpty = false;
                    }
                });
                if (sheets.length === 1 && !sheets[0].valid && !sheets[0].enabled) {
                    isAllValid = false;
                }
                return excelFile;
            });
        }
        
        const activeFile = responseData[activeFileIndex];
        const fileName = activeFile.originalName;
        const sheets = activeFile.sheets;
        const sheet = sheets[activeSheetIndex];
        this.batchUploadId = activeFile.batchId;
        const columnCount = sheet.header != null ? sheet.header.columns.length : sheet.rows[0].cells.length;
        const totalSerials = sheets.reduce(function (prev, cur) {
            return prev + cur.totalRowCount;
        }, 0) - sheets.length; // assuming every sheet has header row

        const errorProps = { 'fileName': fileName, 'batchId': this.batchUploadId, 'pass': pass, 'failed': failed, 'mode': mode, 'shouldShowCompleteAlert': shouldShowCompleteAlert }
        const rows = sheet.rows;
        const animationSettings = showOpposite == null ? { left: null, right: null } : showOpposite === true ? { opposite: true, left: true, right: null, test: Math.random() } : { opposite: true, right: true, left: null, test: Math.random() };// right opposite when = { showOpposite }
        // const headermappingTitle = "Header Mapping";

        if (isComplete) {

            return (<Redirect
                to={{
                    pathname: "/uploadreport",
                    state: { ...errorProps }
                }}
            />)
        }

        //const errorContainer = isComplete ? <UploadErrorContainer {...errorProps} /> : null;
        const sheetView = (<Slide key={sheet.name + "_parent_" + sheet.index} duration={250} delay={0}   {...animationSettings}>
            <div key={sheet.name + "_" + sheet.index} >

                <table className="primaryTable flashtable table-bordered tesst" >
                    {rows.length !== 0 && (<thead>
                        <tr>
                            <RequiredSerialTableHeader requiredFields={sheet.fields} isAllRequiredFieldsMapped={sheet.valid} onRequiredFieldUpdateAndRemove={onRequiredFieldUpdateAndRemove}
                                onRequiredFieldUpdate={onRequiredFieldUpdate} onRequiredFieldRemoved={onRequiredFieldRemoved} headerCellCount={columnCount} />
                        </tr>

                    </thead>)}

                    <tbody>
                        {
                            rows.length > 0 && (<Fragment><ClientRowDecoration rows={rows} headerIndex={sheet.header.index} />
                                <ClientDataRow rows={rows} headerIndex={sheet.header.index} header={sheet.header} /> </Fragment>)}

                        {rows.length === 0 && (<Fragment><tr><td className={classes.emptyTable} colSpan="10">
                            <span style={{ margin: '20px', backgroundColor: '#fafafa', padding: '10px' }}>Sheet is empty & ignored</span></td></tr> </Fragment>)}


                    </tbody>
                </table>

            </div>
        </Slide>);
        //sheet.header = header;
        //sheet.fields = fields;
        //sheet.valid = validateAllRequiredMapped(fields)
        return (
            <Fragment>
                {/*    <Typography variant="h4" className={classes.marginTop} gutterBottom>
                    Import Data
                </Typography> */}
                <div className="mapping">
                    <div className="mapping-area">
                        <Hidden only={['xs']}>
                            <FieldMappingView requiredFields={sheet.fields} />
                        </Hidden>
                        <div className="template-area">
                            <div >
                                <div style={{ float: 'left' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Your Data Mapping and Preview
                              </Typography></div>
                                <div style={{ float: 'right' }}>
                                    <Typography variant="h6" gutterBottom>
                                        <b>Sheet Info</b>: Total rows({sheet.totalRowCount})
                                 </Typography>

                                </div>
                            </div>
                            {commonFormat && sheets.length > 1 && !allEmpty && (<div style={{ clear: 'both' }}><Jump><Paper elevation={0} className={classes.paper} square>
                                <InfoIcon className={classes.InfoIcon} fontSize="large" />
                                System has identified that all sheets share the same format. So any changes to header in any sheet will be synced with others.
                                </Paper ></Jump></div>)}
                            <div className={classes.tableRoot} style={{ position: "relative" }}>
                                {rows.length > 0 && (<p className={classes.prev}>Preview</p>)}
                                {sheetView}
                                {showProgressLoader === true ? (<div className={classes.loaderdiv}>
                                    <div className={classes.progressLoader}>
                                        <FbLoader loaderStyle="default" />
                                    </div>
                                </div>) : null}

                            </div>
                            <div className="temp-footer">
                                <div className="worksheets">
                                    <SpreadSheetFooter commonFormat={commonFormat} responseData={responseData} activeFileIndex={activeFileIndex} activeSheetIndex={activeSheetIndex} onIgnoreSheet={onIgnoreSheet} onSheetChangeComplete={this.onSheetChangeComplete} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <SaveSheet mode={mode} data={responseData} isAllRequiredFieldsMapped={isAllValid} classes={classes} saveDataComplete={saveDataComplete} requiredFields={sheet.fields}
                    totalRows={totalSerials} startSavingData={this.startSavingData} isSaving={isSaving} isComplete={isComplete}
                    readProgress={readProgress} readProgressSheetNo={readProgressSheetNo} writeProgress={writeProgress} isReading={isReading} isWriting={isWriting} />
              

            </Fragment>
        );
    }
}

export default withStyles(styles)(UploadResultContainer);
