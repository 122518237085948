import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import UserProfile from '../auth/UserProfile';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minHeight:300
    },
}))(MuiDialogContent);



const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    table: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.common.borderColor,
    },
});
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        height: "auto"
    },

}))(TableCell);





const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    titleNote: {
        fontSize: 15,
        color: theme.palette.text.secondary
    }
}))(props => {
    const { children, classes, onClose } = props;
    const role = UserProfile.getRole();
    const relevantAccountPlaceholder = role === Role_DISTRIBUTOR_STAFF ? "distributor's" : "";
    const isDistributorOrHisStaff = UserProfile.isDistributor();
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {isDistributorOrHisStaff &&
                <Typography variant="body1"><label className={classes.titleNote}> You can only view serials uploaded by your {relevantAccountPlaceholder} account</label></Typography>
            }
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class PanelDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.state = {open: false,  fullWidth: true, maxWidth: 'md',}
      
    }
 
    handleClose() {
        const { onClose } = this.props;
        onClose();
    }

    _getQueryVariables(role, manufacturerId) {

        const id = this.props.installationId;

        switch (role) {
            case Role_Admin: return { manufacturerId: parseInt(manufacturerId), id };
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { manufacturerId: parseInt(manufacturerId), id };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { id };
        }

    }
    _getData(data, role) {
       
        switch (role) {
            case Role_Admin: return data.admin.productInstallation.serials;
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return  data.supplier.productInstallation.serials; ;
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return  data.productfeed.productInstallation.serials ;;
        }
    }
    //shouldComponentUpdate(nextProps, nextState) {

    //    let shouldUpdate = (this.props.open !== nextProps.open);
    //    return shouldUpdate;
    //}

    render() {
        const { fullWidth, maxWidth, scroll, open,} = this.state;
        const { adminView, manufacturerId, classes, onClose, installationId, ...other } = this.props;

        const role = UserProfile.getRole();
       // const { open, selectedProductId, selectedProductLineId } = this.state;
        const query = QueryHelper().ProductInstallationByInstallationId();
       // const isDistributor = UserProfile.isDistributor();


        //const query = adminView ? ADMIN_PRODUCT_INSTALLATION_SERIALS_QUERY : PRODUCT_INSTALLATION_SERIALS_QUERY;
       // const tableStyle = { width: '400px', margin:'5px 15px 50px 15px', minHeight:'100px'}
        return (
            <Dialog fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={this.handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"               
                 {...other} >
                <DialogTitle id="scroll-dialog-title" onClose={this.handleClose}>List of Installed Serials</DialogTitle>
                <DialogContent>
                    <Query query={query} variables={this._getQueryVariables(role, manufacturerId)}  >
                        {({ loading, error, data }) => {
                        if (loading) return <FbLoader isTableBodyRequired={false} colSpan={3} loaderStyle={'center'} />
                        if (error) return <FbError isTableBodyRequired={false} colSpan={3} errorStyle={'center'} />  
                            const serialList = this._getData(data, role);
                            const listSerials = serialList.map((serial, index) =>
                                (
                                    <TableRow key={index}>
                                        <TableCell>{serial.brandName}</TableCell>
                                        <TableCell>{serial.model}</TableCell>
                                         <TableCell>{serial.serial}</TableCell>
                                    </TableRow>

                                ));
                            const result = (serialList!=null && serialList !== 'undefined' && serialList.length > 0) ? listSerials : <FbEmptyResult isTableBodyRequired={false} colSpan={3} errorStyle={'emptycell'} />;
                            return (<Fragment>
                                <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell>Brand</CustomTableCell>
                                        <CustomTableCell>Models</CustomTableCell>
                                        <CustomTableCell>Serials</CustomTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <tbody>{result}</tbody></Table>
                            </Fragment>);
                        }}
                    </Query>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(PanelDialog);
