import _ from 'lodash';
export const ColumnType = {
    Model: 1,
    Serial: 2,
    ISC: 3,
    VOC: 4,
    PMax: 5,
    VPM: 6,
    IPM: 7,
    FF: 8,
    Country: 9,
    Factory: 10,
    Supplier: 11,
    Response: 12
};

export const detectinRows = (rows, requiredFields, minCountRequired) =>  {
    var fields = requiredFields;
    var minCount = 2;

    var header;
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        var row = rows[rowIndex];
        var totalCellHasValue = 0;
        var totalCellHasEmptyValue = 0;
        for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
            const cell = row.cells[cellIndex];
            if (!_.isNil(cell.value)  && cell.value !== '') {
                totalCellHasValue++;
            }
            else {
                totalCellHasEmptyValue++;
            }
            var found = fields.find((f) => {
                var item = f.knownNames.find((knownName) => {
                    const nameTomatch = knownName.toLowerCase();
                    const cellValue = !_.isNil(cell.value) ? cell.value.trim().toLowerCase() : "";
                    // console.log("s: " + cellValue + " o:" + nameTomatch);
                    return (nameTomatch === cellValue)
                });
                return !_.isNil(item)
            });
            if (!_.isNil(found) && _.isNil(found.index)) {
                found.index = cell.index;
                cell.columnType = found.columnType;
                cell.dataType = found.dataType;
            }
            cell.isValid = true;
            cell.message = '';
            
        }

        row['isEmpty'] = _.isNil(row.cells) || row.cells.length===0 || row.cells.length === totalCellHasEmptyValue;
      
        if (totalCellHasValue >= minCount && _.isNil(header)) {
            header = { "columns": [], "index": null };
            header.columns=row.cells.map(cell => {
                return { "columnIndex": cell.index, "columnName": cell.value, "rowIndex": rowIndex, "columnType": cell.columnType,"dataType":cell.dataType ,"isDataValid":true }
            });
            header.index = rowIndex;
        }
        
    }

    header = header ? header : { "columns": [], "index": -1 };
    return { "fields": fields, "header": header };
}
export const validateCells = (fields, header, cell, isEmpty) => {
   
    if (_.isNil(header)) {
        return;
    }
    if (!_.isNil(isEmpty) && isEmpty === true) {
        cell.message = "";
        cell.isValid = true;
        return;
    }
    let field = fields.find(item => { return item.index === cell.index });
    if (_.isNil(field)|| field.dataType !== "number") {
        return;
    }
    if (field.dataType === "number" && (_.isNil(cell.value) || _.isEmpty(cell.value))) {
        field.isDataValid = false;
        cell.message = "Cell should contain the numeric values";
        cell.isValid = false;
        return;
    }

    if (field.hasOwnProperty('isDataValid') && field.isDataValid === false) {
        if (_.isNil(cell.value) && field.dataType === "number") {
            cell.isValid = true;
            cell.message = "";
        }
        checkNumericCellValue(cell, field);
        return;
    } else if (field.dataType === "number") {
        var status=checkNumericCellValue(cell, field);
        if (!field.hasOwnProperty('isDataValid') || field.isDataValid) {
            field.isDataValid = status;
        }
        
    }
} 
const checkNumericCellValue = (cell, field) => {
    
    if (!_.isNil(cell.value) && field.dataType === "number") {
        var valid = true;
        var trimVal = cell.value.trim().toLowerCase();
        var lastChar = trimVal.charAt(trimVal.length - 1)
          if (lastChar === 'a' || lastChar === 'v' || lastChar === 'w' || lastChar === '%') {
            var subStringLastIndex = trimVal.length - 1;
            trimVal = subStringLastIndex >= 0 ? trimVal.substring(0, subStringLastIndex) : trimVal.charAt(0);
        }
        if (isNaN(trimVal)) {
            cell.isValid = false;
            cell.message = "Cell should contain the numeric values";
            valid = false;
        }
        else {
            cell.isValid = true;
            cell.message = "";
        }
    }
    return valid;

}

export const validateSheet = (sheet) => {
    if (!sheet.enabled) {
        return ;
    }
    const { rows, header, fields } = sheet;
    if (_.isNil(header) || _.isNil(fields) || _.isNil(rows)) return;
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        var row = rows[rowIndex];
        for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
            const cell = row.cells[cellIndex];
            if (header.index < rowIndex) {
                validateCells(fields, header, cell, row['isEmpty']);
            }
        }
    }
}

export const validateAllSheets = (sheets) => {
    if (_.isNil(sheets)) return;
    sheets.forEach((sheet) => {
        if (sheet.enabled) {
            validateSheet(sheet);
        }
        
    });
}

export const validateAllRequiredMapped = (requiredFields)=> {
    let pendingFields = requiredFields.find(function (field) { return _.isNil(field.index) && field.optional === false; })
    if (_.isNil(pendingFields)) {
        return true;
    }
    return false;

}

