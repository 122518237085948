import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './assets/css/App.css';
import Addproduct from "./components/Addproduct";
import Addwarranty from "./components/Addwarranty";
import AssignProductLine from "./components/AssignProductLine";
import Activate from "./components/auth/Activate";
import Forgot from "./components/auth/Forgot-password";
//Authentication components
import FormFooter from "./components/auth/FormFooter";
import InstallationVideo from "./components/auth/installationVideo";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/ResetPassword";
import Dashboard from "./components/Dashboard";
import Error from "./components/Error";
//Dashboard Components
import ChangePassword from "./components/auth/ChangePassword";
import Main from "./components/Main";
import Myaccount from "./components/Myaccount";
import Notification from "./components/Notification";
import PrivateRoute from "./components/PrivateRoute";
import Modeldetail from "./components/product/ProductDetail";
import EditProductLine from "./components/productline/EditProductLine";
import ProductLine from "./components/productline/ProductLineContainer";
import Serialfailed from "./components/Serialfailed";
import UploadReportPage from "./components/uploader/UploadErrorContainer";
import History from "./components/uploadhistory/UploadHistoryContainer";
//import Serialunverified from "./components/Serialunverified";
import Settings from "./components/Settings";
import Addmember from "./components/staff/Addmember";
import EditDistributorStaff from "./components/staff/EditDistributorStaff";
import EditStaff from "./components/staff/EditStaff";
import Staff from "./components/staff/Staff";
import Support from "./components/Support";
import Flash from "./components/uploader/FlashContainer";
import About from "./legal/About";
import legal from "./legal/legal";
import Privacy from "./legal/Privacy";
//Admin Components
import AdminDashboard from "./components/admin/AdminDashboard";
import Miscellaneous from "./components/admin/Miscellaneous";
import AdminStats from "./components/admin/AdminStats";
import AddSupplier from "./components/admin/AddSupplier";
import CreatePullRequest from "./components/admin/CreatePullRequest";
import AdminHeader from "./components/admin/AdminHeader";
import AdminInstaller from "./components/admin/AdminInstaller";
import AdminNav from "./components/admin/AdminNav";
import ImportInstallerData from "./components/admin/ImportInstallerData";
import Installations from './components/admin/Installations';
import Logs from "./components/admin/Logs";
import Manufacturer from "./components/admin/Manufacturer";
import ManufacturerDetail from './components/admin/ManufacturerDetail';
import Module from './components/admin/Module';
import AdminSerials from "./components/admin/AdminSerials";
import AdminMain from './components/AdminMain';
import AddNotificationMember from './components/notification/AddNotificationMember';
import NewGroup from './components/notification/NewGroup';
import SupplierListing from './components/supplier/SupplierListing';
import PullRequests from './components/admin/PullRequests';
import ImportWeeklySupplierList from './components/admin/ImportWeeklySupplierList';
import CityList from './components/admin/ManagePostcodes';
import SUsers from './components/admin/SUsers';
import StatDashboard from './components/admin/Stats/StatDashboard';
import DetailView from './components/admin/Stats/DetailView';
import AdminSettings from "./components/admin/AdminSettings";
import ReferenceDataPresenter from "./components/admin/ReferenceDataPresenter";
import { UserContext } from './libs/UserContext';
//Legal Information
import UserProfile from "./components/auth/UserProfile";
import Termsofuse from "./legal/Termsofuse";
import theme from './theme';
// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!



class App extends Component {

    setManufacturerId = (manId) => {
        this.setState({
            manufacturerId: manId,
        });
    };

    // For re-calculation of is_admin below to route to relevant role component after login
    forceInvokeApp = () => {
        this.setState({
            refresh: true
        });
    }

    state = {
        manufacturerId: UserProfile.getManufacturerId(),
        setManufacturerId: this.setManufacturerId,
        forceInvokeApp: this.forceInvokeApp,
        refresh: false
    };
    render() {
        const is_admin = UserProfile.isAdmin();
        // const { classes } = this.props;     
        return (
            <MuiThemeProvider theme={theme} >
                <UserContext.Provider value={this.state}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route path="/login" component={Login} />
                            <Route path="/login/:signout" component={Login} />
                            <Route path="/installationVideo" component={InstallationVideo} />
                            <Route path="/FormFooter" component={FormFooter} />
                            <Route path="/forgot-password" component={Forgot} />
                            <Route path="/resetpassword" component={ResetPassword} />
                            <Route path="/activate" component={Activate} />
                            <Route path="/register" component={Register} />
                            <Route path="/termsofuse" component={Termsofuse} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/about" component={About} />
                            <Route path="/legal" component={legal} />
                            {!is_admin ?
                                <Main {...this.props}>
                                    <PrivateRoute path="/changepassword" component={ChangePassword} />
                                    <PrivateRoute path="/dashboard/" exact component={Dashboard} />
                                    <PrivateRoute path="/notification/" component={Notification} />
                                    <PrivateRoute path="/staff/" component={Staff} />
                                    <PrivateRoute path="/editstaff/:id" component={EditStaff} />
                                    <PrivateRoute path="/editdistributorstaff/:id" component={EditDistributorStaff} />
                                    <PrivateRoute path="/productline" component={ProductLine} />
                                    <PrivateRoute path="/AssignProductLine/:id" component={AssignProductLine} />
                                    <PrivateRoute path="/EditProductLine/:id" component={EditProductLine} />
                                    <PrivateRoute exact path="/flash/(resolve)?" component={Flash} />
                                    <PrivateRoute path="/settings" component={Settings} />
                                    <PrivateRoute path="/support" component={Support} />
                                    <PrivateRoute path="/myaccount" component={Myaccount} />
                                    <PrivateRoute path="/addwarranty" component={Addwarranty} />
                                    <PrivateRoute path="/addmember" component={Addmember} />
                                    <PrivateRoute path="/addproduct" component={Addproduct} />
                                    <PrivateRoute path="/uploadreport" component={UploadReportPage} />
                                    <PrivateRoute path="/history" component={History} />
                                    <PrivateRoute path="/serialfailed" component={Serialfailed} />
                                    <PrivateRoute path="/model/:id" component={Modeldetail} />
                                    <PrivateRoute path="/AddGroup" component={NewGroup} />
                                    <PrivateRoute path="/AddNotificationAccount" component={AddNotificationMember} />
                                </Main> :
                                <AdminMain>
                                    <PrivateRoute path="/AdminDashboard" exact component={AdminDashboard} />
                                    <PrivateRoute path="/Manufacturers" component={Manufacturer} />
                                    <PrivateRoute path="/AdminSerials" component={AdminSerials} />
                                    <PrivateRoute path="/Suppliers" component={(props) => (
                                        <SupplierListing isAdmin={true} overAllListing={true} />
                                    )} />
                                    <PrivateRoute path="/AdminNav" component={AdminNav} />
                                    <PrivateRoute path="/Logs" component={Logs} />
                                    <PrivateRoute path="/AdminHeader" component={AdminHeader} />
                                    <PrivateRoute path="/AdminInstaller" component={AdminInstaller} />
                                    <PrivateRoute path="/ImportInstallerData" component={ImportInstallerData} />
                                    <PrivateRoute path="/Module" component={Module} />
                                    <PrivateRoute path="/Installations" component={Installations} />
                                    <PrivateRoute path="/ManufacturerDetail" component={ManufacturerDetail} />
                                    <PrivateRoute path="/AddGroup" component={NewGroup} />
                                    <PrivateRoute path="/AddNotificationAccount" component={AddNotificationMember} />
                                    <PrivateRoute exact path="/addmember/:manufacturerId" component={Addmember} />
                                    <PrivateRoute path="/changepassword" component={ChangePassword} />
                                    <PrivateRoute path="/PullRequests" component={PullRequests} />
                                    <PrivateRoute path="/Miscellaneous" component={Miscellaneous} />
                                    <PrivateRoute path="/AdminStats" component={AdminStats} />
                                    <PrivateRoute path="/ImportWeeklySupplierList" component={ImportWeeklySupplierList} />
                                    <PrivateRoute path="/CreatePullRequest/:manufacturerId/:distributorId" component={CreatePullRequest} />
                                    <PrivateRoute path="/AddSupplier/:data?" component={AddSupplier} />
                                    <PrivateRoute path="/ManagePostCodes" component={CityList} />
                                    <PrivateRoute path="/Users" component={SUsers} />
                                    <PrivateRoute path="/Reports" component={StatDashboard} />
                                    <PrivateRoute path="/DetailView" component={DetailView} />
                                    <PrivateRoute path="/AdminSettings" component={AdminSettings} /> {/* Added Settings route */}
                                    <PrivateRoute path="/ReferenceData" component={ReferenceDataPresenter} /> {/* Added Settings route */}
                                    </AdminMain>

                            }


                            <Route component={Error} />

                        </Switch>

                    </BrowserRouter>
                </UserContext.Provider>
            </MuiThemeProvider>
        );
    }
}

export default App;
