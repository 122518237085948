import gql from 'graphql-tag';

// ClientApp/src/libs/AdminQuery.js

export const GET_SETTINGS = gql`
  query GetSettings($settingType: String!) {
   admin{
       hasConfigAccess,
       configuration {
       settings(settingType: $settingType) {
      settingID
      settingType
      settingKey
      settingValue
      createdAt
      updatedAt
    }
    }
    }
  }
`;

export const ADD_SETTING = gql`
  mutation AddSetting($setting: SettingInput!) {
    adminMutation { adminSettings { addSetting(setting: $setting) {
      settingID
      settingType
      settingKey
      settingValue
      createdAt
      updatedAt
    }
    }
  }
  }
`;

// Additional queries and mutations as needed...
export const DELETE_SETTING = gql`
  mutation DeleteSetting($settingType: String!, $settingKey: String!) {
      adminMutation { adminSettings {
    deleteSetting(settingType: $settingType, settingKey: $settingKey)
  }
  }
  }
`;