import React, { useState, useCallback,useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { SerialContainerStyles } from './SerialContainerStyle';
import ModelSearch from "../ModelSearch";
import TabContainer from "../TabContainer";
import DetailAvailableTab from "./AvailableTab";
import DetailFailedTab from "./FailedTab";
import DetailInstalledTab from "./InstalledTab";
import DetailParallelTab from "./ParallelTab";
import SerialImagesDialog from './SerialImagesDialog';
import { handleFailDownload } from '../../Helper';
import QueryHelper from '../../libs/QueryHelper';
import UserProfile from '../auth/UserProfile';
import { useQuery } from '@apollo/react-hooks';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from '../common/Pagination';
import { SerialDataProvider,SerialDataContext } from './SerialDataProvider';
import {
    LINKS_PER_PAGE,
    SERIAL_TYPE,
    Role_Admin,
    Role_DISTRIBUTOR,
    Role_MANUFACTURER,
    Role_STAFF,
    Role_DISTRIBUTOR_STAFF,
} from '../../constants';


const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -27,
        top: 0,
        padding: '0 4px',
        backgroundColor: 'blue'
    },
}))(Badge);

const SerialContainer = ({ classes, adminView,globalView, manufacturerId, name }) => {
   
    const { open, serial, productSerialId, activeTab, filter, pageNumber, pageSize, loading, error, data, changeTab, isTabActive,
        handleSearch, showSerialImagesCallback, onFailDownload, getData, onPageChange,
        handleChange, hideSerialImagesCallback, handleClose } = useContext(SerialDataContext);
    const handleTabChange = (event, newValue) => {

        handleChange(null, newValue);
    }
    const isLoading = loading || error;
    const { totalSerials = null, serials = null } = !isLoading && data ? getData(data) : {};
    const failedSerialTabAttribute = (activeTab == SERIAL_TYPE.FAILED) && totalSerials && totalSerials  > 0 ? { "bgcolor": "error" } : {};
    const failedSerialRootClass = (activeTab == SERIAL_TYPE.FAILED) && totalSerials && totalSerials > 0 ? classes.tabRootWarning : classes.tabRoot;
    
    return (
        <div className={classes.root}>
            
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.Grid}
            >
                <Grid item>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        classes={{ root: classes.tabsRoot }}
                    >
                        <Tab {...failedSerialTabAttribute}
                            value={SERIAL_TYPE.FAILED}
                            classes={{ root: failedSerialRootClass, selected: classes.tabSelected }}
                            label={
                                <StyledBadge max={9999999} invisible={isLoading || activeTab != SERIAL_TYPE.FAILED} badgeContent={totalSerials}>
                                    <Toolbar className={classes.padding} variant="dense" disableGutters={true}>
                                        <Typography variant="h3" className={classes.grow}>
                                            Failed
                                        </Typography>
                                    </Toolbar>
                                </StyledBadge>
                            }
                        />
                        <Tab
                            value={SERIAL_TYPE.APPROVED}
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <StyledBadge max={9999999} invisible={isLoading || activeTab != SERIAL_TYPE.APPROVED} badgeContent={totalSerials} color="secondary">
                                    <Toolbar className={classes.padding} variant="dense" disableGutters={true}>
                                        <Typography variant="h3" className={classes.grow}>
                                            Installed
                                        </Typography>
                                    </Toolbar>
                                </StyledBadge>}
                        />
                        <Tab
                            value={SERIAL_TYPE.PENDING}
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <StyledBadge max={9999999} invisible={isLoading || activeTab != SERIAL_TYPE.PENDING} badgeContent={totalSerials } color="primary">
                                    <Toolbar className={classes.padding} variant="dense" disableGutters={true}>
                                        <Typography variant="h3" className={classes.grow}>
                                            Available
                                        </Typography>
                                    </Toolbar>
                                </StyledBadge>}
                        />
                        { globalView  && <Tab
                            value={SERIAL_TYPE.PARALLEL}
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <StyledBadge invisible={isLoading || activeTab != SERIAL_TYPE.PARALLEL} badgeContent={totalSerials} color="primary">
                                    <Toolbar className={classes.padding} variant="dense" disableGutters={true}>
                                        <Typography variant="h3" className={classes.grow}>
                                            Parallel Import
                                        </Typography>
                                    </Toolbar>
                                </StyledBadge>}
                        />
                        }
                    </Tabs>
                </Grid>

                <Grid item>
                    { isTabActive(SERIAL_TYPE.FAILED) && adminView &&
                        <span style={{ verticalAlign: 'middle', display: 'inline-block', marginTop: '5px' }}>
                            <Button color="primary" size="small" variant="outlined" onClick={() => onFailDownload(manufacturerId, name)}>
                                Download Failed Serials
                            </Button>
                        </span>
                    }
                    <ModelSearch filterText={filter} searchHandler={handleSearch} containerRequired={false} />
                </Grid>
            </Grid>
      
            {loading && <FbLoader extraPadding={true}  loaderStyle={'center'} /> }
            {error && <FbError  errorStyle={'center'} /> }   
                {
                    !isLoading  &&
                    <TabContainer >


                        {  isTabActive(SERIAL_TYPE.FAILED)
                            && <DetailFailedTab manufacturerId={manufacturerId} adminView={adminView} showSerialImagesCallback={showSerialImagesCallback} totalSerials={totalSerials } serials={serials} />
                        }
                        {  isTabActive(SERIAL_TYPE.APPROVED) &&
                            <DetailInstalledTab manufacturerId={manufacturerId} adminView={adminView} showSerialImagesCallback={showSerialImagesCallback}
                                serials={serials} />
                        }
                        {
                            isTabActive(SERIAL_TYPE.PENDING) && <DetailAvailableTab manufacturerId={ manufacturerId} adminView={adminView}
                                serials={serials} />
                        }

                        {
                            isTabActive(SERIAL_TYPE.PARALLEL) && <DetailParallelTab adminView={adminView}
                                globalView={globalView} serials={serials}  />
                        }

                        <Pagination
                            Prefix="available"
                            TotalRecords={totalSerials}
                            PageSize={pageSize}
                            PageNumber={pageNumber}
                            ShowPreviousNext={true}
                            onPageChange={onPageChange}
                        />
                    </TabContainer>}
                
          
            <SerialImagesDialog
                serial={serial}
                productSerialId={productSerialId}
                adminView={adminView}
                manufacturerId={manufacturerId}
                open={open}
                onClose={hideSerialImagesCallback}
            />
        </div>
    );
};


const StyledSerialContainer = withStyles(SerialContainerStyles)(SerialContainer);

 export default  function SerialContainerWithProvider(props) {
    return (
        <SerialDataProvider {...props}>
            <StyledSerialContainer {...props} />
        </SerialDataProvider>
    );
}
