import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';
import { ChineseToEnglishSuggestion } from '../../StringConstants';
const styles = {
    root: {
        flexGrow: 1,
        height: "100%",
    },
    tooltip: {
        color: "red",
        backgroundColor: "white",
        fontSize:'18px'
    },
    
    tooltipPlacementTop: {
        fontSize: '25px'
    },
    cellError: {
        borderColor:'red'
    },
    postip: {
        padding: 0,
        fontSize: 15,
        "&:hover": {
            background: "none"
        }
    }
}


const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.red,
        color: 'rgba(255, 255, 255)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        marginTop:"4px",
        padding: 8,
        borderRadius: "4px"

    },
}))(Tooltip);
class ClientDataCell extends React.Component{

    render() {
        const { cells, isHeader,classes } = this.props;
        return cells.map((cell, index) => {
            const cellValue = cell.value;
            let translated = null;
            
            if (!_.isNil(cellValue)) {
                const key = Object.keys(ChineseToEnglishSuggestion).find(item => { return item === cellValue; });
                translated = key != null ? '(' + ChineseToEnglishSuggestion[key] + ')' : null;
                
            }
           
            const { status, suggestion } = !_.isNil(cellValue)  && !cell.isValid ? { status: false, suggestion: cell.message } : { status: true, suggestion: '' };
            const toolTipContent = !isHeader && !status && (

                <LightTooltip interactive title = {suggestion} aria-label={suggestion} >       
                        <IconButton classes={{ root: classes.postip }} size="small" disableRipple focusRipple>
                        <InfoIcon color="error" fontSize="inherit" />
                    </IconButton>                  
                </LightTooltip>
            );
            const translation = translated ? translated : '';
            const isError = !isHeader && !status;
        
            return isHeader
                ? <th className={"headerCell"} key={"clientcell" + index}>
                    <span>
                        {cell.value}
                        {translation}
                    </span>
                 </th>
                : <td className={classNames({ "contentCell": true, "cellError": isError})} key={"clienttd" + index}>
                    {cell.value}
                    {translation}
                    {toolTipContent}
                </td>;
            
        });
        
     
    }
}
export default withStyles(styles)(ClientDataCell);

