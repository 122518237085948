import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import 'babel-polyfill';
import React, { Fragment } from 'react';
import { ApolloProvider as LegacyApolloProvider } from 'react-apollo';
import 'react-app-polyfill/ie11';
import { render } from 'react-dom';
import { Helmet } from "react-helmet";
import App from './App';
import AuthService from './components/AuthService';
import Login from './components/auth/Login'
import * as constant from './constants';
import * as serviceWorker from './serviceWorker';
//import ClearCache from 'react-clear-cache';
import { SnackbarProvider, useSnackbar } from 'notistack';
const authLink = setContext((_, { headers }) => {
    const token = new AuthService().getToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
})
const logoutLink = onError(({ networkError }) => {
    if (networkError.statusCode === 401) {
        const Auth = new AuthService();
        Auth.logout();
        window.location.pathname = "/login";
    }
})
const client = new ApolloClient({
    link: logoutLink.concat(authLink.concat(createUploadLink({
        uri: constant.SERVERIP + '/graphql/'
    }))),
    cache: new InMemoryCache()
    
})
//const Cache = () => (<ClearCache>
//    {({ isLatestVersion, emptyCacheStorage }) => (
//       <Fragment>
//            {!isLatestVersion && (
                
//                    <a
//                        href="#"
//                        onClick={(e) => {
//                            e.preventDefault();
//                            emptyCacheStorage();
//                        }}
//                    >
//                        Update version
//                    </a>
                
//            )}
//        </Fragment>
//    )}
//</ClearCache>);
const RootApp = () => (
    <ApolloProvider client={client}>
        <LegacyApolloProvider client={client}>
        <Helmet>
            <meta charSet="utf-8" />
                <title>SPV Manufacturers Portal</title>
            </Helmet>
            <SnackbarProvider maxSnack={3}>
                <App />
            </SnackbarProvider>
        </LegacyApolloProvider>
    </ApolloProvider>
);
//{/*<Cache />*/ }

render(<RootApp />, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
