import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

//import TabContainer from "./TabContainer";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Helmet } from "react-helmet";
import theme from '../theme';
import DashboardHeader from './DashboardHeader';
import InstallationStats from './graphsReports/InstallationStats';
import Installations from "./installations/InstallationContainer";
import Model from "./product/Product";
import Serials from "./serial/SerialContainer";

import { UserContext } from '../libs/UserContext';
import UserProfile from './auth/UserProfile';
function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};


const styles = {
    root: {
        height: "100%",
    },
    tabsRoot: {
        color: theme.palette.grey[700],
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    //tabsIndicator: {
    // backgroundColor: 'transparent',
    //},
    tabRoot: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: "transparent",
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        textTransform: 'capitalize',
        fontSize: "18px",
        textAlign: "center",
        color: theme.palette.grey[800],
        borderTopColor: theme.palette.common.borderColor,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        transition: "200ms ease",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        '&:hover': {
            backgroundColor: theme.palette.common.tabHover,
            color: theme.palette.grey[900],
        },
        '&$tabSelected': {
            backgroundColor: theme.palette.grey[100],
            fontFamily: 'NHaasGroteskDSPro-65Md',
            color: theme.palette.grey[900],
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            
            borderColor: theme.palette.common.borderColor
        },
        '&:focus': {
            color: theme.palette.grey[900],
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing(1) * 3,
    },
    badge: {
        verticalALign: "top",
        position: "inherit",
        borderRadius: theme.shape.borderRadius,
        transform: "initial",
        marginTop: "-22px",
        marginLeft: theme.spacing(2),
        fontFamily: "Roboto"
    },
    grow: {
        flexGrow: 1,
    },
    padding: {
        padding: 0,
        minHeight: "auto",
    },
    spaceX: {
        marginRight: theme.spacing(2),
    },
    selectBox: {
        padding: "6px 12px",
    },
    spaceRight: {
        marginRight: theme.spacing(1),
    }
};
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.changeTab = this.changeTab.bind(this);
        this.state = {
            value: '1'
        }
    }

    changeTab(path) {
        var vartabState = { tab1: false, tab2: false, tab3: false };
        switch (path) {
            case '#tab1': { vartabState.tab1 = true; break; }
            case '#tab2': { vartabState.tab2 = true; break; }
            case '#tab3': { vartabState.tab3 = true; break; }
            default: { vartabState.tab1 = true; }
        }
        this.setState({
            tabState: vartabState
        });
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    renderTab(props) {
        const tabList = props.tabs.map((tab, index) =>
            (
                <li key={index}>
                    <a href={tab.path}>
                        <h3>{tab.tabname}</h3>
                        <span className={tab.badgeIcon}>{tab.number}</span>
                        <p>{tab.modelname}</p>
                    </a>
                </li>
            ));
        return (
            <ul> {tabList} </ul>
        );
    }
    render() {
        const { classes } = this.props;
        const { value } = this.state;
        const isDistributor = UserProfile.isDistributor();
        const isDistributorStaffWithoutManufacturer = UserProfile.isDistributorStaffWithoutManufacturer();
        const noManufacturerAttachedView = (<Card className={classes.root}>
            <CardContent>
                <Typography variant="body1" color="inherit" component="p">
                    No manufacturer is attached with your profile. Please contact the distributor.
                </Typography>
            </CardContent>
        </Card>);
           
        const dashboardView = (
            <UserContext.Consumer>
                {({ manufacturerId, setManufacturerId }) =>
                    (
                        
                        <div className={classes.root} >
                     
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>SPV | Dashboard</title>
                            </Helmet>

                            <DashboardHeader />
                            {!isDistributor && <Grid container direction="row"
                                justify="flex-start"
                                alignItems="flex-start" style={{ margin: "16px 0" }}>
                                <InstallationStats />

                            </Grid>}

                            <Grid container direction="row"
                                justify="flex-start"
                                alignItems="flex-start" style={{ margin: "16px 0" }}>


                            </Grid>

                            <Tabs
                                value={value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                            >
                                
                                <Tab
                                    value="1"
                                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                    label={
                                        <Toolbar className={classes.padding} variant="dense" disableGutters>
                                            <Typography variant="h3" className={classes.grow}>
                                                Serials
                            </Typography>
                                        </Toolbar>}
                            />
                            <Tab
                                value="2"
                                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                label={<Toolbar className={classes.padding} variant="dense" disableGutters>
                                    <Typography variant="h3" className={classes.grow}>
                                        Models
                                    </Typography>
                                </Toolbar>}

                            />
                                <Tab
                                    value="3"
                                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                    label={
                                        <Toolbar className={classes.padding} variant="dense" disableGutters>
                                            <Typography variant="h3" className={classes.grow}>
                                                Installation(verified)
                            </Typography>
                                        </Toolbar>}
                                />
                            </Tabs>
                         
                        { value === '1' && <TabContainer><Serials {...(isDistributor && { "manufacturerId": manufacturerId })} /></TabContainer> }
                        { value === '2' && <TabContainer><Model {...( isDistributor && { "manufacturerId": manufacturerId })} /></TabContainer> }
                        { value === '3' && <TabContainer><Installations {...(isDistributor && { "manufacturerId": manufacturerId })} /></TabContainer>}
                        
                        </div >
                    )}
                        </UserContext.Consumer>
        );

        return !isDistributorStaffWithoutManufacturer ? dashboardView : noManufacturerAttachedView;
    }
}
export default withStyles(styles)(Dashboard);
