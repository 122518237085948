//import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { Helmet } from "react-helmet";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import { CheckUsername, DistributorStaffApi, Permissions, Role_DISTRIBUTOR, StaffApi } from '../../constants';
import { AddStaffHeaderTitle } from '../../StringConstants';
import theme from '../../theme';
import UserProfile from '../auth/UserProfile';
import AuthService from '../AuthService';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles ={
    root: {
        flexGrow: 1,
        height: "100%",
    },
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: theme.palette.common.white,
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },

    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        width: "100%",
    },
    avatar: {
        borderRadius: 0,
        width: "100%",
        height: "172px",
        backgroundColor: theme.palette.background.default,
        color: "transparent",
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.background.lightbg,
        maxWidth: 174,
     
    },
    iconHover: {
       color: theme.palette.grey[500],
        verticalAlign: "middle"
    },
    gutterBottom: {
        marginBottom: theme.spacing(2)
    },
   control: {
      marginTop: theme.spacing(1),
   },
    update: {
        marginTop: theme.spacing(1)
    },
    button: {
        marginRight: '8px',
        minWidth: 120,
        height: 42,
    },
    profileImage: {
        width: "100%",
        height: "100%",
        objectFit: " cover",
        imageOrientation: 'from-image',

    },
    //titleBar: {
     //   background: "transparent",
    //},
    backanim: {
        transition: "400ms ease",
        marginLeft: "-5px"
        /*'&:hover': {
            marginLeft: "-5px",
        },*/
    },
    icon: {
        fontSize: 26,
        verticalAlign: "middle",
        marginTop: "-3px",
        transition: "400ms ease",
        '&:hover': {
            marginLeft: "-5px",
        },
    },
    marginy: {
        marginTop: theme.spacing(1) * 1,
        marginBottom: theme.spacing(1) * 1,
    },
    checkbox: {
        paddingRight: 5,
    },
    labelClass: {
       // textTransform:"lowercase"
    },
    labelManufacturerClass: {
        fontWeight: 600
    },
    formLabelFocused: {},
    formLabelRoot: {
        '&$formLabelFocused': { color: "rgba(0, 0, 0, 0.54)" }  // To avoid MuiFormLabel focus color material-ui issue
    },
    ulBottomSeparator: {
        borderBottom: "1px solid #ececec"
    },
    formGroupControl: {
        margin: "10px 0 0"
    },
    errorMessage: {
        padding: "20px",
        color: theme.palette.error.main
    },
    adminCreateStaff: {
        margin: "20px"
    }
};

const Manufacturer_QUERY = gql`
query getmanufacturers {
  supplier {
      manufacturers{
      isDistributor
      address
      displayName
      logo
      manufacturerId
      profilePhoto
      userName
      uploadFrequency
      email
      userId
      isActive
    }
  }
}
`;

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);






class Addmember extends React.Component {
    constructor() {
        super();
        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDistributorStaffSubmit = this.handleDistributorStaffSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.getManufacturerId = this.getManufacturerId.bind(this);
        //this.onProfilePhotoChange = this.onProfilePhotoChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.Auth = new AuthService();
        this.state = {
            uploading: false,
            isimageloading: false,
            showPassword: false,
            previewImage: '',
            user: {
                Username: '', Password: '',
                FirstName: '', LastName: '', Email: '',
                Phone: '', ProfilePhoto: '', Permissions: Permissions, Designation: '', ManufacturerMapping: []
            },errorMessage:'',error:false
        };
        this.isUserNameAvaiable = this.isUserNameAvaiable.bind(this);
        this.handleDistributorStaffPermissionChange = this.handleDistributorStaffPermissionChange.bind(this);
        this.handleManufacturerMapping = this.handleManufacturerMapping.bind(this);
        this.bindAttachedManufacturersOnly = this.bindAttachedManufacturersOnly.bind(this);

        
    }
    async isUserNameAvaiable(filter) {

        if (filter !== '' && !this.state.isloading) {
            const data = await (await fetch(CheckUsername + filter)).json();
            return !data.status;
        }
        else {
            return true;
        }
    }
    componentDidMount() {

        ValidatorForm.addValidationRule('isPassword', (value) => {
            return this.matchPassword(value);
        });
        ValidatorForm.addValidationRule('isUserNameAvaiable', (value) => {
            return this.isUserNameAvaiable(value);
        });

        if (UserProfile.isAdmin()) {
            if(this.getManufacturerId() <= 0) {
                this.setState({ error: true, open: true, errorMessage: "Missing manufacturer information." });
            }
        }

    }
    matchPassword(value) {
        // var patt = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$%*?&]{6,}");
        // var res = patt.test(value);

        // return res;
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$%*?&]{6,}$/.test(value)) { return true }
        return false;
    }
    getManufacturerId() {
        const {
            match: {
                params: {
                    manufacturerId = 0 }
            }
        } = this.props;
        return parseInt(manufacturerId);
    }
    handleSubmit() {

        if (UserProfile.isAdmin()) {
            const manufacturerId = this.getManufacturerId();
            if (manufacturerId <= 0) {
                this.setState({ error: true, open: true, errorMessage:"Missing manufacturer information" }); 
                return false;
            }
            this.updateformState({ "ManufacturerId": manufacturerId });
        }

        this.setState({ uploading: true });
        //const { FirstName, LastName, UploadFrequency, Phone } = this.state.user;
        const token = this.Auth.getToken();
        var obj = this.state.user;
        let formData = new FormData();

        formData.append('MyFile', this.filefield.files[0]);

        Object.keys(obj).forEach((key) => {
            if (!Array.isArray(obj[key]))
                formData.append(key, obj[key]);
        }

        );
        if (obj.Permissions != null) {

            formData.append('SerializedPermissions', JSON.stringify(obj.Permissions));
        }
        fetch(StaffApi, {
            method: 'POST',
            headers: { 'authorization': `Bearer ${token}` },
            body: formData
        }).then(res => {
            
            if (res.status === 200) {
                return res.json();
            }
            if (res.status === 400) {
                let json = res.json(); // there's always a body
                return json.then(Promise.reject.bind(Promise));
            }
            return Promise.reject(res);

        }).then(res => {
            if (UserProfile.isAdmin()) {
                const manufacturer = this.props.location.state.manufacturer;
                this.props.history.push({ pathname: "/ManufacturerDetail/" + this.getManufacturerId(), state: { manufacturer: manufacturer, activeIndexRestore: 4 } });
            }
            else {
                this.setState({ open: true, uploading: false }, function () { this.props.history.push({ pathname: "/staff/" }); });
            }
        }, failed => {
            const failedError = (failed[0] && failed[0].description) || `Error: Staff account couldn't be created - Status:${failed.status}`;
            this.setState({ open: true, uploading: false, error: true, errorMessage: failedError });
        })
            .catch(err => {

                //this.setState({ isLoading: false, isLoginFailed: true });

            });
       
    };


    bindAttachedManufacturersOnly = () => {
        const attachedManufacturerMapping = this.state.user.ManufacturerMapping.filter(mapping => {
            return (mapping.isManufacturerAttached === true && mapping.permissions.find(el => el.checked === true));
        });
        let latestFormStateCopy = Object.assign({}, this.state);
        latestFormStateCopy.user.ManufacturerMapping = attachedManufacturerMapping;
        this.setState(latestFormStateCopy); // just for safety
    }


    handleDistributorStaffSubmit() {
        this.setState({ uploading: true });
        this.bindAttachedManufacturersOnly();
        //const { FirstName, LastName, UploadFrequency, Phone } = this.state.user;
        const token = this.Auth.getToken();
       

        var userObj = Object.assign({}, this.state.user);
        delete userObj.Permissions;

        let formData = new FormData();

        formData.append('MyFile', this.filefield.files[0]);

        Object.keys(userObj).forEach((key) => {
            if (!Array.isArray(userObj[key]))
                formData.append(key, userObj[key]);
        }

        );

        if (userObj.ManufacturerMapping.length > 0) {
            formData.append('SerializedManufacturerMapping', JSON.stringify(userObj.ManufacturerMapping));
        }
       
        
        fetch(DistributorStaffApi, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`
            },
            body: formData
        }).then(res => {

            if (res.status === 200) {
                return res.json();
            }
            if (res.status === 400) {
                let json = res.json(); // there's always a body
                return json.then(Promise.reject.bind(Promise));
            }
            return Promise.reject(res);

        }).then(res => {

            this.setState({ open: true, uploading: false }, function () { this.props.history.push({ pathname: "/staff/" }); });
        }, failed => {

            this.setState({ open: true, uploading: false, error: true, errorMessage: failed[0].description });
        })
            .catch(err => {

                //this.setState({ isLoading: false, isLoginFailed: true });

            });

    };




    handleChange(e) {
        this.updateformState({ [e.target.name]: e.target.value });

    }
    updateformState(obj, extra) {
        const { user } = this.state;
        let userState =
            { ...user, ...obj };
        if (extra != null) {
            this.setState(state => ({ "user": userState, ...extra }));
        }
        else {
            this.setState(state => ({ "user": userState }));
        }


    }
    handlePermissionChange(e) {
        let permissions = this.state.user.Permissions;
        var elem = permissions.find((el) => { return el.name === e.target.name });
        elem.checked = e.target.checked;
        this.updateformState({ "Permissions": permissions });
    }

   

    handleDistributorStaffPermissionChange(e) {

        const targetPermissionName = e.target.name.split("-")[0];
        const manufacturerId = e.target.attributes.manufacturerid.value;
        const staffManufacturerMapping = this.state.user.ManufacturerMapping.find(m => m.manufacturerId === manufacturerId);
        // As no pre-exisitng mappings added to state initially
        if (!staffManufacturerMapping) {
            const mappingData = { manufacturerId: manufacturerId, permissions: JSON.parse(JSON.stringify(Permissions)), isManufacturerAttached: false };
            this.state.user.ManufacturerMapping.push(mappingData);
        }

        let permissions = this.state.user.ManufacturerMapping.find(m => m.manufacturerId === manufacturerId).permissions;
        var elem = permissions.find((el) => { return el.name === targetPermissionName });
        elem.checked = e.target.checked;
        let latestFormStateCopy = Object.assign({}, this.state);
        this.setState(latestFormStateCopy);
        
    }

    handleManufacturerMapping(e) {
        const targetManufacturerId = e.target.value;

        const staffManufacturerMapping = this.state.user.ManufacturerMapping.find(m => m.manufacturerId === targetManufacturerId);
        if (!staffManufacturerMapping) {
            const mappingData = { manufacturerId: targetManufacturerId, permissions: JSON.parse(JSON.stringify(Permissions)), isManufacturerAttached: false };
            this.state.user.ManufacturerMapping.push(mappingData);
        }

        this.state.user.ManufacturerMapping.find(m => m.manufacturerId === targetManufacturerId).isManufacturerAttached = e.target.checked;
        let latestFormStateCopy = Object.assign({}, this.state);
        this.setState(latestFormStateCopy);
    }

    handleImageChange(event) {

        this.setState({
            previewImage: URL.createObjectURL(event.target.files[0])
        })
    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    
    state = {
        open: false,
    };

    handleClick = () => {
        this.setState({ open: true });
    };

    handleClose = (_event, _reason) => {
        
        this.setState({ open: false ,error:false,errorMessage:'' });
    };

    anyManufacturerAttachedWithPermission = () => this.state.user.ManufacturerMapping.some(mapping => mapping.isManufacturerAttached === true && mapping.permissions.some(el => el.checked === true));

    render() {
        const role = UserProfile.getRole();
        let manufacturer = null;
        const { classes } = this.props;

        if (UserProfile.isAdmin()) {
            const locationState = this.props.location.state;
            if (!locationState) {
                return (<div className={classes.errorMessage}>Missing manufacturer information. Please go to dashboard</div>);
            }
            manufacturer = this.props.location.state.manufacturer;
        }
        const { previewImage, isimageloading, uploading, error, errorMessage, open } = this.state;
        const { Username, Password, FirstName, LastName, Email, Phone, Permissions, Designation } = this.state.user;
        //const profileUrl = ProfilePath + ProfilePhoto;
        const requiredMessage = 'This field is required';
        const manufacturerMapping = this.state.user.ManufacturerMapping;

        const permissionView = (role !== Role_DISTRIBUTOR) && Permissions.map((p, index) => {
            return (<FormControlLabel key={"permission" + index}
                control={
                    <Checkbox name={p.name}
                        checked={p.checked}
                        onChange={(e) => this.handlePermissionChange(e)}
                        value={p.Id + ""}
                        color="primary"
                        className={classes.checkbox}
                    />
                }
                label={p.name}
                classes={{ root: classes.labelClass }}
            />)
        })

        const image = isimageloading ? <CircularProgress color={theme.palette.common.white} size={19} style={styles.root} /> : (previewImage != null && previewImage !== '') ? (< img alt="profilepic" src={previewImage} className={classes.profileImage} />) :
            (< img alt="profilepic" src={require('../../assets/images/user.png')} className={classes.profileImage} />);

        return (
            <div className={classes.root + " " + (UserProfile.isAdmin() && classes.adminCreateStaff)}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Add New Staff Member</title>
                </Helmet>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.marginy}>
                    <Grid item>
                        {UserProfile.isAdmin() &&
                            <Link to={{ pathname: "/ManufacturerDetail/" + this.getManufacturerId(), state: { manufacturer: manufacturer, activeIndexRestore:4 } }}
                                title="back to staff" >
                                <Typography color='primary' variant="h4">
                                    <span className={classes.backanim}><Icon className={classes.icon} >keyboard_arrow_left</Icon>Back to Staff</span>
                                </Typography>
                            </Link>

                        }
                        {!UserProfile.isAdmin() &&
                            <Link to="/Staff" title="back to staff" >
                                <Typography color='primary' variant="h4">
                                    <span className={classes.backanim}><Icon className={classes.icon} >keyboard_arrow_left</Icon>Back to Staff</span>
                                </Typography>
                            </Link>

                        }
                    </Grid>
                </Grid>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" >
                            {AddStaffHeaderTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.control}
                 >
                    <Grid item lg={5} md={7} sm={12}>
                        <ValidatorForm ref="form" instantValidate={true} autoComplete="off" onError={errors => console.log(errors)}
                            onSubmit={(role !== Role_DISTRIBUTOR) ? this.handleSubmit : this.handleDistributorStaffSubmit}>
                            <Paper elevation={0} className={classes.paper}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item sm={12} xs={12} className={classes.gutterBottom}>
                                        <Avatar className={classes.avatar}>
                                            {image}
                                            <GridListTileBar
                                                title="Profile Picture"
                                                className={classes.titleBar}
                                                actionIcon={
                                                    <Fragment>
                                                        <input hidden accept="image/*" className={classes.input} ref={(file) => this.filefield = file} id="profile" type="file" onChange={(e) => this.handleImageChange(e)} />
                                                        <label htmlFor="profile">
                                                            <IconButton component="span" color="secondary">
                                                                   <Icon style={{ fontSize: 24 }}>
                                                                     camera_alt
                                                                    </Icon>
                                                            </IconButton>
                                                        </label>
                                                    </Fragment>
                                                }
                                            />
                                        </Avatar>
                                    </Grid>
                                    {/* <Hidden smDown>
                                        <Grid item lg={6} className={classes.gutterBottom}>

                                        </Grid>
                                    </Hidden> */ }
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                name="Username"
                                                label="*Username"
                                                placeholder="Username"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={Username}                                                
                                                validators={['required','isUserNameAvaiable']}
                                                errorMessages={['This field is required','User name already taken']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator autoComplete="off"
                                                id="Password"
                                                name="Password"
                                                fullWidth={true}
                                                margin="dense"
                                                variant="outlined"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                label="*Password"
                                                value={Password}
                                                onChange={this.handleChange}                                             
                                                validators={['isPassword', 'required']}
                                                errorMessages={['Password must be combination of upper case,digit and special characters and should have minimum 6 characters', requiredMessage]}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Toggle password visibility"
                                                                onClick={this.handleClickShowPassword}
                                                            >
                                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}

                                            />
                                        </FormControl>
                                    </Grid>


                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>

                                            <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                name="FirstName"
                                                label="*First Name"
                                                placeholder="First Name"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={FirstName}                               
                                                validators={['required']}
                                                errorMessages={['This field is required']}


                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                name="LastName"
                                                label="*Last Name"
                                                placeholder="Last Name"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={LastName}                                         
                                                validators={['required']}
                                                errorMessages={['This field is required']}


                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator onChange={(e) => this.handleChange(e)}
                                                name="Phone"
                                                label="Phone"
                                                placeholder="Phone"
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                value={Phone}                                       
                                                validators={['matchRegexp:^0(4|6)[0-9]{8}$']}
                                                errorMessages={['Mobile should be 10 digit and must start from 04 or 06']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl className={classes.formControl} error>
                                            <TextValidator
                                                id="Email" name="Email"
                                                label="*Email"
                                                placeholder="* example@domain.com"
                                                type="email"
                                                fullWidth={true}
                                                margin="dense"
                                                variant="outlined"                                    
                                                value={Email}
                                                onChange={this.handleChange}
                                                validators={['required', 'isEmail']}
                                                errorMessages={[requiredMessage, 'Please enter valid email address']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl className={classes.formControl} error>
                                            <TextValidator
                                                id="Designation" name="Designation"
                                                label="Role"
                                                placeholder="Role"
                                                fullWidth={true}
                                                margin="dense"
                                                variant="outlined"                                         
                                                value={Designation}
                                                onChange={this.handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item sm={12} xs={12} className={classes.control}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <FormLabel component="legend" classes={{ root: classes.formLabelRoot, focused: classes.formLabelFocused }}>*Permissions</FormLabel>
                                            <FormGroup row className={classes.formGroupControl}>
                                                {
                                                    (role === Role_DISTRIBUTOR) &&
                                                    <Query query={Manufacturer_QUERY} fetchPolicy="no-cache">
                                                        {({ loading, error, data }) => {
                                                            if (loading) return <FbLoader isTableBodyRequired={false} colSpan={10} loaderStyle={'emptycell'} />
                                                            if (error) return <FbError isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} />
                                                            const { supplier: { manufacturers } } = data;
                                                            if (manufacturers) {

                                                                return manufacturers.map((mmap) => {

                                                                    const staffManufacturerMapping = manufacturerMapping.find(m => m.manufacturerId === mmap.manufacturerId);
                                                                    const isManufacturerAttached = staffManufacturerMapping && staffManufacturerMapping.isManufacturerAttached;
                                                                    const staffManufacturerPermissions = staffManufacturerMapping && staffManufacturerMapping.permissions;

                                                                    return (
                                                                        <ul className={classes.ulBottomSeparator}>
                                                                            <li>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox name={"AttachedManufacturers-" + mmap.manufacturerId}
                                                                                            checked={Boolean(isManufacturerAttached)}
                                                                                            onChange={(e) => this.handleManufacturerMapping(e)}
                                                                                            value={mmap.manufacturerId}
                                                                                            color="primary"
                                                                                            className={classes.checkbox}
                                                                                            inputProps={{ 'manufacturerid': mmap.manufacturerId }}
                                                                                        />
                                                                                    }
                                                                                    label={mmap.displayName}
                                                                                    classes={{ label: classes.labelManufacturerClass }}
                                                                                />
                                                                            </li>
                                                                            <li>
                                                                                {
                                                                                    Permissions.map(p => {
                                                                                        const isPermissionChecked = staffManufacturerPermissions && staffManufacturerPermissions.find(elem => elem.name === p.name).checked;
                                                                                        return (
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox name={p.name}
                                                                                                        checked={Boolean(isPermissionChecked)}
                                                                                                        onChange={(e) => this.handleDistributorStaffPermissionChange(e)}
                                                                                                        value={p.id}
                                                                                                        inputProps={{ 'manufacturerid': mmap.manufacturerId }}
                                                                                                        color="primary"
                                                                                                        className={classes.checkbox}
                                                                                                    />
                                                                                                }
                                                                                                label={p.name}
                                                                                                classes={{ root: classes.labelClass }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </li>

                                                                        </ul>)
                                                                })
                                                            }
                                                        }}
                                                    </Query>
                                                }
                                                {
                                                    (role !== Role_DISTRIBUTOR) && permissionView
                                                }
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Grid container direction="row" className={classes.update} >
                                <Grid item>
                                    <Button className={classes.button} size="large" variant="contained" type="submit" alignitem='center' color="primary" disabled={(role === Role_DISTRIBUTOR && !this.anyManufacturerAttachedWithPermission())} {...uploading === true ? 'disabled' : ''} tabIndex="3">
                                        {uploading ?
                                            <CircularProgress color={theme.palette.common.white} size={19} /> :
                                            <span>Add</span>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
                {
                    open && (<Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                    >
                        <Fragment>
                            {
                                error && open === true && (<MySnackbarContentWrapper
                                    onClose={this.handleClose}
                                    variant="error"
                                    message={errorMessage}
                                />)

                            }{
                                error === false && open === true && (<MySnackbarContentWrapper
                                    onClose={this.handleClose}
                                    variant="success"
                                    message="New user added successfully"
                                />)

                            }
                        </Fragment>
                    </Snackbar>)
                }
              
            </div>
        );
    }
}

export default withStyles(styles)(Addmember);

