import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
const styles = {
    root: {
        flexGrow: 1,
        height: "100%"

    },
    button: {
        minWidth: "25px",    
        height: "25px",
        background:" #f3f3f3",
        '&.active': {
            boxShadow: 'none',
            background:'#0d4add',
            color:" #fff",
        },
    },
   listInline: {
       display: "inline-block", margin: "0 2px",
    }

};

class PaginationItem extends React.Component {
    constructor(props) {
        super(props);
        this.buildPageItem = this.buildPageItem.bind(this);
        this.buildDropdown = this.buildDropdown.bind(this);
        
        this._id = _.uniqueId(this.props.prefix ? this.props.prefix: "pager" );
  
    }
    buildDropdown() {
        const { CurrentPage, FromPage, ToPage } = this.props;
        var pageCounter = FromPage;
        var elements = [];
        while (pageCounter <= ToPage) {

            var option = (pageCounter === CurrentPage) ? (<option key={this._id + "option" + pageCounter} selected value={pageCounter}>{pageCounter}</option>) : (<option key={this._id + "option" + pageCounter} value={pageCounter}>{pageCounter}</option>);
            pageCounter++;
            elements.push(option);
        }
        
        return elements;
    }
    buildPageItem() {
        const { CurrentPage, PageCount, Status, PageNumber, ItemType, onPageChange, classes,Total } = this.props;
        const iconStyle = {fontSize: 16 };
        switch (ItemType) {

            case 'label': {
                return (<li key={this._id} className={classes.listInline}>Total:{Total}, Showing page {CurrentPage} of {PageCount}</li>);
               
            }
            case 'previous': {
                return (<li key={this._id} className={classes.listInline}>
                    <IconButton component="a" color="primary" onClick={() => onPageChange(CurrentPage - 1)}>
                        <Icon style={iconStyle}>
                            arrow_back_ios
                        </Icon>
                    </IconButton>

                    </li>);
               
            }
            case 'next': {
                return (<li key={this._id} className={classes.listInline}>
                    <IconButton component="a" color="primary" onClick={() => onPageChange(CurrentPage + 1)} >
                        <Icon style={iconStyle}>
                            arrow_forward_ios
                        </Icon>
                    </IconButton>
                   </li>);
                
            }
            case 'first': {
                return (<li key={this._id} className={classes.listInline}>
                    <IconButton component="a" color="primary" onClick={() => onPageChange(1)} >
                        <Icon style={iconStyle}>
                            first_page
                        </Icon>
                    </IconButton>
                
                </li>);

            }
            case 'last': {
                return (<li key={this._id} className={classes.listInline}>
                   
                    <IconButton component="a" color="primary" onClick={() => onPageChange(PageNumber)} >
                        <Icon style={iconStyle}>
                            last_page
                        </Icon>
                    </IconButton>
                </li>);

            }
            case 'page': {
                const is_active = classNames([classes.button], { active: Status === 'active' });
                const page_number = parseInt(PageNumber, 10);
                return (<li key={this._id} className={classes.listInline}>
                    <Button component="a" color="primary" className={is_active} onClick={() => onPageChange(page_number)} >
                        {page_number}
                    </Button>
                </li>);
               
            }
            case 'page-dropdown': {
                //const is_active = Status === 'active' ? 'active' : '';
                //const page_number = parseInt(PageNumber, 10);
                const items = this.buildDropdown();
                return (<li key={"drp_" + this._id}><select key={"fbpager" + this._id} value={CurrentPage} onChange={(event) => onPageChange(event.target.value)}>{items}</select></li>);
            }
                
            default:{
                return (<li key={this._id}><Button component="a" onClick={()=>{}}></Button></li>);
            }
        }
        
    }

    render() {
        
        var item = this.buildPageItem(this.props.ItemType);
        return (
            item
        );
    }
}
export default withStyles(styles)(PaginationItem);