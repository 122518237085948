// src/components/Error.js
import React from 'react';
import { Typography, Box } from '@material-ui/core';

const Error = () => (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Typography variant="h6" color="error">
            Something went wrong. Please try again later.
        </Typography>
    </Box>
);

export default Error;