import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { LINKS_PER_PAGE, RandomText, Role_Admin, Role_DISTRIBUTOR, Role_MANUFACTURER, Role_STAFF, Role_DISTRIBUTOR_STAFF } from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import UserProfile from '../auth/UserProfile';
import theme from '../../theme';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from "../common/Pagination";
import ModelSearch from "../ModelSearch";
import ProductHeaderRow from "./ProductHeaderRow";
import ProductLineDialog from './ProductLineDialog';
import ProductRow from "./ProductRow";
import Hidden from '@material-ui/core/Hidden';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';


const styles ={
    root: {
        width: '100%',     
        overflowX: 'auto',    
    },
    title: {
        textTransform: "uppercase"
    },
    table: {
       //borderTopWidth: "1px",
       //borderLeftWidth: "1px",
       // borderRightWidth: "1px", 
       // borderColor: theme.palette.common.borderColor
    },
    Grid: {
        backgroundColor: theme.palette.grey[100],
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        minHeight: 49,
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "1px",
        
        borderColor: theme.palette.common.borderColor,
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    divider: {
        background: "none",
        borderTopWidth: "1px",
        borderStyle:"solid",
        borderColor: theme.palette.common.borderColor,
    },
    paperTable: {
        border: "1px solid", 
        borderColor: theme.palette.common.borderColor,
        minHeight: "calc(100vh - 300px)",
        width: "100%",
        overflowX: 'auto',
        background: theme.palette.common.white,
    }
};

const FooterCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);
class Product extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            pageNumber: 1, pageSize: LINKS_PER_PAGE, filter: null, open: false, pLinePageNumber: 1, pLinePageSize: LINKS_PER_PAGE, pLFilter: null, selectedProductId: null, selectedProductLineId:null,random:null,
            scroll: 'paper',
            fullWidth: true,
            maxWidth: 'lg',};
        this.onPageChange = this.onPageChange.bind(this);
        this.onDialogShow = this.onDialogShow.bind(this);
        this.onDialogCLose = this.onDialogCLose.bind(this);
        this.notifyUpdate = this.notifyUpdate.bind(this);
        this._getData = this._getData.bind(this);
      
    }

    onPageChange(newPage) {
        this.setState({
            pageNumber: newPage
        })
    }

    onDialogCLose() {
        this.setState({ open: false, selectedProductId: null, selectedProductLineId: null, random: RandomText(8) });
    }

    notifyUpdate(mode,newProductLineId) {
        if (mode === true) {
            this.setState({ selectedProductLineId: null });
        }
        else {
            this.setState({ selectedProductLineId: newProductLineId });
        }
        
    }
    onDialogShow(scroll, model){
        const productLineId = model.productLine != null ? model.productLine.productLineId : null;
        this.setState({ selectedProductLineId: productLineId, selectedProductId: model.productId, open: true, scroll });
    }
    handleSearch(data) {
        this.setState({
            filter: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }

    

    _getQueryVariables(role, manufacturerId) {

        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const skip = (page - 1) * page_size;
        const filter = this.state.filter;
        const first = page_size;
        const random = this.state.random;

        switch (role) {
            case Role_Admin: return { manufacturerId:parseInt(manufacturerId), first, skip, filter };
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { manufacturerId: parseInt(manufacturerId), first, skip, filter };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { first, skip, filter, random };
        }
            
    }


    shouldComponentUpdate(nextProps, nextState) {
        
        let shouldUpdate = (this.state.pageNumber !== nextState.pageNumber || this.state.pageSize !== nextState.pageSize ||
            this.state.filter !== nextState.filter || this.state.open !== nextState.open ||
            this.state.selectedProductLineId !== nextState.selectedProductLineId || this.props.manufacturerId !== nextProps.manufacturerId)
        return shouldUpdate;
    }

    _getData(data, role) {
        //adminView ? data.admin.products : data.productfeed.products
        switch (role) {
            case Role_Admin: return { productCount: data.admin.count, products: data.admin.products };
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { productCount: data.supplier.count, products: data.supplier.products };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { productCount: data.productfeed.count, products: data.productfeed.products }; 
        }
    }
    render() {
       
        const { classes } = this.props;
        let { manufacturerId } = this.props;
        const role = UserProfile.getRole();
        const { open, selectedProductId, selectedProductLineId } = this.state;
        const query = QueryHelper().ProductListing();
        const isDistributor = UserProfile.isDistributor();
      
        return (
          
            
                    <Fragment>
                    
                        <Grid 
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            className={classes.Grid}
                        >
                            <Grid item></Grid>
                            <Grid item>
                                <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} />
                            </Grid>
                        </Grid>
                        <div className={classes.root}>
                            <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                                <Table className={classes.table}>
                                    <Hidden smDown>
                                        <TableHead>
                                            <ProductHeaderRow adminView={UserProfile.isAdmin()} />
                                        </TableHead>
                                    </Hidden>
                            {isDistributor && (_.isNil(manufacturerId)) && <tbody><tr><td colSpan={10} style={{ "textAlign": "center" , "padding" : "20px" }}><Typography justify="center" align="center" component="span"
                                        alignItems="center" >Please contact manufacturer to activate account </Typography></td></tr></tbody>
                                    }
                            {((isDistributor && !_.isNil(manufacturerId)) || (!isDistributor)) &&

                                (<Query query={query} variables={this._getQueryVariables(role, manufacturerId)} fetchPolicy="no-cache">
                                            {({ loading, error, data }) => {


                                                if (loading) return <FbLoader isTableBodyRequired={true} colSpan={10} loaderStyle={'emptycell'} />
                                                if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} />
                                                const { productCount, products } = this._getData(data, role);
                                                const paging = (<Pagination Prefix="products" TotalRecords={productCount} PageSize={this.state.pageSize} PageNumber={this.state.pageNumber} ShowPreviousNext={true} onPageChange={this.onPageChange} ></Pagination>);
                                                const listModels = products.map((model, index) =>
                                                    (
                                                        <ProductRow key={index} role={role} isDistributor={isDistributor} adminView={role === Role_Admin   ? true : null} model={model} index={index} shouldOpenDialog={this.onDialogShow} />

                                                    ));
                                                const result = (products !== 'undefined' && products.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} />;
                                                const pagination_content = (products !== 'undefined' && products.length > 0 && productCount > this.state.pageSize) ? <TableFooter ><TableRow ><FooterCell colSpan="10">{paging}</FooterCell ></TableRow ></TableFooter > : null;
                                                return (<Fragment><TableBody>{result}</TableBody>{pagination_content}</Fragment>);
                                            }}
                                        </Query>)
                                    }

                                </Table>
                            </Paper>
                        </div>
                        <ProductLineDialog open={open} handleClose={this.onDialogCLose} selectedProductId={selectedProductId} selectedProductLineId={selectedProductLineId} notifyUpdate={this.notifyUpdate} />
                    </Fragment>
        )
    }
                  
       
        
    
}

export default withStyles(styles)(Product);   
