import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import PlacesAutocomplete from 'react-places-autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1) * 3,
    },
    formControl: {
        width: "100%"
    },
    dense: {
        marginTop: theme.spacing(1) * 0,
        marginBottom: theme.spacing(1) * 0,
        paddingTop: theme.spacing(1) * 0,
    },
    notchedOutline: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    container: {
        height: '100%',
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(1) * 8,
        paddingRight: theme.spacing(1) * 8,
        width: 300,
        height: 50,
    },
    validText: {
        borderColor: "#41A500"
    },
});
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }
 
  handleChange = address => {
    this.setState({ address });
  };
 
    handleSelect = (address, placeId) => {
        
    //    geocodeByPlaceId(placeId).then(results => { debugger });
    //geocodeByAddress(address)
    //  .then(results => getLatLng(results[0]))
    //  .then(latLng => console.log('Success', latLng))
        //  .catch(error => console.error('Error', error));
        if (placeId != null) { this.setState({ address }); this.props.handleAddressChange(address, placeId); }
       
  };
 
    render() {
        const { classes, searchOptions } = this.props;
    return (
      <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange} shouldFetchSuggestions={this.state.address.length > 3}
            onSelect={this.handleSelect} searchOptions={searchOptions}

      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <TextValidator
                        id="address" name="address"
                        label="Address*"
                        placeholder="Street address, postal code etc"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        validators={['required']}
                        errorMessages={['']}
                        className={classNames(classes.dense)} {...getInputProps({
                            placeholder: 'Street address, postal code etc ...',
                            className: 'location-search-input',
                        })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        />
              
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item-active'
                  : 'suggestion-item';
                const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '18px 14px', borderBottom: '1px solid', borderColor: "#dddddd",  }
                    : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '18px 14px', borderBottom: '1px solid', borderColor: "#dddddd",  };
                return (
                  <div
                        {...getSuggestionItemProps(suggestion, {
                            className,
                            style,

                        })}
                    
                    >
                        <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

LocationSearchInput.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(LocationSearchInput);