import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemText, IconButton
} from '@material-ui/core';

import logo from '../../assets/images/logo.svg'
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';


const drawerWidth = 240;
const styles = theme => ({
    drawerPaper: {
        border:"none",
        width: drawerWidth,
        background:"#2c323f",
    },
    text: {
        color: "#fff",
        fontSize:"1rem",
    },
    listCustom: {
        borderLeft: "7px solid transparent",
    },
    listselect: {
        borderColor:theme.palette.common.white,
    },
    bigAvatar: {
        borderRadius:0,
        width: 142,
        height: "auto",
        paddingLeft: "21px",
        paddingTop:16,
    },
    toolbar: theme.mixins.toolbar,
});



const Navigation = ({ classes, onNavigationChange, selectedIndex }) => {
    const [menuState, setMenuState] = React.useState(false);
    const handleMenuClick = () => {
        setMenuState(!menuState);
    }

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <Avatar alt="logo" src={logo} className={classes.bigAvatar} />
                <IconButton aria-label="menu" onClick={() => { handleMenuClick(); } }>
                    {menuState && (<MenuOpenIcon />)}
                    {!menuState && (<MenuIcon />)}
                </IconButton>
            </div>
            <Divider />
            <List component="nav">
                <ListItem
                    button
                    component={Link}
                    to="/AdminDashboard"
                    selected={selectedIndex === 0}
                    onClick={(event) => onNavigationChange(0)}
                    classes={{ selected: classes.listselect, root: classes.listCustom }}
                >
                    <ListItemText primary="Dashboard" classes={{ primary: classes.text }} />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/Manufacturers"
                    selected={selectedIndex === 1}
                    onClick={(event) => onNavigationChange(1)}
                    classes={{ selected: classes.listselect, root: classes.listCustom }}
                >
                    <ListItemText primary="Manufacturers" classes={{ primary: classes.text }} />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/Suppliers"
                    selected={selectedIndex === 3}
                    onClick={(event) => onNavigationChange(1)}
                    classes={{ selected: classes.listselect, root: classes.listCustom }}
                >
                    <ListItemText primary="Suppliers" classes={{ primary: classes.text }} />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/AdminInstaller"
                    selected={selectedIndex === 2}
                    onClick={(event) => onNavigationChange(2)}
                    classes={{ selected: classes.listselect, root: classes.listCustom }}
                >
                    <ListItemText primary="Installers" classes={{ primary: classes.text }} />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/AdminSerials"
                    selected={selectedIndex === 4}
                    onClick={(event) => onNavigationChange(4)}
                    classes={{ selected: classes.listselect, root: classes.listCustom }}
                >
                    <ListItemText primary="Serials" classes={{ primary: classes.text }} />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/Logs"
                    selected={selectedIndex === 5}
                    onClick={(event) => onNavigationChange(5)}
                    classes={{ selected: classes.listselect, root: classes.listCustom }}
                >
                    <ListItemText primary="Logs" classes={{ primary: classes.text }} />
                </ListItem>
            </List>
        </div>
    );

    return (
       
            <Drawer anchor="left"
                classes={{
                    paper: classes.drawerPaper,
                }}
                variant="permanent"
                open={menuState} >
                {drawer}
            </Drawer>
       
    );
};

export default withStyles(styles)(Navigation);



