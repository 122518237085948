import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { LINKS_PER_PAGE, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../../constants';
import QueryHelper from '../../../libs/QueryHelper';
import UserProfile from '../../auth/UserProfile';
import FbEmptyResult from '../../common/FbEmptyResult';
import FbError from '../../common/FbError';
import FbLoader from '../../common/FbLoader';
import Pagination from "../../common/Pagination";
import ModelSearch from "../../ModelSearch";
import ResolveFlashDialog from "../ResolveFlashDialog";
import FailedSerialRow from "../serials/FailedSerialRow";
import SerialHeader from '../serials/SerialHeader';

const styles = theme => ({
    table: {
        //borderTopWidth: "1px",
        //borderLeftWidth: "1px",
        //borderRightWidth: "1px",
        //borderColor: theme.palette.common.borderColor,
    },

    title: {
        textTransform: "uppercase"
    },
    Grid: {
        backgroundColor: theme.palette.grey[100],
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.common.borderColor,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        // borderBottom: '1px solid #e8e8e8',
        minHeight: 49,
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    tableroot: {
        width: "100%",
        overflowX: "auto",
    },
    paperTable: {
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,
        minHeight: "calc(100vh - 470px)",
        width: "100%",
        overflowX: 'auto',
        background: theme.palette.common.white,
    }
});
const FooterCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);
class DetailFailedTab extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = { pageNumber: 1, pageSize: LINKS_PER_PAGE, random: null, open: false };
        this.onPageChange = this.onPageChange.bind(this);
        this.onDialogShow = this.onDialogShow.bind(this);
        this.onDialogCLose = this.onDialogCLose.bind(this);
        this.localRefetch = null;

    }

    onDialogCLose() {
        this.setState({ open: false });
        if (this.localRefetch) {
            this.localRefetch();
        }
    }

    onDialogShow() {

        this.setState({ open: true });
    }

    onPageChange(newPage) {
        this.setState({
            pageNumber: newPage
        })
    }

    handleSearch(data) {
        this.setState({
            filter: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }
    _getQueryVariables(role,manufacturerId) {

        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const skip = (page - 1) * page_size;
        const id = this.props.Model.productId;
        const first = page_size;
        const filter = this.state.filter;
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { id, manufacturerId, filter, first, skip };
            case Role_Admin:
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { id, filter, first, skip };
        }
    }
    _getData(data, role) {
        //adminView ? data.admin.products : data.productfeed.products
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { productFailedSerialsCount: data.supplier.product.productFailedSerialsCount, productFailedSerials: data.supplier.product.productFailedSerials };
            case Role_Admin:
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { productFailedSerialsCount: data.productfeed.product.productFailedSerialsCount, productFailedSerials: data.productfeed.product.productFailedSerials };
        }
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        const role = UserProfile.getRole();
        const query = QueryHelper().ProductFailedSerialByProductId();
        const manufacturerId = UserProfile.getManufacturerId();
        return (
            <Fragment>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.Grid}
                >

                    <Grid lg={9} item>

                    </Grid>
                    <Grid lg={1} item>
                        <Button variant="outlined" size="small" color="primary" onClick={() => { this.onDialogShow(); }}>
                            Upload Serial
                        </Button>
                    </Grid>
                    <Grid lg={2} item>
                       
                        <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} />
                    </Grid>
                </Grid>
                <div className={classes.tableroot}>
                    <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                        <Table className={classes.table}>
                            <TableHead>
                                <SerialHeader />
                            </TableHead>
                            <Query query={query} variables={this._getQueryVariables(role, manufacturerId)}  >
                                {({ loading, error, data, refetch }) => {
                                   
                                    if (loading) return <FbLoader isTableBodyRequired={true} colSpan={8} loaderStyle={'emptycell'} />
                                    if (error) return <FbError isTableBodyRequired={true} colSpan={8} errorStyle={'emptycell'} />

                                    const { productFailedSerialsCount, productFailedSerials} = this._getData(data, role);
                                    this.localRefetch = refetch;
                                    const paging = (<Pagination Prefix="serialFailed" TotalRecords={productFailedSerialsCount}
                                        PageSize={this.state.pageSize} PageNumber={this.state.pageNumber} ShowPreviousNext={true} onPageChange={this.onPageChange} ></Pagination>);
                                    const listSerials = productFailedSerials.map((serial, index) =>
                                        (
                                            <FailedSerialRow key={index} serial={serial} index={index} />

                                        ));
                                    const result = (productFailedSerials !== 'undefined' && productFailedSerials.length > 0)
                                        ? listSerials : <FbEmptyResult isTableBodyRequired={false} colSpan={8} errorStyle={'emptycell'} />;
                                    const pagination_content = (productFailedSerials !== 'undefined' && productFailedSerials.length > 0
                                        && productFailedSerialsCount > this.state.pageSize) ? <TableFooter><TableRow><FooterCell colSpan="6">{paging}</FooterCell></TableRow></TableFooter> : null;
                                    return (<Fragment><TableBody>{result}</TableBody>{pagination_content}</Fragment>);
                                }}
                            </Query>
                        </Table>
                    </Paper>
                </div>
                <ResolveFlashDialog open={open} handleClose={this.onDialogCLose} />
            </Fragment>
           
               
        );
    }
}

export default withStyles(styles)(DetailFailedTab); 
