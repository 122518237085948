import Button from '@material-ui/core/Button';
import { orange } from '@material-ui/core/colors';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Customicon from '../Customicon';
import Modal from '@material-ui/core/Modal';
import { handleFailDownload } from '../Helper';
import { DashboardHeaderTitle, FailedImportDataCaption, FlashDataCaption } from '../StringConstants';
import AuthService from './AuthService';
import UserProfile from '../components/auth/UserProfile';
import FbButton from './common/FbButton';
const styles = theme => ({
    align: {
        textAlign: 'right',
    },
    icon: {
        fontSize: '22px',
        margin: '0 5px 0 0',
    },
    downloadcsv: {
        
        color: 'white',
        background: red[500],
        '&:hover': {
            background: red[600],
            color: 'white',
        },
    },
    button: {
        marginRight: '5px',
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        //'&:hover': {
        //    background: theme.palette.common.green
        //}
       
    },
    customicon: {
        marginRight: theme.spacing(2),
    },
    btnColor: {
        backgroundColor: orange[500],
        color: "#fff",
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
    paper: {
        position: 'absolute',
        width: 300,
        minHeight:100,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '15px 15px',
    },
    chip: {
        margin: '20px 10px',
        textAlign: 'center',
        padding:'10px'
    }

});

class DashboardHeader extends React.Component {

    constructor(props) {
        super(props);
        this.onFailDownload = this.onFailDownload.bind(this);
        this.Auth = new AuthService();
        this.state = {
            loadingCsv: false ,open:false
        }
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.rand = this.rand.bind(this);
        this.getModalStyle = this.getModalStyle.bind(this);
    }
     handleOpen = () => {
         this.setState({ "open": true });
    };
     rand() {
    return  - 5;
}

 getModalStyle() {
    const top = 50 + this.rand();
    const left = 50 + this.rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
     handleClose = () => {
         this.setState({ "open": false });
    };
    onFailDownload(format) {
        
        let parentThis = this;
        handleFailDownload((data) => {
          
            parentThis.handleClose();
        }, (error) => { parentThis.handleClose(); }, UserProfile.getManufacturerId(), null, format);
    }
    render() {
        const { loadingCsv,open } = this.state;
        const { classes } = this.props;
        const compositeClass = classes.button + " " + classes.downloadcsv;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item xs={12} sm={7}>
                        <Typography variant="h1">
                            {DashboardHeaderTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.align}>
                     
                       
                            
                        <FbButton loading={loadingCsv} type="button" title={"Failed Serials"} className={compositeClass} onClick={() => this.handleOpen()} renderIcon={self => (<Icon className={classes.icon}>
                            arrow_downward
                            </Icon>)} />
                          
                       
                        
                        <Button variant="contained" size="large" component={Link} to="/flash" color="primary" className={classes.button}>
                            <Customicon name="flash" width={19} fill="#fff" className={classes.customicon} />{FlashDataCaption}
                        </Button>
                        <Button variant="contained" size="large" component={Link} to={"/flash"}  className={classNames(classes.button, classes.btnColor)}>
                            <Customicon name="flash" width={19} fill="#fff" className={classes.customicon} />{FailedImportDataCaption}
                        </Button>
                    </Grid>
                </Grid>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.handleClose} >
                    <div style={this.getModalStyle()} className={classes.paper}>
                        <Typography variant="display2">
                            Download failed serials 
                                 <Typography variant="body1">
                                choose download format
                        </Typography> 
                        </Typography> 
                        <Chip className={classes.chip} label="CSV" onClick={() => this.onFailDownload("csv")} />
                        <Chip className={classes.chip} color="primary" label="XLSX" onClick={() => this.onFailDownload("xlsx")} />
                    </div>
                </Modal>
            </div>
        );
    }
}
export default withStyles(styles)(DashboardHeader);