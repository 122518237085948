import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import gql from 'graphql-tag';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { LINKS_PER_PAGE } from '../../constants';
import theme from '../../theme';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from "../common/Pagination";
import ModelSearch from "../ModelSearch";
import AddProduct from './AddProduct';
import ProductLineHeader from './ProductLineHeader';
import ProductLineRow from './ProductLineRow';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import { Helmet } from "react-helmet";
const styles = {
    root: {
        height:"100%"
    },
    tableroot: {
        width: "100%",
        overflowX:"auto"
    },
    table: {
       // borderLeftWidth: "1px",    
       // borderTopWidth: "1px",
       // borderColor: theme.palette.common.borderColor,
       // borderRightWidth: 1,
    },
    paperBorder: {
        borderColor: theme.palette.common.borderColor,
        border: "1px solid",
        background: theme.palette.common.white,
        lineHeight: 1.5,
        minHeight: "calc(100vh - 260px)",
        [theme.breakpoints.down('sm')]: {
            border: "none",
        },
    },
    Grid: {
        backgroundColor: theme.palette.grey[50],       
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        
        // borderBottom: '1px solid #e8e8e8',
        minHeight: 49,
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "1px",
        borderColor: theme.palette.common.borderColor,
    },
  
};
const PRODUCT_Line_QUERY = gql`
query get_products($filter: String, $skip: Int, $first: Int) {
  productfeed {
    productLines(filter: $filter, skip: $skip, first: $first) {
      productLineId,
      lineName
      updatedOn
      totalProduct
      logo
      productWarranty {
        componentInYears
        componentWarrantyLink
        performanceInYears
        performanceWarrantyLink
        productWarrantyId
      }
    }
    productLineCount(filter: $filter)
  }
}
`;
class ProductLineContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = { pageNumber: 1, pageSize: LINKS_PER_PAGE, filter: null };
        this.onPageChange = this.onPageChange.bind(this);

    }
    onPageChange(newPage) {
        this.setState({
            pageNumber: newPage
        })
    }

    handleSearch(data) {
        this.setState({
            filter: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }
    _getQueryVariables() {

        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const skip = (page - 1) * page_size;
        const filter = this.state.filter;
        const first = page_size;
        return { first, skip, filter }
    }

    refetchProductLinesCallback = () => this.refetch();

    shouldComponentUpdate(nextProps, nextState) {

        let shouldUpdate = (this.state.pageNumber !== nextState.pageNumber || this.state.pageSize !== nextState.pageSize || this.state.filter !== nextState.filter);
        return shouldUpdate;
    }
    render() {
        const { classes } = this.props;
        return (
            <div key="mainProductLine" className={classes.root}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Product Line</title>
                </Helmet>
                <AddProduct />
                <div key="mainProductContainer">
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={classes.Grid}
                    >
                        <Grid item></Grid>
                        <Grid item>
                            <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} />
                        </Grid>
                    </Grid>
                    <Paper square elevation={0} className={classNames(classes.paperBorder, classes.tableroot)} > 
                        <Table className={classes.table}>         
                            <Hidden smDown>
                                <ProductLineHeader show_action={true} />
                            </Hidden>
                        <Query query={PRODUCT_Line_QUERY} variables={this._getQueryVariables()} fetchPolicy="network-only"
                             >
                                {({ loading, error, data, refetch }) => {
                                    this.refetch = refetch;

                                if (loading) return <FbLoader isTableBodyRequired={true} colSpan={10} loaderStyle={'emptycell'} />
                                if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} message='Product Line Not Found' />
                                const paging = (<Pagination Prefix="products" TotalRecords={data.productfeed.productLineCount} PageSize={this.state.pageSize} PageNumber={this.state.pageNumber} ShowPreviousNext={true} onPageChange={this.onPageChange} ></Pagination>);
                                const listModels = data.productfeed.productLines.map((model, index) =>
                                    (
                                    <ProductLineRow refetchProductLinesCallback={this.refetchProductLinesCallback} key={"test" + index} data={model} index={index} rowType={1} show_action={true} />

                                    ));

                                    const result = (data.productfeed.productLines !== 'undefined' && data.productfeed.productLines.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} colSpan={5} errorStyle={'emptycell'} />;
                                    const pagination_content = (data.productfeed.productLines !== 'undefined' && data.productfeed.productLines.length > 0 && data.productfeed.productLineCount > this.state.pageSize ) ? <TableFooter><TableRow><TableCell colSpan="10">{paging}</TableCell></TableRow></TableFooter> : null;
                                    return (<Fragment><TableBody key="productLine">{result}</TableBody>{pagination_content}</Fragment>);
                            }}
                        </Query>
                            </Table>
                      </Paper>
                 
                </div>
            </div>
        );
    }
}
export default compose(withStyles(styles))(withRouter(ProductLineContainer))