import { useQuery } from '@apollo/react-hooks';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import React, { Fragment, useState } from "react";
import { downloadInstalledProductsWattageReportForModelAndState } from "../../Helper";
import "./GeoApp.css";
import GraphReport from './GraphReports';
import InstallsPerState from "./InstallsPerState";
import StatsSelector from './StatsSelector';

const INSTALLATION_STATS_QUERY = gql`
query getInstallationStats {
  manufacturer {
    manufacturerInstallationStats {
      year
      month
      monthName
      totalPower
      totalInstallation
      stateName
    }
  }
}
`;
const useStyles = makeStyles(theme => ({
    spaceX: {
        marginRight: theme.spacing(2)
    },
    statsLoading: {
        position: 'absolute',
        padding: '0 0 0 16px',
        bottom: '62px'
    }
}));

export default function InstallationStats() {
    const [activeState, setActiveState] = useState(-1);
    const [productInstallationStatsLoading, setProductInstallationStatsLoading] = useState(false);
    const [statsLoadingError, setStatsLoadingError] = useState(false);
    const [statsLoadingErrorMessage, setStatsLoadingErrorMessage] = useState(null);

    const [activeStateForStatewiseChart, setActiveStateForStatewiseChart] = useState(-1);
    const [filterType, setFilterType] = useState('installation');
    const { loading, error, data } = useQuery(INSTALLATION_STATS_QUERY);

    const classes = useStyles();

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    
    const nonUniqueYears = data.manufacturer.manufacturerInstallationStats.map(function (o) { return o.year; });
    const yearList = [...new Set(nonUniqueYears)];
    let maxYear = parseInt(Math.max.apply(Math, yearList));
    let maxYearForStateWiseChart = maxYear;
    maxYear = parseInt(activeState) === -1 ? maxYear : parseInt(activeState);
    maxYearForStateWiseChart = activeStateForStatewiseChart === -1 ? maxYearForStateWiseChart : activeStateForStatewiseChart;
    const monthMapper = { 'Jan': null, 'Feb': null, 'Mar': null, 'Apr': null, 'May': null, 'Jun': null, 'Jul': null, 'Aug': null, 'Sep': null, 'Oct': null, 'Nov': null, 'Dec': null};
    const activeStateWiseStats = data.manufacturer.manufacturerInstallationStats.filter(function (o) { return o.year === maxYearForStateWiseChart; })
    const activeYearWiseStats = data.manufacturer.manufacturerInstallationStats.filter(function (o) { return o.year === maxYear; })
    // const p = _.partition(data.manufacturer.manufacturerInstallationStats, function (o) { return o.year; });
    const totalInstallations = activeYearWiseStats.reduce((acc, val) => acc + val.totalInstallation, 0);
    const totalPower = (activeYearWiseStats.reduce((acc, val) => acc + val.totalPower, 0) /1000000).toFixed(2);
    let output = [["Month", "ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]];
    Object.keys(monthMapper).forEach((month)=> {
        let statsData = { "ACT": 0, "NSW": 0, "NT": 0, "QLD": 0, "SA": 0, "TAS": 0, "VIC": 0, "WA": 0 };
        var currentMonthData = activeYearWiseStats.filter(function (o) { return o.monthName === month; })
        for (var i = 0; i < currentMonthData.length; i++) {
            var item = currentMonthData[i];
            if (filterType === 'installation') {
                statsData[item.stateName] = statsData[item.stateName] + item.totalInstallation;
            }
            else {
                statsData[item.stateName] = statsData[item.stateName] + item.totalPower / 1000000;
            }
            
        }
        monthMapper[month] = statsData;
        
        output.push([month, statsData.ACT, statsData.NSW, statsData.NT, statsData.QLD, statsData.SA, statsData.TAS, statsData.VIC, statsData.WA]);
        console.log(monthMapper);
    });

    const onChange = (elem) => {

        setActiveState(parseInt(elem.target.value));
    };

    const handleSnackbarClose = () => {
        setStatsLoadingError(false);
        setStatsLoadingErrorMessage(null);
    };

    const onStateWiseDropdownChange = (elem) => {
        setActiveStateForStatewiseChart(parseInt(elem.target.value));
    };

    const onStatsLoadingError = (data) => {
        setProductInstallationStatsLoading(false);
        setStatsLoadingError(true);
        setStatsLoadingErrorMessage(data);
    };

    const getInstalledProductsWattageReport = (month) => {
        if (productInstallationStatsLoading) {
            return false;
        }
        const monthYearName = `${Object.keys(monthMapper)[month]}_${maxYear}`;
        const timespan = `${month + 1}/${maxYear}`;
        const params = { timespan, monthYearName };
        setProductInstallationStatsLoading(true);
        downloadInstalledProductsWattageReportForModelAndState(data => setProductInstallationStatsLoading(data), data => onStatsLoadingError(data), params);
    }

    return (
        <Fragment>
            <Grid item lg={6} style={{ position: "relative" }}>
                <Paper square elevation={0} style={{ padding: "16px 16px 0 16px", borderBottom: "1px solid #e5e5e5" }}>
                    <Grid container direction="row">
                        <Grid item className={classes.spaceX}>
                            <Typography variant="subtitle2" gutterBottom>Installations</Typography>
                            <Typography variant="h3">{totalInstallations}</Typography>
                        </Grid>
                        <Grid item className={classes.spaceX}>
                            <Typography variant="subtitle2" gutterBottom> Capacity MW</Typography>
                            <Typography variant="h3">{totalPower}</Typography>
                        </Grid>
                        <Grid item className={classes.spaceX}>
                            <RadioGroup row aria-label="position" name="position" defaultValue={filterType} onChange={(e) => { setFilterType(e.target.value); } }>
                                <FormControlLabel
                                    value="installation"
                                    control={<Radio color="primary" />}
                                    label="Installation Stats"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="power"
                                    control={<Radio color="primary" />}
                                    label="Total Power in MW"
                                    labelPlacement="start"
                                />
                                </RadioGroup>
                        </Grid>
                    </Grid>
                    <GraphReport data={output} downloadReportCallback={getInstalledProductsWattageReport} />
                    
                </Paper>
                <StatsSelector data={yearList} onChange={onChange} selected={maxYear} />
                {productInstallationStatsLoading && <Box component="div" className={classes.statsLoading}>Loading stats...</Box>}
            </Grid>
            <Grid item lg={6} style={{ borderLeft: "12px solid #fff" }}>
                <Paper square elevation={0} style={{ padding: "16px", borderBottom: "1px solid #e5e5e5" }}>
                    <InstallsPerState data={activeStateWiseStats} />
                </Paper>
                <Paper square elevation={0} style={{ padding: "16px" }}>
                    <StatsSelector data={yearList} onChange={onStateWiseDropdownChange} selected={maxYearForStateWiseChart} />
                </Paper>
                
            </Grid>
            {
                statsLoadingError && (<Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={statsLoadingError}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    message={statsLoadingErrorMessage}
                >
                </Snackbar>)
            }
        </Fragment>
    );
}