import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const Footer = styled.footer`
@media (min-width: 992px)
 {
    background: #fff;
    li a {
        color: #333 ;
    }
    .copyright {
    color: #333 ;
}
}

@media (min-width: 768px)
 {
    background: transparent;
    position: relative;
}
@media (min-width: 1200px)
{
 li {
    margin: 0 13px 0 13px;
}
}

@media (min-width: 768px){
 li a {
    color: #fff;
    }
}
 li.register-footer a{
    color: #0d4add;
    &:hover{
      color:#0d4add;
      text-decoration: underline;
    }
}
    width: 100%;
    float: left;
    margin: 30px 0 0 0;
    padding: 20px 0 0;
    border-top: 1px solid #e5e5e5;
    background: none;
    ul {
        text-align: center;
    }
    li {
        display: inline-block;
        margin: 0 15px 0 15px;
        a {
            font-family: 'NHaasGroteskDSPro-55Rg';
            font-size: 15px;
            color: #e5e5e5;
            text-decoration: none;
            &:hover{
                text-decoration: none;
                color:#fff;
            }
        }
    }
    p{
        text-align: center;
        font-size: 14px;
        color: #333 ;
        font-family: 'NHaasGroteskDSPro-55Rg';
        padding: 15px 0 10px 0;
    }   
.copyright{
  color: #e5e5e5;
}
`;
class AccountFooter extends React.Component {
    
    render() {
        const { customClass} = this.props;
        var links = [
            { name: 'Terms of use', path: '/legal' },
            { name: 'Privacy', path: '/legal' },
            { name: 'About', path: '/legal' }
        ];
        const listItems = links.map((link, index) =>
            <li className={customClass} key={index}>
                <Link title={link.name} to={link.path}>{link.name}</Link>
            </li>
        );

        return (<Footer >
            <ul>{listItems}</ul>
            <p className={customClass}>Copyright &copy;2019 Formbay, Inc. All rights reserved.</p>
        </Footer>);
         
    }
}

export default AccountFooter;
