import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import LeftNav from './LeftNav';

const styles = theme => ({
    menuButton: {
        marginRight: theme.spacing(1),
        marginLeft: -10,
        padding: theme.spacing(1),
    },

});

class MobileNavigation extends React.Component {
    constructor() {
        super();       
        this.state = { top: false, left: false, bottom: false, right: false, }
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };


    render() {
        const { classes, selectedIndex, onNavigationChange } = this.props;       
        return (<div className={classes.root}>      
            <IconButton className={classes.menuButton} color="inherit" onClick={this.toggleDrawer('left', true)}>
                <MenuIcon />
            </IconButton>  
            <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.toggleDrawer('left', false)}
                    onKeyDown={this.toggleDrawer('left', false)}
                >
                    <LeftNav selectedIndex={selectedIndex} onNavigationChange={onNavigationChange} />
                </div>
            </Drawer>
        </div>
            );
    }
}


export default withStyles(styles)(MobileNavigation);