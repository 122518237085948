import FormControl from "@material-ui/core/FormControl";
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import theme from '../../theme';
const styles = {
    selectBox: {
        padding: "6px 12px",
    },
    spaceRight: {
        marginRight: theme.spacing(1),
    }

}
class StatsSelector extends React.Component {
    render() {
        const { classes, data, onChange ,selected} = this.props;
        
        return (
            <Paper square elevation={0} style={{ padding: "16px" }}>
                <FormControl variant="filled" className={classes.spaceRight}>
                    <Select native classes={{ select: classes.selectBox }} onChange={(e) => { onChange(e) }}>
                        {
                          
                            data.map((val) => { return (<option selected={selected === val} value={val}>{val}</option>) })
                        }
                    </Select>
                </FormControl>
            </Paper>
            
            )}

}
export default withStyles(styles)(StatsSelector);