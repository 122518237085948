import { Button, Divider, FormControl, Grid, Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Mutation } from 'react-apollo';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import gql from 'graphql-tag';
import React, { createRef } from 'react';
import { Query } from 'react-apollo';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import { DeleteStaffApi } from '../../constants';
import { NotificationHeader } from '../../StringConstants';
import AuthService from '../AuthService';
import FBAlertDialog from '../common/FBAlertDialog';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import TransferList from './TransferList';
export const SAVE_USER = gql`
mutation saveNotificationUser($notificationUser: NotificationGroupUserTypeInput!) {
  saveNotificationUser(notificationUser:$notificationUser){
   manufacturerId
  }
}
`;
const ADMIN_Staff_QUERY = gql`
query getNotificationGroups($manufacturerId: Int,$type: Int) {
  admin(manufacturerId:$manufacturerId,type:$type) {
    notificationGroups {
            id
            groupName
            manufacturerId
            createdOn
            updatedOn
            updatedBy
            enabled
            deleted
            description
            notificationGroupUsers(manufacturerId:$manufacturerId,type:$type) {
            notificationUserId
            email
            phone
            manufacturerId
            createdOn
            updatedOn
            enabled
            deleted
          }
           notificationAvailableUsers:notificationGroupUsers(manufacturerId:$manufacturerId,type:3) {
            notificationUserId
            email
            phone
            manufacturerId
            createdOn
            updatedOn
            enabled
            deleted
          }
    }
  }
}
`;
const styles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'left',
    },
    formControl: {
        width: "100%",
    },
    saveContainer: {
        width: '100%'
    },
    saveGrid: {
        marginLeft: '0px'
    },
    column: {
        flexBasis: '33.33%',
    },
    columnLarge: {
        flexBasis: '50%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

class NotificationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showDialog: false, dialogTitle: '', dialogContent: '', dialogData: null, queryInProgress: false, dummyText: '', status: false }
        this.handleSelect = this.handleSelect.bind(this);
        this._getQueryVariables = this._getQueryVariables.bind(this);
        this.dialogConfirmation = this.dialogConfirmation.bind(this);
        this.dialogCancel = this.dialogCancel.bind(this);
        this.myquery = createRef();
        this.Auth = new AuthService();
        this.onQueryComplete = this.onQueryComplete.bind(this);
        this.changeDialog = this.changeDialog.bind(this);
        this.resetData = this.resetData.bind(this);
    }
    _getQueryVariables(adminView, manufacturerId) {
        return adminView ? { manufacturerId ,type:0 } : { "dummyText": this.state.dummyText };

    }
    changeDialog(status) {
        if (status) {
            this.setState({ "status": status });
        }
        else {
            this.setState({ showResetPassword: false, userId: null, firstName: null, lastName: null, status: status });
        }

    }
    handleSelect(option) {

        switch (option.title) {
            case 'Edit': {
                debugger
                this.props.history.push({ pathname: "/AddGroup/" + option.data.id, state: { group: option.data, manufacturer: this.props.manufacturer } });
                break;
            }
            case 'Delete': {
                this.setState({ showDialog: true, dialogTitle: 'Do you want to delete this group?', dialogContent: 'This will delete the group and can no longer be used', dialogData: option.data });
                break;
            }
            case 'Manage Members': {
                this.props.history.push({ pathname: "/AddNotificationAccount/" + option.data.id, state: { group: option.data, manufacturer: this.props.manufacturer } });
                break;
            }
            default: { }

        }

    }
    prepareMutationVariable(groupId, manufacturerId) {

        const { Phone, Email } = this.state;
        let group = {

            "notificationUser": { "email": Email, "phone": Phone, "manufacturerId": manufacturerId, "groupId": groupId, "enabled": true, "deleted": false }

        };
        return group;
    }

    dialogConfirmation(data) {
        const token = this.Auth.getToken();
        let formData = new FormData();
        formData.append("StaffUserId", data.staffUserId);
        fetch(`${DeleteStaffApi}`, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "FilterText": data.staffUserId })
        })
            .then(res => res.json())
            .then(() => {
                this.setState({ queryInProgress: true, dummyText: Date.now().toString() })
            });
        this.dialogCancel();
    }
    dialogCancel() {

        this.setState({ showDialog: false, dialogTitle: '', dialogContent: '', dialogData: null });
    }
    onQueryComplete() {
        if (this.state.queryInProgress !== false) {
            this.setState({ queryInProgress: false });
        }

    }
    resetData() {
        this.setState({ 'Email': '', 'Phone': '' });
    }
    handleChange(e) {

        this.setState({ [e.target.name]: e.target.value });
    }


    render() {
        const requiredMessage = "required";
        const { Email, Phone} = this.state;
        const {  classes,adminView, manufacturer } = this.props;
        const manufacturerId = manufacturer.manufacturerId;
        const query = ADMIN_Staff_QUERY;
        const { showDialog, dialogTitle, dialogContent, dialogData } = this.state;
        const dialog = showDialog ? <FBAlertDialog cancel={this.dialogCancel} confirmed={this.dialogConfirmation}
            open={showDialog} title={dialogTitle} content={dialogContent} data={dialogData} /> : null;

        //const options = ['edit', 'delete'];
        return (
            <div className={classes.root}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Notifications</title>
                </Helmet>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center" className={classes.fieldsection}>
                    <Grid item xs={12} sm={6} style={{ marginTop: '10px',marginBottom:'10px' }}>
                        <Typography variant="h3">
                            {NotificationHeader}
                        </Typography>
                    </Grid>
                
                </Grid>


             
                    {dialog}
                <Query query={query} variables={this._getQueryVariables(adminView, manufacturerId)} ref={Query => { this.myquery = Query }}
                    onCompleted={this.onQueryComplete} fetchPolicy="no-cache"    >
                    {({ loading, error, data, refetch }) => {

                            if (loading) return <FbLoader loaderStyle={'center'} />
                            if (error) return <FbError isTableBodyRequired={false} errorStyle={'emptycell'} />
                            const notificationGroups = adminView ? data.admin.notificationGroups : null;
                        const listModels = notificationGroups.map((notificationGroup) =>
                            (
                                <ExpansionPanel key={"grp"+notificationGroup.id} xs={12}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header">
                                            <div className={classes.columnLarge}>
                                                <Typography variant="h4" >Title: {notificationGroup.groupName} </Typography>
                                                <Typography variant="subtitle2" className={classes.secondaryHeading}>Description: {notificationGroup.description}</Typography>
                                                <Typography variant="caption" >Total subscribed accounts: {notificationGroup.notificationGroupUsers && notificationGroup.notificationGroupUsers.length}</Typography>
                                                <br />
                                                <Typography variant="caption" >Total available accounts: {notificationGroup.notificationAvailableUsers && notificationGroup.notificationAvailableUsers.length}</Typography>
                                            </div>
                                        
                                         
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails  className={classes.details}>
                                        <TransferList key={"m" + manufacturerId + "_g" + notificationGroup.id + "l" + notificationGroup.notificationGroupUsers.length + "_a_" + notificationGroup.notificationAvailableUsers.length}
                                            data={{ "manufacturerId": manufacturerId, "groupId": notificationGroup.id, "assigned": notificationGroup.notificationGroupUsers, "available": notificationGroup.notificationAvailableUsers }}
                                          
                                            />
                                        </ExpansionPanelDetails>
                                        <Divider />
                                        <ExpansionPanelActions>

                                        <Mutation key="StandardUpload" mutation={SAVE_USER} onCompleted={() => { this.resetData(); refetch(); }}   >
                                                {(standardUpload, { loading, error }) => {
                                                    if (loading) {
                                                        return (<div style={{
                                                            position: 'absolute',
                                                            left: '50%',
                                                            top: '50%',
                                                            transform: 'translate(-50%, -50%)'
                                                        }}><FbLoader loaderStyle={'default'} /> </div>)
                                                    }
                                                    if (error) {
                                                        if (error.networkError !== null && error.networkError.result !== null && error.networkError.result.errors !== null) {
                                                        }
                                                    }
                                                    return (
                                                        <ValidatorForm ref="form" instantValidate={true} autoComplete="off" onError={errors => console.log(errors)}
                                                            onSubmit={() => standardUpload({ variables: this.prepareMutationVariable(notificationGroup.id, manufacturerId) })} className={classes.saveContainer}>

                                                            <Grid className={classes.saveGrid} container  direction="row" spacing={6} justify="flex-start" alignItems="center" >
                                                                <Grid item xs={3}>
                                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                                        <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                                            name="Email"
                                                                            label="*Email"
                                                                            placeholder="Email"
                                                                            fullWidth
                                                                            margin="dense"
                                                                            variant="outlined"
                                                                            validators={['required', 'isEmail']}
                                                                            errorMessages={[requiredMessage, 'Please enter valid email address']}
                                                                            value={Email}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                                        <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                                            name="Phone"
                                                                            label="Phone"
                                                                            placeholder="Phone"
                                                                            fullWidth
                                                                            margin="dense"
                                                                            variant="outlined"
                                                                            validators={['matchRegexp:^[0-9]{6,12}$']}
                                                                            errorMessages={['Phone number should be between 6-12 characters']}
                                                                            value={Phone}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Button type="submit"  size="small" color="primary">Save </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </ValidatorForm>
                                                    );
                                                }}
                                            </Mutation>




                                            
                                        </ExpansionPanelActions>
                                    </ExpansionPanel>



                                ));
                            const result = (!_.isNil(notificationGroups)  && notificationGroups.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} errorStyle={'notfound'} />;
                            return (result);
                        }}
                    </Query>
           

            </div>
        );
    }
}

export default withStyles(styles)(withRouter(NotificationContainer));
