import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles,alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import React from 'react';
import { ResolveModeConstant } from '../../constants';
import theme from '../../theme';
import AuthService from '../AuthService';
import UploadFlash from '../uploader/FlashContainer';


const styles = {
    title: {
        textTransform: "uppercase"
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    table: {
      //  borderTopWidth: "1px",
      //  borderLeftWidth: "1px",
     //   borderRightWidth: "1px",
     //   borderColor: theme.palette.common.borderColor,      
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    textField: {
       float: "right"
    },
    Grid: { 
        minHeight: 49,
        marginBottom: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        background: alpha(theme.palette.common.white, 0.75),
        border: "1px solid",
        borderColor: theme.palette.grey[400],
        overflow: "hidden",

        '&:hover': {
            background: alpha(theme.palette.common.white, 0.90),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(1) * 5,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1) * 5,
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 250,
          //  '&:focus': {
          //      width: 240,
         //  },
        },
    },
    searchBase: {
        display: "block",
        justifyContent: "right",
        padding: theme.spacing(1) * 0.5,
        overflow: "hidden",
    },
    paper: {
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,      
    }
};




const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

//const DialogActions = withStyles(theme => ({
//    root: {
//        borderTop: `1px solid ${theme.palette.divider}`,
//        margin: 0,
//        padding: theme.spacing(1),
//    },
//}))(MuiDialogActions);

class ResolveFlashDialog extends React.Component {

    constructor(props) {
        super(props);
        this._id = _.uniqueId('resolve_header_row');
        this.state = {
             pLinePageNumber: 1, pLinePageSize: 20, pLFilter: null,
            scroll: 'paper',
            fullWidth: true,
            maxWidth: 'lg',
            open: false,
        };
        this.Auth = new AuthService();
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose = () => {
        this.props.handleClose();
    };
    render() {
        const { classes, open } = this.props;
        const { fullWidth, maxWidth,   scroll, } = this.state;

        return (
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                classes={{ paper: classes.dialogPaper }}
                open={open}
                onClose={this.handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title">
                <DialogTitle id="scroll-dialog-title" onClose={this.handleClose}>Resolve failed serials</DialogTitle>
                <DialogContent >
                    <main>
                        <section className="main-container">
                            <UploadFlash isDialog={true} mode={ResolveModeConstant}  />
                            </section>
                    </main>
                </DialogContent>

            </Dialog>
        );
    }
}
export default withStyles(styles)(ResolveFlashDialog);

