import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { Fragment } from 'react';
import theme from '../../theme';





const styles = {
    fontSmall: {
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        color: theme.palette.common.paragraphText,
        fontSize: 14,
    },
};



class FBLongMenu extends React.Component {

    constructor() {
        super();
        this.state = { anchorEl: null,}
    };
    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };
    handleSelect = (option) => {

        this.setState({ anchorEl: null });
      //  this.handleClose();
        this.props.onMenuSelect(option);
    }; 
    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

 //   state = {
   //     anchorEl: null,
   // };

  //handleClick = event => {
  //      this.setState({ anchorEl: event.currentTarget });
  //  };

  //  handleClose = () => {
  //      this.setState({ anchorEl: null });

  //  };
  //  handleSelect = (option) => {
        
  //      this.setState({ anchorEl: null });
  //      this.handleClose();
  //      this.props.onMenuSelect(option);
  //  };
    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const options = this.props.options; 
        return (<div className={classes.root}>
            <Fragment >           
                <IconButton aria-owns={open ? 'simple-popper' : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={this.handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Popover id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Paper>
                        {options.map(option  => (
                            <MenuItem key={option.title} onClick={() => this.handleSelect(option)} className={classes.fontSmall}>
                          {option.title}
                            </MenuItem> 
                        ))}
                    </Paper>
                </Popover>

            </Fragment>
        </div>
        );
    }
}

export default withStyles(styles)(FBLongMenu);