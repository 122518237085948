import React, { Fragment } from 'react';
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: '100%',      
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "top",
        paddingTop: theme.spacing(2),
        paddingBottom:0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "auto",
        whiteSpace: " nowrap",
    },
 
}))(TableCell);
const SubTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[600],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        fontSize: 13,
        verticalAlign: "top",
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "auto",
        whiteSpace: " nowrap",
        //lineHeight:"12px",
    },

}))(TableCell);

class ProductHeaderRow extends React.Component {


    constructor(props) {
        super(props);
        this._id = _.uniqueId('product_header_row');

    }
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }
    render() {
        const { classes, adminView } = this.props;

        return (
            <Fragment>
                <TableRow key={this._id} className={classes.tableRow}>               
                    <CustomTableCell rowSpan={2}>Models</CustomTableCell>
                    <CustomTableCell rowSpan={2}>Product line</CustomTableCell>
                    <CustomTableCell colSpan={2}>Dates</CustomTableCell>
                    <CustomTableCell colSpan={2}>Warranty (in yrs)</CustomTableCell>                 
                    <CustomTableCell colSpan={2} align="left">&nbsp;</CustomTableCell>
                    <CustomTableCell>Serials not found on service</CustomTableCell>
                    {adminView ? null : (<CustomTableCell>&nbsp;</CustomTableCell>)}
                </TableRow>
                <TableRow className={classes.tableRow}>
                    <SubTableCell>Approval date</SubTableCell>
                    <SubTableCell>Expiry date</SubTableCell>
                    <SubTableCell>Component</SubTableCell>
                    <SubTableCell>Performance</SubTableCell>  
                    <SubTableCell>Available vs Total Serials</SubTableCell>
                    <SubTableCell>&nbsp;</SubTableCell>  
                    <SubTableCell>Failed vs Total Requests</SubTableCell>
                    {adminView ? null : (<SubTableCell>&nbsp;</SubTableCell>)} 
                </TableRow>
                    {/* <tr key={this._id}>
                    <th rowSpan="2">Model</th>
                    <th rowSpan="2">Product line</th>
                    <th colSpan="2">Dates</th>
                    <th colSpan="2">Warranty (in yrs)</th>
                    <th className="verserout">Serials available for verification</th>
                    <th>&nbsp;</th>
                    <th >Serials not found on service</th>
                    <th>&nbsp;</th>
                </tr>
                <tr className="subhead">
                    <th>Approval date</th>
                    <th>Expiry date</th>
                    <th>Component</th>
                    <th>Performance</th>

                    <th className="verserout">Avaiable vs Total Serials</th>
                    <th>&nbsp;</th>
                    <th>Failed vs Total Requests</th>
                    <th>&nbsp;</th>
                </tr> */}
            </Fragment>
        );




    }
}

export default withStyles(styles)(ProductHeaderRow);
