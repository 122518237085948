import { IconButton } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/NotInterestedOutlined';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { SHEET_TRIM_LENGTH } from '../../constants';
import _ from 'lodash';

const styles = {
    root: {
        flexGrow: 1,
        height: "100%",
    },
    incomplete: {
        color: 'red'
    },
    active: {
        color: "#0d4add !important",
        boxShadow: "0px -3px 0 #0d4add inset !important"
    },
    activeDisabled: {
        color: "grey !important",
        boxShadow: "0px -3px 0 grey inset !important"
    },
    disabled: {
        color: 'grey !important'
    },
    removeSheet: {
        width: "16px",
        height: "16px",
        padding: 0,
        verticalAlign: "middle",
        position: "absolute",
        right: "5px",
        top: "11px",
        color:'red !important'
    },
    addSheet: {
        width: "16px",
        height: "16px",
        padding: 0,
        verticalAlign: "middle",
        position: "absolute",
        right: "5px",
        top: "11px",
        color: 'green !important'
    },
    iconSuccess: {
        color: green[700],
        width: "16px",
        height: "16px",
        padding: 0,
        verticalAlign: "middle",
        position: "absolute",
        right: "5px",
        top: "11px"
    },
    sheetName: {
        verticalAlign: "middle",
    },
    sheetDisabled: {
        textDecoration: "line-through",
    },
    sheetLink: {
        position: "relative"
    }

};
class WorkSheetFooterItem extends React.Component {
    constructor(props) {
        super(props);
        this.fetchSheetData = this.fetchSheetData.bind(this);
        this.sheetIndex = 0;
        this.onSheetChange = this.onSheetChange.bind(this);
    }

    fetchSheetData(index, fileName, batchId) {
        let product = {
            "product": { "name": fileName, "index": index, "sheets": [], "batchId": batchId, "type": 1 }
        };
        this.sheetIndex = index;
        return product;
    }
    onSheetChange(data, activeFileIndex) {
        this.props.onSheetChangeComplete(data, activeFileIndex, this.sheetIndex);
    }
    onIgnoreSheet(fileIndex, sheetIndex) {
        this.props.onIgnoreSheet(fileIndex, sheetIndex);
    }

    render() {

        const { classes, sheets, activeSheetIndex, activeFileIndex } = this.props;

        const items = sheets.map((sheet, index) => {
            //const activeClass = index === activeSheetIndex ? 'active' : '';
            const length = SHEET_TRIM_LENGTH;
            const sheetname = sheet.name;
            const noData = (_.isNil(sheet.rows) || sheet.rows.length === 0);
            const isEnable = sheet.enabled != null ? sheet.enabled : true;
            const shouldDisable = !isEnable && !sheet.valid;
            const trimmedString = sheetname.length > length ?
                sheetname.substring(0, length - 3) + "..." :
                sheetname;
            const mutation = (<Fragment><a className={classNames({
                [classes.activeDisabled]: !isEnable,
                'active': index === activeSheetIndex, [classes.incomplete]: isEnable && !sheet.valid, [classes.sheetLink]: true,
                [classes.disabled]: shouldDisable })} href="javascript:void(0);" onClick={(e) => this.props.onSheetChangeComplete(null, activeFileIndex, index)} >
                <span className={classNames({
                    [classes.sheetName]: true,
                    [classes.sheetDisabled]: !sheet.valid && !isEnable,
                })}> {trimmedString}</span>
                {sheet.valid && (<Check className={classes.iconSuccess} />)}



                {!noData && shouldDisable  && (<Tooltip title="Add" placement="top">
                    <IconButton onClick={(e) => { this.onIgnoreSheet(activeFileIndex, sheet.index); e.preventDefault(); e.stopPropagation(); }} className={classes.addSheet} aria-label="add">
                        <AddIcon style={{ width: '16px', height: '16px' }} />
                    </IconButton>
                </Tooltip>)}
                {!noData && !sheet.valid && isEnable && (<Tooltip title="Ignore" placement="top">
                    <IconButton onClick={(e) => { this.onIgnoreSheet(activeFileIndex, sheet.index); e.preventDefault(); e.stopPropagation(); }} className={classes.removeSheet} aria-label="ignore">
                        <RemoveIcon style={{ width: '16px', height: '16px' }} />
                    </IconButton>
                </Tooltip>)}

            </a>

            </Fragment>)
            return (
                <li key={sheet.name + "_" + index}>
                    {mutation}
                </li>
            );
        });
        return <ul> {items}</ul >

    }
}
export default withStyles(styles)(WorkSheetFooterItem);

