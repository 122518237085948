import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from "react";
//import Nav from "./Nav";
import Footer from "./Footer";
import Header from "./Header";
import LeftNav from "./LeftNav";


const styles = theme => ({
    root: {
        flexGrow: 1,
        height: "100%"
    },
    content: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        width:"100%",
    },
    Height: {
        height: "calc(100% - 115px)",
        paddingLeft: theme.spacing(1) * 11.3

    },
    [theme.breakpoints.down('xs')]: {
        Height: {
            paddingLeft: theme.spacing(1) * 0,
        },
    },
    toolbar: theme.mixins.toolbar,
});

class Main extends React.Component {
    constructor() {
        super();
        this.updateProfile = this.updateProfile.bind(this);
        this.state = { header: false, selectedIndex:0 }
        this.onNavigationChange = this.onNavigationChange.bind(this);
    }
    onNavigationChange(newIndex) {
        this.setState({ selectedIndex: newIndex });
    }
    updateProfile() {
        this.setState({ header: !this.state.header });
    }
    render() {
        
        const { selectedIndex } = this.state;
        const { children, classes } = this.props;

        return (
            <div className={classes.root}>             
                <Header header={this.state.header} selectedIndex={selectedIndex} onNavigationChange={this.onNavigationChange} />
                <Hidden only={['xs']}>
                    <LeftNav selectedIndex={selectedIndex} onNavigationChange={this.onNavigationChange} />
                </Hidden>
                <div className={classes.toolbar} />
                <Grid container component="main" className={classes.Height}>                 
                    <Grid item lg={12} component="section" className={`${classes.content} main-container`} >
                        {children}
                    </Grid>
                    <Footer />
                </Grid>
               
            </div>
        );
    }
};

Main.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Main);