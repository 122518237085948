import { Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Table, TableBody, TableCell, TableHead, TableRow, Grid } from '@material-ui/core/';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles,alpha } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Modal from '@material-ui/core/Modal';
import Report from '@material-ui/icons/Report';
import gql from 'graphql-tag';
import PropTypes from "prop-types";
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { Link } from "react-router-dom";
import { ManufacturerTitle } from '../../StringConstants';
import theme from '../../theme';
import AdminResetPassword from '../admin/AdminResetPassword';
import AdmintableHead from '../admin/AdmintableHead';
import EditPage from '../admin/EditPage';
import PageTitles from '../admin/PageTitles';
import FbEmptyResult from '../common/FbEmptyResult';
import SupplierListing from '../supplier/SupplierListing';
import { UserIdExceptions, ShowNotUploadingAlert } from '../../constants';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import { handleFailDownload, handleActivateDeactivate, handleNotFoundDownload, handleParallelImportDownload } from '../../Helper';
import ModelSearch from "../ModelSearch";
import UserProfile from '../auth/UserProfile';
const Manufacturer_QUERY = gql`
query getmanufacturers($filter: String) {
  admin {
    manufacturers(filter:$filter) {
      isDistributor
      address
      displayName
      logo
      manufacturerId
      profilePhoto
      userName
      uploadFrequency
      email
      totalFailed
      totalRequest
      totalSerial
      totalInstalled
      recentUpload
      userId
      isActive

    }
  }
}
`;


const fontprimary = "1rem";
const fontsecondary = "0.9rem";
const drawerWidth = 360;


const styles = {
    root: {
        padding: 0,
        width:'100%'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        background: theme.palette.grey[50],
    },
    cardinfo: {
        boxShadow: "none",
        background: "none",
        width: "auto",
        whiteSpace: "normal",
    },
    card: {
        boxShadow: "none",
        background: "none",
    },
    titleroot: {
        fontSize: "1.3rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    titleupload: {
        fontSize: "1rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    lightGray: {
        color: theme.palette.grey[900],
        marginLeft: "4px",
    },
    contentPaper: {
        margin: theme.spacing(2),
        background: theme.palette.common.white,
        border: "1px solid",
        borderColor: theme.palette.common.borderColor, overflowX: 'auto',
    },
    AccessTime: {
        verticalAlign: "middle",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1) / 2,
    },
    rootCard: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: "1rem",
    },
    uploadFreq: {
        padding: theme.spacing(1),
        
    },
    uploadFreqAlert: {
        padding: theme.spacing(1),
        background: alpha(red[50], 1),
    },
    subCard: {
        fontSize: fontprimary,
    },
    subTitle: {
        fontSize: fontsecondary,
    },
    span: {
        position: "absolute",
        top: 0,
        color: theme.palette.common.white,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        left: theme.spacing(1),
        lineHeight: "26px",
        fontSize: "1rem",
    },
    link: {
        color: theme.palette.primary.main,
        marginTop: "4px",
        fontSize: "0.8rem",
        display: "block",
    },
    timeupload: {
        fontSize: fontsecondary,
        marginLeft: "4px",
        color: theme.palette.grey[600],
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    exclamation: {
        background: red[500],
        width: 34,
        height: 34,
    },
    scannedbg: {
        background: alpha(blue[50], 0.3),
    },
    titlescan: {
        fontSize: "1.3rem",
    },
    primarytext: {
        fontSize: fontprimary,
    },
    secondarytext: {
        fontSize: fontsecondary,
    },
    inline: {
        display: 'inline',
    },
    alert: {
        background: theme.palette.primary.main,
        fontSize: "1rem",

    },
    paperwhite: {
        background: "#fff", width: drawerWidth,
        flexShrink: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    space: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    lbl: {
        display: "inline-block",
        minWidth: "62px",
        marginRight: 4,
    },
    error: {
        color: "red",
    },
    errorValue: {
        color: "red",
        marginLeft: "4px"
    },
    progress: {
        position: "relative",
        minWidth: 200,
    },
    linearColorPrimary: {
        height: 26,
        background: theme.palette.common.borderColor,
    },
    linearBarColorPrimary: {
        background: theme.palette.common.green

    },
    linearBarColorSecondary: {
        background: theme.palette.common.red
    },
    linearBarColorWarning: {
        background: theme.palette.common.warning
    },
    secondary: {
        display: "inline",
        float: "left",
        marginRight: theme.spacing(1),
    },
    rootBlock: {
        display: "inline",
        padding: 0,
    },
    index: {
        paddingTop: 15,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: "1.3rem",
        verticalAlign: "top"
    },
    userId: {
        color: theme.palette.grey[900],
    },
    button: {
        color: theme.palette.grey[500],
    },
    listsimple: {
        listStyle: "none",
        display: "inline-block",
        marginRight: "8px",
        marginTop: "8px",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: "1rem",

    },
    paperModel: {
        position: 'absolute',
        width: 300,
        minHeight: 100,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '15px 15px',
    },
    paperModelSupplier: {
        position: 'absolute',
        minWidth: 100,
        minHeight: 100,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '15px 15px',
    },
    chip: {
        margin: '20px 10px',
        textAlign: 'center',
        padding: '10px'
    }

}
const CustomTableCell = withStyles(theme => ({
    body: {
        borderBottomColor: theme.palette.grey[200],
        fontSize: 16,
        whiteSpace: " nowrap",
        padding: "4px 16px 4px 16px"
    },


}))(TableCell);


class SimpleList extends React.Component {
    render() {
        const { classes, } = this.props;
        const Style = { color: theme.palette.grey[500], fontFamily: 'NHaasGroteskDSPro-55Rg', }
        return (
            <Fragment >
                <li className={classes.listsimple}><span style={Style}>{this.props.listLabel} :</span> {this.props.serialVal},</li>
            </Fragment>
        );
    }
}
SimpleList.propTypes = {
    classes: PropTypes.object.isRequired,
};
const SimpleListWrap = withStyles(styles)(SimpleList);

class Manufacturer extends React.Component {
    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 4, open: false, filter: '', modelOpen: false, modelOpenSupplier: false,activeManufacturerId:0,activeManufacturerDisplayName:''
        };
        this.onFailDownload = this.onFailDownload.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.handleModelOpen = this.handleModelOpen.bind(this);
        this.handleModelOpenSupplier = this.handleModelOpenSupplier.bind(this);
        this.handleModelClose = this.handleModelClose.bind(this);
     
        this.getModalStyle = this.getModalStyle.bind(this);
    }
    handleModelOpen = (manufacturerId, name) => {
        this.setState({ "modelOpen": true, activeManufacturerId: manufacturerId, activeManufacturerDisplayName:name });
    };
  
    handleModelOpenSupplier = (manufacturerId, name) => {
        this.setState({ "modelOpenSupplier": true, activeManufacturerId: manufacturerId, activeManufacturerDisplayName: name });
    };


    getModalStyle() {
        const top = 50 -5;
        const left = 50 -5;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }
    handleModelClose = () => {
        this.setState({ "modelOpen": false, activeManufacturerId: null, activeManufacturerDisplayName: null });
    };
    handleModelCloseSupplier = () => {
        this.setState({ "modelOpenSupplier": false, activeManufacturerId: null, activeManufacturerDisplayName: null });
    };


    handleClick = (self, manufacturerid, userId, status, refetch) => {
        
        const newStatus = !status;
        this.buttonRef.current.innerText = "Loading";
        handleActivateDeactivate(() => { refetch(); this.buttonRef.current.innerText = newStatus ? "Active" : "De-active";  }, () => { refetch() }, manufacturerid, userId, newStatus );
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    onFailDownload(manufacturerId, name,format) {
        let parentThis = this;
        handleFailDownload((data) => {
            parentThis.handleModelClose();
        }, (error) => { parentThis.setState(error); parentThis.handleModelClose();}, manufacturerId, name,format);
    }


    onNotFoundDownload(manufacturerId, name) {
        let parentThis = this;
        handleNotFoundDownload((data) => {
            parentThis.setState(data);
        }, (error) => { parentThis.setState(error); }, manufacturerId, name);
    }
    onParallelImportDownload(manufacturerId, name) {
        let parentThis = this;
        handleParallelImportDownload((data) => {
            parentThis.setState(data);
        }, (error) => { parentThis.setState(error); }, manufacturerId, name);
    }
   

    handleSearch(data) {
        this.setState({ filter: data });

    }

    _getQueryVariables() {
        const filter = this.state.filter;
        return { filter};
    }


    render() {
        const { classes, } = this.props;

        const { modelOpen, modelOpenSupplier, activeManufacturerId, activeManufacturerDisplayName } = this.state;
        const userId = UserProfile.getUserId();
        const allowedToActivateManufacturers = UserIdExceptions.indexOf(userId) > -1;
       
        return (
            <Grid containers className={classes.root}>
                <PageTitles titles={ManufacturerTitle} />
                <Paper elevation={0} className={classes.contentPaper}>
                    <div>  <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} containerRequired={false} /></div>
                    <Table>
                        <TableHead>
                            <AdmintableHead AllowedToActivate={allowedToActivateManufacturers}/>
                        </TableHead>

                        <Query query={Manufacturer_QUERY} variables={this._getQueryVariables()}  fetchPolicy="no-cache">


                            {({ loading, error, data, refetch }) => {


                                if (loading) return <FbLoader isTableBodyRequired={true} colSpan={10} loaderStyle={'emptycell'} />
                                if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} />
                                const listModels = data.admin.manufacturers.map((row, index) =>
                                (
                                    

                                        <TableRow className={classes.row} key={index}>
                                            <CustomTableCell component="th" scope="row" className={classes.index}>
                                                {index + 1}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <Card className={classes.cardinfo}>
                                                    <CardHeader
                                                        title={row.displayName}
                                                        subheader={<span>
                                                            <p><label className={classes.lbl}>Address:</label>{row.address}</p>
                                                            <p><label className={classes.lbl}>UserId:</label><span className={classes.userId}>{row.userName}</span></p>
                                                            <p><label className={classes.lbl}>Email:</label><span>{row.email}</span></p>
                                                            <p><label className={classes.lbl}>Phone:</label><span>{row.phone}</span></p>
                                                        </span>
                                                        }
                                                        classes={{ title: classes.titleroot, root: classes.rootCard, subheader: classes.subCard }}
                                                    />
                                                </Card>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <div style={{ marginBottom: '3px' }}>
                                                     <Button color="primary" size="small" variant="outlined" onClick={() => this.handleModelOpen(row.manufacturerId, row.displayName)}>
                                                        Failed Serials
                                                     </Button>
                                                    <Button color="primary" size="small" variant="outlined" onClick={() => this.onNotFoundDownload(row.manufacturerId, row.displayName)}>
                                                    Not-found serials
                                                </Button>
                                                <Button color="primary" size="small" variant="outlined" onClick={() => this.onParallelImportDownload(row.manufacturerId, row.displayName)}>
                                                    Parallel Import
                                                </Button>
                                              
                                                {row.isDistributor &&
                                                    <Button color="primary" endIcon={<DoneAllIcon />} size="small" variant="outlined"
                                                        onClick={() => this.handleModelOpenSupplier(row.manufacturerId, row.displayName)}>Self Supplier</Button>}
                                                {!row.isDistributor &&
                                                    <Button color="primary" size="small" variant="outlined"
                                                        onClick={() => this.handleModelOpenSupplier(row.manufacturerId, row.displayName)}>Supplier</Button>}
                                                </div>
                                               
                                                <div className={classes.progress}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={row.totalRequest >0 ? Math.floor(row.totalFailed / row.totalRequest * 100) :0}
                                                 
                                                        classes={{
                                                            determinate: classes.linearColorPrimary,
                                                            barColorPrimary: row.totalRequest === 0 || Math.floor(row.totalFailed / row.totalRequest * 100) < 5 ? classes.linearBarColorPrimary
                                                                : Math.floor(row.totalFailed / row.totalRequest * 100) < 40 ? classes.linearBarColorWarning : classes.linearBarColorSecondary ,
                                                        }}
                                                    />
                                                    <span className={classes.span}>{row.totalFailed}/{row.totalRequest}</span>
                                                </div>
                                                <ul>
                                                    <SimpleListWrap listLabel={"Uploaded"} serialVal={row.totalSerial != null ? row.totalSerial : 0} />
                                                    <SimpleListWrap listLabel={"Scanned"} serialVal={row.totalInstalled != null ? row.totalInstalled : 0} />
                                                    <SimpleListWrap listLabel={"Pending"} serialVal={(row.totalInstalled != null ? row.totalSerial - row.totalInstalled : (row.totalSerial != null ? row.totalSerial : 0))} />
                                                </ul>

                                                {/*
                                                    <ListItemText primary={row.totalSerial != null ? row.totalSerial : 0} secondary="Total Uploaded:" classes={{ secondary: classes.secondary, root: classes.rootBlock, primary: classes.rootBlock }} />
                                                    <br />
                                                    <ListItemText primary={row.totalInstalled != null ? row.totalInstalled : 0} secondary="Total Scanned:" classes={{ secondary: classes.secondary, root: classes.rootBlock, primary: classes.rootBlock }} />
                                                    <br />
                                                    <ListItemText primary={(row.totalInstalled != null ? row.totalSerial - row.totalInstalled :( row.totalSerial != null ? row.totalSerial : 0) )} secondary="Total Pending:" classes={{ secondary: classes.secondary, root: classes.rootBlock, primary: classes.rootBlock }} /> */}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <Card className={classes.card}>
                                                    <CardHeader
                                                        avatar={
                                                        ShowNotUploadingAlert(row.recentUpload) && ( <Avatar className={classes.exclamation}>
                                                            <Report />
                                                            </Avatar>)
                                                        }
                                                        title={row.recentUpload}
                                                        subheader={<span><p><label className={classes.lbl}>Expected frequency:</label><span className={classes.lightGray}>{row.uploadFrequency}</span></p>

                                                        </span>
                                                        }
                                                    classes={{ title: classes.titleupload, root: ShowNotUploadingAlert(row.recentUpload) ? classes.uploadFreqAlert : classes.uploadFreq, subheader: classes.subTitle }}
                                                    />
                                                   
                                                </Card>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <EditPage />
                                                <AdminResetPassword userId={row.userId} />
                                               
                                            </CustomTableCell>
                                            { allowedToActivateManufacturers  && (
                                                <CustomTableCell>
                                                    <Button ref={this.buttonRef} color="primary" onClick={(e) => this.handleClick(e, row.manufacturerId, row.userId, row.isActive, refetch)}>
                                                        {row.isActive && ("Active")}
                                                        {!row.isActive && ("De-Active")}
                                                    </Button>

                                                </CustomTableCell>
                                            )}
                                            <CustomTableCell>
                                                <IconButton aria-label="ArrowForward" size="small" component={Link} to={{ pathname: "/ManufacturerDetail/" + row.manufacturerId, state: { manufacturer: row } }}>
                                                    <ArrowForward fontSize="inherit" />
                                                </IconButton>
                                            </CustomTableCell>
                                        </TableRow>

                                    ));
                                const result = (data.admin.manufacturers !== 'undefined' && data.admin.manufacturers.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} />;


                                //  const pagination_content = (data.productfeed.products !== 'undefined' && data.productfeed.products.length > 0 && data.productfeed.count > this.state.pageSize) ? <TableFooter ><TableRow ><FooterCell colSpan="10">{paging}</FooterCell ></TableRow ></TableFooter > : null;
                                return (<Fragment><TableBody>{result}</TableBody></Fragment>);


                            }}
                        </Query>
                    </Table>
                </Paper>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Access account</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to disable the account ?
                     </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} className={classes.button} color="default">
                            Cancel
                       </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Yes
                         </Button>
                    </DialogActions>
                </Dialog>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={modelOpen}
                    onClose={this.handleModelClose} >
                    <div style={this.getModalStyle()} className={classes.paperModel}>
                        <Typography variant="display2">
                            Download failed serials for {activeManufacturerDisplayName}
                                 <Typography variant="body1">
                                  choose download format
                                 </Typography>
                        </Typography>
                        <Chip className={classes.chip} label="CSV" onClick={() => this.onFailDownload(activeManufacturerId,activeManufacturerDisplayName,"csv")} />
                        <Chip className={classes.chip} color="primary" label="XLSX" onClick={() => this.onFailDownload(activeManufacturerId, activeManufacturerDisplayName, "xlsx")} />
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={modelOpenSupplier}
                    onClose={this.handleModelCloseSupplier}  >
                    <div style={this.getModalStyle()} className={classes.paperModelSupplier} xs={10}>
                        <Typography variant="display2">
                            Supplier List for {activeManufacturerDisplayName}
                             
                        </Typography>
                        <SupplierListing isAdmin={true} showNavigation={false} manufacturerId={activeManufacturerId} />
                    </div>
                </Modal>
            </Grid>
        )
    }
}
Manufacturer.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Manufacturer);

