import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import nofoundresult from '../../assets/images/searchmagnifier.svg'
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';
import Grid from '@material-ui/core/Grid';
const styles = {
    image: {
        float: "none",
        width: "76px",
        height: "76px",
        padding: theme.spacing(2),
        marginLeft: 0,
    },
    messageSecond: {
        color: theme.palette.text.secondary
    },
    errBg: {
        textAlign: "center",
        height:"calc(100vh - 350px)"
    },
    emptyContainer: {
        padding: '20px',
        margin: '20px',
        minHeight:'400px'
    }
};

class FbEmptyResult extends React.Component {
    constructor(props) {
        super(props);
        this._id = _.uniqueId(this.props.Prefix ? this.props.Prefix : "no_record");
        this.errorStyle = this.props.errorStyle != null ? this.props.errorStyle : 'default';
    
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const { classes } = this.props;
        const message = this.props.message != null ? this.props.message : <Typography variant="h3" gutterBottom>
          No result found.</Typography>;
        const messageSecond = this.props.messageSecond != null ? this.props.messageSecond : <Typography variant="body1" className={classes.messageSecond} gutterBottom>we can`t find any product matching<br /> your search.</Typography>;
        const messageThird = this.props.messageThird != null ? this.props.message : <Typography variant="body1" className={classes.messageSecond} gutterBottom>we can`t find any staff member for<br /> your search.</Typography>;
        const style = { "textAlign": "center", "height":"300px" , "verticalAlign" :"middle" }
        const colspan = this.props.colSpan != null ? this.props.colSpan : 1;
        
        const base_message = (<Grid className={classes.emptyContainer}
            container
            direction="column-reverse"
            justify="center"
            alignItems="center"
        >{message}</Grid>);
        const httperror = (<Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.errBg}
        >
            <img src={nofoundresult} className={classes.image} alt="No result found" />{base_message}{messageThird}   
        </Grid>);

        if (this.errorStyle === 'default') {
            return base_message;
        }
        if (this.errorStyle === 'notfound') {
            return httperror;
        }
        else if (this.errorStyle === 'emptycell') {
            const common_td = (<tr><td colSpan={colspan} style={style}> <img src={nofoundresult} className={classes.image} alt="No result found" />{base_message}{messageSecond}   </td></tr>);
            if (this.props.isTableBodyRequired === true) {
                return (<tbody>{common_td}</tbody>);
            }
            return common_td;
        }

        return base_message;
    }
}

export default withStyles(styles)(FbEmptyResult);