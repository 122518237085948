// src/components/ManufacturerLeadershipBoardWrapper.js
import React, { useState } from 'react';
import moment from 'moment';
import ManufacturerLeadershipBoard from './ManufacturerLeadershipBoard';
import ManufacturerStatSearch from './ManufacturerStatSearch';
import { useDebounce } from '../../../hooks/useDebounce';

const ManufacturerLeadershipBoardWrapper = () => {
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 300); // 300ms debounce delay
    const [fromDate, setFromDate] = useState(moment().subtract(30, 'days')); // Default to 30 days ago
    const [toDate, setToDate] = useState(moment());

    const handleSearchChange = event => {
        setSearch(event.target.value);
    };

    const handleFromDateChange = date => {
        if (!date.isValid()) { return; }
        setFromDate(date);
    };

    const handleToDateChange = date => {
        if (!date.isValid()) { return; }
        setToDate(date);
    };

    return (
        <div>
            <ManufacturerStatSearch
                search={search}
                onSearchChange={handleSearchChange}
                fromDate={fromDate}
                onFromDateChange={handleFromDateChange}
                toDate={toDate}
                onToDateChange={handleToDateChange}
            />
            <ManufacturerLeadershipBoard
                search={debouncedSearch}
                fromDate={fromDate}
                onFromDateChange={handleFromDateChange}
                toDate={toDate}
                onToDateChange={handleToDateChange}
            />
        </div>
    );
};

export default ManufacturerLeadershipBoardWrapper;
