import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { default as React, Fragment } from 'react';
import { Query } from 'react-apollo';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { handleErrors, handleHttpException, ProductLineImagePath, UpdateProductLineApi } from '../../constants';
import theme from '../../theme';
import AuthService from '../AuthService';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import { Helmet } from "react-helmet";
const PRODUCT_LINE = gql`
query get_productline($productLineId:Int) {
  manufacturer {

 productLine(id:$productLineId) {
       productLineId,
       logo,
      lineName
      updatedOn
      totalProduct
      productWarranty {
        componentInYears
        componentWarrantyLink
        performanceInYears
        performanceWarrantyLink
        productWarrantyId
      }
    }
  }
}
`;
const styles = {
    root: {
        flexGrow: 1,
        height: "100%"
    },
    textFieldInput: {
        fontSize: 14,
        height: '20px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    avatar: {
        borderRadius: 0,
        width: "100%",
        height: "172px",
        backgroundColor: theme.palette.background.default,
        color: "transparent",
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.background.lightbg,
        maxWidth: "178px",
        minWidth: "178px",
    },
    iconHover: {
        color: theme.palette.grey[500],
        verticalAlign: "middle"
    },
    paper: {
        //padding: theme.spacing(2),
        //color: theme.palette.text.secondary,
        background: theme.palette.common.white,
    },
    paperCard: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
        //color: theme.palette.text.secondary,
    },
    control: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    menuposition: {
        marginTop: theme.spacing(2),
        fontWeight: '900',
        color: '#333',
        textTransform: 'uppercase',
    },
    update: {
        marginTop: theme.spacing(2),
    },
    titleBar: {
        fontSize: "13px",
    },
    buttonProgress: {       
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {   
        position: 'relative',
    },
    button: {
        marginRight: '8px',
        minWidth: 120,
        height: 42,
        background: theme.palette.primary.white,
        '&:disabled': {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
        '&:hover': {
            '&:disabled': {
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
            },
        },
    },
    textHidden: {
        color: "#fff",
        visibility: " hidden",
    },
    or: {
        verticalAlign: "middle",
        lineHeight: " 85px",
    }
};



class EditProductLine extends React.Component {

    constructor() {
        super();
        this.state = {
            uploading: false,
            error: false, errorMessage: null,
            user: {
                Title: null, ProductLineLogo: null, ComponentWarrantyInYears: null, ComponentWarrantyFile: null,
                ComponentWarrantyUrl: null, PerformanceWarrantyInYears: null, PerformanceWarrantyFile: null,
                PerformanceWarrantyUrl: null
            }
        }
        this.componentFile = React.createRef();
        this.performanceFile = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Auth = new AuthService();
        this.Id = null;
       
        this.productLine = null;
        this.productWarranty = null;
        this._getQueryVariablesProductLine = this._getQueryVariablesProductLine.bind(this);
        this.validateUrl = this.validateUrl.bind(this);
        this.addValidations = this.addValidations.bind(this);
        this.removeValidations = this.removeValidations.bind(this);
        this.validateExtension = this.validateExtension.bind(this);
        this.validateExtensionPerformance = this.validateExtensionPerformance.bind(this);
        this.validateExtensionProduct = this.validateExtensionProduct.bind(this);
        //this.onComplete = this.onComplete.bind(this);
    }

    _getQueryVariablesProductLine() {

   
        const { id } = this.props.match.params;
        this.Id = parseInt(id, 10);
   
        const productLineId = this.Id;
         
        return {  productLineId }
    }
    removeValidations() {
        ValidatorForm.removeValidationRule('isComponentWarrantyFieldValid');
        ValidatorForm.removeValidationRule('isPerformanceWarrantyFieldValid');
        ValidatorForm.removeValidationRule('validateUrl');
        ValidatorForm.removeValidationRule('validateExtensionPerformance');
        ValidatorForm.removeValidationRule('validateExtensionProduct');
    }
    addValidations() {
        ValidatorForm.addValidationRule('isComponentWarrantyFieldValid', (value) => {
            return this.isComponentWarrantyFieldValid(value);
        });
        ValidatorForm.addValidationRule('isPerformanceWarrantyFieldValid', (value) => {
            return this.isPerformanceWarrantyFieldValid(value);
        });
        ValidatorForm.addValidationRule('validateUrl', (value) => {
            return this.validateUrl(value);
        });
        ValidatorForm.addValidationRule('validateExtensionPerformance', (value) => {
            return this.validateExtensionPerformance(value);
        });
        ValidatorForm.addValidationRule('validateExtensionProduct', (value) => {
            return this.validateExtensionProduct(value);
        });
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
        this.removeValidations();
    }

    componentDidMount() {
        this.addValidations();
    }
    validateUrl(value) {
        if (value.length === 0) return true;
        var re = /^(http[s]?:\/\/){1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
        return re.test(value);
    }
    validateExtensionPerformance(value) {
        if (!this.state.user.PerformanceWarrantyFile) {
            return true;
        }
        const ext = this.state.user.PerformanceWarrantyFile.name.split(".").pop();
        return this.validateExtension(ext);
    }
    validateExtension(ext) {
        if (ext === "pdf" || ext === "docx" || ext === "doc") {
            return true;
        } else {
            return false;
        }
    }
    validateExtensionProduct(value) {
       
        if (!this.state.user.ComponentWarrantyFile) {
            return true;
        }
        const ext = this.state.user.ComponentWarrantyFile.name.split(".").pop();
        return this.validateExtension(ext);
    }
    isComponentWarrantyFieldValid(value) {
        if (this.state.user.ComponentWarrantyFile == null && this.state.user.ComponentWarrantyUrl != null && this.state.user.ComponentWarrantyUrl.length === 0) {
            return false;
        }
        return this.state.user.ComponentWarrantyFile !== null || (this.state.user.ComponentWarrantyUrl!=null && this.state.user.ComponentWarrantyUrl.length > 0) || (this.productWarranty !== null && this.productWarranty.componentWarrantyLink !== null && this.productWarranty.componentWarrantyLink.length > 0);
    }
    isPerformanceWarrantyFieldValid(value) {
        if (this.state.user.PerformanceWarrantyUrl == null && this.state.user.PerformanceWarrantyUrl != null && this.state.user.PerformanceWarrantyUrl.length === 0) {
            return false;
        }
        return this.state.user.PerformanceWarrantyFile !== null ||( this.state.user.PerformanceWarrantyUrl != null && this.state.user.PerformanceWarrantyUrl.length > 0) || (this.productWarranty !== null && this.productWarranty.performanceWarrantyLink !== null && this.productWarranty.performanceWarrantyLink.length > 0);
    }
    handleSubmit() {
        
        let formData = new FormData();
        const token = this.Auth.getToken();
        var obj = this.state.user;
        
        formData.append("Title", obj.Title !== null && obj.Title !== '' ? obj.Title:  this.productLine.lineName);
        formData.append("ProductLineId", this.productLine.productLineId);
        formData.append("ProductLineLogo", obj.ProductLineLogo !== null && obj.ProductLineLogo !== '' ? obj.ProductLineLogo:  null);
        formData.append("ComponentWarrantyInYears", obj.ComponentWarrantyInYears !== null && obj.ComponentWarrantyInYears !== '' ? obj.ComponentWarrantyInYears : this.productWarranty.componentInYears);
        formData.append("ComponentWarrantyFile", obj.ComponentWarrantyFile !== null && obj.ComponentWarrantyFile !== '' ? obj.ComponentWarrantyFile : null);
        formData.append("ComponentWarrantyUrl", obj.ComponentWarrantyUrl !== null && obj.ComponentWarrantyUrl !== '' ? obj.ComponentWarrantyUrl : this.productWarranty.componentWarrantyLink);
        formData.append("PerformanceWarrantyInYears", obj.PerformanceWarrantyInYears !== null && obj.PerformanceWarrantyInYears !== '' ? obj.PerformanceWarrantyInYears : this.productWarranty.performanceInYears);
        formData.append("PerformanceWarrantyFile", obj.PerformanceWarrantyFile !== null && obj.PerformanceWarrantyFile !== '' ? obj.PerformanceWarrantyFile : null);
        formData.append("PerformanceWarrantyUrl", obj.PerformanceWarrantyUrl !== null && obj.PerformanceWarrantyUrl !== '' ? obj.PerformanceWarrantyUrl : this.productWarranty.performanceWarrantyLink);
      

        

        formData.append("ProductLineId", this.productLine.productLineId);
        formData.append("ProductWarrantyId", this.productWarranty.productWarrantyId);
        fetch(UpdateProductLineApi, {
            method: 'POST',
            headers: { 'authorization': `Bearer ${token}` },
            body: formData
        }).then(res => {
            return handleErrors(res);

        }).then(res => {
            if (res.status === true) {
                this.setState({ uploading: false }, function () {
                    this.props.history.goBack();
                });
            }
            else {
                this.setState({ uploading: false, error: true, errorMessage: res.message });
            }

        }).catch(err => {

            handleHttpException(err).then(result => {

                this.setState({ uploading: false, error: true, errorMessage: result });
            });;
            });


        if (!this.state.uploading) {
            this.setState(
                {
                    success: false,
                    uploading: true,
                },
                () => {
                    this.timer = setTimeout(() => {
                        this.setState({
                            uploading: false,
                            success: true,
                        });
                    }, 2000);
                },
            );
        }
    }

    handleFileChange(e) {
    }

    handleChange(e) {
        if (e.target.type === 'file') {
            if (e.target.files.length > 0) {
                
                if ((e.target.name === 'ComponentWarrantyFile' )) {
                  this.updateformState({ [e.target.name]: e.target.files[0], "ComponentWarrantyUrl":null  });
               }
                else if ((e.target.name === 'PerformanceWarrantyFile') ) {
                    this.updateformState({ [e.target.name]: e.target.files[0], "PerformanceWarrantyUrl": null});
                }
            }
            else {
                this.updateformState({ [e.target.name]: null });
            }
        }
        else {
            //debugger
            //if ((e.target.name === 'ComponentWarrantyUrl' || e.target.name === 'PerformanceWarrantyUrl') && e.target.value === '') {
            //    this.updateformState({ [e.target.name]: null });
            //}
            //else {
                this.updateformState({ [e.target.name]: e.target.value });
            //}
           
        }


    }
    updateformState(obj, extra) {
        const { user } = this.state;
        let userState =
            { ...user, ...obj };
        if (extra != null) {

            this.setState(state => ({ "user": userState, ...extra }));
        }
        else {
            this.setState(state => ({ "user": userState }));
        }


    }
    //onComplete(data) {
    //    this.productLine = data.manufacturer.productLine;
    //    this.productWarranty = data.manufacturer.productLine.productWarranty;
    //    this.productWarrantyId = this.productWarranty.productWarrantyId;
    
    //    const imageSrc = this.productLine.logo !== null ? ProductLineImagePath + this.productLine.logo  : require('../../assets/images/NoImageAvailable.png'));

    //    const userObject = {
    //        Title: this.productLine.lineName, ProductLineLogo: imageSrc, ComponentWarrantyInYears: this.productWarranty.componentInYears, ComponentWarrantyFile: null,
    //        ComponentWarrantyUrl: this.productWarranty.componentWarrantyLink, PerformanceWarrantyInYears: this.productWarranty.performanceInYears, PerformanceWarrantyFile: null,
    //        PerformanceWarrantyUrl: this.productWarranty.performanceWarrantyLink
    //    };

    //    this.setState({ "user": userObject });
    //}
    render() {
        const { classes } = this.props;
        const { uploading, error, errorMessage, user, success } = this.state;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });
        let { Title, ProductLineLogo, ComponentWarrantyInYears, ComponentWarrantyUrl, PerformanceWarrantyInYears, PerformanceWarrantyUrl, ComponentWarrantyFile, PerformanceWarrantyFile } = user;


        const tooBigYearValidationMessage = 'Years should be less than 50';
        const yearValidationMessage = 'Years should be greater than 0';
      
        const errorInfo = error ? <Grid item lg={8} sm={12} error>  <Typography variant="h4" color='error' >{errorMessage} </Typography> </Grid> : null;
        return (
            <Query query={PRODUCT_LINE} variables={this._getQueryVariablesProductLine()} fetchPolicy="no-cache"    >
                {({ loading, error, data, refetch }) => {
                    if (loading) return <FbLoader isTableBodyRequired={true} colSpan={10} loaderStyle={'center'} />
                    if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'center'} message='Product Line Not Found' />

                    this.productLine = data.manufacturer.productLine;
                    this.productWarranty = data.manufacturer.productLine.productWarranty;
                    this.productWarrantyId = this.productWarranty.productWarrantyId;
                    Title = Title !== null  ? Title : this.productLine.lineName;
          
                    ComponentWarrantyInYears = ComponentWarrantyInYears !== null  ? ComponentWarrantyInYears : this.productWarranty.componentInYears;
                    ComponentWarrantyUrl = ComponentWarrantyFile!=null ? '' : ComponentWarrantyUrl != null  ? ComponentWarrantyUrl : this.productWarranty.componentWarrantyLink;
                    PerformanceWarrantyInYears = PerformanceWarrantyInYears !== null  ? PerformanceWarrantyInYears : this.productWarranty.performanceInYears;
                    PerformanceWarrantyUrl = PerformanceWarrantyFile!=null ? '' : PerformanceWarrantyUrl !== null  ? PerformanceWarrantyUrl : this.productWarranty.performanceWarrantyLink;
                    const imageSrc = ProductLineLogo !== null && ProductLineLogo !== '' ? URL.createObjectURL(ProductLineLogo) : this.productLine.logo !== null ? ProductLineImagePath + this.productLine.logo : ( require('../../assets/images/NoImageAvailable.png')) ;
                    const image = <img alt="profilepic" src={imageSrc} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />;
                return (
                    <div className={classes.root}>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>SPV | Edit Product Line</title>
                        </Helmet>
                            <Grid container direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="h1" >
                                        Edit Product Line
                        </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row"
                                justify="space-between"
                                alignItems="center"
                                className={classes.control}
                                spacing={8}

                            >
                                {errorInfo}
                                <Grid item lg={5} sm={12}>
                                    <ValidatorForm ref="form" instantvalidate="true" autoComplete="off" onError={errors => console.log(errors)}
                                        onSubmit={this.handleSubmit}>
                                        <Paper elevation={0} className={classes.paper}>
                                            <Grid container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="flex-start"
                                                spacing={8}>
                                                <Grid item lg={4} sm={12} className={classes.gutterBottom}>
                                                    <Avatar className={classes.avatar}>
                                                        {image}
                                                        <GridListTileBar
                                                           // title="Upload"
                                                            className={classes.titleBar}
                                                            actionIcon={
                                                                <Fragment>
                                                                    <input hidden accept="image/*" className={classes.input} ref={(file) => this.filefield = file} id="ProductLineLogo" name="ProductLineLogo" type="file" onChange={(e) => this.handleChange(e)} />
                                                                    <label htmlFor="ProductLineLogo">
                                                                        <IconButton component="span" color="secondary">
                                                                            <Icon style={{ fontSize: 26 }}>
                                                                                camera_alt
                                                                                </Icon>
                                                                        </IconButton>
                                                                    </label>
                                                                </Fragment>
                                                            }
                                                        />
                                                    </Avatar>
                                                </Grid>
                                            <Grid item lg={12} sm={12} xs={12}>
                                                    <TextValidator
                                                        name="Title" onChange={(e) => this.handleChange(e)}
                                                        label="Product Line"
                                                        placeholder="Product Line"
                                                        fullWidth
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={Title}
                                                        validators={['required']}
                                                        errorMessages={['this field is required']}
                                                    />
                                                </Grid>
                                                <Grid item lg={12} sm={12}>
                                                    <FormLabel component="legend" className={classes.menuposition}>Component Warranty</FormLabel>
                                                    <Grid container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="stretch"
                                                        spacing={8}
                                                    >
                                                    <Grid item lg={12} sm={12} xs={12}>
                                                            <TextValidator onChange={(e) => this.handleChange(e)}
                                                                name="ComponentWarrantyInYears"
                                                                label="Component warranty in years"
                                                                placeholder="Component warranty in years"
                                                                fullWidth
                                                                margin="normal"
                                                                variant="outlined"
                                                                instantvalidate="true"
                                                                value={ComponentWarrantyInYears}
                                                                validators={['required', 'isFloat', 'minFloat:0.1', 'maxFloat:50']}
                                                                errorMessages={['this field is required', 'input should be number only', yearValidationMessage, tooBigYearValidationMessage]}
                                                            />
                                                        </Grid>
                                                    <Grid item lg={12} sm={12} xs={12} >
                                                            <Grid container
                                                                direction="row"
                                                                justify="center"
                                                                alignItems="center"
                                                                spacing={8}
                                                            >
                                                            <Grid item sm={5} xs={12} >
                                                                    <TextValidator onChange={(e) => this.handleChange(e)}
                                                                        label="Upload"
                                                                        fullWidth
                                                                        helperText="Upload component warranty in PDF or XLS File!"
                                                                    name="ComponentWarrantyFile"
                                                                    instantvalidate="true"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    type='file'
                                                                    InputProps={{
                                                                          
                                                                            classes: {
                                                                                input: classes.textFieldInput
                                                                            }
                                                                    }}
                                                                     
                                                                    validators={['isComponentWarrantyFieldValid', 'validateExtensionProduct']}
                                                                    errorMessages={['Component warranty is required', 'file with .doc or .pdf extensions are allowed']}
                                                                    inputProps={{ accept: "application/msword, application/pdf" }}
                                                                    />
                                                                </Grid>
                                                            <Grid item sm={1} xs={12}  style={{ textAlign: "center" }} >
                                                                <Typography variant="h4" className={classes.or} style={{ marginTop: "-6px" }}> OR </Typography>
                                                                </Grid>
                                                            <Grid item sm={6} xs={12} >
                                                                    <TextValidator onChange={(e) => this.handleChange(e)}
                                                                        label="Url"
                                                                        placeholder="Enter Warranty Url"
                                                                        helperText="Enter component warranty universal resource locator!;"
                                                                        name="ComponentWarrantyUrl"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        value={ComponentWarrantyUrl}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        type='text'
                                                                        InputProps={{
                                                                         
                                                                            classes: {
                                                                                input: classes.textFieldInput
                                                                            }
                                                                        }}
                                                                    validators={['isComponentWarrantyFieldValid',  'validateUrl']}
                                                                    errorMessages={['Component warranty is required', 'please enter valid url']}
                                                                    FormHelperTextProps={{ classes: { root: classes.textHidden } }}
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            <Grid item sm={12} xs={12}>
                                                    <FormLabel component="legend" className={classes.menuposition}>Performance Warranty</FormLabel>
                                                    <Grid container
                                                        direction="row"
                                                        justify="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={8}
                                                    >
                                                    <Grid item sm={12} xs={12} >
                                                            <TextValidator onChange={(e) => this.handleChange(e)}
                                                                name="PerformanceWarrantyInYears"
                                                                label="Warranty in years"
                                                                placeholder="Enter warranty in years"
                                                                fullWidth
                                                                margin="normal"
                                                                instantvalidate="true"
                                                                value={PerformanceWarrantyInYears}
                                                                errorMessages={['this field is required', 'input should be number only', yearValidationMessage, tooBigYearValidationMessage]}
                                                            />
                                                        </Grid>
                                                    <Grid item sm={12} xs={12} >
                                                            <Grid container
                                                            direction="row"
                                                            justify="center"
                                                            alignItems="stretch"
                                                                spacing={8}
                                                            >
                                                            <Grid item sm={5} xs={12} >
                                                                    <TextValidator onChange={(e) => this.handleChange(e)}
                                                                        label="Upload"
                                                                        name="PerformanceWarrantyFile"
                                                                        helperText="Upload performance warranty in PDF or XLS File!"
                                                                        fullWidth
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                               
                                                                        type='file'
                                                                        InputProps={{
                                                                         
                                                                            classes: {
                                                                                input: classes.textFieldInput
                                                                            }
                                                                    }}
                                                                    
                                                                    validators={['isPerformanceWarrantyFieldValid', 'validateExtensionPerformance']}
                                                                    errorMessages={['Performace warranty is required', 'file with .doc or .pdf extensions are allowed']}
                                                                    inputProps={{ accept: "application/msword, application/pdf" }}
                                                                    />
                                                                </Grid>
                                                            <Grid item sm={1} xs={12}  style={{ textAlign: "center" }} >
                                                                <Typography variant="h4" className={classes.or}  > OR </Typography>
                                                                </Grid>
                                                            <Grid item sm={6} xs={12} >
                                                                    <TextValidator onChange={(e) => this.handleChange(e)}
                                                                        name="PerformanceWarrantyUrl"
                                                                        label="Url"
                                                                        placeholder="Enter Warranty Url"
                                                                      helperText="Enter component warranty universal resource locator!;"
                                                                        value={PerformanceWarrantyUrl}
                                                                        fullWidth
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        type='text'
                                                                        InputProps={{
                                                                         
                                                                            classes: {
                                                                                input: classes.textFieldInput
                                                                            }
                                                                        }}
                                                                        validators={['isPerformanceWarrantyFieldValid','validateUrl']}
                                                                    errorMessages={['Performace warranty is required',  'please enter valid url']}
                                                                    FormHelperTextProps={{ classes: { root: classes.textHidden } }}
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Grid container
                                            direction="row"
                                            //justify="flex-end"
                                            //alignItems="flex-end"
                                            className={classes.update}
                                        >



                                        <Grid item><div className={classes.wrapper}>
                                            <Button variant="contained" type="submit" size="large" color="primary" className={buttonClassname} disabled={uploading}
                                               >
                                                Update
                                              </Button> {uploading && <CircularProgress color={theme.palette.common.white} size={19} className={classes.buttonProgress} />}
                                        </div>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>

                                </Grid>
                            </Grid>
                        </div>);
                }}
            </Query>
        );
    }
}
export default withStyles(styles)(EditProductLine);

