import React from 'react';
import PaginationItem from './PaginationItem'
import { PAGINATION_TYPE, PAGINATION_SETTING } from '../../constants';
import _ from 'lodash';
class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.buildPage = this.buildPage.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this._id = _.uniqueId(this.props.Prefix ? this.props.Prefix : "pager");
        this.showDropdown = this.props.showDropdown ? true : false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = (this.props.TotalRecords !== nextProps.TotalRecords || this.props.PageSize !== nextProps.PageSize || this.props.PageNumber !== nextProps.PageNumber || this.props.ShowPreviousNext !== nextProps.ShowPreviousNext);
        return shouldUpdate;
    }

    pageChange(pageNumber) {
        this.props.onPageChange(pageNumber);
    }

    buildPage() {
        const itemCount = parseInt( this.props.TotalRecords,10);
        const pageSize = parseInt(this.props.PageSize, 10);
        var pageNumber = parseInt(this.props.PageNumber, 10);
        const showPreviousNext = this.props.ShowPreviousNext != null && this.props.ShowPreviousNext;

        const pages = Math.ceil(itemCount / pageSize);
        if (pages <= 1) {
            return;
        }
        if (pageNumber > pages) {
            pageNumber = pages;
        }

        var pagesArray = [];
        if (showPreviousNext) {
            //pages = pages + 3; //to show active page summary, and previpous next
            var item = this._getPageItem((
                { ItemType: PAGINATION_TYPE.PAGE_ITEM_LABEL, CurrentPage: pageNumber, PageCount: pages, key: this._id + "currentPage", Total: itemCount  }
            )
            );
            
            pagesArray.push(item);
            if (pageNumber > 1) {
                pagesArray.push(this._getPageItem({ onPageChange: this.pageChange, ItemType: PAGINATION_TYPE.PAGE_ITEM_FIRST, PageNumber: 1, CurrentPage: pageNumber, key: this._id + "first" }));

                pagesArray.push(this._getPageItem({ onPageChange: this.pageChange, ItemType: PAGINATION_TYPE.PAGE_ITEM_PREVIOUS, CurrentPage: pageNumber, key: this._id + "previous" }));
            }
            
        }
        if (pages > PAGINATION_SETTING.MAX_PAGES) {
            var from = Math.max(1, (pageNumber - (PAGINATION_SETTING.MAX_PAGES / 2)))
            var to = from + PAGINATION_SETTING.MAX_PAGES;
            for (from; from <= to; from++) {
                pagesArray.push(this._getPageItem({ key: this._id + "initial"+from,  onPageChange: this.pageChange, ItemType: PAGINATION_TYPE.PAGE_ITEM_PAGE, Status: (pageNumber === from ? "active" : ""), PageNumber: from, CurrentPage: pageNumber }));
            }
            if (this.showDropdown) {
                pagesArray.push(this._getPageItem({
                    key: this._id + "drp",
                    onPageChange: this.pageChange, ItemType: PAGINATION_TYPE.PAGE_ITEM_PAGE_DROPDOWN, CurrentPage: pageNumber,
                    FromPage: to + 1, ToPage: pages
                }));
            }
            
        }
        else {
            for (var i = 1; i <= pages; i++) {
                pagesArray.push(this._getPageItem({ key: this._id + "item"+i, onPageChange: this.pageChange, ItemType: PAGINATION_TYPE.PAGE_ITEM_PAGE, Status: (pageNumber === i ? "active" : ""), PageNumber: i, CurrentPage: pageNumber }));
            }
        }
        
        if (showPreviousNext) {
            if (pageNumber < pages) {
                pagesArray.push(this._getPageItem({ key: this._id + "next", onPageChange: this.pageChange, ItemType: PAGINATION_TYPE.PAGE_ITEM_NEXT, CurrentPage: pageNumber }));
                pagesArray.push(this._getPageItem({ onPageChange: this.pageChange, PageNumber: pages, ItemType: PAGINATION_TYPE.PAGE_ITEM_LAST, CurrentPage: pageNumber, key: this._id + "last" }));
            }
        }
        
        return pagesArray;

    }
    _getPageItem(props) {
        return (
            <PaginationItem  prefix={this._id} {...props} />
        );
    }

    render() {
        var scope = {
            borderStyleTd: {
                borderTop: 'none'
            }
        };
        const itemCount = parseInt(this.props.TotalRecords, 10);
        const pageSize = parseInt(this.props.PageSize, 10);
        var pageNumber = parseInt(this.props.PageNumber, 10);

        if (itemCount < pageSize && pageNumber <= 1) {
            return null;
        }
        const is_custom = this.props.Custom;

        const views=is_custom ? (this.props.children) : this.buildPage();
        
        return (
            <div className="pagination-frame" style={scope.borderStyleTd} >
                <ul>
                    {views}
                </ul>
            </div>
        );
    }
}

export default Pagination;