import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { InstallersTitle } from '../../StringConstants';
import theme from '../../theme';
import InstallerHeader from '../admin/InstallerHeader';
const styles = {
    root: {
        padding: '0 16px',
        marginTop: 16,
    },
    button: {
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        '&:hover': {
            background: theme.palette.common.green
        }
    },
    customicon: {
        marginRight: theme.spacing(2),
    }
};

class AdminInstHeader extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h1">
                            {InstallersTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InstallerHeader />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(AdminInstHeader);
