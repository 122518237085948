import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
//import BreadCrumbsCustom from '../BreadCrumbsCustom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from "react-helmet";
import { AddProductLineApi, handleErrors, handleHttpException } from '../constants';
import theme from '../theme';
import AuthService from './AuthService';
const styles = {
    root: {
        flexGrow: 1,
        height: "100%"
    },
    textFieldInput: {
        fontSize: 14,
        height: '20px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    avatar: {
        borderRadius: 0,
        width: "100%",
        height: "172px",
        backgroundColor: theme.palette.background.default,
        color: "transparent",
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.background.lightbg,
        maxWidth: "178px",
        minWidth: "178px",
    },
    iconHover: {
        color: theme.palette.grey[500],
        verticalAlign: "middle"
    },
    paper: {
        //padding: theme.spacing(2),
        //color: theme.palette.text.secondary,
        background: theme.palette.common.white,
    },
    paperCard: {
        padding: theme.spacing(2),
        background: theme.palette.grey[50],
        //color: theme.palette.text.secondary,
    },
    control: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    menuposition: {
        marginTop: theme.spacing(2),
        fontWeight: '900',
        color: '#333',
        textTransform: 'uppercase',
    },
    update: {
        marginTop: theme.spacing(2),
    },
    titleBar: {
        fontSize: "13px",
    },
    textHidden: {
        color: "#fff",
        visibility:" hidden",
    },
    or: {
        verticalAlign:"middle",
        lineHeight:" 85px",
    }
};



class Addproduct extends React.Component {

    constructor() {
        super();
        this.state = {
            uploading:false,
            error: false, errorMessage: null,
            user: { Title: '', ProductLineLogo: null, ComponentWarrantyInYears: '', ComponentWarrantyFile: null, ComponentWarrantyUrl: '', PerformanceWarrantyInYears: '', PerformanceWarrantyFile: null, PerformanceWarrantyUrl: '' }
        }
        this.componentFile = React.createRef();
        this.performanceFile = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Auth = new AuthService();
        this.validateUrl = this.validateUrl.bind(this);
        this.addValidations = this.addValidations.bind(this);
        this.removeValidations = this.removeValidations.bind(this);
    }
    componentDidMount() {

        this.addValidations();

    }
    componentWillUnmount() {
        this.removeValidations();
    }
    removeValidations() {
        ValidatorForm.removeValidationRule('isComponentWarrantyFieldValid');
        ValidatorForm.removeValidationRule('isPerformanceWarrantyFieldValid');
        ValidatorForm.removeValidationRule('validateUrl');
    }
    addValidations() {
        ValidatorForm.addValidationRule('isComponentWarrantyFieldValid', (value) => {
            return this.isComponentWarrantyFieldValid(value);
        });
        ValidatorForm.addValidationRule('isPerformanceWarrantyFieldValid', (value) => {
            return this.isPerformanceWarrantyFieldValid(value);
        });
        ValidatorForm.addValidationRule('validateUrl', (value) => {
            return this.validateUrl(value);
        });
    }
    isComponentWarrantyFieldValid(value) {
        return this.state.user.ComponentWarrantyFile != null || (this.state.user.ComponentWarrantyUrl.length > 0);
    }
    validateUrl(value) {
        if (value.length === 0) return true;
        var re = /^(http[s]?:\/\/){1}(www.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
        return re.test(value);
    }
    isPerformanceWarrantyFieldValid(value) {
        return this.state.user.PerformanceWarrantyFile != null || this.state.user.PerformanceWarrantyUrl.length > 0;
    }
    handleSubmit() {
        this.setState({ uploading: true });
        let formData = new FormData();
        const token = this.Auth.getToken();
        var obj = this.state.user;
        Object.keys(obj).forEach((key) => {
            if (!Array.isArray(obj[key]))
                formData.append(key, obj[key]);
        });
        fetch(AddProductLineApi, {
            method: 'POST',
            headers: { 'authorization': `Bearer ${token}` },
            body: formData
        }).then(res => {
            return handleErrors(res);

        }).then(res => {
            if (res.status === true) {
                this.setState({ uploading: false }, function () { this.props.history.push({ pathname: "/AssignProductLine/" + res.productLineId }); });
            }
            else {
                this.setState({ uploading: false, error: true, errorMessage: res.message });
            }
            }).catch(err => {
                
                handleHttpException(err).then(result => {
                    
                    this.setState({ uploading: false, error: true, errorMessage: result });
                });;
            });
    }

    handleFileChange(e) {

    }

    handleChange(e) {
        if (e.target.type === 'file') {
            if (e.target.files.length > 0) {
                this.updateformState({ [e.target.name]: e.target.files[0] });
            }
            else {
                this.updateformState({ [e.target.name]: null });
            }
        }
        else {
            this.updateformState({ [e.target.name]: e.target.value });
        }


    }
    updateformState(obj, extra) {
        const { user } = this.state;
        let userState =
            { ...user, ...obj };
        if (extra != null) {

            this.setState(state => ({ "user": userState, ...extra }));
        }
        else {
            this.setState(state => ({ "user": userState }));
        }


    }


    render() {
        const { classes } = this.props;
        const { error, errorMessage, user, uploading } = this.state;
        const { Title, ProductLineLogo, ComponentWarrantyInYears, ComponentWarrantyUrl, PerformanceWarrantyInYears, PerformanceWarrantyUrl } = user;

        const imageSrc = ProductLineLogo != null ? URL.createObjectURL(ProductLineLogo) : require('../assets/images/product.png');

        const yearValidationMessage = 'Years should be greater than 0';
        const tooBigYearValidationMessage = 'Years should be less than 50';
        const image = <img alt="profilepic" src={imageSrc} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />;
        const errorInfo = error ? <Paper align="center" square={true} className={classes.Paper} elevation={0}>
            <Typography align="center" color="error"> {errorMessage}</Typography>
        </Paper>   : null;
       
        return (
            <div className={classes.root}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Product Lines</title>
                </Helmet>
                {/* <BreadCrumbsCustom /> */}
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" >
                            Add Product Line
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.control}
                    spacing={8}

                >
                  
                    <Grid item lg={5} md={7} sm={12}>
                        <ValidatorForm ref="form" instantvalidate="true" autoComplete="off" onError={errors => console.log(errors)}
                            onSubmit={this.handleSubmit}>
                            <Paper elevation={0} className={classes.paper}>
                                <Grid container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    spacing={8}>
                                    <Grid item sm={12} xs={12} className={classes.gutterBottom}>
                                        <Avatar className={classes.avatar}>
                                            {image}
                                            <GridListTileBar
                                                title="Product Line Logo"
                                                className={classes.titleBar}
                                                actionIcon={
                                                    <Fragment>
                                                        <input hidden accept="image/*" className={classes.input} ref={(file) => this.filefield = file} id="ProductLineLogo" name="ProductLineLogo" type="file" onChange={(e) => this.handleChange(e)} />
                                                        <label htmlFor="ProductLineLogo">
                                                            <IconButton component="span" color="secondary">
                                                                <Icon  style={{ fontSize: 20 }}>
                                                                    camera_alt
                                                        </Icon>
                                                            </IconButton>
                                                        </label>
                                                    </Fragment>
                                                }
                                            />
                                        </Avatar>
                                    </Grid>
                                    <Grid item lg={12} sm={12} xs={12}>
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator
                                                name="Title" onChange={(e) => this.handleChange(e)}
                                                label="Product Line"
                                                placeholder="Enter product line name"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                value={Title}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </Grid>

                                        <FormLabel component="legend" className={classes.menuposition}>Component Warranty</FormLabel>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="stretch"
                                            spacing={8}
                                        >
                                            <Grid item sm={12} xs={12}>
                                                <TextValidator onChange={(e) => this.handleChange(e)}
                                                    name="ComponentWarrantyInYears"
                                                    label="Warranty in years"
                                                    placeholder="Enter warranty in years"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    instantvalidate="true"
                                                    value={ComponentWarrantyInYears}
                                                    validators={['required', 'isFloat', 'minFloat:0.1','maxFloat:50']}
                                                    errorMessages={['This field is required', 'Input should be number only', yearValidationMessage, tooBigYearValidationMessage]}
                                                />
                                            </Grid>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="stretch"
                                                    spacing={8}
                                                >
                                                    <Grid item sm={5} xs={12} >
                                                        <TextValidator onChange={(e) => this.handleChange(e)}
                                                            label="Upload"
                                                            fullWidth
                                                            helperText="Upload component warranty in PDF or XLS File!"
                                                            name="ComponentWarrantyFile"
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            type='file'
                                                            InputProps={{
                                                               
                                                                classes: {
                                                                    input: classes.textFieldInput
                                                                }
                                                            }}
                                                            validators={['isComponentWarrantyFieldValid']}
                                                            errorMessages={['Component warranty is required']}
                                                            inputProps={{ accept: "application/msword, application/pdf" }}
                                                        />
                                                    </Grid>

                                                    <Grid item sm={1} xs={12}  style={{ textAlign: "center", textTransform: "uppercase" }} >
                                                        <Typography variant="h4" className={classes.or}> Or </Typography>
                                                    </Grid>
                                                    <Grid item sm={6} xs={12} >
                                                        <TextValidator onChange={(e) => this.handleChange(e)}
                                                            label="Url"
                                                            helperText="Enter component warranty universal resource locator!"
                                                            placeholder="Enter Warranty Url"
                                                            name="ComponentWarrantyUrl"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={ComponentWarrantyUrl}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                       
                                                            InputProps={{
                                                               
                                                                classes: {
                                                                    input: classes.textFieldInput
                                                                }
                                                            }}
                                                            validators={['isComponentWarrantyFieldValid', 'validateUrl']}
                                                            errorMessages={['Component warranty is required', 'please enter valid url']}
                                                            FormHelperTextProps={{ classes: { root: classes.textHidden } }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <FormLabel component="legend" className={classes.menuposition}>Performance Warranty</FormLabel>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="stretch"
                                            spacing={8}
                                        >
                                            <Grid item sm={12} xs={12} >
                                                <TextValidator onChange={(e) => this.handleChange(e)}
                                                    name="PerformanceWarrantyInYears"
                                                    label="Warranty in years"
                                                    placeholder="Enter warranty in years"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={PerformanceWarrantyInYears}
                                                    instantvalidate="true"
                                                    validators={['required', 'isFloat', 'minFloat:0.1', 'maxFloat:50']}
                                                    errorMessages={['This field is required', 'Input should be number only', yearValidationMessage, tooBigYearValidationMessage]}
                                                />
                                            </Grid>
                                            <Grid item sm={12} xs={12} >
                                                <Grid container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                    spacing={8}
                                                >
                                                    <Grid item sm={5} xs={12} >
                                                        <TextValidator onChange={(e) => this.handleChange(e)}
                                                            label="Upload"
                                                            name="PerformanceWarrantyFile"
                                                            helperText="Upload performance warranty in PDF or XLS File!"
                                                            fullWidth
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            type='file'
                                                            InputProps={{
                                                               
                                                                classes: {
                                                                    input: classes.textFieldInput
                                                                }
                                                            }}
                                                            validators={['isPerformanceWarrantyFieldValid']}
                                                            errorMessages={['Performace warranty is required']}
                                                            inputProps={{ accept: "application/msword, application/pdf" }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={1} xs={12}  style={{ textAlign: "center", textTransform: "uppercase" }} >
                                                        <Typography variant="h4" className={classes.or} style={{ marginTop:"-6px" }}> Or </Typography>                                                      
                                                    </Grid>
                                                    <Grid item sm={6} xs={12} >
                                                        <TextValidator onChange={(e) => this.handleChange(e)}
                                                            name="PerformanceWarrantyUrl"
                                                            label="Url"
                                                            helperText="Enter performance warranty universal resource locator!"
                                                            placeholder="Enter Warranty Url"
                                                            value={PerformanceWarrantyUrl}
                                                            fullWidth
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                           
                                                            InputProps={{
                                                               
                                                                classes: {
                                                                    input: classes.textFieldInput,                                                                 
                                                                }
                                                            }}
                                                            validators={['isPerformanceWarrantyFieldValid', 'validateUrl']}
                                                            errorMessages={['Performace warranty is required','please enter valid url']}
                                                            FormHelperTextProps={{ classes: { root: classes.textHidden } }}
                                                         
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Grid container
                                direction="row"
                                //justify="flex-end"
                                //alignItems="flex-end"
                                className={classes.update}
                            >



                                <Grid item xs={12} lg={12} >
                                    <Button className={classes.button} size="large" variant="contained" type="submit" alignitem='center' color="primary" {...uploading === true ? { disabled: true } : ''} tabIndex="3">
                                        {uploading ?
                                            <CircularProgress color={theme.palette.common.white} size={19} style={styles.root} /> :
                                            <span>Save</span>
                                        }
                                    </Button>
                                    {errorInfo}
                                </Grid>
                            </Grid>
                        </ValidatorForm>

                    </Grid>
                </Grid>
            </div>
        );
    }
}
export default withStyles(styles)(Addproduct);

