import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PersonAdd from '@material-ui/icons/PersonAdd';
import gql from 'graphql-tag';
import React, { createRef } from 'react';
import { Query } from 'react-apollo';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Background from '../../assets/images/user.png';
import { DeleteDistributorStaffApi, DeleteStaffApi, ProfilePath, Role_DISTRIBUTOR } from '../../constants';
import { StaffHeaderTitle } from '../../StringConstants';
import theme from '../../theme';
import AdminStaffResetPassword from '../admin/AdminStaffResetPassword';
import AuthService from '../AuthService';
import FBAlertDialog from '../common/FBAlertDialog';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import FBLongMenu from '../common/FBLongMenu';
import UserProfile from './../auth/UserProfile';
import _ from 'lodash'
const Staff_QUERY = gql`
{
  stafffeed {
    staffList {
      designation
      email
      firstName
      isActive
      lastName
      manufacturerId
      permission
      phoneNumber
      profilePhoto
      staffId
      staffUserId
      userName
      permissions {
        id
        checked
        name
      }
    }
  }
}
`;
const ADMIN_Staff_QUERY = gql`
query getstaff($manufacturerId: Int) {
  admin(manufacturerId:$manufacturerId) {
    staffList {
      designation
      email
      firstName
      isActive
      lastName
      manufacturerId
      permission
      phoneNumber
      profilePhoto
      staffId
      staffUserId
      userName
      permissions {
        id
        checked
        name
      }
    }
  }
}
`;
const Distributor_Staff_QUERY = gql`
{
  supplier{
 staffList {
      designation
      email
      firstName
      isActive
      lastName
      manufacturerId
      permission
      phoneNumber
      profilePhoto
      staffId
      userId
      userName
      displayName
      permissions {
        id
        checked
        name
      }
    }
  }
}
`;

const Manufacturer_QUERY = gql`
query getmanufacturers {
  supplier {
      manufacturers{
      isDistributor
      address
      displayName
      logo
      manufacturerId
      profilePhoto
      userName
      uploadFrequency
      email
      userId
      isActive
    }
  }
}
`;
const styles = {
    root: {
        flexGrow: 1,
        height: "100%",
    },
    control: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        fontSize: 16,
        textTransform: "capitalize",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        //'&:hover': {
        //    background: theme.palette.common.green,
        //}
    },
    customicon: {
        marginRight: 9,
    },
    card: {
        boxShadow: "none",
        position: "relative",
        background: "transparent",
        border: "1px solid",
        borderColor: theme.palette.text.divider,
       //inHeight: 200,
        //[theme.breakpoints.up('md')]: {
        //    maxWidth: 272,
        //},
    },
    staffName: {
        fontFamily: 'NHaasGroteskDSPro-65Md',
        letterSpacing: "0.1px",
        fontSize: "1.2rem",
        lineHeight: 1.4,
        //color: theme.palette.common.headingText,

    },
    staffDesignation: {
        letterSpacing: "0.1px",
        fontSize: "1rem"

    },
    designation: {
        fontFamily: 'NHaasGroteskDSPro-65Md',
        marginTop: "4px",
        display: "inline-block",
    },

    bigAvatar: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 100,
        height: 100,
        color: "transparent",
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        borderRadius: 2,
        background: "#fafafa",
    },
    cardHeader: {
        //alignItems: "flex-start",
    },
    errorMessage: {
        color: theme.palette.error.main,
    }

};

class Staff extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showDialog: false, dialogTitle: '', dialogContent: '', dialogData: null, queryInProgress: false, dummyText: '', status: false, role : UserProfile.getRole() }
        this.handleSelect = this.handleSelect.bind(this);
        this._getQueryVariables = this._getQueryVariables.bind(this);
        this.dialogConfirmation = this.dialogConfirmation.bind(this);
        this.dialogCancel = this.dialogCancel.bind(this);
        this.myquery = createRef();
        this.Auth = new AuthService();
        this.onQueryComplete = this.onQueryComplete.bind(this);
        this.changeDialog = this.changeDialog.bind(this);
        this.manufacturerList = [];
    }
    _getQueryVariables(adminView, manufacturerId) {
        return adminView ? { manufacturerId } : { "dummyText": this.state.dummyText };

    }
    changeDialog(status) {
        if (status) {
            this.setState({ "status": status });
        }
        else {
            this.setState({ showResetPassword: false, userId: null, firstName: null, lastName: null, status: status });
        }
        
    }
    handleSelect(option) {
        if (this.props.adminView) {
            switch (option.title) {
                case 'Reset Password': {
                    this.setState({ showResetPassword: true, userId: option.data.staffUserId, firstName: option.data.firstName, lastName: option.data.lastName, status: true });
                    break;
                }
                default: { }
            }
        }
        else {
            switch (option.title) {
                case 'Edit': {
                    option.role === Role_DISTRIBUTOR ?
                        this.props.history.push({ pathname: "/editdistributorstaff/" + option.data.staffId, state: { staff: option.data, manufacturerList: this.manufacturerList } }) :
                        this.props.history.push({ pathname: "/editstaff/" + option.data.staffId, state: { staff: option.data } });
                    break;
                }
                case 'Delete': {
                    this.setState({ showDialog: true, dialogTitle: 'Do you want to delete this account?', dialogContent: 'This will delete the user account and can no longer be used', dialogData: option.data });
                    break;
                }
                default: { }

            }
        }
       


    }
    dialogConfirmation(data) {
        const token = this.Auth.getToken();
        const role = this.state.role;
        let delete_endpoint;
        let body;
        if (role === Role_DISTRIBUTOR) {
            delete_endpoint = DeleteDistributorStaffApi;
            body = JSON.stringify({ "FilterText": data.userId });
        } else {
            delete_endpoint = DeleteStaffApi;
            body = JSON.stringify({ "FilterText": data.staffUserId });
        }

        fetch(`${delete_endpoint}`, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: body,
        })
            .then(res => res.json())
            .then(() => {
                this.setState({ queryInProgress: true, dummyText: Date.now().toString() })
            });
        this.dialogCancel();
    }
    dialogCancel() {
      
        this.setState({ showDialog: false, dialogTitle: '', dialogContent: '', dialogData: null });
    }
    onQueryComplete() {
        if (this.state.queryInProgress !== false) {
            this.setState({ queryInProgress: false });
        }
       
    }

    //combine same staff users to single model containing mappings
    getDistinctStaffUsersWithMappings(staffList) {
        let staffModelsWithMapping = [];
        staffList.forEach((staff) => {
            var staffItem = staffModelsWithMapping.find(s => s.staffId === staff.staffId);
            if (!staffItem) {
                const isAttachedToAnyManufacturer = staff.manufacturerId > 0;
                const mappingData = { manufacturerId: staff.manufacturerId, displayName: staff.displayName, permissions: staff.permissions, isManufacturerAttached: isAttachedToAnyManufacturer };
                staff.manufacturerMapping = isAttachedToAnyManufacturer ? [mappingData] : [];
                staffModelsWithMapping.push(staff);
            } else {
                //Unattached will never enter here due to no mapping repetition
                const mappingData = { manufacturerId: staff.manufacturerId, displayName: staff.displayName, permissions: staff.permissions, isManufacturerAttached: true };
                staffItem.manufacturerMapping.push(mappingData);
            }
        });

        staffModelsWithMapping.forEach((sm) => {
           // delete sm.permissions;
            //delete sm.manufacturerId;
           // delete sm.displayName;
        });

        return staffModelsWithMapping;
    }


    render() {
        const { classes, adminView, manufacturer } = this.props;
        const manufacturerId = manufacturer && manufacturer.manufacturerId;
        const { showDialog, dialogTitle, dialogContent, dialogData, showResetPassword, status, firstName, lastName, userId, role } = this.state;
        const query = adminView ? ADMIN_Staff_QUERY : (role === Role_DISTRIBUTOR ? Distributor_Staff_QUERY : Staff_QUERY);
        const dialog = showDialog ? <FBAlertDialog cancel={this.dialogCancel} confirmed={this.dialogConfirmation} open={showDialog} title={dialogTitle} content={dialogContent} data={dialogData} /> : null;

        //const options = ['edit', 'delete'];
        return (
            <div className={classes.root}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Staff</title>
                </Helmet>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
               <Grid item xs={12} sm={6}>
                         <Typography variant="h1">
                            {StaffHeaderTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {adminView &&
                            <Button variant="contained" size="large" component={Link}
                            to={{
                                pathname: "/addmember/" + manufacturerId,
                                state: { manufacturer: manufacturer }
                            }}
                               color="primary" className={classes.button}>
                                <PersonAdd className={classes.customicon} />Add Members
                        </Button>}
                        {!adminView &&
                            <Button variant="contained" size="large" component={Link} to="/addmember/" color="primary" className={classes.button}>
                                <PersonAdd className={classes.customicon} />Add Members
                        </Button>}
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className={classes.control} >
                    {dialog}

                    {(role === Role_DISTRIBUTOR) &&
                        <Query query={Manufacturer_QUERY} fetchPolicy="no-cache">
                            {({ loading, error, data }) => {
                            if (loading) return null;
                                if (error) return <FbError isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} />
                                const { supplier: { manufacturers } } = data;
                                if (manufacturers) {
                                    this.manufacturerList = manufacturers;
                                }

                                return null;
                            }}
                        </Query>
                    }
                   

                    <Query query={query} variables={this._getQueryVariables(adminView, manufacturerId)} ref={Query => { this.myquery = Query }} onCompleted={this.onQueryComplete} fetchPolicy="network-only"    >
                        {({ loading, error, data }) => {

                            if (loading) return <FbLoader  loaderStyle={'center'} />
                            if (error) return <FbError isTableBodyRequired={false} errorStyle={'emptycell'} />  
                            const staffList = adminView ? data.admin.staffList : (role === Role_DISTRIBUTOR ? data.supplier.staffList : data.stafffeed.staffList);
        
                            const  listModels=    (role !== Role_DISTRIBUTOR) ? (staffList.map((staff) =>
                                    (
                                        <Grid item lg={4} sm={6} xs={12}>
                                            <Card className={classes.card}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar alt={staff.firstName + ' ' + staff.lastName} src={`${ProfilePath}/${staff.profilePhoto === null ? 'user.png' : staff.profilePhoto}`} className={classes.bigAvatar} />
                                                    }
                                                    action={
                                                        adminView ? < FBLongMenu options={[{ title: "Reset Password", data: staff }]} onMenuSelect={this.handleSelect} /> :
                                                            < FBLongMenu options={[{ title: "Edit", data: staff,  }, { title: "Delete", data: staff }]} onMenuSelect={this.handleSelect} />
                                                    }
                                                    title={staff.firstName + ' ' + staff.lastName}
                                                    subheader={<span className={classes.staffDesignation}> {staff.userName}<br /><span className={classes.designation}> {staff.designation}</span> </span>}
                                                    classes={{ root: classes.cardHeader, title: classes.staffName }}
                                                />
                                            </Card>
                                        </Grid>
                                    ))) :  this.getDistinctStaffUsersWithMappings(staffList).map((staff) =>
                                        (
                                            <Grid item lg={4} sm={6} xs={12}>
                                                <Card className={classes.card}>
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar alt={staff.firstName + ' ' + staff.lastName} src={`${ProfilePath}/${staff.profilePhoto === null ? 'user.png' : staff.profilePhoto}`} className={classes.bigAvatar} />
                                                        }
    
                                                        action={< FBLongMenu options={[{ title: "Edit", data: staff, role }, { title: "Delete", data: staff, role }]} onMenuSelect={this.handleSelect} />}
                                                        title={staff.firstName + ' ' + staff.lastName}
                                                        subheader={<span className={classes.staffDesignation}> {staff.userName}<br /><span className={classes.designation}> {staff.designation}</span></span>}
                                                        classes={{ root: classes.cardHeader, title: classes.staffName }}
    
                                                    />
                                                    <CardContent>
                                                        <Typography variant="body2" component="p">
                                                            <span> {staff.manufacturerMapping.length > 0 ? `For : ${staff.manufacturerMapping.map(e => " " + e.displayName).toString()}` : <span className={classes.errorMessage}>No manufacturer attached</span>} </span>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ));
                            const result = (!_.isNil(staffList) && staffList.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} errorStyle={'notfound'} />;
                            return (result);
                        }}
                    </Query>


                    {adminView && showResetPassword && <AdminStaffResetPassword userId={userId} show={status} firstName={firstName} lastName={lastName} changeDialog={this.changeDialog} />}
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Staff);
