
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import theme from '../../theme';
import GeoApp from "./GeoApp";

const styles = {
    table: {
        width: "120px",
        padding: 0
    },
    tableCell: {
        border: "none",
        padding: 0,
    },
    installerCount: {
        fontFamily: 'NHaasGroteskDSPro-65Md',
        border: "none",
        padding: 0,
    },
    selectBox: {
        padding: "6px 12px",
    },
    spaceRight: {
        marginRight: theme.spacing(1),
    }

}
class InstallsPerState extends React.Component {

    render() {
        const { classes, data } = this.props;

        let statsData = { "ACT": 0, "NSW": 0, "NT": 0, "QLD": 0, "SA": 0, "TAS": 0, "VIC": 0, "WA": 0};
        for (var i = 0; i < data.length; i++) {
            var item = data[i];
            statsData[item.stateName] = statsData[item.stateName] + item.totalInstallation;
           
            
        }
        const rows = [];
        for (var key in statsData) {
            rows.push((<TableRow>
                <TableCell className={classes.tableCell} align="left">{key}</TableCell>
                <TableCell className={classes.installerCount} align="left">{statsData[key]}</TableCell>
            </TableRow>));
        }
       
        return (
            <div>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" gutterBottom>Installs per state</Typography>
                        <Table className={classes.table}>
                            <TableBody>
                                {rows}
                            </TableBody>
                        </Table>

                    </Grid>
                    <Grid item xs={9}>
                        <GeoApp data={statsData}/>

                    </Grid>
                </Grid>

            </div>

        );
    }
}
export default withStyles(styles)(InstallsPerState);