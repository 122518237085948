import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Modal,
    TextField,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import {
    Role_Admin,
    Role_DISTRIBUTOR,
    Role_DISTRIBUTOR_STAFF,
    Role_MANUFACTURER,
    Role_STAFF,
} from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import theme from '../../theme';
import AdminResetPassword from '../admin/AdminResetPassword';
import UserProfile from '../auth/UserProfile';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import NavigateNext from '@material-ui/icons/NavigateNext';
import AddIcon from '@material-ui/icons/Add';


const styles = {
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        background: theme.palette.grey[50],
    },
    contentPaper: {
        margin: theme.spacing(2),
        background: theme.palette.common.white,
        border: '1px solid',
        borderColor: theme.palette.common.borderColor,
        overflowX: 'auto',
    },
    index: {
        paddingTop: 15,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: '1.3rem',
        verticalAlign: 'top',
    },
    paperModelSupplier: {
        position: 'absolute',
        minWidth: 100,
        minHeight: 100,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '15px 15px',
    },
};
const getQueryVariables = (role, manufacturerId, overAllListing,filter) => {
    switch (role) {
        case Role_Admin:
            return !_.isNil(overAllListing) && overAllListing === true
                ? { filter}
                : { manufacturerId };
        case Role_DISTRIBUTOR:
        case Role_DISTRIBUTOR_STAFF:
        case Role_MANUFACTURER:
        case Role_STAFF:
        default:
            return {};
    }
};
const getModalStyle=()=> {
    const top = 50 - 5;
    const left = 50 - 5;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const getData = (data, role) => {
    switch (role) {
        case Role_Admin:
            return { distributors: data.admin.distributors };
        case Role_DISTRIBUTOR:
        case Role_DISTRIBUTOR_STAFF:
        case Role_MANUFACTURER:
        case Role_STAFF:
        default:
            return { distributors: data.manufacturer.distributors };
    }
};

const CustomTableCell = withStyles((theme) => ({
    head: {
        background: theme.palette.grey[100],
        color: theme.palette.grey[900],
        border: 'none',
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 15,
        height: 'auto',
        whiteSpace: 'nowrap',
        lineHeight: '1.4',
    },
    body: {
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const SHeader = withStyles(styles)(({ classes }) => (
    <TableHead>
        <TableRow>
            <CustomTableCell sm={2} style={{ width: '100px' }}>
                Username
            </CustomTableCell>
            <CustomTableCell sm={3}>
                <span className={classes.subtext}>Rec Registry Names</span>
            </CustomTableCell>
            <CustomTableCell sm={2}>
                <span className={classes.subtext}>ABN & Internal Code</span>
            </CustomTableCell>
            <CustomTableCell sm={2}>Actions</CustomTableCell>
        </TableRow>
    </TableHead>
));

const SRow = withStyles(styles)(
    ({ classes, isAdmin, row, showNavigation, handleModelOpenSupplier }) => {
        const [isActive, setIsActive] = useState(row.isActive);

        const handleActiveChange = () => {
            setIsActive(!isActive);
        };

        return (
            <TableRow className={classes.row}>
                <CustomTableCell sm={2} scope="row" className={classes.index}>
                    <>
                        {row.index}
                        <Typography variant="h4">{row.userName}</Typography>
                        {showNavigation && (
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() =>
                                    handleModelOpenSupplier(
                                        row.manufacturerId,
                                        row.displayName,
                                        row.manufacturerNames
                                    )
                                }
                            >
                                Total Manufacturers: {row.totalManufacturers}
                            </Button>
                        )}
                    </>
                </CustomTableCell>
                <CustomTableCell sm={3}>
                    <Typography variant="h4">
                        Default: {row.defaultName ?? 'Not set'}
                    </Typography>
                    <div style={{ width: '350px', textWrap: 'wrap' }}>
                        {(row.distributorNames ?? '').split(',').map((supplier, index) => (
                            <Typography key={index}>{supplier.trim()}</Typography>
                        ))}
                    </div>
                </CustomTableCell>
                <CustomTableCell sm={2}>
                    <Typography>Abn: {row.abn}</Typography>
                    <Typography>
                        IC: {row.distributorInternalCode ? row.distributorInternalCode : 'NA'}
                    </Typography>
                    <Typography>Signing Key: {row.signingKey ?? ''}</Typography>
                </CustomTableCell>
                <CustomTableCell sm={2}>
                    {isAdmin && (
                        <FormGroup>
                            <span>{isActive ? 'Active' :'Disabled'}</span>
                            <AdminResetPassword userId={row.distributorUserId} />
                        </FormGroup>
                    )}
                    {showNavigation && (
                        <IconButton
                            component={Link}
                            to={{ pathname: '/supplier/' + row.distributorId, state: { distributor: row } }}
                            className={classes.margin}
                        >
                            <NavigateNext className={classes.iconNext} />
                        </IconButton>
                    )}
                   
                </CustomTableCell>
            </TableRow>
        );
    }
);
const SupplierListing = ({ classes, isDistributor, isAdmin, showNavigation, manufacturerId, overAllListing }) => {
    const [modelOpenSupplier, setModelOpenSupplier] = useState(false);
    const [activeDistributorId, setActiveDistributorId] = useState(0);
    const [activeSupplierDisplayName, setActiveSupplierDisplayName] = useState('');
    const [activeSupplierManufacturerNames, setActiveSupplierManufacturerNames] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const mnames = !_.isNil(activeSupplierManufacturerNames)
        ? activeSupplierManufacturerNames.split(',').map((m) => <div>{m}</div>)
        : [];
    const handleSearchChange = useCallback((event) => {
        const value = event.target.value;
        setSearchValue(value);
    }, []);
    const handleModelCloseSupplier = () => {
        setModelOpenSupplier(false);
        setActiveDistributorId(null);
        setActiveSupplierDisplayName(null);
        setActiveSupplierManufacturerNames(null);
    };

    const handleModelOpenSupplier = (manufacturerId, name, mnames) => {
        setModelOpenSupplier(true);
        setActiveDistributorId(manufacturerId);
        setActiveSupplierDisplayName(name);
        setActiveSupplierManufacturerNames(mnames);
    };

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper} square style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h1" style={{ marginRight: 'auto' }}>Supplier</Typography>

                {isAdmin && (
                    <TextField
                        label="Search Supplier"
                        value={searchValue}
                        onChange={handleSearchChange}
                        style={{ marginLeft: 'auto' }}
                    />
                )}

               
            </Paper>
            <Paper elevation={0} className={classes.contentPaper}>
                <Table>
                    <SHeader isAdmin={isAdmin} isDistributor={isDistributor} />
                    <TableBody>
                        <SupplierRecordMapper handleModelOpenSupplier={handleModelOpenSupplier} searchValue={searchValue} classes={classes} isDistributor={isDistributor} isAdmin={isAdmin} showNavigation={showNavigation} manufacturerId={manufacturerId} overAllListing={overAllListing} />
                    </TableBody>
                </Table>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={modelOpenSupplier}
                    onClose={handleModelCloseSupplier}
                >
                    <div style={getModalStyle()} className={classes.paperModelSupplier} xs={10}>
                        <Typography variant="subtitle1">Linked Manufacturers</Typography>
                        <div style={{ width: '500px' }}>{mnames}</div>
                    </div>
                </Modal>
            </Paper>
        </div>
    );

}

const SupplierRecordMapper = ({ classes, isDistributor, isAdmin, showNavigation, manufacturerId, overAllListing, searchValue, handleModelOpenSupplier }) => {




    const role = UserProfile.getRole();
    const query = overAllListing
        ? QueryHelper().OverallSupplierListingForAdmin(searchValue)
        : QueryHelper().SupplierListingByManufacturer();


    const { loading, error, data } = useQuery(query, {
        fetchPolicy: 'no-cache',
        variables: getQueryVariables(role, manufacturerId, overAllListing, searchValue),
    });



    if (loading) return <FbLoader isTableBodyRequired={false} colSpan={5} loaderStyle={'emptycell'} />;
    if (error) return <FbError isTableBodyRequired={false} colSpan={5} errorStyle={'emptycell'} />;
    const distributors = getData(data, role).distributors;
    return (
        <>
            {distributors && distributors.length > 0 ? (
                distributors.map((distributor) => (
                    <SRow
                        key={distributor.id}
                        classes={classes}
                        row={distributor}
                        showNavigation={showNavigation}
                        isAdmin={isAdmin}
                        handleModelOpenSupplier={handleModelOpenSupplier}
                    />
                ))
            ) : (
                <TableRow style={{ height: 57 }}>
                        <TableCell colSpan={4} style={{ textAlign: 'center',fontWeight:'bold',fontSize:'22px' }}>
                            <span>No supplier available with matching details</span>
                            {isAdmin && (
                                <IconButton
                                    component={Link}
                                    to={ '/AddSupplier/'+searchValue }
                                    className={classes.margin}
                                    style={{ marginLeft: '20px' }}
                                >
                                    <AddIcon className={classes.iconNext} />
                                </IconButton>
                            )}
                        </TableCell>
                </TableRow>
            )}
        </>
    );
          
    
};

SupplierListing.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SupplierListing);
