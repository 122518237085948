import React, { Component } from 'react';
import { Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../theme';
const styles = {

    paper: {
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        //  background: theme.palette.grey[50],
        background: "transparent",
    },


}



class PageTitles extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={0} className={classes.paper} square>
                <Typography variant="h1">
                    {this.props.titles}
                </Typography>
            </Paper>

        )
    }
}



export default withStyles(styles)(PageTitles);
