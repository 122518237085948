import {
    DownloadFailedSerialCsv, ActivateUserProfile, DownloadReport, DownloadOriginal,
    DownloadNotFoundSerialCsv, DownloadLogs, InstalledProductsWattageReport, DownloadParallelSerialCsv, DeleteBatchApi, RestoreBatchApi,
    RefreshInstallers, RefreshProducts, RefreshManufacturerProducts, RefreshReferenceData, RefreshCerts,FetchCertInfo,FetchAnalytics,DownloadInstallersCsv
} from './constants';
import AuthService from './components/AuthService';
import { reject } from 'lodash';
export const handleFailDownload = (onSuccess,onError, manufacturerId,name,format) => {
    const Auth = new AuthService(); 
    const token = Auth.getToken();
    const formatParam = format ? "format=" + format : "";
    const extension = format ? format : "csv";
    const downloadType = format == null || format === "csv" ? "text/csv" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const url = manufacturerId ? DownloadFailedSerialCsv + "?manufacturerId=" + manufacturerId + "&" + formatParam : DownloadFailedSerialCsv + "?" + formatParam;
    const output_filename = name ? name.replace(/ /g, "_") + "_failed." + extension : "failed." + extension;
    
            fetch(url, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${token}`, 'Accept': 'text/csv', 'Content-Type': 'application/json'
                    }
         
                })
            .then((response) => response.blob())
            .then((blob) => {
            // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob], { 'type': downloadType }));
            const link = document.createElement('a');
            link.href = url; 
                link.setAttribute('download', output_filename);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
                onSuccess({ loadingCsv: false });
        })
        .catch((error) => {
        
            
            onError({
                    errors: "some error occured",
                    loadingCsv: false
                });
            
        }); 

}

export const handleInstallersDownload = (filter,additionalFilter) => {
    const Auth = new AuthService();
    const token = Auth.getToken();
    const url = DownloadInstallersCsv + "?filter=" + filter +"&additionalFilter="+JSON.stringify(additionalFilter)   ;
    const output_filename = "installers.csv";

    return new Promise((resolve,reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'text/csv', 'Content-Type': 'application/json'
            }

        })
            .then((response) => response.blob())
            .then((blob) => {
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob], { 'type': "text/csv" }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', output_filename);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
                resolve({ loadingCsv: false });
            })
            .catch((error) => {

                reject({
                    errors: "some error occured",
                    loadingCsv: false
                });

            });

    })
    

}
export const handleNotFoundDownload = (onSuccess, onError, manufacturerId, name) => {
    const Auth = new AuthService();
    const token = Auth.getToken();
    const url = DownloadNotFoundSerialCsv + "?manufacturerId=" + manufacturerId ;
    const output_filename = name ? name.replace(/ /g, "_") + "_failed.csv" : "failed.csv";
    fetch(url, {
        method: 'GET',
        headers: {
            'authorization': `Bearer ${token}`, 'Accept': 'text/csv', 'Content-Type': 'application/json'
        }

    })
        .then((response) => response.blob())
        .then((blob) => {
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob], { 'type': "text/csv" }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', output_filename);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            onSuccess({ loadingCsv: false });
        })
        .catch((error) => {


            onError({
                errors: "some error occured",
                loadingCsv: false
            });

        });

}

export const handleParallelImportDownload = (onSuccess, onError, manufacturerId, name) => {
    
    const Auth = new AuthService();
    const token = Auth.getToken();
 
    let url = DownloadParallelSerialCsv;
    if (manufacturerId && name) {
        url = url + "?manufacturerId=" + manufacturerId + "&filter=" + name;
    }
    else if (manufacturerId) {
        url = url + "?manufacturerId=" + manufacturerId;
    }
    else if (name) {
        url = url + "?filter=" + name;
    }
    
    const output_filename = "parallel_comments_failed.csv";
    fetch(url, {
        method: 'GET',
        headers: {
            'authorization': `Bearer ${token}`, 'Accept': 'text/csv', 'Content-Type': 'application/json'
        }

    })
        .then((response) => response.blob())
        .then((blob) => {
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob], { 'type': "text/csv" }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', output_filename);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            onSuccess({ loadingCsv: false });
        })
        .catch((error) => {


            onError({
                errors: "some error occured",
                loadingCsv: false
            });

        });

}

export const handleDownloadReport = ( batchId, manufacturerId, filename, extension, contentType) => {
    const operation = new Promise((resolve, reject) => {
        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = DownloadReport + batchId + "&manufacturerId=" + manufacturerId;;
        const filenameWithoutExtension = filename.replace(/ /g, "_").replace(extension, '');
        const output_filename = filenameWithoutExtension + "_report" + extension;
        fetch(url, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': contentType, 'Content-Type': 'application/json'
            }

        })
            .then((response) =>
                response.blob()
            )
            .then((blob) => {
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob], { 'type': blob.type }));
                const link = document.createElement('a');
                link.href = url;
                const download_name = (blob.type === 'text/csv' ? output_filename.replace(".xlsx", ".csv") : output_filename);

                link.setAttribute('download', download_name);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
                resolve({ loadingCsv: false, fileTimePeriod: 'historic' });
            })
            .catch((error) => {


                reject({
                    errors: "some error occured",
                    loadingCsv: false,
                    fileTimePeriod: 'historic'
                });

            });
    });
    return operation;

}

export const refreshInstallers = () => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = RefreshInstallers ;

            fetch(url, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
                }

            }).then((response) => response.json())
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {

                reject({
                    status: false
                });

            });
    });
    return operation;

}
export const refreshManufacturerProducts = () => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = RefreshManufacturerProducts;

        fetch(url, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            }

        }).then((response) => response.json())
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {

                reject({
                    status: false
                });

            });
    });
    return operation;

}
export const refreshAppCerts = () => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = RefreshCerts;

        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            }

        }).then((response) => response.json())
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {

                reject({
                    status: false
                });

            });
    });
    return operation;

}
export const refreshReferenceData = () => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = RefreshReferenceData;

        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            }

        }).then((response) => response.json())
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {

                reject({
                    status: false
                });

            });
    });
    return operation;

}
export const fetchCertInfo = (filter) => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = FetchCertInfo + filter;

        fetch(url, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            }

        }).then((response) => response.json())
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {

                reject({
                    status: false
                });

            });
    });
    return operation;

}

export const fetchAnalyticsInfo = (from,to) => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = FetchAnalytics + "from=" + from + "&to=" + to;

        fetch(url, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            }

        }).then((response) =>
            response.blob()
        )
            .then((blob) => {
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob], { 'type': blob.type }));
                const link = document.createElement('a');
                link.href = url;
                const download_name = 'analytics.csv';

                link.setAttribute('download', download_name);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
                resolve({ loadingCsv: false, fileTimePeriod: download_name });
            })
            .catch((error) => {

                reject({
                    status: false
                });

            });
    });
    return operation;

}
export const refreshProducts = () => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = RefreshProducts;

        fetch(url, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            }

        }).then((response) => response.json())
            .then((json) => {
                resolve(json);
            })
            .catch((error) => {

                reject({
                    status: false
                });

            });
    });
    return operation;

}


export const handleDownloadOriginal = (batchId,manufacturerId, filename, extension, contentType) => {


    const operation = new Promise((resolve, reject) => {

        const Auth = new AuthService();
        const token = Auth.getToken();
        const url = DownloadOriginal + batchId + "&manufacturerId=" + manufacturerId;
        const output_filename = filename ? filename.replace(/ /g, "_") : "original" + extension;
        fetch(url, {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': contentType, 'Content-Type': 'application/json'
            }

        })
            .then((response) => response.blob())
            .then((blob) => {
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob], { 'type': contentType }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', output_filename);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
                resolve({ loadingCsv: false });
            })
            .catch((error) => {

                reject({
                    errors: "some error occured",
                    loadingCsv: false
                });

            });
    });
    return operation;

}

export const handleActivateDeactivate = (onSuccess, onError, manufacturerId, userId, active) => {
    const Auth = new AuthService();
    const token = Auth.getToken();
    const url = ActivateUserProfile;

    fetch(url, {
        method: 'POST',
        headers: {
            'authorization': `Bearer ${token}`,'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "ManufacturerId": manufacturerId, "UserId": userId, "Active": active})

    })
        .then((response) => response.json())
        .then((json) => {
            onSuccess(json);
        })
        .catch((error) => {


            onError({
                errors: "some error occured"
            });

        });

}

export const handleDeleteBatch = (uploadId) => {
    const Auth = new AuthService();
    const token = Auth.getToken();

    const operation = new Promise((resolve, reject) => {
        fetch(DeleteBatchApi, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "UploadId": uploadId })

        }).then((response) => {
            if (response.status === 400 || response.status === 200) {
                return response.json()
            }
            if (!response.ok) {
                throw new Error("Some error has occured, please try again");
            }

        })
            .then((json) => {
                if (json.status == false) {
                    reject({ status:false, message:json.message });
                }
                else {
                    resolve(json);
                }

            })
            .catch((error) => {
                reject({ status:false, message:error.message });
            });
    });
   
    return operation;
}

export const handleRestoreBatch = (uploadId) => {
    const Auth = new AuthService();
    const token = Auth.getToken();

    const operation = new Promise((resolve, reject) => {
        fetch(RestoreBatchApi, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "UploadId": uploadId })

        })
            .then((response) => {
                if (response.status === 400 || response.status === 200) {
                    return response.json()
                }
                if (!response.ok) {
                    throw new Error("Some error has occured, please try again");  
                }
                 
            })
            .then((json) => {
                if (json.status == false) {
                    reject({ status:false, message:json.message });
                }
                else {
                    resolve(json);
                }
            })
            .catch((error) => {
                reject({status:false,message:error.message});
            });
    });

    return operation;
}
export const handleVerificationDataDownload = (onSuccess, onError, batchId,type) => {
    const Auth = new AuthService();
    const token = Auth.getToken();
   
    const extension =  "xml";
    const downloadType = "application/xml";
    const url = DownloadLogs + batchId +"&type="+type;// manufacturerId ? DownloadFailedSerialCsv + "?manufacturerId=" + manufacturerId + "&" + formatParam : DownloadFailedSerialCsv + "?" + formatParam;
    const output_filename = batchId+ "." + extension;

    fetch(url, {
        method: 'GET',
        headers: {
            'authorization': `Bearer ${token}`, 'Accept': 'text/xml', 'Content-Type': 'application/xml'
        }

    })
        .then((response) => response.blob())
        .then((blob) => {
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob], { 'type': downloadType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', output_filename);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            onSuccess({ loadingXML: false });
        })
        .catch((error) => {


            onError({
                errors: "some error occured",
                loadingXML: false
            });

        });

}


export const downloadInstalledProductsWattageReportForModelAndState = (onSuccess, onError, params) => {
    const Auth = new AuthService();
    const token = Auth.getToken();
    const contentType = 'application/excel';
    const { timespan, monthYearName } = params;
    const url = InstalledProductsWattageReport + timespan;
    const output_filename = `Products_installed_${monthYearName}.xlsx`;
    const errorMessage = "Some error occurred while downloading product stats";

    fetch(url, {
        method: 'GET',
        headers: {
            'authorization': `Bearer ${token}`, 'Accept': contentType, 'Content-Type': 'application/json'
        }

    }).then((response) => {
        return response.status === 200 ? response.blob() : false;
    })
        .then((blob) => {
            if (!blob) {
                onError(errorMessage);
                return false;
            }
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob], { 'type': contentType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', output_filename);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            onSuccess(false);
        })
        .catch((error) => {
            onError(errorMessage);
        });

}