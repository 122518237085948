import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { default as React } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { DeleteProductLineApi, handleErrors, handleHttpException, ProductLineImagePath } from '../../constants';
import AuthService from '../AuthService';

import Customicon from '../../Customicon';
import theme from '../../theme';
const styles = {
    title: {
        textTransform: "uppercase"
    },
    space: {
        padding: 0,
    },
    titlebar: {
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    subHeader: {
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    alignMiddle: {
        verticalAlign: "middle",
        textTransform: "capitalize",
    },
    alignButton: {
        verticalAlign: "middle",
        width: "200px"
    },
    placeholder: {
        //position: "absolute",
        //top: "28px",
        //left:"90px",
        display: "inline-block",
        verticalAlign: "middle",
        marginLeft: "5px",
    },
    notAvataravailable: {
        borderRadius: 0,
        width: "160px", height: "auto",
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(1) * 0.5,
        margin: 0,
    },
    icon: {
        fontSize: 14
    },
    tableRow: {
        height: "auto",
        [theme.breakpoints.down('sm')]: {
            display:"block",
            borderColor: theme.palette.common.borderColor,
            border: "1px solid",
            marginBottom: theme.spacing(1),
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    row: {
      
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    card: {
        boxShadow: "none",
        border: "1px dotted",
        borderColor: theme.palette.grey[300],
        padding: theme.spacing(1) * 0.5,
        borderRadius: theme.shape.borderRadius,
        width: 82,
        margin: 0,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            float:"right",
        },

    },
    iconButton: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 0,
        '&:hover': {
            background: "transparent",
        },

    },
    paper: {
        textAlign: 'center',
        color: theme.palette.grey[800],
        background: "transparent",
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderColor: theme.palette.grey[400],
        fontSize: 14,
    },
    paper2: {
        textAlign: 'center',
        color: theme.palette.grey[800],
        background: "transparent",
        borderRightWidth: "1px",
        borderColor: theme.palette.grey[400],
        fontSize: 14,
    },
    input: {
        display: 'none',
    },
    button: {
        minwidth: "82px",
    },
    avatar: {
        borderRadius: 0,
        width: 42,
        height: "auto",
        color: 'tranparent'
    },
    avatar1: {
        borderRadius: 2,
        fontSize: " 12px",
        padding: 0,
        width: "24px",
        height: "26px",
        marginLeft: "8px",
        backgroundColor: theme.palette.primary.main
    },
    button1: {
        padding: "6px 8px",
    },
    modalBox: {
        position: 'absolute',
        top: '45%',
        left: '45%',
        transform: 'translate(-45%, -45%)',
        width: 430,
        background: '#ffffff',
        border: '2px solid #000',
        padding: '25px 20px'

    },

    modalButtonContainer: {
        textAlign: 'right',
        marginBottom : '7px',
        '& Button': {
            margin: '0 10px',
            textAlign: 'center',
            minWidth: '80px',
            marginTop: '22px'
        }

    },
    modalResponse: {
        color: 'red',
        fontSize: '13px',
        position: 'absolute',
        overflow: 'hidden',
        width: 400,
        height: 20,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
};


const ProTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.common.borderColor,
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            display: "block",
            textAlign: "right",
            minHeight: "73px",         
            '&:last-child': {
                paddingRight:16,
            },
            '&:before': {
                content: "attr(data-label)",
                float: "left",
                fontWeight: "bold",
                textTransform: "uppercase",
                lineHeight: "24px",
                marginRight: "16px",
            },
        },
    },
}))(TableCell);


class ProductLineRow extends React.Component {

    constructor() {
        super();
        this.getDynamicCell = this.getDynamicCell.bind(this);
        this.state = {
            loading: false,
            query: 'idle',
            deleting: false,
            openModal: false,
            errorMessage: null
        };
        
        this.handleClickQuery = this.handleClickQuery.bind(this);
        this.deleteProductline = this.deleteProductline.bind(this);
        
        this.Auth = new AuthService();
        

    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleClickLoading = () => {
        this.setState(state => ({
            loading: !state.loading,
        }));
    };

    handleClickQuery(id, alreadyassigned) {

        this.props.handleAssignment(id, alreadyassigned);
        setTimeout(() => { this.props.refresh() }, 1500)
        clearTimeout(this.timer);

        if (this.state.query !== 'idle') {
            this.setState({
                query: 'idle',
            });
            return;
        }

        this.setState({
            query: 'progress',
        });
        this.timer = setTimeout(() => {
            this.setState({
                query: 'success',
            });
        }, 2000);
    };

    deleteProductline() {
        
        this.setState({ deleting: true, error: false, errorMessage: null });
        const token = this.Auth.getToken();
        const { data: { productLineId } } = this.props;
        fetch(DeleteProductLineApi, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "ProductLineId": productLineId
            })
        }).then(res => {
            return handleErrors(res);

        }).then(res => {

            if (res.status === true) {
                const { match: { path } } = this.props;
                if (path.toLowerCase().includes('assignproductline')) {
                    this.props.history.push('/productline');
                }
                else {
                    const { refetchProductLinesCallback } = this.props;
                    refetchProductLinesCallback && refetchProductLinesCallback();
                }
                this.handleModalClose();
                this.setState({ deleting: false });
            }

        }).catch(err => {
            handleHttpException(err).then(result => {
                this.setState({ deleting: false, error: true, errorMessage: result });
            });
        });
    }

    handleModalClose = () => { this.setState({ "openModal": false, error: false, errorMessage: null }) };
    handleModalOpen = () => { this.setState({ "openModal": true })};

    getDynamicCell(type, classes, data) {


        const alreadyassigned = this.props.selectedProductLineId != null && data.productLineId === this.props.selectedProductLineId;
        switch (type) {

            case 1: {
                return (<ProTableCell data-label="	Assigned Models">
                    {/*  <Tooltip title={<span className={classes.title}>click to edit models</span>}
                        placement="bottom" classes={{ tooltip: classes.lightTooltip }} interactive>                    
                    <Chip label={"View"} className={classes.chip} avatar={<Avatar> {data.totalProduct}</Avatar>} onClick={() => {
                                this.props.history.push({
                                    pathname: `/AssignProductLine/${data.productLineId}`
                                })
                            }}  variant="outlined" >
                                Models    
                            </Chip> 

                    
                    </Tooltip>*/}

                    <Button variant="outlined" classes={{ root: classes.button1 }} onClick={() => {
                        this.props.history.push({
                            pathname: `/AssignProductLine/${data.productLineId}`
                        })
                    }} >
                        View  <Avatar className={classes.avatar1} color="primary">{data.totalProduct}    </Avatar>

                    </Button>
                </ProTableCell>)
            }


            case 2: {
                return (<ProTableCell style={{ verticalAlign: 'middle' }}>
                    {/*      <Chip label={"Total Assigned Models :" + data.totalProduct} className={classes.chip} variant="outlined" /> */}
                    <Button variant="outlined" classes={{ root: classes.button }} disabled>
                        {"Total Assigned Models: " + data.totalProduct}
                    </Button>
                </ProTableCell>)
            }
            case 3: {
                return (<ProTableCell className={classes.alignButton}>
                    <Button size="medium" variant="contained" color={alreadyassigned ? "default" : "primary"} onClick={(e) => this.handleClickQuery(data.productLineId, alreadyassigned)}>
                        {alreadyassigned ? "remove" : "assign"}
                    </Button>

                </ProTableCell>)
            }
            default: {
                return (<ProTableCell>
                    <Tooltip title={<span className={classes.title}>click to edit models</span>} placement="bottom" classes={{ tooltip: classes.lightTooltip }} interactive>

                        <Chip label={"View Models "} className={classes.chip} avatar={<Avatar> {data.totalProduct}</Avatar>} onClick={() => {
                            this.props.history.push({
                                pathname: `/AssignProductLine/${data.productLineId}`
                            })
                        }} variant="outlined" >


                        </Chip>

                    </Tooltip>
                </ProTableCell>)
            }
        }

    }
    render() {
        const { rowType, isDialog } = this.props;
        const data = this.props.data;
        const { deleting, openModal, errorMessage }= this.state;
        const warranty = data.productWarranty;
        const logo = data.logo;
        const componentInYears = warranty && warranty.componentInYears ? warranty.componentInYears : '';
        const componentWarrantyLink = warranty && warranty.componentWarrantyLink ? warranty.componentWarrantyLink : '';
        const performanceInYears = warranty && warranty.performanceInYears ? warranty.performanceInYears : '';
        const performanceWarrantyLink = warranty && warranty.performanceWarrantyLink ? warranty.performanceWarrantyLink : '';
        const { classes } = this.props;
        const lastCell = this.getDynamicCell(rowType, classes, data);
        const imageUrl = logo != null ? ProductLineImagePath + data.logo : require('../../assets/images/noimage.png');
        const editCell = isDialog ? null : <ProTableCell colSpan={2} data-label="Assigned Models">
            <div>
            <Button variant="contained" size="medium" component={Link} to={{ pathname: "/EditProductLine/" + data.productLineId }} color="primary" className={classes.button} style={{ 'margin-right': 35 }}>
                edit
            </Button>
            <Button variant="contained" size="medium" onClick={this.handleModalOpen} disabled={deleting} color="primary" className={classes.button} >
                delete
            </Button></div>
        </ProTableCell>;
        return (
            <TableRow className={classes.tableRow}>
                <ProTableCell className={classes.alignMiddle} data-label="Product Line">
                    <CardHeader
                        avatar={
                            <Avatar aria-label="Recipe" src={imageUrl} className={classes.avatar}>
                                R
                             </Avatar>
                        }
                        title={data.lineName}
                        subheader={data.updatedOn}
                        classes={{ title: classes.titlebar, subheader: classes.subHeader, root: classes.space }}
                    />
                </ProTableCell>
                <ProTableCell data-label="Component Warranty">
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={classes.card}

                    >
                        <Grid item xs={6}>
                            <Paper square className={classes.paper} elevation={0}>{componentInYears}</Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton color="primary" disableRipple target="_blank" href={componentWarrantyLink} download={componentWarrantyLink} aria-label="View" className={classes.iconButton} component="a" rel="noopener noreferrer">
                                <Customicon name="pdf" width={18} fill={theme.palette.common.red} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </ProTableCell>
                <ProTableCell data-label="Performance Warranty">
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={classes.card}

                    >
                        <Grid item xs={6}>
                            <Paper square className={classes.paper} elevation={0}>{performanceInYears}</Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton color="primary" disableRipple target="_blank" href={performanceWarrantyLink} download={performanceWarrantyLink} aria-label="View" className={classes.iconButton} component="a" rel="noopener noreferrer">
                                <Customicon name="pdf" width={18} fill={theme.palette.common.red} />
                            </IconButton>
                        </Grid>
                    </Grid>

                </ProTableCell>
                {lastCell}

                {editCell}

                <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={openModal} onClose={this.handleClose} >
                    <div className={classes.modalBox}>
                        <Typography variant='h3' style={{ marginBottom: 10 }}>
                            Are you sure you want to delete this product line : {data.lineName} ?
                        </Typography>
                        <Typography variant="body2">
                            All assigned products will be unassigned from this product line and product line will be deleted.
                        </Typography>
                        <div className={classes.modalButtonContainer}>
                            <Button variant="contained" size="small" onClick={this.deleteProductline} disabled={deleting} color="primary" >
                                OK
                            </Button>
                            <Button variant="contained" size="small" onClick={this.handleModalClose} disabled={deleting} color="primary" >
                                cancel
                            </Button>
                        </div>
                        {errorMessage && <div className={classes.modalResponse}>{errorMessage}</div>}
                    </div>
                </Modal>

            </TableRow>
        );

        




    }
}
export default withStyles(styles)(withRouter(ProductLineRow));


