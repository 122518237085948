import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import { ResolveModeConstant } from '../../constants';
import Customicon from '../../Customicon';
import { handleDownloadReport } from '../../Helper';
import UserProfile from '../auth/UserProfile';
const styles = theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
    },
    Grid: {
        borderBottom: '1px solid #e8e8e8',
    },
    button: {
        marginBottom: theme.spacing(1) * 4,
    },
    download: {
        backgroundColor:blue[400],
        marginBottom: theme.spacing(1) * 4,
    },
    more: {
        marginBottom: theme.spacing(1) * 4,
        backgroundColor: theme.palette.grey[100],
    },
    gutters: {
        paddingTop: theme.spacing(2),
        // marginBottom: theme.spacing(2),
        textTransform: 'inherit',
        color: grey[800],
     
    },
    paper: {
        padding: theme.spacing(2),
        //marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(2),
       
        textAlign: "center",
        margin: 'auto',
        maxWidth: 500,
        marginTop:'100px'
        //background: 'linear-gradient(to top, #ffefba, #ffffff)',
    },
    customicon: {
        marginTop: theme.spacing(2),
    },
    widthMain: {
        width: 768,
        overflowX: "auto"
    },
    serialList: {
        //paddingLeft: theme.spacing(2),
        //paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: "center",
        alignItems:"center"
        //   minHeight:"600px",
    },
    [theme.breakpoints.down('xs')]: {
        widthMain: {
            width: 360,
        },
        serialList: {
            width: 768,
        },
    },
    spanFailed: {
        color: theme.palette.grey[500]
    },
    serialFab: {
        borderColor: theme.palette.common.red,
        border: "1px solid",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        borderRadius: 0,
        background: theme.palette.common.white,
        boxShadow: "none",
        transition: "200ms ease",
        '&:hover': {
            background: theme.palette.common.red,
            color: theme.palette.common.white,
        },
    },
    serialFabPass: {
        borderColor: "#006400",
        border: "1px solid",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        borderRadius: 0,
        background: theme.palette.common.white,
        boxShadow: "none",
        transition: "200ms ease",
        '&:hover': {
            background: "#006400",
            color: theme.palette.common.white,
        },
    },
    tabsRoot: {
        color: theme.palette.grey[700],
        marginTop: theme.spacing(1),
    },
    //tabsIndicator: {
    //    backgroundColor: 'transparent',
    //},
    tabRoot: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.grey[100],
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        textTransform: 'capitalize',
        fontSize: "18px",
        textAlign: "center",
        color: theme.palette.grey[800],
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(1),

        //fontWeight: theme.typography.fontWeightRegular,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        //letterSpacing:"1px",
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[900],


        },
        '&$tabSelected': {
            backgroundColor: theme.palette.grey[100],
            fontFamily: 'NHaasGroteskDSPro-65Md',
            color: theme.palette.grey[900],
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            borderColor: theme.palette.grey[300],
        },
        '&:focus': {
            color: theme.palette.grey[900],
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing(1) * 3,
    },
    grow: {
        flexGrow: 1,
    },
    padding: {
        padding: 0,
        minHeight: "auto",
    },
    color1: {
        color: red[600],
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    color3: {
        color: green[600],
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    color2: {
        color: orange[600],
    },
    heightD: {
        minHeight: "auto",
    },
    center: {
        textAlign: 'center',
    },
    btnRed: {

        marginTop: 26,
        backgroundColor: 'lightgreen',
        color: theme.palette.common.white,
        transition: "200ms ease",
        '&:hover': {
            backgroundColor: theme.palette.common.green,
            color: theme.palette.common.white,
        }
    },
    gutterBottom: {
        marginBottom: theme.spacing(2),
    },
    spaceX: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1) * 1,
        marginRight: theme.spacing(1) * 1,
        marginBottom: theme.spacing(1) * 1,
        minWidth:162,
    }, 
    result: {
        padding: theme.spacing(2),
    },
    saved: {
        display: "inline-block",
        margin: theme.spacing(1),
        fontSize: "16px",
    },
    resLabel: {       
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        marginRight: theme.spacing(1) / 2,
        color: grey[500],
    },
    grid: {
        width:'60%'
    }

});


function TabContainer(props) {

    return (
        <Typography component="div" disableTypography>
            {props.children}
        </Typography>

    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};


class UploadErrorContainer extends React.Component {


    constructor(props) {
        super(props);
        this.changeTab = this.changeTab.bind(this);
        this.state = {
            tabState: { tab1: true, tab2: false, tab3: false }, value: 'SerialFailed', shouldRenderFailedAgain: false, shouldRenderInstalledAgain: false, open: false, fullWidth: true,
            maxWidth: 'sm',
        }
        // this.updateTabCount = this.updateTabCount.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

    }

    handleChange = (event, value) => {
        this.changeTab(value);
    };

    handleSearch(data) {
        let nextState = { filter: data, shouldRenderFailedAgain: false, shouldRenderAvailableAgain: false, shouldRenderInstalledAgain: false };
        if (this.state.tabState.tab1 === true) {
            nextState.shouldRenderFailedAgain = true;
            this.setState(nextState);
        }
        else if (this.state.tabState.tab2 === true) {
            nextState.shouldRenderInstalledAgain = true;
            this.setState(nextState);
        }

    }

    changeTab(path) {

        var vartabState = { tab1: false, tab2: false, tab3: false };
        switch (path) {
            case 'SerialFailed': { vartabState.tab1 = true; break; }
            case 'SerialDuplicate': { vartabState.tab2 = true; break; }

            default: { vartabState.tab1 = true; }
        }

        this.setState({
            value: path,
            tabState: vartabState, shouldRenderFailedAgain: false, shouldRenderDuplicateAgain: false
        });
    }



    toggleDrawer = (status) => () => {
        this.props.onToggleErrorContainer(status);
    };
    download = (batchId, fileName) => {
        
        const extension = "."+fileName.split('.').pop();

        const contentType = extension.toLowerCase() === ".csv" ? "text/csv" : "application/excel";
        let parentThis = this;

        handleDownloadReport(batchId, UserProfile.getManufacturerId(), fileName, extension, contentType)
            .then(() => parentThis.setState({ loadingCsv: false, fileTimePeriod: 'historic' }))
            .catch(() => parentThis.setState({
                errors: "some error occured",
                loadingCsv: false,
                fileTimePeriod: 'historic'
            }));

    }

    renderTab(props) {
        const tabList = props.tabs.map((tab, index) =>
            (
                <li key={index}>
                    <a href={tab.path}>
                        <h3>{tab.tabname}</h3>
                        <span className={tab.badgeIcon}>{tab.number}</span>
                        <p>{tab.modelname}</p>
                    </a>
                </li>
            ));

        return (
            <ul> {tabList} </ul>
        );
    }

    render() {
        
        
        const {  classes } = this.props;
        const {  failed, batchId,  pass, mode, fileName } = this.props.location.state;
        const message = mode !== undefined && mode === ResolveModeConstant ? "Upload complete" : "Upload complete";
        

     

     
        var downloadClasses = classNames({
            [classes.button]: true,
            [classes.download]: true
        });
        var moreClasses = classNames({
            [classes.button]: true,
            [classes.more]: true
        });
        
        return (<div className={classes.serialList}>
            <Grid alignContent="center"
                container
                direction="row"
                justify="center"
                

            >
                <Grid item xs={12} justify="center"
                    alignItems="center">
                    <Paper className={classes.paper} elevation={0} >
                        <Customicon name="flash" width={42} className={classes.customicon} fill="#9e9e9e" />
                        <Typography variant="h2" className={classes.gutters}>{message}</Typography>
                        <div className={classes.result}>
                            <div className={classes.saved}>
                                <span className={classes.resLabel}>Uploaded:</span><span className={classNames({ [classes.color3]: true })}>{pass}</span>
                           </div>
                            <div className={classes.saved}>
                                <span className={classes.resLabel}>Errors:</span><span className={classNames({ [classes.color1]: true })} >{failed}</span>

                            </div>
                          </div>
                        <Divider />
                        <Button variant="contained" size="large" component={Link} to="/Flash" color="secondary" classNames={moreClasses} classes={{ root: classes.spaceX }}>
                            Upload more
                         </Button>
                        <Button variant="contained" size="large" component={"a"} onClick={() => { this.download(batchId, fileName); }} color="primary" classNames={downloadClasses} classes={{ root: classes.spaceX }}>
                            Download Report
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </div>);
    }
}

UploadErrorContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UploadErrorContainer);