import React from 'react';
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

const AvailableTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 16,
        verticalAlign: "middle",
        padding: theme.spacing(2),
        height: "auto", 
        whiteSpace: "nowrap",
    },

}))(TableCell);

class AvailableSerialHeader extends React.Component {


    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_row');

    }

    render() {
        const { classes } = this.props;
        return (
            <TableRow key={this._id} className={classes.tableRow}>
                <AvailableTableCell>Serial Number</AvailableTableCell >
                <AvailableTableCell>Uploaded On</AvailableTableCell>
                <AvailableTableCell >Supplier</AvailableTableCell>
                <AvailableTableCell>Flash Data</AvailableTableCell>         
            </TableRow>
        );




    }
}

export default withStyles(styles)(AvailableSerialHeader);
