import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { ForgotApi } from '../../constants';
import theme from '../../theme';
import InstallationVideo from './installationVideo';
import FormFooter from './FormFooter';
import { Helmet } from "react-helmet";
const styles = theme => ({
    root: {
        flexGrow: 1,
        //height: "100%",

    },
    formControl: {
        width: "100%"
    },
    Paper: {
        padding: theme.spacing(1) * 1,
        background: theme.palette.common.logIn,
        marginBottom: theme.spacing(2),
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(1) * 8,
        paddingRight: theme.spacing(1) * 8,
        width: 275,
        height: 50,
        '&:disabled': {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
        '&:hover': {
            '&:disabled': {
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    formContent: {
        minHeight: "500px",
        //display: "inline-flex",
        //flexWrap: "wrap",
        //justifyContent: "center",
        //alignItems: "center",
        height: "calc(100vh - 130px)",
    },
    successMessage: {
        color: green[500],
    },
    errorMessage: {
        color: theme.palette.common.red,
    },
    logText: {
        marginBottom: "30px",
        textAlign: "center",
        textTransform: 'inherit',
        fontWeight: 'normal',
    },
    setPass: {
        textAlign: "center"
    },
    instructions: {
        lineHeight:"21px",
    },
    center: {
        textAlign: "center",
    },
    headFrame: {
        width: "100%",
        height: '180px',
    },
    formOuterFrame: {
        width: "100%",
        height: '300px',
    },
});

const LeftWrapper = styled.div`
    float: left;
    width:25%;
    position: relative;
    height: 100%;
    background-color: #fff;
    display:table;
    padding:0 60px 0 60px;
    @media(max-width:1600px){
      width:30%;
    }
    @media(max-width:1400px){
      width:40%;
    }
    @media(max-width:1280px){
      padding:0 40px 0 40px;
    }
    @media(max-width:992px){
      width:100%;
      float:none;
    }
    @media(max-width:767px){
      padding:0 30px 0 30px;
    }
`;

const RightWrapper = styled.div`
    float: left;
    width: 75%;
    position: relative;  
    display:block;
    @media(max-width:1600px){
      width:70%;
    }
    @media(max-width:1400px){
      width:60%;
    }
    @media(max-width:992px){
     display:none;
    }
    &::before {
        content: "";
        position: fixed;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: -1;
    }
    .header {
        position: fixed;
        top: 0;
        bottom: 0;
        padding: 50px 50px 50px 50px;
        h1 {
            color: #fff;
            margin-bottom: 10px;
            font-weight: normal;
        }

        p {
            color: #fff;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .footer {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 50px 50px 30px 50px;

        .address-box {
            padding: 30px 0 0 0;
            border-top: 1px solid rgba(255, 255, 255, 0.3);

            h3 {
                color: #fff;
                margin-bottom: 5px;
            }

            p {
                color: #fff;
                font-size: $font-size-base;
            }
        }
    }
`;
const Logo = styled.div`
    padding: 30px 0 30px 0;
    text-align: center;
`;
//const Footer = styled.footer`
//    width: 100%;
//    float: left;
//    height: 100px;
//    margin: 30px 0 0 0;
//    padding: 20px 0 0;
//    border-top: 1px solid #e5e5e5;
//    background: none;
//    ul {

//        text-align: center;
//    }
//    li {
//        display: inline-block;
//        margin: 0 15px 0 15px;
//        a {
//            font-family: 'NHaasGroteskDSPro-55Rg';
//            font-size: 15px;
//            color:#0d4add;
//            text-decoration: none;
//            &:hover{
//                color: #0d4add;
//                text-decoration: underline;
//            }
//        }
//    }
//    p{
//        text-align: center;
//        font-size: 14px;
//        color:#333;
//        font-family: 'NHaasGroteskDSPro-55Rg';
//        padding: 15px 0 10px 0;
//    }   
//`;

class Forgot extends React.Component {

    constructor() {
        super();
        this.state = {
            resolved: false, Email: '', failed: false, isloading:false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        //  this.notifyMessage = this.notifyMessage.bind(this);
        this.forgotForm = this.forgotForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    handleSubmit = () => {
        this.setState({ isloading:true})
        fetch(ForgotApi, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.Email)
        }).then(res => {
            return res.json();
        }).then(res => {

            this.setState({ failed: !res.status, resolved: res.status, isloading:false });
        })
            .catch(err => {
                this.setState({ failed: true, resolved: false, isloading:false });

            });
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    forgotForm(classes, requiredMessage, Email) {
        const isloading = this.state.isloading;
        return <ValidatorForm ref="form" instantValidate={true} onError={errors => console.log(errors)}
            onSubmit={this.handleSubmit}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SPV | Forgot Password</title>
            </Helmet>
            <Grid item xs={12}>
                <div className="loginForm">
                    <FormControl className={classes.formControl} error>
                        <TextValidator
                            fullWidth={true}
                            autoComplete='off'
                            type="email"
                            id="Email"
                            name="Email"
                            label="Email*"
                            placeholder="example@domain.com"
                            margin="normal"
                            variant="outlined"
                            value={Email}
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            validators={['required', 'isEmail']}
                            errorMessages={[requiredMessage, 'Please enter valid email address']}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                        />
                    </FormControl>
                </div>
            </Grid>
            <Grid item xs={12} className={classes.setPass}>     
                <Button className={classes.button} variant="contained" color="primary" type="submit" alignitem='center'>
                    {isloading ?
                        <CircularProgress color={theme.palette.common.white} size={19} style={styles.root} /> :
                        <span>Send Reset Instructions</span>
                    }
                </Button>
            </Grid>
         
        </ValidatorForm>
    }


    render() {
        
        const { classes } = this.props;
        const { resolved, failed, Email } = this.state;
        const requiredMessage = "This field is required";
        const title = "Forgot your password?";
        const message = "Provide your email address and we will send you the instructions to reset your password.";
        //const output = failed ? this.notifyMessage(classes, "Account reset failed", "We are unable to find account associated with the provided email, please try with correct email address") :
        //    (resolved ? this.notifyMessage(classes, "Reset email succesfully sent", "Password rest link has been emailed to your email address.") : this.forgotForm(classes, requiredMessage, Email));
        const output = this.forgotForm(classes, requiredMessage, Email);
        const output_message = failed ? <Paper align="center" square={true} className={classes.Paper} elevation={0}>Account reset failed, We are unable to find account associated with the provided email, please try with correct email address</Paper> : (resolved) ? <Paper align="center" square={true} className={classes.Paper} elevation={0}>Password reset link has been sent to your email address.</Paper> : null;
        const output_result_class = failed ? classes.errorMessage : resolved ? classes.successMessage : classes.root;
        //var links = [
        //    { name: 'Terms of use', path: '/legal' },
        //    { name: 'Privacy', path: '/legal' },
        //    { name: 'About', path: '/legal' }
        //];
        //const listItems = links.map((link, index) =>
        //    <li key={index}>
        //        <Link component={RouterLink} title={link.name} to={link.path}>{link.name}</Link>
        //    </li>
        //);
        return (<div className={classes.root}>
            <LeftWrapper>
                <Grid container direction="row" alignItems="center" justify="center" className={classes.formContent}>
                    <Grid item xs={12}>
                        <Grid item className={classes.headFrame}>
                            <Logo><img src={require("../../assets/images/solar-logo.svg")} width="250" alt="Formbay Logo" /></Logo>
                            <Typography variant="h1" className={classes.logText}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.formOuterFrame}>
                            <Typography variant="subtitle1" className={classes.instructions} gutterBottom>
                                {message}
                            </Typography>
                            {output}
                            <Typography className={output_result_class}>
                                {output_message}
                            </Typography>
                            <Grid item xs={12} className={classes.center}>
                                Just Remembered?&nbsp;<Link component={RouterLink} to="/login" title="Log In">Log In</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <FormFooter />
            </LeftWrapper>
            <Hidden>
                <InstallationVideo /> 
            </Hidden>
            <RightWrapper>
                <div className="header">
                    <h1>Solar Panel Validation (Formbay)</h1>
                    <p>The Solar Panel Validation Project is intending to establish a voluntary mechanism that allows businesses in the supply chain to validate the authenticity of solar panels.</p>
                </div>
                <div className="footer">
                    <div className="address-box">
                        <h3>Formbay Pty. Ltd.</h3>
                        <p>Level 1, 222 Clarence Street , NSW 2000</p>
                    </div>
                </div>
            </RightWrapper>
        </div>
           
        );
    }
}

//export default Forgot;
Forgot.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Forgot);