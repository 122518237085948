import { Avatar, Button, Grid, TableCell, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LocationIcon from '@material-ui/icons/LocationOnTwoTone';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { setTimeout } from 'timers';
import theme from '../../../theme';
import { SerialImagePath } from '../../../constants';



const styles = {
    paperBox: {
        boxShadow: "none",
        border: "1px solid #ddd",
    },
    serialToggle: {
       // width: 250,
    },
    seialNum: {
        color: "#333",
        padding: "0",
        fontSize: "18px",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: "transparent",
        },
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    avatar: {
        borderRadius: 0,
        width: 42,
        height: 42,
        color: 'tranparent',
        marginRight: '10px'
    },
    avatar2: {
        borderRadius: 0,
        width: 42,
        height: 'auto',
        color: 'tranparent'
    },
    Leftmargin: {
        marginRight: theme.spacing(1) * 6,
    },
    leftIcon: {
        //   marginRight: theme.spacing(1) * 0.5,
    },
    iconSmall: {
        fontSize: 20,

    },
    linkBtn: {
        padding: 0,
        transition: "200ms ease",
        justifyContent: "left",
        '&:hover': {
            background: "transparent",
            textDecoration: "underline",
        }
    },
    button: {
        marginLeft: theme.spacing(1) * 0.5,
        padding:0,
        '&:hover': {
            background: "transparent",
           // color: theme.palette.primary.contrastText,            
        },
    },
    action: {
        width: 140,
        color: "#4e4e4e",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    view: {
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    marginBtm: {
        marginBottom: theme.spacing(1) / 2
    },
};

const SerialTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
        '&:last-child': {
            paddingRight: 16,
        },
        [theme.breakpoints.down('sm')]: {
       
            display: "block",
            textAlign: "right",
            '&:before': {
                content: "attr(data-label)",
                float: "left",
                fontWeight: "bold",
                textTransform: " uppercase",
                lineHeight: "24px",
                marginRight: "16px",
            },
        },
    },
}))(TableCell);

class RowParallel extends React.Component {
    constructor(props) {
        super(props);
        this._id = _.uniqueId('s_failed');
        this.state = {
            copySuccess: '', isSwitchOn: false,
            open: false, }
        this.copyToClipboard = this.copyToClipboard.bind(this);

    }
    copyToClipboard = (text) => {
      //  this.textArea.select();
       // document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
      //  e.target.focus();
        navigator.clipboard.writeText(text);
        this.setState({ copySuccess: 'Copied!' });
        setTimeout(() => { this.setState({ copySuccess: '' }); }, 3000);
    };

    fetchAndShowSerialImages = () => {
        const { serial: { failedSerial: serialNumber, productSerialId, manufacturerId: manufacturerIdForSuperAdmin }, showSerialImagesCallback } = this.props;
        showSerialImagesCallback(serialNumber, productSerialId, manufacturerIdForSuperAdmin);
    }

    handleDialogOpen = () => {
        this.setState({ open: !this.state.open });
    };

    render() {

        const { classes, serial} = this.props;
        const requestType = (serial.requesterType === 1) ? "Installation" : ((serial.requesterType === 2) ? "Product" : "Serial");

        //const fallbackImage = require("../../../assets/images/solar-panel.jpg");
        const serialImage = serial.image ? SerialImagePath + serial.image : null;
         
        return (
            <TableRow className={classes.row}>
                <SerialTableCell data-label="Serial Number">
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={16}
                        className={classes.serialToggle}
                    >
                        <Grid item>
                            <Avatar onClick={serial.image && this.fetchAndShowSerialImages} alt="Serial image" src={serialImage} className={classes.avatar} style={serial.image && { cursor: "pointer" }}>
                                NA
                            </Avatar>
                        </Grid>
                        <Grid item>                            
                            <Typography variant="h4">
                                {serial.failedSerial}
                                <IconButton className={classes.button} aria-label="copy" color="primary" onClick={() => { this.copyToClipboard(serial.failedSerial) }}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Typography>                         
                            {this.state.copySuccess}
                            <br />
                            Comment:{serial.parralelImportComment}
                        </Grid>
                      
                    </Grid>
                </SerialTableCell>
                < SerialTableCell >
                   

                    <div>
                        Brand:{serial.requestedBrand}
                    </div>
                    <div>
                        Model:{serial.requestedModel}
                    </div>

                    <div> Time: {serial.requestedTime} </div>
                </SerialTableCell>
           
                <SerialTableCell data-label="Request Type">
                    <div>
                    <Typography variant="subtitle2">
                        Requester Type: {requestType} 
                        </Typography>
                    </div>
                    <div>
                    <Typography variant="subtitle2">
                        Requester Name: {serial.requesterName}
                        </Typography>
                    </div>
                    <div>
                       Requester App Name: {serial.requesterAppName}
                    </div>

                    {serial.longitude &&
                        <Button color='primary' variant='outlined' size="small" className={classNames(classes.view)} rel="noopener noreferrer" href={`http://www.google.com/maps/place/${serial.latitude},${serial.longitude}`} target="_blank">
                            <LocationIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                            View
                        </Button>
                    }
                    {!serial.longitude &&
                        ("Location Not Available")
                    }
                </SerialTableCell>

                <SerialTableCell data-label="Request Type">
                </SerialTableCell>
                
               
                
                
            </TableRow>
        );
    }
}

export default withStyles(styles)(RowParallel);
