import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
const styles = theme => ({
    root: {
        flexGrow: 1,
        position: "relative",
    },
  
    OrderList: {
        paddingLeft: theme.spacing(2),
        paddingtop: theme.spacing(1) * 0.5,
        paddingbottom: theme.spacing(1) * 0.5,
    },
    heading: {
        fontSize: "22px",
        marginBottom: "18px",
    },
    toolbar: theme.mixins.toolbar,
});

const Paragraph = styled.p`
        line-height:21px;
        padding:4px;
        margin-bottom:10px;
        color:#4e4e4e;
        font-size:16px;
`
const Subheading = styled.h3`
        line-height:21px;
        padding:4px;
        margin-bottom:10px;
        color:#4e4e4e;
        font-size:18px;
`
class About extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>            
  
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">    
                    <Grid item lg={12}>
                <Typography component="h3" variant="h3" gutterBottom className={classes.heading}>
                               About
                            </Typography>
                            <Paragraph>
                                 Formbay is a software and technology company based out of Sydney's CBD. We are also one of the
                                  largest Small-scale Technology Certificate (STC) agents in the country with over 7 years'
                                  experience in the market.
                            </Paragraph>
                            <Paragraph>
                                 Since inception, Formbay's mission has been to eliminate traditional paperwork through the
                                 development of software and app technology to allow for the digitisation of paperwork and the
                                 creation of digital workflows for the ease and convenience of our users.
                            </Paragraph>
                            <Paragraph>
                                      Our most recent endeavours include working with the Clean Energy Regulator in the creation of
                  the Solar Panel Validation Initiative which was officially launched in October 2018 but has
                  been in operation since September 2017 in a pilot state.
                            </Paragraph>
                            <Paragraph>
                                     After years of innovation, including recent work on Artificial Intelligence and Image
                     Recognition technology, we are once again looking to the horizon in terms of what we can do to
                     continue to eliminate paper forms and make user's lives easier.
                            </Paragraph>
                            <Paragraph>
                               It is with this goal in mind that we submit this proposal for Digital Integration between the
                                       Clean Energy Council and Formbay for the benefit of our mutual users.
                            </Paragraph>
                            <Subheading>
                               Memberships and Certifications
                            </Subheading>
                            <Grid container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={16}>
                            <Grid item xs={12}>
                                    <img src={require("../assets/images/member-logo1.jpg")} alt="Member Logo" />
                                </Grid>
                            <Grid item xs={12}>                                   
                                    <img src={require("../assets/images/member-logo2.jpg")} alt="Member Logo" />                     
                                </Grid>
                            <Grid item xs={12}>
                                    <img src={require("../assets/images/member-logo3.jpg")} alt="Member Logo" />
                                </Grid>
                                </Grid>
                     
                    </Grid>
                </Grid>
           
            </div>
        );
    }
}

export default withStyles(styles)(About);
