export const DashboardHeaderTitle = "Dashboard";
export const NotificationHeaderTitle = "Notification";
export const StaffHeaderTitle = "Staff Members";
export const NotificationHeader = "Manage notification email";
export const AddStaffHeaderTitle = "Add new staff member";
export const AddGroupHeaderTitle = "Add Group";
export const AddNotificationUserHeaderTitle = "Add notification contact -";
export const EditStaffHeaderTitle = "Edit staff member";
export const AddProductLineHeaderTitle = "Add Product Line";
export const WarrantyHeaderTitle = "Product Lines & Warranties";
export const FlashHeaderTitle = "Import flash data";
export const FailedFlashHeaderTitle = "Resolve failed serials";
export const SettingHeaderTitle = "Settings";
export const SupportHeaderTitle = "Support";

export const ManufacturerTitle = "Manufacturer";
export const ManufacturerDetailsTitle = "Solar Juice Pty Ltd";
export const InstallersTitle = "Installers";
export const SupplierTitle = "Supplier";
export const SerialsTitle = "Serials";
//Button and actions text

//export const AddProductText = "Add Product";
export const WarrantyText = "Add Product Line";

//navigations caption

export const DashboardCaption = "Dashboard";
export const NotificationCaption = "Notification";
export const StaffCaption = "Staff";
export const WarrantyCaption = "Product Line";
export const FlashDataCaption = "Flash Data";
export const FailedImportDataCaption = "Resolve Failed ";
export const SettingsCaption = "Settings";
export const HistoryCaption = "History";
export const SupportCaption = "Support";
export const LogOutCaption = "Logout";
export const ChineseToEnglishSuggestion = {
    "物料号":"Item number",
    "库位": "Location",
    "数量": "Quantity",
    "晶硅托盘号": "Crystal silicon tray number",
    "A级": "Class A",
    "电流分档": "Current bin",
    "实际功率": "actual power",
    "标称功率": "Nominal power",
    "分档方式": "Split mode",
    "红外等级下限": "Infrared level lower limit",
    "花色": "Color",
    "晶硅功率范围": "Crystal silicon power range",
    "最近的收货日期": "Recent receipt date"
}













