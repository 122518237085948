//import FormHelperText from '@material-ui/core/FormHelperText';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PermIdentity from '@material-ui/icons/PermIdentity';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import Background from '../../assets/images/user.png';
import { EditStaffApi, ProfilePath, ProfilePhotoStaffApi } from '../../constants';
import { EditStaffHeaderTitle } from '../../StringConstants';
import theme from '../../theme';
import UserProfile from '../auth/UserProfile';
import AuthService from '../AuthService';
import { Helmet } from "react-helmet";
const styles = {
    root: {
        flexGrow: 1,
        height: "100%"
    },
    gridList: {
        listStyle: "none"
    },
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: theme.palette.common.white,
    },
    dense: {
        marginTop: "0",
    },
    formControl: {
        width: "100%",
    },
    avatar: {
        borderRadius: 0,
        width: "100%",
        height: "172px",
        backgroundColor: theme.palette.background.default,
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: "transparent",
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.background.lightbg,
        maxWidth: "178px",
        minWidth: "178px",
    },
    iconHover: {
        color: theme.palette.grey[500],
        verticalAlign: "middle"
    },
    gutterBottom: {
        marginBottom: theme.spacing(2),
    },
    control: {
        marginTop: theme.spacing(1),
    },
    update: {
        marginTop: theme.spacing(1),
    },
    button: {
        marginRight: '8px',
        minWidth: 120,
        height: 42,
    },
    profileImage: {
        width: "100%",
        height: "100%",
        objectFit: " cover",
        imageOrientation: 'from-image',
        color: "transparent",
    },
    input: {
        display: 'none',
    },
    //titleBar: {
    // background: "transparent",
    //},
    backanim: {
        transition: "400ms ease",
        '&:hover': {
            marginLeft: "-5px",
        },
    },
    icon: {
        fontSize: 26,
        verticalAlign: "middle",
        marginTop: "-3px",
        transition: "400ms ease",
        marginLeft: "-5px"
        /*'&:hover': {
        marginLeft: "-5px",
        },*/
    },
    marginy: {
        marginTop: theme.spacing(1) * 1,
        marginBottom: theme.spacing(1) * 1,
    },
    checkbox: {
        paddingRight: 5,
    },
    marginNegative: {
        marginTop: -1,
    },
    labelClass: {
        // textTransform:"lowercase"
    }

};
class EditStaff extends React.Component {
    constructor() {
        super();
        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.onProfilePhotoChange = this.onProfilePhotoChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.Auth = new AuthService();
        this.state = {
            isloading: false,
            isimageloading: false,
            showPassword: false,
            previewImage: '',
            user: {

                FirstName: '', LastName: '', Email: '',
                Mobile: '', ProfilePhoto: '', Permissions: []
            }
        };

    }
    UNSAFE_componentWillMount() {

        const staffState = this.props.location.state.staff;
        this.setState({
            user: {
                FirstName: staffState.firstName, LastName: staffState.lastName, Email: staffState.email, Mobile: staffState.phoneNumber,
                ProfilePhoto: staffState.profilePhoto, Permissions: staffState.permissions, StaffUserId: staffState.staffUserId, StaffId: staffState.staffId, Designation: staffState.designation
            }
        });

    }
    componentDidMount() {
    }


    handleSubmit() {
        this.setState({ isloading: true });
        const token = this.Auth.getToken();
        fetch(EditStaffApi, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.user)
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return Promise.reject(res);

        }).then(res => {

            this.setState({ isloading: false });
        })
            .catch(err => {
                //this.setState({ isLoading: false, isLoginFailed: true });

            });
    };
    handleChange(e) {
        this.updateformState({ [e.target.name]: e.target.value });

    }
    updateformState(obj, extra) {
        const { user } = this.state;
        let userState =
            { ...user, ...obj };
        if (extra != null) {

            this.setState(state => ({ "user": userState, ...extra }));
        }
        else {
            this.setState(state => ({ "user": userState }));
        }


    }
    handlePermissionChange(e) {
        let permissions = this.state.user.Permissions;
        var elem = permissions.find((el) => { return el.name === e.target.name });
        elem.checked = e.target.checked;
        this.updateformState({ "Permissions": permissions });
    }
    handleImageChange(event) {

        this.setState({
            previewImage: URL.createObjectURL(event.target.files[0])
        })
        this.onProfilePhotoChange(event);
    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    onProfilePhotoChange = e => {
        const files = Array.from(e.target.files)
        this.setState({ isimageloading: true })

        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append("File", file);
        })
        formData.append("StaffUserId", this.state.user.StaffUserId);
        const token = this.Auth.getToken();
        fetch(`${ProfilePhotoStaffApi}`, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`
            },
            body: formData
        })
            .then(res => res.json())
            .then(images => {
                UserProfile.setProfilePhoto(images.fileName);
                this.updateformState({ ProfilePhoto: images.fileName }, { isimageloading: false });
            });
    }
    render() {

        const { classes } = this.props;

        const { FirstName, LastName, Mobile, ProfilePhoto, Permissions, Designation } = this.state.user;
        const { isloading, isimageloading } = this.state;

        const profileUrl = ProfilePath + ProfilePhoto;

        const permissionView = Permissions.map(p => {

            return (<FormControlLabel
                control={
                    <Checkbox name={p.name}
                        checked={Boolean(p.checked)}
                        onChange={(e) => this.handlePermissionChange(e)}
                        value={p.id}
                        color="primary"
                        className={classes.checkbox}
                    />
                }
                label={p.name}
                classes={{ root: classes.labelClass }}
            />)
        });

        const image = isimageloading ? <CircularProgress color={theme.palette.common.white} size={19} style={styles.root} /> : (profileUrl != null && profileUrl !== '') ? (< img alt="profilepic" src={profileUrl} className={classes.profileImage} />) : (<PermIdentity></PermIdentity>);
        return (
            <div className={classes.root}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Edit Staff</title>
                </Helmet>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.marginy}>
                    <Grid item>
                        <Link to="/Staff" title="back to staff" >
                            <Typography color='primary' variant="h4">
                                <span className={classes.backanim}><Icon className={classes.icon} >keyboard_arrow_left</Icon>Back to Staff</span>
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" >
                            {EditStaffHeaderTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="space-between" alignItems="center" className={classes.control}>
                    <Grid item lg={5} md={7} sm={12}>
                        <ValidatorForm ref="form" instantValidate={true} autoComplete="off" onError={errors => console.log(errors)}
                            onSubmit={this.handleSubmit}>
                            <Paper elevation={0} className={classes.paper}>
                                <Grid container spacing={2}>
                                    <Grid item sm={12} xs={12} className={classes.gutterBottom}>
                                        <div className={classes.root}>
                                            <GridListTile className={classes.gridList}>
                                                <Avatar className={classes.avatar}>{image}
                                                    <GridListTileBar
                                                        title={<span className={classes.title}>Update</span>}
                                                        className={classes.titleBar}
                                                        actionIcon={<Fragment>
                                                            <input hidden accept="image/*" className={classes.input} ref={(file) => this.filefield = file} id="profile" type="file" onChange={(e) => this.handleImageChange(e)} />
                                                            <label htmlFor="profile">
                                                                <IconButton component="span" color="secondary">
                                                                    <Icon style={{ fontSize: 24 }}>
                                                                        camera_alt
                                                                 </Icon>
                                                                </IconButton>
                                                            </label>
                                                        </Fragment>
                                                        }
                                                    />
                                                </Avatar>
                                            </GridListTile>
                                        </div>
                                    </Grid>
                                    {/* <Hidden smDown>
                                        <Grid item lg={6} className={classes.gutterBottom}></Grid>
                                        </Hidden> */}
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                name="FirstName"
                                                label="* First Name"
                                                placeholder="First Name"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                value={FirstName}
                                                className={classNames(classes.dense)}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator autoComplete="off" onChange={(e) => this.handleChange(e)}
                                                name="LastName"
                                                label="* Last Name"
                                                placeholder="Last Name"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                value={LastName}
                                                className={classNames(classes.dense)}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <TextValidator onChange={(e) => this.handleChange(e)}
                                                name="Mobile"
                                                label="Phone"
                                                placeholder="Phone"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                value={Mobile}
                                                className={classNames(classes.dense)}
                                                validators={['matchRegexp:^0(4|6)[0-9]{8}$', 'required']}
                                                errorMessages={['Mobile should be 10 digit and must start from 04 or 06', 'This field is required']}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl className={classes.formControl} error>
                                            <TextValidator
                                                id="Designation" name="Designation"
                                                label="Designation"
                                                placeholder="Designation"

                                                fullWidth={true}
                                                margin="normal"
                                                variant="outlined"
                                                className={classNames(classes.dense)}
                                                value={Designation}
                                                onChange={this.handleChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} xs={12} className={classes.control}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <FormLabel component="legend">* Permissions</FormLabel>
                                            <FormGroup row>
                                                {permissionView}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </Paper>
                            <Grid container direction="row" className={classes.update} >
                                <Grid item={true} className={classes.marginNegative}>
                                    <Button variant="contained" size="large" color="#efefef" className={classes.button} type="button" onClick={this.props.history.goBack} >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item={true}>
                                    <Button className={classes.button} size="large" variant="contained" type="submit" alignitem='center' color="primary" {...isloading === true ? 'disabled' : ''} tabIndex="3">
                                        {isloading ?
                                            <CircularProgress color={theme.palette.common.white} size={19} variant="indeterminate"
                                                thickness={4} /> :
                                            <span>Update</span>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>

                </Grid>

            </div>
        );
    }
}
export default withStyles(styles)(EditStaff);

