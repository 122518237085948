import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from "react-helmet";
import styled from 'styled-components';
import RegisterForm from '../register/RegisterForm';
import FormFooter from './FormFooter';
import InstallationVideo from './installationVideo';
const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
    },
    formControl: {
        width: "100%"
    }, 
    formContent: {
        minHeight: "500px",
        height:"100%",
    },
    dense: {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
    },
    notchedOutline: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    container: {
        height: '100%',
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        width: 300,
        height:50,
    },
    validText: {
        borderColor: "#41A500"
    },
    registerWrapper: {
        display: "table",
        height:"calc(100% - 130px)"
    }
});


const RegisterWrapper = styled.section`
    width: 100%;
    float: left;
    height: 100%;
`;

const LeftWrapper = styled.div`
     float: left;
    width:40%;
    position: relative;
    height: 100%;
     background-color: #fff;
    display:table;
    padding:0 60px 0 60px;
    @media(max-width:1600px){
      width:40%;
    }
    @media(max-width:1400px){
      width:40%;
    }
    @media(max-width:1280px){
      padding:0 40px 0 40px;
    }
    @media(max-width:992px){
      width:100%;
      float:none;
    }
    @media(max-width:767px){
      padding:0 30px 0 30px;
    }
`;
const RightWrapper = styled.div`
     float: left;
    width:60%;
    position: relative;  
    display:block;
    @media(max-width:1600px){
      width:60%;
    }
    @media(max-width:1400px){
      width:60%;
    }
    @media(max-width:992px){
        display:none;
    }
    &::before {
        content: "";
        position: fixed;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: -1;
    }
    .header {
        position: fixed;
        top: 0;
        bottom: 0;
        padding: 50px 50px 50px 50px;
        //width:75%;

        h1 {
            color: #fff;
            margin-bottom: 10px;
            font-weight: normal;
        }

        p {
            color: #fff;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .footer {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 50px 50px 30px 50px;

        .address-box {
            padding: 30px 0 0 0;
            border-top: 1px solid rgba(255, 255, 255, 0.3);

            h3 {
                color: #fff;
                margin-bottom: 5px;
            }

            p {
                color: #fff;
                font-size: $font-size-base;
            }
        }
    }
`;

class Register extends React.Component { 

    constructor() {
        super();
        this.state = {
            isValidManufacturer: true, isValidated: false ,  password: '',
            showPassword: false,};
        this.callbackFunc = this.callbackFunc.bind(this);
        this.submit = this.submit.bind(this);
    }

 
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    submitHandler = (event) => {
        event.preventDefault();

        if (this.validate()) {
            this.props.submit();
        }

        this.setState({ isValidated: true });
    }
    validate = () => {
        const formLength = this.formEl.length;

        if (this.formEl.checkValidity() === false) {
            for (let i = 0; i < formLength; i++) {
                const elem = this.formEl[i];
                const errorLabel = elem.parentNode.querySelector('.invalid-feedback');

                if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
                    if (!elem.validity.valid) {
                        errorLabel.textContent = elem.validationMessage;
                    } else {
                        errorLabel.textContent = '';
                    }
                }
            }

            return false;
        } else {
            for (let i = 0; i < formLength; i++) {
                const elem = this.formEl[i];
                const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
                if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
                    errorLabel.textContent = '';
                }
            };

            return true;
        }
    }
    
    callbackFunc = (autoCompleteData) => {
        
        //You can use the address data, passed by autocomplete as you want.
    }
    submit() {


    }
    render() {
        const { classes } = this.props
        return (    
            <RegisterWrapper>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Register</title>
                </Helmet>
                <LeftWrapper>
                    <div className={classes.registerWrapper}>
                        <Grid container direction="row" alignItems="center" justify="center" className={classes.formContent} >
                            <Grid item xs={12}>
                                <RegisterForm />

                                {/*<AccountFooter customClass="register-footer" />*/}
                            </Grid>
                        </Grid>
                    </div>
                    <FormFooter />
                </LeftWrapper>
                <Hidden xsDown>
                    <InstallationVideo />
                </Hidden>
               <RightWrapper>
                    <div className="header">
                        <h1>Solar Panel Validation (Formbay)</h1>
                        <p>The Solar Panel Validation Project is intending to establish a voluntary mechanism that allows businesses in the supply chain to validate the authenticity of solar panels.</p>
                    </div>
                    <div className="footer">
                        <div className="address-box">
                            <h3>Formbay Pty. Ltd.</h3>
                            <p>Level 1, 222 Clarence Street , NSW 2000</p>
                        </div>
                    </div>
                </RightWrapper>
            </RegisterWrapper>
        );
    }
}
//export default Register;

Register.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Register);
