import { createTheme } from '@material-ui/core/styles'

export default createTheme({
    typography: {
        useNextVariants: true,
        fontSize: 14,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        h1: {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "26px",
            lineHeight:"42px",
            textTransform: "capitalize",
            fontFamily: 'NHaasGroteskDSPro-65Md',
            fontWeight: 900,
            minHeight: 42,
        },
        h2: {
            fontSize: "22px",
            lineHeight: 1,
            textTransform: "uppercase",
            fontFamily: 'NHaasGroteskDSPro-65Md',
        },

        h3: {
            fontSize: "18px",
            lineHeight: 1,
            fontFamily: 'NHaasGroteskDSPro-65Md',
        },
        h4: {
            fontSize: "16px",
            lineHeight: 1,
            fontFamily: 'NHaasGroteskDSPro-65Md', 
        },
    },
    palette: {
        common: {
            black: "#212121",
            white: "#fff",
            type: "light",
            headingText: "#333333",
            paragraphText: "#4e4e4e",
            red: "#ff0000",
            linkColor: " #4e4e4e",
            assign: "#ff8600",
            borderColor: "#dddddd",
            tabHover: "#f8f8f8",
            green: "#00a651",
            logIn: "#fff4ce",
            lightGrey: "#F6F6F6",
            lightext: "#9C9FA3",
            warning: "#FFD700",
            deletedWarningRowColor: "#ffcdd2",
            blue: "#007FFF",
            formbayBlue:'##0d4add'
        },
        primary: {
            light: "#3d6ee3",
            main: "#0d4add",
            dark: "#09339a",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ffcc47",
            main: "#ffc01a",
            dark: "#b28612",
            contrastText: "#fff",
        },
        error: {
            light: "#ffcc47",
            main: "#f44336",
            dark: "#b28612",
            contrastText: "#fff",
            alert:"#fa9c7d"
        },
        errorColor: {
            
            main: "#f44336",
            contrastText: "#fff",
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
            divider: "rgba(0, 0, 0, 0.12)",
            light: "rgba(255, 255, 255, 1)",
        },
        background: {
            default: "#fff",
            paper: "#fafafa",
            lightbg: "#dddddd",
        },

      },
    spacing: 8,
    shape: {
        borderRadius:2
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: 14,
            },
        },
        MuiButton: { // Name of the component ⚛️ / style sheet
            root: {
                fontFamily: 'NHaasGroteskDSPro-65Md',
                textTransform: 'capitalize',
                
            },
            text: { // Name of the rule
                color: '#fff', // Some CSS
               
            },
        },
        //MuiTab: {
        //    root: {
        //        borderRightColor: "#dddddd"
        //    }
        //},
        MuiDialog: {
            paper: {
                borderRadius:4,
            }
        },           
    },
});