import React, { Fragment } from 'react';

import CornerIcon from '@material-ui/icons/SignalCellular4BarSharp';

class ClientRowDecoration extends React.Component {

    
    convertToText(index) {
        const div = index > 0 ? index / 25 : 0;
        let output = '';
        for (var j = 0; j <= div; j++) {
            if (j > 0) {
                output = output + String.fromCharCode(64 + j);
            }
            const modulo = index % 25;
            output = output + String.fromCharCode(65 + modulo);

        }
        return output;

    }
    render() {
  
        const { rows } = this.props;
        let maxCellCount = 0;
         rows.forEach((row, index) => {
            maxCellCount = row.cells != null && row.cells.length > 0 &&
                row.cells.length > maxCellCount ? row.cells.length : maxCellCount;
           
        });
 
        let cells = [];
        cells.push((<td key={"commonheader"} className={"counterCell"} ><CornerIcon  opacity={0.3} /></td>));
        for (var i = 0; i < maxCellCount; i++) {

            cells.push((<td key={"commonheader"+i}>{this.convertToText(i)}</td>));
        }

        return <Fragment><tr key={"commonheaderRow"}>{cells}</tr></Fragment>;

    }
}

export default ClientRowDecoration;
