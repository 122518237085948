import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import theme from '../theme';
const styles = {
    appBar: {
        background: theme.palette.background.default,
        borderTop: "1px solid #ececec",
        // position: "fixed",
        boxShadow: "none",
        position: "relative",
        padding: theme.spacing(1),
    },
};

class LegalFooter extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div color="default" className={classes.appBar} component="footer">
                <Typography component="p">
                    Copyright &copy;2019 Formbay, Inc. All rights reserved.
                    </Typography>
            </div>
        );
    }
}

export default withStyles(styles)(LegalFooter);

