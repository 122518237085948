import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { detectinRows, validateAllRequiredMapped, validateSheet } from '../../libs/UploadHelper';
import HelpBox from './HelpBox';
import UploadFlash from './UploadFlash';
import UploadResultContainer from './UploadResultContainer';
import _ from 'lodash';

const styles = theme => ({
    root: {
        height: "100%"
    },

});

class FlashContainer extends React.Component {
    minCountRequired = 0;
    constructor(props) {
        super(props);
        const { minCountRequired, } = { minCountRequired :1 };
        this.minCountRequired = minCountRequired;
        this.state = { activeFileIndex: 0, activeSheetIndex: 0, responseData: null, commonFormat: false, showOpposite: null,mode:null }
        this.uploadComplete = this.uploadComplete.bind(this);
        this.onRequiredFieldUpdate = this.onRequiredFieldUpdate.bind(this);
        this.onRequiredFieldRemoved = this.onRequiredFieldRemoved.bind(this);
        this.saveDataComplete = this.saveDataComplete.bind(this);
        this.updateData = this.updateData.bind(this);
        this.onRequiredFieldUpdateAndRemove = this.onRequiredFieldUpdateAndRemove.bind(this);
        this.mergeFields = this.mergeFields.bind(this);
        this.compareSheets = this.compareSheets.bind(this);
        this._removeUpdateField = this._removeUpdateField.bind(this);
        this.getDefaultOptions = this.getDefaultOptions.bind(this);
        this.onIgnoreSheet = this.onIgnoreSheet.bind(this);
    }
   
    componentDidMount() {
        //Functionality for upload flash data 
        $('.upload-btn').on("click", function () {
            $(".loader").show().delay(700).fadeOut();
            $('.message-card').delay(1000).fadeIn(200);
            $(".upload-card").hide();
            setTimeout(function () {
                $('.mapping').delay(1000).fadeIn(200)
                $('.message-card').delay(300).fadeOut(300);
            }, 1500);
        });


    }
    componentDidUpdate(prevProps) {    // using componentDidUpdate because componentWillReceiveProps will be renamed to UNSAFE_componentWillReceiveProps from v16.3.0 and later removed
        
        const { location: { search = '' } = { 'search': undefined }, isDialog=false } = this.props;
        const new_values = search ? queryString.parse(search) :'';
        const new_stamp = search ? new_values.stamp : '';


        const { location: old_location = { 'search': undefined } } = prevProps;
        const { search:old_search } = old_location;
        const old_values = old_search ? queryString.parse(old_search):'';
        const old_stamp = old_search ? old_values.stamp : '' ;
        if (new_stamp !== old_stamp && !isDialog) {
            this.setState({ activeFileIndex: 0, activeSheetIndex: 0, responseData: null });
        }
    }

    saveDataComplete(data) {


    }
    
    onRequiredFieldRemoved(index) {
        
        const { activeFileIndex, activeSheetIndex, responseData } = this.state;
        var sheet = this.state.responseData[activeFileIndex].sheets[activeSheetIndex];
        let fieldToRemove = sheet.fields.find(function (field) { return field.index === index });
        if (!_.isNil(fieldToRemove)) {
            fieldToRemove.index = null;
            fieldToRemove.isDataValid = true;
            for (var i = 0; i < sheet.header.columns.length; i++) {
                var cell = sheet.header.columns[i];
                if (cell.columnIndex === index) {
                    cell.columnType = null;
                    break;
                }
            }
            
            sheet.valid = validateAllRequiredMapped(sheet.fields);
            
            if (sheet.valid) {
                sheet.enabled = sheet.valid;
            }
            validateSheet(sheet);
            this.setState({ responseData });
        }
      
    }
    onRequiredFieldUpdateAndRemove(old_index, new_index, new_field_name) {
        
        const { activeFileIndex, activeSheetIndex, responseData, commonFormat } = this.state;
        responseData.forEach((file,fIndex) => {
            if (file.index === activeFileIndex) {
                file.sheets.forEach((sheet,sIndex) => {
                    if (commonFormat) {
                        this._removeUpdateField(sheet, old_index, new_index, new_field_name);
                    }
                    else if (sIndex === activeSheetIndex) {
                        this._removeUpdateField(sheet, old_index, new_index, new_field_name);
                    }
                    sheet.valid = validateAllRequiredMapped(sheet.fields);
                    
                    if (sheet.valid) {
                        sheet.enabled = sheet.valid;
                    }
                    validateSheet(sheet);
                })
            }
        });
        this.setState({ responseData });
    }

    _removeUpdateField(sheet, old_index, new_index, new_field_name) {

        let fieldToRemove = sheet.fields.find(function (field) { return field.index === old_index });
        if (!_.isNil(fieldToRemove)) {
            fieldToRemove.index = null;
            fieldToRemove.isDataValid = true;
            for (var i = 0; i < sheet.header.columns.length; i++) {
                var cell = sheet.header.columns[i];
                if (cell.columnIndex === old_index) {
                    cell.columnType = null;
                    break;
                }
            }
        }
        if (!_.isNil(new_field_name)) {
            let found = sheet.fields.find((field) => { return field.fieldName === new_field_name.fieldName });
            if (!_.isNil(found)) {
                found.index = new_index;
                found.isDataValid = true;
            }
        }
    }

    onRequiredFieldUpdate(index, objField) {
        const { activeFileIndex, activeSheetIndex, responseData, commonFormat } = this.state;
        
        responseData.forEach((file) => {
            if (file.index === activeFileIndex) {
                file.sheets.forEach((sheet, sIndex) => {
                    if (commonFormat) {
                        let found = sheet.fields.find((field) => { return field.fieldName === objField.fieldName });
                        found.index = index;
                        found.isDataValid = true;
                    }
                    else if (sIndex === activeSheetIndex) {
                        let found = sheet.fields.find((field) => { return field.fieldName === objField.fieldName });
                        found.index = index;
                        found.isDataValid = true;
                    }
                    sheet.valid = validateAllRequiredMapped(sheet.fields);
                    
                    if (sheet.valid) {
                        sheet.enabled = sheet.valid;
                    }
                    validateSheet(sheet);
                   
                })
            }
        });
        this.setState({ responseData });
    }

    updateData(data, fileIndex, sheetIndex) {
        let { responseData, activeSheetIndex } = this.state;
        let showOpposite = sheetIndex < activeSheetIndex;
        if (!_.isNil(responseData) && !_.isNil(responseData[fileIndex])) {
            let activePreviewFile = responseData[fileIndex];
                const { sheets } = activePreviewFile;
                const activeSheet=sheets.find((sheet, index) => {
                    if (index === sheetIndex) {
                        sheet.valid = validateAllRequiredMapped(sheet.fields);
                        validateSheet(sheet);
                        return true;
                    }
                    return false;
            });
            if (!_.isNil(activeSheet)) {
                this.updateState(showOpposite,fileIndex, sheetIndex, responseData);
            }
        }
    }
    mergeFields(oldFields, newFields) {
        newFields.map(newItem => {
            oldFields.find(oldItem => { 
                if ( _.isNil(newItem.index) && newItem.fieldName === oldItem.fieldName)
                 { 
                    newItem.index = oldItem.index;
                     return true; 
                 } 
                return false;
            });
            return newItem;
        });
        return newFields;
    }
    mapSheetInfo(sheet, columns, minRequired) {
        const { header, fields } = detectinRows(sheet.rows, columns, minRequired);
        sheet.header = header;
        sheet.fields = fields;
        sheet.valid = validateAllRequiredMapped(fields);
        validateSheet(sheet);
        return sheet;
    }
    uploadComplete(data, fileIndex, sheetIndex, mode) {
        if (!_.isNil(data) && !_.isNil(data.uploadFile)) {
            data.uploadFile.map((excelFile) => {
                const { sheets,mappings } = excelFile;
                sheets.map(sheet => {
                    const { minCountRequired, default_columns } = this.getDefaultOptions(mappings); //defaultFields(this.props);
                    sheet = this.mapSheetInfo(sheet, default_columns, minCountRequired);
                    if (_.isNil(sheet.rows) || sheet.rows.length === 0) {
                        sheet.enabled = false;
                    }
                    return sheet;
                });
                return excelFile;
            });
            let commonFormat=this.compareSheets(data.uploadFile);
            let activeSheetIndex = sheetIndex ? parseInt(sheetIndex,10) : 0;
            let activeFileIndex = fileIndex ? parseInt(fileIndex, 10) : 0;
            this.updateState(null,activeFileIndex, activeSheetIndex, data.uploadFile, commonFormat, mode);
        }
    } 
    getDefaultOptions(mappings) {
        let default_columns = cloneDeep(mappings);
        const minCountRequired = 1;
        return { minCountRequired, default_columns };
    }

    compareSheets(data) {
        let allMatch = true;
        data.map((excelFile) => {
            const { sheets } = excelFile;
            let lastElement = null

            for (var i = 0; i < sheets.length; i++) {
                let header = sheets[i].header;
                if (i === 0) {
                    lastElement = header;
                    continue;
                }
                if (header.columns.length !== lastElement.columns.length) {
                    allMatch = false;
                    break;
                }
                lastElement.columns.forEach((c, index) => {
                    try {
                        if (header.columns[index].columnName !== c.columnName) {
                            allMatch = false;
                            return false;
                        }
                    }
                    catch(ex) {
                        console.log(ex);
                    }
                });
                lastElement = header;
            }
            return excelFile;
        });
        return allMatch;
    }
    updateState(showOpposite,activeFileIndex, activeSheetIndex, responseData, format,mode ) {
        if (!_.isNil(format)) {
            this.setState({ showOpposite: showOpposite, activeFileIndex: activeFileIndex, activeSheetIndex: activeSheetIndex, responseData: responseData, commonFormat: format, mode: mode });
        }
        else {
            this.setState({ showOpposite: showOpposite, activeFileIndex: activeFileIndex, activeSheetIndex: activeSheetIndex, responseData: responseData });
        }
        

    }
    onIgnoreSheet(fileIndex, sheetIndex) {
        let { responseData } = this.state;
        if (!_.isNil(responseData) && !_.isNil(responseData[fileIndex])) {
            let activePreviewFile = responseData[fileIndex];
            const { sheets } = activePreviewFile;
            const activeSheet = sheets.find((sheet, index) => {
                if (index === sheetIndex) {
                    sheet.valid = validateAllRequiredMapped(sheet.fields);
                    return true;
                }
                return false;
            });
            if (!_.isNil(activeSheet)) {
                activeSheet.enabled = activeSheet.enabled != null ? !activeSheet.enabled : false;
                this.setState({ responseData: responseData });
            }
        }
    }
    render() {
        
        const { classes } = this.props;
        //let error = params !== undefined ? params["0"] : (mode != undefined) ? mode : undefined;
        //if (error == null && this.props.mode === ResolveModeConstant) {
        //    error = ResolveModeConstant;
        //}
        const { mode: error } = this.state;
        const { activeFileIndex, activeSheetIndex, responseData, commonFormat, showOpposite } = this.state;
        const spreadsheetResponseData = { 'activeFileIndex': activeFileIndex, 'activeSheetIndex': activeSheetIndex, 'responseData': responseData, 'mode': error, 'commonFormat': commonFormat, showOpposite: showOpposite}
        const callbacks = {
            saveDataComplete: this.saveDataComplete, onRequiredFieldRemoved: this.onRequiredFieldRemoved,
            onRequiredFieldUpdate: this.onRequiredFieldUpdate, updateData: this.updateData, onRequiredFieldUpdateAndRemove: this.onRequiredFieldUpdateAndRemove, onIgnoreSheet: this.onIgnoreSheet
        };
        const is_initial_loading = _.isNil(responseData);
        let result;
        if (is_initial_loading) {
            result = <UploadFlash key="uploadFlashData" OnComplete={this.uploadComplete} dummyKey={Math.random()} mode={error} />
        }
        else {
            result = <UploadResultContainer key="mainResultContainer" {...spreadsheetResponseData } { ...callbacks }  />
        }
        return (
            <Fragment>
                <div className={classes.root}>
                    <HelpBox mode={error}/>
                    <div className="flash-data clearfix">
                       
                            {result}
                           
                    </div>
                </div>
            </Fragment>);
    }
}
export default withStyles(styles)(FlashContainer);