import { Button, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { blue800 } from 'material-ui/styles/colors';
import React from 'react';
import theme from '../../../theme';
const styles = {
    serialToggle: {
        width: 250,
    },
    seialNum: {
        color: "#333",
        padding: "0",
        fontSize: "16px",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: "transparent",
        },
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    supplier: {
        color: "#333",
        padding: "0",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: "transparent",
        },
    },
    Leftmargin: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        borderRadius: 0,
        width:42,
        height: 'auto',
        color: 'tranparent'
    },
    listClass: {
        display: "flex",
        flexDirection: 'row',
    },
    inline: {
        display: 'inline',
        marginLeft: theme.spacing(1) * 0.5,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        color: theme.palette.grey[700],
    },
    tag: {
        color: theme.palette.grey[900],
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
};

const AvailableTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);

class AvailableSerialRow extends React.Component {
    state = {
        isSwitchOn: false,
        open: false,
    }

    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_row');

    }

    render() {
        const serial = this.props.serial;
        const { classes } = this.props;
        const isOn = this.state.isSwitchOn;  
        return (

            <TableRow key={this._id} className={classes.row}>
                <AvailableTableCell data-label="Serial Number">
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        className={classes.serialToggle}
                    >
                        <Grid item>
                            <Tooltip title="Click to view serial" placement="bottom">
                                <Button component="h3" color="primary" className={classes.seialNum}
                                    onClick={() => this.setState({ isSwitchOn: !isOn })}>
                                    {isOn ? (<div>{serial.serial} <div style={{ color: blue800, fontSize: '12px' }}>Click to hide</div></div>) : (<div>XXXX-XXXX-XXXX <div style={{ color: blue800, fontSize: '12px' }}>Click to view</div></div>)}
                                </Button>
                            </Tooltip>

                        </Grid>
                    </Grid>
                </AvailableTableCell>
                <AvailableTableCell>{serial.uploadedOn}</AvailableTableCell>
                <AvailableTableCell >      <Typography component="span" className={classes.supplier} color="textPrimary">
                    {serial.supplier ? serial.supplier : "Self supplier"}
                </Typography></AvailableTableCell>
                <AvailableTableCell>
                    <List className={classes.root} disablePadding classes={{
                        root: classes.listClass
                    }}>
                        <ListItem alignItems="flex-start" disableGutters={true} >
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Pmax:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.pmax}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>    {"FF:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.ff}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Voc:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.voc}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Isc:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.isc}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Vpm:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.Vpm}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}> {"Ipm:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.ipm}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                    {/*  <div className="flashDataContainer">
                   <span>Pmax:{serial.pmax} </span><span>FF: {serial.ff}</span> <span> Voc:{serial.voc}</span> <span>Isc:{serial.isc}</span> <span> Vpm:{serial.vpm}</span><span>Ipm:{serial.ipm}</span>
                </div> */}
                </AvailableTableCell>           
             </TableRow>
           

        );

       


    }
}

export default withStyles(styles)(AvailableSerialRow);
