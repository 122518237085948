import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';

import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { LINKS_PER_PAGE, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import theme from '../../theme';
import UserProfile from '../auth/UserProfile';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from "../common/Pagination";
import HeaderParallel from './rows/header/HeaderParallel';
import RowParallel from "./rows/RowParallel";
const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        //borderLeftWidth: "1px",
        // borderRightWidth: "1px",
        // borderColor: theme.palette.common.borderColor
    },

    paperTable: {
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,
        minHeight: "calc(100vh - 300px)",
        width: "100%",
        overflowX: 'auto',
        background: theme.palette.common.white,

    }
};
const FooterCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);
class ParallelTab extends React.Component {
    constructor(props) {
        super(props);
      
    }
    

    render() {
        const { classes, adminView, globalView, manufacturerId, showSerialImagesCallback, filter,serials } = this.props;
        
      
        const listSerials = serials != null ? serials.map((serial, index) =>
        (
            <RowParallel showSerialImagesCallback={showSerialImagesCallback} key={index} serial={serial} index={index} adminView={adminView} />

        )) : null;

        return (
            <Fragment>
                <div className={classes.root}>
                
                    <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                        <Table className={classes.table}>
                            <TableHead>
                                <HeaderParallel adminView={adminView} filter={filter} />
                            </TableHead>
                            {!listSerials && <FbEmptyResult isTableBodyRequired={false} colSpan={8} errorStyle={'emptycell'} />}
                            {listSerials && <Fragment><TableBody>{listSerials}</TableBody></Fragment>}
                            
                        </Table>
                    </Paper>
                </div >
            </Fragment>
        );
    }
}

export default withStyles(styles)(ParallelTab);
