import GraphQlQuery from './GraphQlQuery';
import { Role_Admin, Role_MANUFACTURER, Role_DISTRIBUTOR, Role_STAFF,Role_DISTRIBUTOR_STAFF } from '../constants';
import UserProfile from "../components/auth/UserProfile";

const QueryHelper = (function () {


    var getProductListing = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_Admin: { return GraphQlQuery().ADMIN_PRODUCTS_QUERY; }
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().SUPPLIER_PRODUCTS_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().PRODUCTS_QUERY; }
            default: { return GraphQlQuery().PRODUCTS_QUERY; }
        }
    }

    var getFailedProductSerialListing = function (globalView) {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_Admin: { if (globalView) { return GraphQlQuery().FAILED_ADMIN_GLOBAL_PRODUCTS_SERIAL_QUERY; } else { return GraphQlQuery().FAILED_ADMIN_PRODUCTS_SERIAL_QUERY; } }
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().FAILED_DISTRIBUTOR_PRODUCTS_SERIAL_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().FAILED_PRODUCTS_SERIAL_QUERY; }
            default: { return GraphQlQuery().FAILED_PRODUCTS_SERIAL_QUERY; }
        }
    }
    var getParallelSerialListing = function (globalView) {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_Admin: { if (globalView) { return GraphQlQuery().ParallelImport_ADMIN_GLOBAL_PRODUCTS_SERIAL_QUERY; } else { return GraphQlQuery().ParallelImport_ADMIN_GLOBAL_PRODUCTS_SERIAL_QUERY; } }
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: 
            case Role_STAFF:
            case Role_MANUFACTURER: 
            default: { return null; }
        }
    }
    var getProductSerialListing = function (globalView) {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_Admin: { if (globalView) { return GraphQlQuery().PRODUCT_SERIAL_ADMIN_GLOBAL_QUERY; } else { return GraphQlQuery().PRODUCT_SERIAL_ADMIN_QUERY; } }
            case Role_DISTRIBUTOR_STAFF: 
            case Role_DISTRIBUTOR: { return GraphQlQuery().PRODUCT_SERIAL_DISTRIBUTOR_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().PRODUCT_SERIAL_QUERY; }
            default: { return GraphQlQuery().PRODUCT_SERIAL_QUERY; }
        }
    }


    var getProductInstallationListing = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_Admin: { return GraphQlQuery().PRODUCT_INSTALLATION_ADMIN_QUERY  }
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().PRODUCT_INSTALLATION_SUPPLIER_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().PRODUCT_INSTALLATION_QUERY; }
            default: { return GraphQlQuery().PRODUCT_INSTALLATION_QUERY; }
        }
    }

    var getProductInstalledSerialByProductId = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().PRODUCT_INSTALLED_SERIALS_BY_PRODUCT_ID_SUPPLIER_QUERY; }
            case Role_Admin: 
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().PRODUCT_INSTALLED_SERIALS_BY_PRODUCT_ID_QUERY; }
            default: { return GraphQlQuery().PRODUCT_INSTALLED_SERIALS_BY_PRODUCT_ID_QUERY; }
        }
    }
    var getProductAvailableSerialByProductId = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().PRODUCT_PENDING_SERIALS_BY_PRODUCT_ID_SUPPLIER_QUERY; }
            case Role_Admin:
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().PRODUCT_PENDING_SERIALS_BY_PRODUCT_ID_QUERY; }
            default: { return GraphQlQuery().PRODUCT_PENDING_SERIALS_BY_PRODUCT_ID_QUERY; }
        }
    }
    var getProductFailedSerialByProductId = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().PRODUCT_FAILED_SERIALS_BY_PRODUCT_ID_SUPPLIER_QUERY; }
            case Role_Admin:
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().PRODUCT_FAILED_SERIALS_BY_PRODUCT_ID_QUERY; }
            default: { return GraphQlQuery().PRODUCT_FAILED_SERIALS_BY_PRODUCT_ID_QUERY; }
        }
    }


    var getHistory = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().HISTORY_DISTRIBUTOR_QUERY; }
            case Role_Admin: { return GraphQlQuery().HISTORY_ADMIN_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().HISTORY_MANUFACTURER_QUERY; }
            default: { return GraphQlQuery().HISTORY_MANUFACTURER_QUERY; }
        }
    }

    var getSerialUploadHistory = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().SERIAL_HISTORY_DISTRIBUTOR_QUERY; }
            case Role_Admin: { return GraphQlQuery().SERIAL_HISTORY_ADMIN_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().SERIAL_HISTORY_MANUFACTURER_QUERY; }
            default: { return GraphQlQuery().SERIAL_HISTORY_MANUFACTURER_QUERY; }
        }
    }
 

    var getProductInstallationByProductId = function () {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().SUPPLIER_PRODUCT_INSTALLATION_SERIALS_QUERY; }
            case Role_Admin: { return GraphQlQuery().ADMIN_PRODUCT_INSTALLATION_SERIALS_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().MANUFACTURER_PRODUCT_INSTALLATION_SERIALS_QUERY; }
            default: { return GraphQlQuery().MANUFACTURER_PRODUCT_INSTALLATION_SERIALS_QUERY; }
        }
    }
    var getSupplier = function () {
        const role = UserProfile.getRole();
        switch (role) {


            case Role_Admin: { return GraphQlQuery().ADMIN_Supplier_QUERY; }
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR:
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().Supplier_QUERY; }
            default: { return GraphQlQuery().Supplier_QUERY; }
        }
     

        
    }
    var getSupplierlistingForAdmin = function (filter) {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_Admin: { return filter ? GraphQlQuery().ADMIN_Overall_Supplierlist_WITH_FILTER_QUERY : GraphQlQuery().ADMIN_Overall_Supplierlist_QUERY; }
         
            default: { return ''; }
        }
    }
    var getWeeklySuppliers = function (filter) {
        const role = UserProfile.getRole();
        switch (role) {
            case Role_Admin: { return GraphQlQuery().ADMIN_WeeklySupplier_WITH_FILTER_QUERY  }

            default: { return ''; }
        }
    }



    var getPullRequest = () => { return GraphQlQuery().ADMIN_PULL_REQUEST_INFO_QUERY; }

    var getManufacturerLeadershipBoard = () => { return GraphQlQuery().ADMIN_MANUFACTURER_LEADERSHIP_BOARD_QUERY; }
    var getAppLeadershipBoard = () => { return GraphQlQuery().ADMIN_APP_PROVIDER_BOARD_QUERY; }
    var getCityList = () => { return GraphQlQuery().ADMIN_CITY_LIST; }
    var getAllLogs = function () {

        return GraphQlQuery().ADMIN_Logs_QUERY;
    }
    const getSUsers = () => {
        return GraphQlQuery().GET_SUsers;
    }
    var getProductSerialImagesByProductSerialIdOrSerial = function () {

        const role = UserProfile.getRole();
        switch (role) {
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: { return GraphQlQuery().SUPPLIER_PRODUCT_SERIAL_IMAGES_QUERY; }
            case Role_Admin: { return GraphQlQuery().ADMIN_PRODUCT_SERIAL_IMAGES_QUERY; }
            case Role_STAFF:
            case Role_MANUFACTURER: { return GraphQlQuery().PRODUCT_SERIAL_IMAGES_QUERY; }
            default: { return ''; }
        }
    }

    return {
        ProductListing: getProductListing,
        FailedProductSerialListing: getFailedProductSerialListing,
        ProductSerialListing: getProductSerialListing,
        ProductInstallationListing: getProductInstallationListing,
        ProductInstalledSerialsByProductId: getProductInstalledSerialByProductId,
        ProductAvailableSerialsByProductId: getProductAvailableSerialByProductId,
        ProductFailedSerialByProductId: getProductFailedSerialByProductId,
        History: getHistory,
        SerialUploadHistory: getSerialUploadHistory,
        ProductInstallationByInstallationId: getProductInstallationByProductId,
        GetProductSerialImagesByProductSerialIdOrSerial: getProductSerialImagesByProductSerialIdOrSerial,
        SupplierListingByManufacturer: getSupplier,
        OverallSupplierListingForAdmin: getSupplierlistingForAdmin,
        AllLogs: getAllLogs,
        AdminParallelImport: getParallelSerialListing,
        PullRequests: getPullRequest,
        AdminWeeklySupplierList: getWeeklySuppliers,
        ManufacturerLeadershipBoard: getManufacturerLeadershipBoard,
        AppProviderLeadershipBoard: getAppLeadershipBoard,
        CityList: getCityList,
        SUsers: getSUsers
    };
}
);

export default QueryHelper;

         
  