import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CloseIcon from '@material-ui/icons/Close';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import { SerialImagePath, Role_Admin, Role_DISTRIBUTOR, Role_MANUFACTURER, Role_STAFF, Role_DISTRIBUTOR_STAFF } from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import UserProfile from '../auth/UserProfile';


const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minHeight: 300
    },
}))(MuiDialogContent);



const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto'
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    table: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.common.borderColor,
    },
    media: {
        height: "350px",
        backgroundSize: 'contain'
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: '16px'
        }
    }
});
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        height: "auto"
    },

}))(TableCell);





const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    titleNote: {
        fontSize: 15,
        color: theme.palette.text.secondary
    }
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class SerialImagesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.state = { fullWidth: true, maxWidth: 'md', }

    }

    handleClose() {
        const { onClose } = this.props;
        onClose();
    }

    _getData(data) {
        const nodeName = ({
            [Role_Admin]: "admin",
            [Role_DISTRIBUTOR_STAFF]: "supplier",
            [Role_DISTRIBUTOR]: "supplier",
            [Role_MANUFACTURER]: "productfeed",
            [Role_STAFF]: "productfeed"
        })[UserProfile.getRole()];

        return data[nodeName].productSerialImages;
    }

    _getQueryVariables() {
        const { manufacturerId, serial, productSerialId } = this.props;
        switch (UserProfile.getRole()) {
            case Role_Admin:
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { manufacturerId: parseInt(manufacturerId), serial, productSerialId:parseInt(productSerialId) };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { serial, productSerialId: parseInt(productSerialId) };
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = (this.props.open !== nextProps.open);
        return shouldUpdate;
    }

    render() {
        const { fullWidth, maxWidth, scroll } = this.state;
        const { adminView, productSerialId, serial, classes, onClose, installationId, open, ...other } = this.props;
        const noImageFoundMessage = "we could'nt find any image for this serial.";

        const fallbackImage = require("../../assets/images/solar-panel.jpg");
        const query = QueryHelper().GetProductSerialImagesByProductSerialIdOrSerial();
        return (
            <Dialog fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={this.handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                {...other} >
                <DialogTitle id="scroll-dialog-title" onClose={this.handleClose}>Serial images</DialogTitle>
                <DialogContent>
                    <Query query={query} variables={this._getQueryVariables()}  >
                        {({ loading, error, data }) => {
                            if (loading) return <FbLoader isTableBodyRequired={false} colSpan={3} loaderStyle={'center'} />
                            if (error) return <FbError isTableBodyRequired={false} colSpan={3} errorStyle={'center'} />
                            const productSerialImagesList = this._getData(data);
                            const listSerialImages = productSerialImagesList.map((imageData, index) => {

                                const serialImage = imageData.image ? SerialImagePath + imageData.image : fallbackImage;
                                return (<TableRow key={index}>
                                    <TableCell>
                                        <Card className={classes.root}>

                                            <CardMedia
                                                className={classes.media}
                                                image={serialImage}
                                                title="Serial Image"
                                            />
                                            <CardContent classes={{ root: classes.cardContentRoot }} >
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Created On : {imageData.createdOn}
                                                </Typography>
                                            </CardContent>

                                        </Card>
                                    </TableCell>
                                </TableRow>)

                            });
                            const result = (productSerialImagesList != null && productSerialImagesList !== 'undefined' && productSerialImagesList.length > 0) ? listSerialImages : <FbEmptyResult isTableBodyRequired={false} messageSecond={noImageFoundMessage} colSpan={3} errorStyle={'emptycell'} />;
                            return (<Fragment>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>Serial : {serial}</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <tbody>{result}</tbody></Table>
                            </Fragment>);
                        }}
                    </Query>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(SerialImagesDialog);
