import gql from 'graphql-tag';

export const CREATE_CITY = gql`
  mutation CreateCity($city: CityInput!) {
    adminMutation { createCity(city: $city) {
      cityName
      stateId
      postalCode
      createdOn
      updatedOn
      createdBy
      updatedBy
    }
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($userId: String!, $newPassword: String!) {
    adminMutation { changePassword(userId: $userId, newPassword: $newPassword) {
      id
      userName
      email
      lockoutEnd
      lockoutEnabled
    }
    }
  }
`;

export const TOGGLE_USER_STATUS = gql`
mutation ToggleUserStatus($userId: String!, $lockoutEnabled: Boolean!) {
    adminMutation {
      toggleUserStatus(userId: $userId, lockoutEnabled: $lockoutEnabled) {
        id
      userName
      email
      lockoutEnd
      lockoutEnabled
      }
    }
  }
`;

export const ADD_ADMIN_USER = gql`
  mutation AddAdminUser($userName: String!, $email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    adminMutation {
      addAdminUser(userName: $userName, email: $email, password: $password, firstName: $firstName, lastName: $lastName) {
        id
        userName
        email
        firstName
        lastName
        cTime
      }
    }
  }
`;

