import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import gql from 'graphql-tag';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { AssignProductLineApi, handleErrors, handleHttpException, LINKS_PER_PAGE, RandomText, UnAssignProductlineApi } from '../../constants';
import theme from '../../theme';
import AuthService from '../AuthService';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from "../common/Pagination";
import ProductLineHeader from '../productline/ProductLineHeader';
import ProductLineRow from '../productline/ProductLineRow';

const PRODUCT_Line_QUERY = gql`
query get_products($filter: String, $skip: Int, $first: Int) {
  productfeed {
    productLines(filter: $filter, skip: $skip, first: $first) {
productLineId
      logo
      lineName
      updatedOn
      totalProduct
      productWarranty {
        componentInYears
        componentWarrantyLink
        performanceInYears
        performanceWarrantyLink
        productWarrantyId
      }
    }
    productLineCount(filter: $filter)
  }
}
`;

const styles = {
    title: {
        textTransform: "uppercase"
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    table: {
      //  borderTopWidth: "1px",
      //  borderLeftWidth: "1px",
     //   borderRightWidth: "1px",
     //   borderColor: theme.palette.common.borderColor,      
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    textField: {
       float: "right"
    },
    Grid: { 
        minHeight: 49,
        marginBottom: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        background: alpha(theme.palette.common.white, 0.75),
        border: "1px solid",
        borderColor: theme.palette.grey[400],
        overflow: "hidden",

        '&:hover': {
            background: alpha(theme.palette.common.white, 0.90),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(1) * 5,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1) * 5,
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 250,
          //  '&:focus': {
          //      width: 240,
         //  },
        },
    },
    searchBase: {
        display: "block",
        justifyContent: "right",
        padding: theme.spacing(1) * 0.5,
        overflow: "hidden",
    },
    paper: {
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,      
    }
};




const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

//const DialogActions = withStyles(theme => ({
//    root: {
//        borderTop: `1px solid ${theme.palette.divider}`,
//        margin: 0,
//        padding: theme.spacing(1),
//    },
//}))(MuiDialogActions);

class ProductLineDialog extends React.Component {

    constructor(props) {
        super(props);
        this._id = _.uniqueId('product_header_row');
        this.state = {
             pLinePageNumber: 1, pLinePageSize: 20, pLFilter: null,
            scroll: 'paper',
            fullWidth: true,
            maxWidth: 'lg',
            open: false,
        };
        this.Auth = new AuthService();
        this.handleClose = this.handleClose.bind(this);
       
        this.handleProductLineSearch = this.handleProductLineSearch.bind(this);
        this.onProductLinePageChange = this.onProductLinePageChange.bind(this);
        this._getQueryVariablesProductLine = this._getQueryVariablesProductLine.bind(this);
        this.handleAssignment = this.handleAssignment.bind(this);

    }
    _getQueryVariablesProductLine() {
        
        const page = this.state.pLinePageNumber;
        const page_size = this.state.pLinePageSize;
        const skip = (page - 1) * page_size;
        const filter = this.state.pLFilter;
        const first = page_size;
        return { first, skip, filter }
    }

  


    handleClose = () => {
        this.props.handleClose();
    };

    onProductLinePageChange(newPage) {
        this.setState({
            pLinePageNumber: newPage
        })
    }

    handleProductLineSearch(data) {
        
        this.setState({
            pLFilter: data, pLinePageNumber: 1, pLinePageSize: LINKS_PER_PAGE
        })
    }
    handleAssignment(id,exists) {
        const token = this.Auth.getToken();
        const url = exists ? UnAssignProductlineApi : AssignProductLineApi;
        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "ProductLineId": id, "ProductId": [this.props.selectedProductId]
            })
        }).then(res => {
            return handleErrors(res);

        }).then(res => {

            this.setState({ random: RandomText(9) });
            this.props.handleClose();

        }).catch(err => {

            handleHttpException(err).then(result => {

                this.setState({ uploading: false, error: true, errorMessage: result });
            });;
        });
    }
  
    render() {
        const { classes, open,  selectedProductLineId } = this.props;
        const { fullWidth, maxWidth,   scroll, } = this.state;

        return (
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                classes={{ paper: classes.dialogPaper }}
                open={open}
                onClose={this.handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title">

                <DialogTitle id="scroll-dialog-title" onClose={this.handleClose}>Modify Product Line</DialogTitle>
                <DialogContent >
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={classes.Grid}
                    >
                        <Grid item></Grid>
                        <Grid item>
                            <div className={classes.searchBase}>
                                <Paper elevation={0}>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search Product…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            name="search" type="text" onChange={(e) => { this.handleProductLineSearch(e.target.value) }} />
                                    </div>
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                    <Paper elevation={0} square className={classes.paper}>
                    <Table className={classes.table}>                      
                            <ProductLineHeader rowType={3} show_action={false} />            
                        <Query query={PRODUCT_Line_QUERY} variables={this._getQueryVariablesProductLine()} fetchPolicy="no-cache"   >
                            {({ loading, error, data,refetch }) => {
                                if (loading) return <FbLoader isTableBodyRequired={true} colSpan={10} loaderStyle={'emptycell'} />
                                if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} message='Product Line Not Found' />
                                const paging = (<Pagination Prefix="products" TotalRecords={data.productfeed.productLineCount} PageSize={this.state.pLinePageSize} PageNumber={this.state.pLinePageNumber} ShowPreviousNext={true} onPageChange={this.onProductLinePageChange} ></Pagination>);
                                const listModels = data.productfeed.productLines.map((model, index) =>
                                    (
                                        <ProductLineRow isDialog={true} selectedProductLineId={selectedProductLineId} key={index} data={model} index={index} rowType={3} handleAssignment={this.handleAssignment} refresh={refetch} />

                                    ));
                                    const result = (data.productfeed.productLines !== 'undefined' && data.productfeed.productLines.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} colSpan={4} errorStyle={'emptycell'} />;
                                    const pagination_content = (data.productfeed.productLines !== 'undefined' && data.productfeed.productLines.length > 0 && data.productfeed.productLineCount > this.state.pageSize) ? <tfoot><tr><td colSpan="4">{paging}</td></tr></tfoot> : null;

                                    return (<Fragment><TableBody>{result}</TableBody>{pagination_content}</Fragment>);
                            }}
                        </Query>
                        </Table>
                    </Paper>                 
                </DialogContent>

            </Dialog>
        );




    }
}
export default withStyles(styles)(ProductLineDialog);

