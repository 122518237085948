import { Avatar, Card, CardHeader } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import AccessTime from '@material-ui/icons/AccessTime';
import gql from 'graphql-tag';
import PropTypes from "prop-types";
import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PageTitles from '../admin/PageTitles';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
const styles = theme => ({
  
        root: {
            height: "100%",
        },
        avatar: {
            width: 'auto',
            color: theme.palette.primary.main,
            fontSize: '2.6rem',
            borderRadius: "0",
            background: "none",
            justifyContent: "start",
            minWidth: "76px",
        },
        icon: {
            verticalAlign: 'middle',
            marginRight: theme.spacing(1) / 2,
            opacity: 0.5
        },
        title: {
            fontSize: "1.25rem"
        },
        PaperBack: {
            background: theme.palette.common.white
        },
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1),
            background: theme.palette.grey[50],
        },
        contentPaper: {
            margin: theme.spacing(2),
            background: theme.palette.common.white,
            width:'100%'
        },
        card: {
            minWidth: 248,
        }

    
});
const Manufacturer_QUERY = gql`
query getmanufacturers {
  admin {
    adminFeed {
        totalInstallers,
        totalManufacturers,
        totalSuppliers
    }
  }
}
`;

class DashboardAdmin extends React.Component {
    render() {
        const { classes } = this.props
        const titleDash = 'Dashboard';
        const today = new Date();
        const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        return (
            <div className={classes.root}>
                <PageTitles titles={titleDash} />
                <Paper elevation={0} className={classes.contentPaper}>
                

                        <Query query={Manufacturer_QUERY} fetchPolicy="no-cache">


                            {({ loading, error, data, refetch }) => {


                            if (loading) return <FbLoader isTableBodyRequired={false} loaderStyle={'center'} />
                            if (error) return <FbError isTableBodyRequired={false} errorStyle={'center'} />
                            
                                return (
                                 
                                        <Grid container direction="column"
                                            justify="flex-start"
                                            alignItems="flex-start" spacing={8}>
                                        <Grid item>
                                            <Card className={classes.card} style={{cursor:'pointer'}} onClick={() => { this.props.history.push({ pathname: "/Manufacturers" }); }}>
                                                <CardHeader
                                                    avatar={<Avatar aria-label="Recipe" className={classes.avatar}>{data.admin.adminFeed.totalManufacturers}</Avatar>}
                                                    title="Manufacturers"
                                                    subheader={<span><AccessTime className={classes.icon} />{date}</span>}
                                                    classes={{ title: classes.title, root: classes.PaperBack }}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item>
                                            <Card className={classes.card} style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push({ pathname: "/Suppliers" }); }}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar aria-label="Recipe" className={classes.avatar}>{data.admin.adminFeed.totalSuppliers} </Avatar>}
                                                    title="Suppliers"
                                                    subheader={<span><AccessTime className={classes.icon} />{date}</span>}
                                                    classes={{ title: classes.title, root: classes.PaperBack }}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item>
                                            <Card className={classes.card} style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push({ pathname: "/AdminInstaller" }); }}>
                                                <CardHeader
                                                    avatar={<Avatar aria-label="Recipe" className={classes.avatar}>{data.admin.adminFeed.totalInstallers}</Avatar>}
                                                    title="Installers"
                                                    subheader={<span><AccessTime className={classes.icon} />{date}</span>}
                                                    classes={{ title: classes.title, root: classes.PaperBack }}
                                                />
                                            </Card>
                                        </Grid>
                                              </Grid>
                                    
                                );

                            }
                            }
                        </Query>




              
                </Paper>
            </div>
        )

    }
}
DashboardAdmin.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(withRouter(DashboardAdmin)); 

