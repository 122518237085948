import React from 'react';

class TabContainer extends React.Component {
    
    render() {
        const defaultClassName = this.props.customClass != null ? this.props.customClass : "tab-container";
        const additionalClassName = this.props.additionalClass != null ? this.props.additionalClass : "";
        return (
            <div className={`${defaultClassName} ${additionalClassName}`}>
                {this.props.children}
            </div>
        );
    }
}

export default TabContainer;
