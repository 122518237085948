import React from 'react';
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {  handleParallelImportDownload } from '../../../../Helper';
const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "middle",
        padding: theme.spacing(2),
        height: "auto",
        whiteSpace: " nowrap",
    },

}))(TableCell);

class HeaderParallel extends React.Component {
    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_row');
        this.handleModelOpen = this.handleModelOpen.bind(this);
    }
    onParallelImportDownload(manufacturerId, filter) {
        let parentThis = this;
        handleParallelImportDownload((data) => {
            parentThis.setState(data);
        }, (error) => { parentThis.setState(error); }, manufacturerId, filter);
    }
    handleModelOpen = (manufacturerId, name) => {
        this.setState({ "modelOpen": true, activeManufacturerId: manufacturerId, activeManufacturerDisplayName: name });
    };
    render() {
 
        const { filter } = this.props;
        return (
            <TableRow key={this._id}>
                <CustomTableCell>Serial Number</CustomTableCell>
                <CustomTableCell>Product Info</CustomTableCell>
              
             
                <CustomTableCell>Requester Info</CustomTableCell>
                <CustomTableCell>
                    <Button color="primary" size="small" variant="outlined" onClick={() => this.onParallelImportDownload(null, filter)}>
                        Download
                    </Button></CustomTableCell>
               
           
               
            </TableRow>
        );
    }
}
export default withStyles(styles)(HeaderParallel);
