import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Customicon from '../../Customicon';
import theme from '../../theme';

const styles ={
    card: {
        boxShadow: "none",
        border: "1px dotted",
        borderColor: theme.palette.grey[300],        
        padding: theme.spacing(1) * 0.5,
        borderRadius: theme.shape.borderRadius,
        width: 82,
        margin: 0,
        textAlign:'center',

    }, 
    iconButton: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft:4,
        paddingRight:0,
        '&:hover': {
            background: "transparent",
        },

    },
    paper: {       
        textAlign: 'center',
        color: theme.palette.grey[800],
        background: "transparent",
        borderRightWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.grey[400],
        fontSize:14,
    },
    input: {
        display: 'none',
    },
    button: {
        width: 82,
        height: 34, fontFamily: 'NHaasGroteskDSPro-55Rg',
        transition:"200ms ease",
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },

    },
 
};
class WarrantyCell extends React.Component {
   

    EmptyWarrantyCell(props) {
        const { classes, isDistributor } = props;
        return isDistributor ? "No Warranty Info Available":( <Button size="small" color="primary" variant="outlined" type="button" className={classes.button} onClick={() => { this.props.handleProductLine() }}>
                Add
            </Button>)
    }
    HavingWarrantyNotLinkCell(props) {
        const { classes, isDistributor } = props;
        return <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.card}
            spacing={8}
        >
            <Grid item xs>
                <Paper square className={classes.paper} elevation={0}>{props.warrantyInYears}</Paper>                
            </Grid>
            {!isDistributor && <Grid item>
                <input
                    accept="file/*"
                    className={classes.input}
                    id="text-button-file"
                    type="file"
                />
                <label htmlFor="text-button-file">
                    <IconButton color="primary" aria-label="View" className={classes.iconButton} component="span">
                        <Customicon name="upload" width={13} fill="#0d4add" />
                    </IconButton>
                </label>
            </Grid>}
        </Grid>

     
    }

    HasCompleteWarranty(props) {
        const { classes} = props;
        return <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.card}

        >
            <Grid item xs={6}>
                <Paper square className={classes.paper} elevation={0}>{props.warrantyInYears}</Paper>
            </Grid>
            <Grid item xs={6}>           
                <IconButton color="primary" disableRipple href={props.warrantyLinks} target="_blank" download={props.warrantyLinks} aria-label="View" className={classes.iconButton} component="a">
                    <Customicon name="pdf" width={18} fill={theme.palette.common.red} />
                    </IconButton>
            </Grid>
        </Grid>
    
    }


    render() {
        const exists = this.props.iswarrantyExists;
        const warrantyInYears = this.props.warrantyInYears;
        if (!exists) {
            return this.EmptyWarrantyCell(this.props);
        }
        else if (warrantyInYears != null && warrantyInYears > 0 && this.props.warrantyLinks==null) {
            return this.HavingWarrantyNotLinkCell(this.props);
        }
        else {
            return this.HasCompleteWarranty(this.props);
        }
    }

}

export default withStyles(styles)(WarrantyCell);