import React from "react";

const getViewBox = name => {
    switch (name) {
    case "add":
        return "0 0 520 341.3";
    case "phone":
      return "0 0 32 33";
    case "message":
      return "0 0 38 34";
    case "envelope":
      return "0 0 40 26";
    case "trash":
            return "0 0 13.5 17.5";
        case "history":
            return "0 0 24 24";
    case "wifi":
      return "0 0 12 9";
    case "money":
      return "0 0 20 20";
    case "dashboard":
      return "0 0 161.1 186.8";
    case "notification":
      return "0 0 183.4 182.5";
    case "setting":
      return "0 0 512 512"
    case "warranty":
      return "0 0 447.7 512";
    case "support":
      return "0 0 471.8 512";
    case "logout":
      return "0 0 462.2 423.1";
    case "staff":
      return "0 0 473.8 512";
    case "flash":
        return "0 0 79.7 90";
      case "menu":
      return "0 0 14.4 9.6";
    case "chevronLeft":
      return "0 0 38 66";
      case "chevronRight":
      return "0 0 38 66";
      case "chevoronDown":
          return "0 0 9.6 5.9";
    case "pdf":
        return "0 0 76.7 96";
    case "upload":
            return "0 0 11.2 13.6";
    case "view":
        return "0 0 488.85 488.85";
    case "time":
        return "0 0 70 70.1";
    case "date":
        return "0 0 512 512";
    case "check":
        return "0 0 426.666 426.666";
    case "warning":
        return "0 0 246.027 246.027";
    case "email":
        return "0 0 512 512";
    case "close":
        return "0 0 47.971 47.971";
    default:
      return "0 0 32 32";
  }
};

const getPath = (name, props) => {
    switch (name) {
      case "pdf":
      return (
        <path
          {...props}
          d="M61.6,62.6c-1.2,0.3-2.9,0.4-4.7,0.1c-2-0.3-4-0.9-6-1.8c3.5-0.5,6.3-0.4,8.6,0.5C60.1,61.6,61,62.2,61.6,62.6
	 M41.9,59.4c-0.1,0-0.3,0.1-0.4,0.1c-1,0.3-1.9,0.5-2.8,0.7l-1.2,0.3c-2.4,0.6-4.9,1.2-7.3,2c0.9-2.2,1.8-4.5,2.6-6.7
	c0.6-1.6,1.3-3.3,1.9-5c0.3,0.6,0.7,1.1,1,1.7C37.4,55,39.5,57.4,41.9,59.4 M35.7,34.2c0.2,2.8-0.4,5.4-1.3,8
	c-1.1-3.2-1.6-6.6-0.2-9.4c0.3-0.7,0.6-1.1,0.8-1.3C35.3,31.8,35.7,32.8,35.7,34.2 M23.1,69.1c-0.6,1.1-1.2,2.1-1.9,3.1
	c-1.5,2.3-4,4.8-5.3,4.8c-0.1,0-0.3,0-0.5-0.3c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.8,1.2-2.4,2.8-3.8C19.6,71.3,21.2,70.1,23.1,69.1
	 M65.7,62.8c-0.2-2.8-4.9-4.6-5-4.6c-1.8-0.7-3.8-1-6.1-1c-2.4,0-5,0.4-8.4,1.1c-3-2.1-5.6-4.8-7.5-7.7c-0.9-1.3-1.6-2.6-2.3-3.9
	c1.6-3.9,3.1-8.1,2.8-12.8c-0.2-3.8-1.9-6.3-4.2-6.3c-1.6,0-2.9,1.2-4,3.5c-2,4.1-1.5,9.4,1.5,15.7c-1.1,2.5-2.1,5.2-3.1,7.7
	c-1.2,3.2-2.5,6.4-3.9,9.5c-3.9,1.6-7.2,3.4-9.9,5.8c-1.8,1.5-3.9,3.8-4,6.3c-0.1,1.1,0.3,2.2,1.1,3c0.8,0.9,1.9,1.4,3.1,1.4
	c3.8,0,7.6-5.3,8.3-6.3c1.4-2.1,2.7-4.5,4-7.3c3.3-1.2,6.7-2.1,10.1-2.9l1.2-0.3c0.9-0.2,1.9-0.5,2.8-0.8c1-0.3,2.1-0.6,3.2-0.8
	c3.5,2.2,7.2,3.6,10.8,4.2c3.1,0.4,5.8,0.2,7.6-0.8C65.6,64.6,65.7,63.3,65.7,62.8 M73.1,87c0,5.2-4.5,5.5-5.5,5.5H9
	c-5.1,0-5.5-4.6-5.5-5.5V9c0-5.2,4.6-5.5,5.5-5.5h39.6l0,0V19c0,3.1,1.9,9,9,9H73l0.1,0.1C73.1,28.1,73.1,87,73.1,87z M69.5,24.5
	H57.6c-5.1,0-5.5-4.6-5.5-5.5V7.1L69.5,24.5z M76.7,87V26.7L52.2,2.1V2h-0.1l-2-2H9C5.9,0,0,1.9,0,9v78c0,3.1,1.9,9,9,9h58.7
	C70.8,96,76.7,94.1,76.7,87"
        />
      );
     case "add":
      return (
        <path
          {...props}
          d="M441.3,213.3c0,11.7-9.6,21.3-21.3,21.3h-85.3c-11.7,0-21.3-9.6-21.3-21.3V106.7H340v96.1h24v-74.9h26.7v74.9h24v-96.1h26.7
	V213.3z M292,133.4h-53.3v23.9H292v26.8h-53.3v23.8H292v26.8h-85.3v-128H292V133.4z M185.3,234.7h-25.6L105.3,160v74.7H78.7v-128
	h26.7l53.3,74.7v-74.7h26.7V234.7z M474,0H46C20.6,0,0.2,19.1,0.2,42.7L0,298.7c0,23.6,20.6,42.7,46,42.7h428
	c25.4,0,46-19.1,46-42.7v-256C520,19.1,499.4,0,474,0z"
        />
      );
        case "upload":
            return (
                <path
                    {...props}
                    d="M3.2,10.4H8V5.6h3.2L5.6,0L0,5.6h3.2V10.4z M3.2,10.4 M0,12h11.2v1.6H0V12z"
                />
            );
    case "phone":
      return (
        <path
          {...props}
          d="M 2.56635 12.9241C 0.708307 9.55549 0 6.83983 0 5.00558C 0 3.17134 0.450658 2.64526 0.907953 2.22025C 1.36525 1.79524 3.42732 0.523908 3.77867 0.286808C 4.13002 0.0497085 5.47099 -0.41107 6.31193 0.798636C 7.15287 2.00834 8.73646 4.43718 9.82825 6.05069C 11.5415 8.33611 10.1766 9.33937 9.73572 9.94069C 8.92447 11.0472 8.45734 11.3201 8.45734 12.6788C 8.45734 14.0375 12.2545 17.8976 13.1625 18.8487C 14.0635 19.7926 17.8471 23.1094 19.0195 23.2868C 20.2002 23.4654 21.7807 22.2154 22.1168 21.8985C 23.8263 20.586 24.7912 21.581 25.5787 22.0136C 26.3661 22.4461 29.9239 24.663 31.0264 25.4103C 32.0641 26.1576 31.9992 27.3125 31.9992 27.3125C 31.9992 27.3125 29.859 30.7092 29.5996 31.1168C 29.2753 31.5924 28.4971 32 26.746 32C 24.995 32 23.1241 31.6802 18.6777 29.2349C 15.0396 27.234 11.5714 24.1009 9.75551 22.2666C 7.87475 20.4324 4.68876 16.772 2.56635 12.9241Z"
        />
      );
    case "message":
      return (
        <path
          {...props}
          d="M19.0675 0C8.5371 0 0 7.092 0 15.8401c0 5.5527 3.4417 10.4345 8.6471 13.2632.0083.0091.0221.0182.0434.0277.8433.3798.41 1.6817-.5651 2.8751-.742.9083-2.1668 1.8444-1.6251 1.8444.8667 0 2.7535-.4018 3.94-1.0597 1.7586-.9752 2.9897-1.8141 4.5758-1.469l-.0013-.0035a22.867 22.867 0 0 0 4.0527.3619c10.5309 0 19.0676-7.0916 19.0676-15.8401C38.1351 7.0921 29.5984 0 19.0675 0z"
        />
      );
    case "envelope":
      return (
        <path
          {...props}
          d="M 22.8196 15.7456C 22.0005 16.5862 20.9038 17.0508 19.7317 17.0542C 18.5632 17.0444 17.46 16.5994 16.6362 15.7646L 1.09009 0L 38.1626 0L 22.8196 15.7456ZM 0 1.06567L 11.2952 12.5818L 0 24.1392L 0 1.06567ZM 27.9519 12.623L 39.2878 24.1233L 39.2878 1.06567L 27.9519 12.623ZM 23.7844 16.9265L 27.0105 13.6885L 38.1833 25.082L 1.10547 25.082L 12.4006 13.6885L 15.6772 16.9512C 16.7539 18.0415 18.1877 18.6418 19.7185 18.6418L 19.7363 18.6418C 21.2734 18.6375 22.7109 18.0281 23.7844 16.9265Z"
        />
            );
        case "history":
            return (
                <path {...props}
                    d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"
                />
            );
    case "flash":
      return (
        <path
          {...props}
          d="M51,38.4h13.1v8.7H51V38.4z M51,51.8h13.1v8.7H51V51.8z M51,65.1h13.1v8.7H51V65.1z M33.3,65.1h13.1v8.7H33.3
	V65.1z M15.6,65.1h13.1v8.7H15.6V65.1z M28.8,60l4.4-6l4.4,6h8.1l-8.5-10.9l7.8-9.8h-7.8l-4,5.3l-3.8-5.3h-8.1l8,10L21,60H28.8z
	 M76.1,0H3.7C1.7,0,0,1.7,0,3.7v82.6c0,2,1.7,3.7,3.7,3.7h72.4c2,0,3.7-1.7,3.7-3.7V3.7C79.7,1.7,78.1,0,76.1,0z M74.6,84.9H5.1
	l0-57.5h69.5L74.6,84.9z M20.5,13.3c0-3.9,2.6-6.7,6.5-6.7c1.4,0,2.7,0.4,3.6,1c1.3,0.9,2.1,2.4,2.3,4h-3.8c-0.1-1-0.9-1.9-2.2-1.9
	c-1.8,0-2.6,1.5-2.6,3.6c0,2.1,1,3.6,2.7,3.6c1.3,0,2.1-0.9,2.2-2H33c-0.1,1.4-0.8,2.7-1.7,3.6C30.2,19.5,28.9,20,27,20
	C23.1,20,20.5,17.1,20.5,13.3z M34.2,15.6H38c0.1,1,0.9,1.5,2.2,1.5c0.7,0,1.6-0.3,1.6-1.1c0-0.8-0.9-1-2.4-1.4
	c-2.2-0.5-4.8-1.3-4.8-4c0-3,2.4-4.1,5.3-4.1c2.7,0,5.1,1.1,5.2,3.9h-3.7c-0.1-0.7-0.6-1.2-1.6-1.2c-0.8,0-1.4,0.4-1.4,0.9
	c0,0.8,1.1,0.9,3,1.3c2.2,0.5,4.3,1.4,4.3,4.1c0,2.8-2.5,4.3-5.5,4.3C36.7,20,34.2,18.5,34.2,15.6z M46.2,6.8h3.9l1.8,6
	c0.3,1.1,0.6,2.5,0.6,2.5h0c0,0,0.3-1.4,0.6-2.5l1.7-6h3.8l-4.1,12.9h-4.2L46.2,6.8z"
        />
      );
    case "support":
      return (
        <path
          {...props}
          d="M432.2,202.6L432.2,202.6l0-6.2C432.2,88.1,344.2,0,235.9,0C127.4,0,39.6,87.8,39.6,196.3v6.2C16.7,210.1,0,233.3,0,260.7
          v30.1c0,33.5,24.9,60.7,55.5,60.7H84c7.7,15.6,23.8,26.3,42.3,26.3h21.6c8.3,0,15.1-6.8,15.1-15.1v-174c0-8.3-6.8-15.1-15.1-15.1
          h-21.6c-18.5,0-34.6,10.8-42.3,26.3H69.7v-3.7c0-91.8,74.3-166.2,166.1-166.2c91.6,0,166.1,74.5,166.1,166.2v3.7h-14.2
          c-7.7-15.6-23.8-26.3-42.3-26.3H324c-8.3,0-15.1,6.8-15.1,15.1v174c0,8.3,6.8,15.1,15.1,15.1h21.6c18.5,0,34.6-10.7,42.3-26.3h20.1
          v2.9c0,54.2-44.1,98.4-98.4,98.4h-4.1c-5.4-18-22.2-31.1-41.9-31.1h-41.8c-24.2,0-43.8,19.7-43.8,43.8v2.8
          c0,24.2,19.6,43.8,43.8,43.8h41.8c19,0,35.2-12.1,41.3-29.1h4.8c70.9,0,128.5-57.7,128.5-128.6v-7.7c19.8-9.3,33.7-30.8,33.7-55.8
          v-30.1C471.8,233.3,455.1,210.1,432.2,202.6L432.2,202.6z M30.2,290.8v-30.1c0-16.8,11.4-30.5,25.3-30.5h23.6v91.1H55.5
          C41.5,321.3,30.2,307.7,30.2,290.8L30.2,290.8z M126.2,203.9h6.5v143.8h-6.5c-9.2,0-16.7-7.4-16.9-16.5V220.4
          C109.5,211.2,117.1,203.9,126.2,203.9L126.2,203.9z M221.7,481.8c-7.5,0-13.6-6.1-13.6-13.6v-2.8c0-7.5,6.1-13.6,13.6-13.6h41.8
          c7.5,0,13.6,6.1,13.6,13.6v2.8c0,7.5-6.1,13.6-13.6,13.6H221.7z M345.6,347.7h-6.5V203.9h6.5c9.2,0,16.7,7.4,16.9,16.5v110.8
          C362.2,340.3,354.7,347.7,345.6,347.7L345.6,347.7z M441.6,290.8c0,16.8-11.4,30.5-25.3,30.5h-23.6v-91.1h23.6
          c14,0,25.3,13.7,25.3,30.5V290.8z M441.6,290.8"
        />
      );
    case "trash":
      return (
        <path
          {...props}
          d="M 8.18183 4.36365L 9.27274 4.36365L 9.27274 14.7273L 8.18183 14.7273L 8.18183 4.36365ZM 6.00001 4.36365L 7.09092 4.36365L 7.09092 14.7273L 6.00001 14.7273L 6.00001 4.36365ZM 3.81819 4.36365L 4.9091 4.36365L 4.9091 14.7273L 3.81819 14.7273L 3.81819 4.36365ZM 1.67946 2.72729L 2.72509 16.3216L 10.3636 16.3637L 11.4103 2.72729L 1.67946 2.72729ZM 7.512 1.08873L 5.56309 1.09091C 5.54127 1.10455 5.454 1.22236 5.454 1.41818L 5.454 1.63638L 7.63582 1.63638L 7.63582 1.41818C 7.63582 1.22236 7.54855 1.10455 7.512 1.08873ZM 5.56309 0L 7.52673 0C 8.19928 0 8.72673 0.622909 8.72673 1.41818L 8.72673 1.63638L 12.5885 1.63638L 13.0909 1.63638L 13.0909 2.72729L 12.5046 2.72729L 11.4524 16.4056C 11.454 16.9653 10.9642 17.4546 10.3631 17.4546L 2.72676 17.4546C 2.12511 17.4546 1.63585 16.9653 1.63585 16.3637L 0.585305 2.72729L 0 2.72729L 0 1.63638L 0.501262 1.63638L 4.36309 1.63638L 4.36309 1.41818C 4.36309 0.622909 4.89054 0 5.56309 0Z"
        />
      );
      case "money":
      return (
        <path
          {...props}
          d="M9.2,2.3c0.3,0.4,0.7,0.6,1.1,0.8C10.1,3.6,10,4,9.9,4.4c-0.1,0-0.3,0-0.4,0H9.4c-0.3,0-0.7-0.1-1-0.1
          C8.3,4.1,8.1,3.8,8,3.6c0-0.1-0.1-0.2-0.1-0.2C8.3,3.2,8.8,2.8,9.2,2.3 M9.2,0L9.2,0c-0.5,0-1,0.1-1.5,0.9c-0.4,0.7-1,0.7-1.9,0.7
          c-0.8,0-1,0.6-0.6,1.2c0.2,0.3,1.5,2.5,1.8,3c0.3,0.4,1.5,0.7,2.4,0.7c0.1,0,0.1,0,0.2,0c0.9,0,2.3-0.4,2.2-1.1
          c-0.1-1.3,1.2-3,1.4-3.2c0.3-0.4,0.2-1.3-0.6-1.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.2,0-0.4-0.1-0.5-0.3
          C10.1,0.1,9.6,0,9.2,0L9.2,0z M7,5.8c0.3,0.5,1.7,0.7,2.6,0.7c0.9,0,2.3-0.4,2.2-1.1c-0.1-1.3,1.2-3,1.4-3.2
          c-0.4,0.2-0.7,0.5-1.1,0.4C11.9,2.8,11.4,4,10.4,4C9.7,4,9.6,3,9.1,3s-0.6,0.7-1,0.8C7.2,4,6.5,3.1,6,2.5c-0.2,0-0.6-0.3-0.8,0.4
          C5.4,3.1,6.7,5.3,7,5.8z M11.6,6.8c-0.7,0.3-1.5,0.5-2,0.5H9.4c-0.2,0-1.1,0-1.9-0.3C3.2,8.3,0,12.7,0,15.8C0,19.5,4.5,20,10,20
          s10-0.5,10-4.2C20,12.5,16.4,7.8,11.6,6.8z M9.6,12.5c0.1,0.1,0.3,0.3,0.6,0.5c0.6,0.2,1.1,0.5,1.4,0.8c0.3,0.3,0.5,0.8,0.5,1.4
          c0,0.5-0.2,1-0.5,1.3s-0.7,0.5-1.2,0.6V18H9.7v-0.9c-0.5-0.1-1-0.3-1.3-0.6c-0.3-0.3-0.5-0.9-0.5-1.5l0,0h1.3c0,0.4,0.1,0.7,0.2,0.9
          s0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.3-0.6-0.5
          c-0.6-0.2-1.1-0.5-1.4-0.8S8,12.7,8,12.2s0.2-1,0.5-1.3s0.7-0.5,1.2-0.6v-1h0.7v1c0.5,0.1,0.9,0.3,1.2,0.7s0.4,0.8,0.4,1.4h-1.3
          c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.3-0.5-0.3s-0.4,0.1-0.5,0.2c-0.1,0.2-0.1,0.4-0.1,0.6C9.5,12.2,9.5,12.4,9.6,12.5z"
        />
      );
      case "dashboard":
      return (
        <path
          {...props}
          d="M158.9,81.5L83.9,25c-2-1.5-4.8-1.5-6.7,0l-75,56.5C0.8,82.6,0,84.2,0,86v95.2c0,3.1,2.5,5.6,5.6,5.6h149.9
          c3.1,0,5.6-2.5,5.6-5.6V86C161.1,84.2,160.3,82.6,158.9,81.5L158.9,81.5z M89.7,175.6H71.4V135h18.3V175.6z M149.9,175.6h-49v-46.2
          c0-3.1-2.5-5.6-5.6-5.6H65.8c-3.1,0-5.6,2.5-5.6,5.6v46.2h-49V88.8l69.4-52.3l69.4,52.3V175.6z M149.9,175.6 M9,66.6l71.6-54
          l71.6,54c1,0.8,2.2,1.1,3.4,1.1c1.7,0,3.4-0.8,4.5-2.2c1.9-2.5,1.4-6-1.1-7.8l-75-56.5c-2-1.5-4.8-1.5-6.7,0l-75,56.5
          c-2.5,1.9-3,5.4-1.1,7.8C3,68,6.5,68.5,9,66.6L9,66.6z M9,66.6"

        />
      );
      case "notification":
      return (
        <path
          {...props}
          d="M176.2,137.3c-16.5-14.3-31.7-34.4-31.7-84.6C144.4,23.7,120.8,0,91.7,0C62.6,0,38.9,23.7,38.9,52.7
          c0,50.3-15.2,70.4-31.7,84.6c-4.6,4-7.2,9.7-7.2,15.7c0,3.3,2.7,6,6,6h56.9c2.8,13.3,14.6,23.4,28.8,23.4c14.1,0,26-10,28.8-23.4
          h56.9c3.3,0,6-2.7,6-6C183.4,147,180.8,141.3,176.2,137.3L176.2,137.3z M91.7,170.5c-7.5,0-13.9-4.7-16.3-11.4H108
          C105.6,165.7,99.2,170.5,91.7,170.5L91.7,170.5z M14.4,147.1c0.2-0.2,0.4-0.5,0.7-0.7c25.2-21.7,35.9-49.7,35.9-93.7
          C50.9,30.3,69.2,12,91.7,12c22.5,0,40.7,18.3,40.7,40.7c0,43.9,10.7,71.9,35.9,93.7c0.2,0.2,0.5,0.4,0.7,0.7H14.4z M14.4,147.1"
        />
      );

        case "setting":
        return(
          <path 
            {...props}
            d="M474.8,190.6l-25-4.2c-2.2-6.2-4.7-12.3-7.5-18.2l14.7-20.6c12.8-18,10.7-42.2-4.8-57.7l-30-30
            c-8.5-8.5-19.7-13.2-31.7-13.2c-9.4,0-18.4,2.9-25.9,8.3L344,69.8c-6.4-3-12.9-5.7-19.4-8l-4.1-24.5C316.8,15.7,298.3,0,276.3,0
            h-42.4c-22,0-40.6,15.7-44.2,37.3l-4.3,25.4c-6.1,2.2-12.2,4.8-18.1,7.6L147,55.8c-7.6-5.5-16.6-8.4-26.1-8.4
            c-12,0-23.3,4.7-31.7,13.2l-30.1,30c-15.5,15.5-17.6,39.8-4.8,57.7l14.9,21c-2.8,5.9-5.2,12-7.4,18.2l-24.5,4.1
            C15.7,195.3,0,213.8,0,235.8v42.4c0,22,15.7,40.6,37.3,44.2l25.4,4.3c2.2,6.1,4.8,12.2,7.6,18.1l-14.5,20.2
            c-12.8,18-10.7,42.3,4.8,57.7l30,30c8.5,8.5,19.7,13.2,31.7,13.2c9.4,0,18.4-2.9,25.9-8.3l21-14.9c5.5,2.6,11.2,4.9,17.1,7l4.2,25
            c3.6,21.7,22.2,37.4,44.2,37.4h42.5c22,0,40.6-15.7,44.2-37.4l4.2-25c6.2-2.2,12.3-4.7,18.2-7.5l20.5,14.7
            c7.6,5.5,16.6,8.4,26.1,8.4c11.9,0,23.2-4.7,31.6-13.2l30-30c15.5-15.5,17.6-39.8,4.8-57.7l-14.7-20.7c2.9-6,5.4-12.1,7.5-18.1
            l24.9-4.2c21.7-3.6,37.4-22.2,37.4-44.2v-42.3C512.2,212.9,496.6,194.3,474.8,190.6L474.8,190.6z M482,277.2
            c0,7.3-5.2,13.4-12.3,14.6l-33.1,5.5c-6.1,1-11,5.3-12.9,11.2c-2.9,9.5-7,19.3-12,28.9c-2.8,5.4-2.4,11.9,1.2,16.9l19.5,27.4
            c4.2,5.9,3.5,13.9-1.6,19.1l-30,30c-2.9,2.9-6.5,4.4-10.4,4.4c-3.1,0-6.1-0.9-8.6-2.7L354.5,413c-5-3.6-11.5-4.1-17-1.2
            c-9.2,4.8-18.9,8.8-28.9,12c-5.9,1.8-10.1,6.7-11.2,12.8l-5.6,33.1c-1.2,7.2-7.3,12.4-14.6,12.4h-42.5c-7.3,0-13.4-5.2-14.6-12.3
            l-5.5-33.1c-1-6.1-5.3-11-11.1-12.8c-9.9-3.1-19.3-7-28-11.5c-2.4-1.2-4.9-1.8-7.5-1.8c-3.3,0-6.6,1-9.4,3l-27.7,19.7
            c-2.5,1.8-5.3,2.7-8.5,2.7c-4,0-7.6-1.5-10.4-4.4l-30.1-30.1c-5.1-5.1-5.8-13.1-1.6-18.9l19.4-26.9c3.6-5.1,4.1-11.6,1.2-17.1
            c-4.9-9.2-9-18.9-12.1-28.8c-1.9-5.8-6.7-10.1-12.8-11.1l-33.5-5.7c-7.2-1.2-12.4-7.3-12.4-14.6v-42.4c0-7.3,5.2-13.4,12.4-14.6
            l32.7-5.5c6.1-1,11-5.3,12.8-11.3c3.1-10.1,7-19.8,11.8-29c2.8-5.4,2.4-11.9-1.2-16.9l-19.7-27.7c-4.2-5.9-3.5-13.9,1.6-19l30.1-30
            l0.1-0.1c2.7-2.8,6.4-4.3,10.3-4.3c3.1,0,6.1,0.9,8.6,2.7l27.1,19.5c5.1,3.6,11.6,4.1,17.1,1.1c9.2-4.9,18.9-9,28.8-12.1
            c5.8-1.8,10.1-6.7,11.1-12.8l5.7-33.5c1.2-7.2,7.3-12.4,14.6-12.4h42.4c7.3,0,13.4,5.2,14.6,12.4l5.5,32.7c1,6.1,5.4,11,11.3,12.8
            c10.1,3.1,20.2,7.2,30,12.4c5.4,2.8,11.9,2.4,16.9-1.2l27.4-19.5c2.5-1.8,5.3-2.7,8.5-2.7c4,0,7.6,1.5,10.4,4.4l30.1,30.1
            c5.1,5.1,5.8,13.1,1.6,19l-19.5,27.3c-3.6,5-4,11.5-1.2,17c4.8,9.2,8.8,18.9,12,28.9c1.8,5.9,6.7,10.1,12.8,11.2l33.1,5.6l0,0
            c7.1,1.2,12.3,7.3,12.1,14.4V277.2z M482,277.2 M256,156.5c-54.9,0-99.5,44.7-99.5,99.5s44.7,99.5,99.5,99.5s99.5-44.7,99.5-99.5
            S310.9,156.5,256,156.5L256,156.5z M256,325.5c-38.3,0-69.5-31.2-69.5-69.5s31.2-69.5,69.5-69.5s69.5,31.2,69.5,69.5
            S294.3,325.5,256,325.5L256,325.5z M256,325.5"
          />
        );

          case "warranty":
          return (
            <path
            {...props}
            d="M446.1,426L395,323.7c3.3-3.6,6.3-7.7,8.8-12c9.6-16.7,11.8-36.9,6.4-54c13.2-12.1,21.5-30.7,21.5-49.9s-8.3-37.8-21.5-49.9
            c5.4-17.1,3.3-37.3-6.4-54c-9.6-16.7-26.1-28.6-43.6-32.5c-3.9-17.5-15.8-33.9-32.5-43.6c-16.7-9.6-36.9-11.8-54-6.4
            C261.7,8.3,243.1,0,223.9,0c-19.2,0-37.8,8.3-49.9,21.5c-17.1-5.4-37.3-3.3-54,6.4c-16.7,9.6-28.6,26.1-32.5,43.6
            C70,75.3,53.5,87.2,43.9,103.9c-9.6,16.7-11.7,36.9-6.4,54c-13.2,12.1-21.5,30.7-21.5,49.9c0,19.3,8.3,37.8,21.5,49.9
            c-5.4,17.1-3.3,37.3,6.4,54c2.5,4.3,5.5,8.4,8.8,12L1.6,426c-2.3,4.7-2.1,10.2,0.7,14.6c2.7,4.4,7.6,7.1,12.8,7.1h72.8l43.7,58.3
            c2.9,3.8,7.3,6,12,6c6.2,0,11.1-3.7,13.4-8.3l45.7-91.5c6.8,2.2,13.9,3.4,21.2,3.4s14.4-1.2,21.2-3.4l45.7,91.5
            c2.3,4.6,7.2,8.3,13.4,8.3c4.7,0,9.2-2.2,12-6l43.7-58.3h72.8c5.2,0,10-2.7,12.8-7.1C448.2,436.2,448.5,430.7,446.1,426L446.1,426z
             M141,468.6l-33.6-44.9c-2.8-3.8-7.3-6-12-6H39.3l38.3-76.6c3.2,1.3,6.5,2.3,9.9,3.1c3.9,17.5,15.8,33.9,32.5,43.6
            c16.7,9.6,36.9,11.7,54,6.3c0.9,1,1.9,1.9,2.8,2.9L141,468.6z M192.3,368.5c-2.8-5.4-11.9-11.7-21.4-5.7c-7.8,5-22.4,6.8-35.9-1
            c-13.5-7.8-19.2-21.3-18.8-30.6c0.4-8.8-6.9-16-15.6-15.6c-9.3,0.4-22.8-5.3-30.6-18.8c-7.8-13.5-6-28.1-1-35.9
            c4.7-7.4,2.1-17.3-5.7-21.4c-8.2-4.3-17.1-16-17.1-31.6c0-15.6,8.9-27.3,17.1-31.6c7.8-4.1,10.5-14,5.7-21.4c-5-7.8-6.8-22.4,1-35.9
            c7.8-13.5,21.3-19.2,30.6-18.8c8.8,0.4,16-6.9,15.6-15.6c-0.4-9.3,5.3-22.8,18.8-30.6c13.5-7.8,28.1-6,35.9-1
            c7.4,4.7,17.3,2.1,21.4-5.7c4.3-8.2,16-17.1,31.6-17.1c15.6,0,27.3,8.9,31.6,17.1c4.1,7.8,13.9,10.5,21.4,5.7c7.8-5,22.4-6.8,35.9,1
            c13.5,7.8,19.2,21.3,18.8,30.6c-0.4,8.8,6.9,16,15.6,15.6c9.3-0.4,22.8,5.3,30.6,18.8c7.8,13.5,6,28.1,1,35.9
            c-4.7,7.4-2.1,17.3,5.7,21.4c8.2,4.3,17.1,16,17.1,31.6c0,15.6-8.9,27.3-17.1,31.6c-7.8,4.1-10.5,14-5.7,21.4c5,7.8,6.8,22.4-1,35.9
            c-7.8,13.5-21.3,19.2-30.6,18.8c-8.8-0.4-16,6.9-15.6,15.6c0.4,9.3-5.3,22.8-18.8,30.6c-13.5,7.8-28.1,6-35.9,1
            c-7.4-4.7-17.3-2.1-21.4,5.7c-4.3,8.2-16,17.1-31.6,17.1S196.6,376.7,192.3,368.5L192.3,368.5z M352.4,417.7c-4.7,0-9.2,2.2-12,6
            l-33.6,44.8L271,397c1-0.9,1.9-1.9,2.8-2.9c17.1,5.4,37.3,3.3,54-6.3c16.7-9.6,28.6-26.1,32.5-43.6c3.3-0.7,6.7-1.8,9.9-3.1
            l38.3,76.6H352.4z M352.4,417.7 M313.9,136.6c-10.3-8-25-6.1-33,4.1L209.1,233l-44.1-44.1c-9.2-9.2-24.1-9.2-33.3,0
            c-9.2,9.2-9.2,24.1,0,33.3l63,63c10.1,10.1,26.6,8.9,35.2-2.2L318,169.6C326,159.3,324.1,144.6,313.9,136.6L313.9,136.6z
             M313.9,136.6"
            />
          );

          case "logout":
          return (
            <path
              {...props}
              d="M412.6,0H245.1c-27.5,0-50,22.8-50,50.3v44.5c0,6.9,5.6,12.5,12.5,12.5c6.9,0,12.5-5.6,12.5-12.5V50.3
              c0-13.9,11.2-25.2,25.1-25.4h167.5c13.8,0,24.6,11.6,24.6,25.4v322.8c0.2,13.7-10.8,24.9-24.5,25.1H245.1
              c-13.8-0.1-25-11.2-25.1-25.1v-43.6c0-6.9-5.6-12.5-12.5-12.5c-6.9,0-12.5,5.6-12.5,12.5v43.6c0.1,27.6,22.4,50,50,50h167.5
              c27.5-0.1,49.7-22.5,49.6-49.9V50.3C462.2,22.8,440.2,0,412.6,0L412.6,0z M412.6,0 M42.8,224.7h295.4c6.9,0,12.5-5.6,12.5-12.5
              c0-6.9-5.6-12.5-12.5-12.5H40.1l64-74c4.5-5.3,4-13.2-1.2-17.7c-5.2-4.5-13.1-4-17.6,1.2v0l-80.7,93c-3,2.4-4.7,6.1-4.5,10
              c0.1,3.9,2,7.4,5.1,9.7l80.7,80.7c4.9,4.9,12.8,4.9,17.6,0c4.8-4.7,4.9-12.4,0.2-17.3c-0.1-0.1-0.1-0.1-0.2-0.2L42.8,224.7z
               M42.8,224.7"
            />
          );
          case "staff":
          return (
            <path
              {...props}
              d="M458.6,512c8.4,0,15.2-6.8,15.2-15.2c0-63.3-24.6-122.8-69.4-167.5c-27.2-27.2-59.8-46.9-95.4-58.3
              c43.6-25.1,73-72.1,73-125.9C382,65.1,316.9,0,236.9,0S91.8,65.1,91.8,145.1c0,53.8,29.4,100.9,73,125.9
              c-35.6,11.3-68.2,31.1-95.4,58.3C24.6,374.1,0,433.5,0,496.8c0,8.4,6.8,15.2,15.2,15.2 M122.1,145.1c0-63.3,51.5-114.8,114.8-114.8
              s114.8,51.5,114.8,114.8s-51.5,114.8-114.8,114.8S122.1,208.4,122.1,145.1z M30.9,481.6c7.8-106.8,97.2-191.4,206-191.4
              s198.2,84.5,206,191.4"
            />
          );
          case "menu":
          return (
            <path
              {...props}
              d="M0,9.6h14.4V8H0V9.6z M0,5.6h14.4V4H0V5.6z M0,0v1.6h14.4V0H0z M0,0"
            />
          );
    case "wifi":
      return (
        <path
          {...props}
          d="M 5.99609 1.51367C 7.93604 1.51367 9.7041 2.22479 11.0596 3.39136L 12 2.35657C 10.3945 0.969788 8.29785 0 5.99561 0C 3.69775 0 1.60352 0.967163 0 2.35022L 0.938965 3.38574C 2.29395 2.22217 4.05908 1.51367 5.99609 1.51367ZM 5.91553 5.9726C 5.13672 5.9726 4.42383 6.28912 3.86963 6.74567L 5.91553 9L 7.96338 6.74792C 7.40918 6.29028 6.69531 5.9726 5.91553 5.9726ZM 9.01318 5.61206C 8.19385 4.92072 7.13184 4.5 5.96875 4.5C 4.80811 4.5 3.74756 4.91882 2.9292 5.60907L 1.93457 4.5127C 3.01758 3.58954 4.42578 2.98633 5.96924 2.98633C 7.51562 2.98633 8.92578 3.59143 10.0088 4.51721L 9.01318 5.61206Z"
        />
      );
      case "chevronRight":
      return (
        <path
          {...props}
          d="M7,64.4L37.3,34c0.4-0.4,0.7-0.9,0.7-1.5c0-0.6-0.2-1.1-0.7-1.5L7,0.7C6.5,0.2,6,0,5.5,0C4.9,0,4.4,0.2,4,0.7
          L0.7,3.9C0.3,4.3,0.1,4.8,0.1,5.4c0,0.6,0.2,1.1,0.7,1.5l25.6,25.6L0.7,58.1c-0.4,0.4-0.7,0.9-0.7,1.5c0,0.6,0.2,1.1,0.7,1.5
          L4,64.4C4.4,64.8,4.9,65,5.5,65C6,65,6.5,64.8,7,64.4L7,64.4z M7,64.4"
        />
      );    
    case "chevronLeft":
      return (
        <path
          {...props}
          d="M31,1.6L0.7,32C0.2,32.4,0,32.9,0,33.5C0,34,0.2,34.5,0.7,35L31,65.3c0.4,0.4,0.9,0.7,1.5,0.7
          c0.6,0,1.1-0.2,1.5-0.7l3.3-3.3c0.4-0.4,0.7-0.9,0.7-1.5c0-0.6-0.2-1.1-0.7-1.5L11.7,33.5L37.3,7.9c0.4-0.4,0.7-0.9,0.7-1.5
          c0-0.6-0.2-1.1-0.7-1.5L34,1.6C33.6,1.2,33.1,1,32.5,1C32,1,31.5,1.2,31,1.6L31,1.6z M31,1.6"
        />
      );
      case "chevoronDown":
      return (
        <path
          {...props}
          d="M8.5,0L4.8,3.7L1.1,0L0,1.1l4.8,4.8l4.8-4.8L8.5,0z M8.5,0"
        />
    );

    case "view":
        return (
            <path
                {...props}
                d="M280.5,89.25C153,89.25,43.35,168.3,0,280.5c43.35,112.2,153,191.25,280.5,191.25S517.65,392.7,561,280.5
                C517.65,168.3,408,89.25,280.5,89.25z M280.5,408C209.1,408,153,351.9,153,280.5c0-71.4,56.1-127.5,127.5-127.5
                c71.4,0,127.5,56.1,127.5,127.5C408,351.9,351.9,408,280.5,408z M280.5,204c-43.35,0-76.5,33.15-76.5,76.5
                c0,43.35,33.15,76.5,76.5,76.5c43.35,0,76.5-33.15,76.5-76.5C357,237.15,323.85,204,280.5,204z"
                />
            );
    case "time":
        return (
            <path
                {...props}
                d="M53,42l-15-8.6V14.1c0-1.7-1.3-3-3-3c-1.7,0-3,1.3-3,3v21c0,1.1,0.6,2.1,1.5,2.6L50,47.2c1.4,0.8,3.3,0.3,4.1-1.1   C54.9,44.6,54.4,42.8,53,42z M35,0.1c-19.3,0-35,15.7-35,35c0,19.3,15.7,35,35,35c19.3,0,35-15.7,35-35C70,15.8,54.3,0.1,35,0.1z M35,64.1   c-16.1,0-29-12.9-29-29s12.9-29,29-29s29,12.9,29,29S51.1,64.1,35,64.1z"
            />
        );
    case "date":
        return (
            <path
                {...props}
                d="M453.3,42.7h-48V16c0-8.8-7.2-16-16-16s-16,7.2-16,16v26.7H138.7V16c0-8.8-7.2-16-16-16s-16,7.2-16,16v26.7h-48  C26.3,42.7,0,69,0,101.3v352C0,485.7,26.3,512,58.7,512h394.7c32.4,0,58.7-26.3,58.7-58.7v-352C512,69,485.7,42.7,453.3,42.7z   M58.7,74.7h48V112c0,8.8,7.2,16,16,16s16-7.2,16-16V74.7h234.7V112c0,8.8,7.2,16,16,16s16-7.2,16-16V74.7h48  c14.7,0,26.7,12,26.7,26.7v69.3H32v-69.3C32,86.6,44,74.7,58.7,74.7z M453.3,480H58.7C44,480,32,468,32,453.3V202.7h448v250.7  C480,468,468,480,453.3,480z"
            />
            );
    case "check":
        return (
            <path
                {...props}
                d="M213.333,0C95.518,0,0,95.514,0,213.333S95.518,426.666,213.333,426.666s213.333-95.514,213.333-213.333S331.157,0,213.333,0ZM174.2,322.918,80.264,228.987l31.309-31.309L174.2,260.3,315.093,119.4,346.4,150.711Z"
            />
            );
        case "warning":
            return (
                <path
                    {...props}
                    d="M242.751,196.508L143.937,25.358c-4.367-7.564-12.189-12.081-20.924-12.081c-8.735,0-16.557,4.516-20.924,12.081  L3.276,196.508c-4.368,7.564-4.368,16.596,0,24.161s12.189,12.081,20.924,12.081h197.629c8.734,0,16.556-4.516,20.923-12.08  C247.119,213.105,247.118,204.073,242.751,196.508z M123.014,204.906c-8.672,0-15.727-7.055-15.727-15.727  c0-8.671,7.055-15.726,15.727-15.726s15.727,7.055,15.727,15.726C138.74,197.852,131.685,204.906,123.014,204.906z M138.847,137.68  c0,8.73-7.103,15.833-15.833,15.833s-15.833-7.103-15.833-15.833V65.013c0-4.142,3.358-7.5,7.5-7.5h16.667  c4.143,0,7.5,3.358,7.5,7.5V137.68z"
                />
            );

        case "email":
            return (
                <path
                    {...props}
                    d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106    C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z     M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787    L338.213,256L482,112.212V399.787z"
                />
            );

        case "close":
            return (
                <path
                    {...props}
                    d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
                />
            );

    default:
      return <path />;
  }
};

const Icon = ({
  name = "",
  style = {},
  fill = "#212121", 
  viewBox = "",
  width = "100%",
  className = "",
  height = "100%"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name, { fill })}
  </svg>
);

export default Icon;
