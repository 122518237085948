import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, withStyles, Switch, CircularProgress, Snackbar, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { CHANGE_PASSWORD, TOGGLE_USER_STATUS, ADD_ADMIN_USER } from '../../libs/Mutations'; // Adjust the import path accordingly
import QueryHelper from '../../libs/QueryHelper'; // Adjust the import path accordingly
import ConfirmDialog from './ConfirmDialog'; // Adjust the import path accordingly
import AddAdminUserDialog from './AddAdminUserDialog'; // Adjust the import path accordingly
import UserProfile from '../auth/UserProfile';
import { SAdminList }  from '../../constants';
const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexItem: { flex: 1 },
    adminHeaderLabel: {
        marginLeft: '13px'
    },
    button: {
        margin: theme.spacing(1),
    },
    highlightedRow: {
        backgroundColor: theme.palette.action.hover,
    },
    tableHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    boldHeader: {
        fontWeight: 'bold',
    },
    searchField: {
        marginBottom: theme.spacing(2),
    },
});

const SUsers = ({ classes }) => {
    const { loading, error, data, refetch } = useQuery(QueryHelper().SUsers());
    const [changePassword] = useMutation(CHANGE_PASSWORD);
    const [toggleUserStatus] = useMutation(TOGGLE_USER_STATUS);
    const [addAdminUser] = useMutation(ADD_ADMIN_USER);
    const [statusMessages, setStatusMessages] = useState({});
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({ open: false, userId: null, action: null, message: '' });
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
    const [loadingState, setLoadingState] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [search, setSearch] = useState('');
    const currentUserId = UserProfile.getUserId();
    const allowedAdmin = SAdminList.indexOf(currentUserId) > -1;
    // Initialize users state when data is loaded
    React.useEffect(() => {
        if (data) {
            setUsers(data.admin.sUsers);
            setFilteredUsers(data.admin.sUsers);
        }
    }, [data]);

    React.useEffect(() => {
        setFilteredUsers(
            users.filter(user =>
                user.userName.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, users]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const handlePasswordChange = async (userId, newPassword) => {
        setLoadingState(prev => ({ ...prev, [`changePassword_${userId}`]: true }));
        try {
            const response = await changePassword({ variables: { userId, newPassword } });
            const updatedUser = response.data.adminMutation.changePassword;
            setUsers(prevUsers => prevUsers.map(user => (user.id === userId ? updatedUser : user)));
            setSnackbar({ open: true, message: 'Password changed successfully', severity: 'success' });
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to change password', severity: 'error' });
        }
        setLoadingState(prev => ({ ...prev, [`changePassword_${userId}`]: false }));
    };

    const handleToggleActive = async (userId, lockoutEnabled, lockoutEnd) => {
        setLoadingState(prev => ({ ...prev, [`toggleActive_${userId}`]: true }));
        try {
            const response = await toggleUserStatus({ variables: { userId, lockoutEnabled: !lockoutEnabled } });
            const updatedUser = response.data.adminMutation.toggleUserStatus;
            setUsers(prevUsers => prevUsers.map(user => (user.id === userId ? updatedUser : user)));
            setSnackbar({ open: true, message: `User ${isUserActive(updatedUser.lockoutEnabled, updatedUser.lockoutEnd) ? 'activated' : 'deactivated'} successfully`, severity: 'success' });
        } catch (error) {
            setSnackbar({ open: true, message: `Failed to ${!isUserActive(lockoutEnabled, lockoutEnd) ? 'activate' : 'deactivate'} user`, severity: 'error' });
        }
        setLoadingState(prev => ({ ...prev, [`toggleActive_${userId}`]: false }));
    };

    const handleAddAdminUser = async ({ userName, email, password, firstName, lastName }) => {
        setLoadingState(prev => ({ ...prev, addUser: true }));
        try {
            const response = await addAdminUser({ variables: { userName, email, password, firstName, lastName } });
            const newUser = response.data.adminMutation.addAdminUser;
            setUsers([...users, newUser]);
            setSnackbar({ open: true, message: 'User added successfully', severity: 'success' });
            setAddUserDialogOpen(false);
            refetch();  // Refresh the user list
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to add user', severity: 'error' });
        }
        setLoadingState(prev => ({ ...prev, addUser: false }));
    };

    const handleConfirmAction = (newPassword) => {
        const { userId, action } = confirmDialog;

        if (action === 'changePassword') {
            handlePasswordChange(userId, newPassword);
        } else if (action === 'toggleActive') {
            const user = users.find(u => u.id === userId);
            handleToggleActive(userId, user.lockoutEnabled, user.lockoutEnd);
        }

        setConfirmDialog({ open: false, userId: null, action: null, message: '' });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const isUserActive = (lockoutEnabled, lockoutEnd) => {
        return !lockoutEnabled || (lockoutEnabled && !lockoutEnd) || (lockoutEnabled && lockoutEnd && new Date(lockoutEnd) < new Date());
    };

    return (
        <div className={`${classes.container} ${classes.flexItem}`} >
            <div className={classes.tableHeader}>
                <h2 className={classes.adminHeaderLabel}>Admin Users</h2>
                {allowedAdmin  && <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => setAddUserDialogOpen(true)}
                >
                    Add Admin User
                </Button>}
            </div>
            <TextField
                className={classes.searchField}
                label="Search Users"
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.boldHeader}>Username</TableCell>
                        <TableCell className={classes.boldHeader}>Email</TableCell>
                        <TableCell className={classes.boldHeader}>Active</TableCell>
                        <TableCell className={classes.boldHeader}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredUsers.map(user => (
                        <TableRow key={user.id} className={confirmDialog.userId === user.id ? classes.highlightedRow : ''}>
                            <TableCell>{user.userName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                                {loadingState[`toggleActive_${user.id}`] ? (
                                    <CircularProgress size={24} />
                                ) : (
                                        allowedAdmin ?  <Switch
                                        checked={isUserActive(user.lockoutEnabled, user.lockoutEnd)}
                                        onChange={() => setConfirmDialog({
                                            open: true,
                                            userId: user.id,
                                            action: 'toggleActive',
                                            message: `Are you sure you want to ${isUserActive(user.lockoutEnabled, user.lockoutEnd) ? 'deactivate' : 'activate'} this user?`
                                        })}
                                        color="primary"
                                    /> : <span>Not Authorized</span>
                                )}
                            </TableCell>
                            <TableCell>
                                {loadingState[`changePassword_${user.id}`] ? (
                                    <CircularProgress size={24} />
                                ) : (
                                      allowedAdmin ?  <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setConfirmDialog({
                                            open: true,
                                            userId: user.id,
                                            action: 'changePassword',
                                            message: 'Are you sure you want to change the password for this user?'
                                        })}
                                    >
                                        Change Password
                                    </Button> : <span>Not Authorized</span>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ConfirmDialog
                open={confirmDialog.open}
                title="Confirmation"
                message={confirmDialog.message}
                onConfirm={handleConfirmAction}
                onClose={() => setConfirmDialog({ open: false, userId: null, action: null, message: '' })}
                action={confirmDialog.action}
            />
            <AddAdminUserDialog
                open={addUserDialogOpen}
                onClose={() => setAddUserDialogOpen(false)}
                onAdd={handleAddAdminUser}
            />
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default withStyles(styles)(SUsers);
