import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import $ from 'jquery';
import React from 'react';
import UserProfile from './auth/UserProfile';
import Profile from './Profile';
import TopNavigation from './TopNavigation';

import MobileNavigation from './MobileNavigation';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'none',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    margin: {
        marginRight: theme.spacing(1) * 3,
    },
    logoclass: {
        width: "auto",
        height:"46px",
    },
    menuButton: {
        marginRight:theme.spacing(1),
    },
});


class Header extends React.Component {
    
    componentDidMount() { 
        // Profile Dropdown functionality
        $('.user a').on("click",function(){
            $(this).next('.profile-dropdown').toggle();
        });
    
        $(document).on("click",function(e){
            var target = e.target;
            if (!$(target).is('.user a') && !$(target).parents().is('.user a')) {
            $('.profile-dropdown').hide();
            }
        });
        
    }
    render() {
        
        const { classes, selectedIndex, onNavigationChange, adminView } = this.props;
        
        const logoImage = UserProfile.getLogo();
        const logo = logoImage !== "null" ? <img src={UserProfile.getLogoImageUrl()} className={classes.logoclass}  alt='logo' /> : <Typography variant="h6" color="inherit">{UserProfile.getDisplayName()}  </Typography>  
        return (

            <div className={classes.root}>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar disableGutters>
                        <Hidden lgUp mdUp smUp><MobileNavigation selectedIndex={selectedIndex} onNavigationChange={onNavigationChange} /></Hidden>  {logo}
                        <Hidden xsUp >
                            <TopNavigation />   
                        </Hidden>                                            
                        <div className={classes.grow} />
                        <Profile adminView={adminView} />
                    </Toolbar>
                </AppBar>
            
            </div>
           
        );
    }
    logout(e) {
      //  new AuthService().logout();
        this.props.history.push("/login:signout");
    }
}

export default withStyles(styles)(Header); 

