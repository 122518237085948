import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    Card,
    CardContent,
    Typography,
    Chip,
    TextField,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

import { Schedule as ScheduleIcon, CheckCircle as CheckCircleIcon, Person as PersonIcon, Comment as CommentIcon, FiberManualRecord as FilledCircleIcon } from '@material-ui/icons';
import FbEmptyResult from '../../common/FbEmptyResult';
import FbError from '../../common/FbError';
import FbLoader from '../../common/FbLoader';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import QueryHelper from '../../../libs/QueryHelper';
import _ from 'lodash';
import { Fragment } from 'react';
import DownloadCSV from './DownloadCSV';



const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flex:'1'
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: theme.spacing(1),
    },
    listItemText: {
        marginBottom: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    approvedIcon: {
        color: theme.palette.success.main,
        marginRight: theme.spacing(1),
    },
    mergedIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    commentIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    closedIcon: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
    },
    assignees: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    filterContainer: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    filterLabel: {
        marginRight: theme.spacing(2),
    },
    searchInput: {
        marginLeft: theme.spacing(2),
    },
    metaData: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        ' && > span': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            margin:'0px 10px'
        },
        ' && strong': {
            fontSize: 16,
            padding:'0px 5px'
        }
    },
    metaIcons: {
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flex:'1',
        ' && > span': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            margin: '0px 10px'
        }
    },
    circle: {
        fontSize:'0.9em'
    }
}));

const AppProviderStat = (props) => {
    const classes = useStyles();
    const { loading, error, data } = useQuery(QueryHelper().AppProviderLeadershipBoard(), { variables: {  } });

    if (loading) return <FbLoader loaderStyle={'center'} />;
    if (error) return <FbError errorStyle={'default'} message='No pull request available' />;

    if (!data.admin.leadershipStats || !data.admin.leadershipStats.appProviders || data.admin.leadershipStats.appProviders.length === 0) {
        return <FbEmptyResult />;
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Total Installations</TableCell>
                        <TableCell>Total Rated Power (kW)</TableCell>
                        <TableCell>App Provider <DownloadCSV query={QueryHelper().AppProviderLeadershipBoard()} type={1} /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.admin.leadershipStats.appProviders.map((pullRequest) => (
                        <TableRow key={pullRequest.id}> {/* Ensure each pullRequest has a unique 'id' */}
                            <TableCell>{pullRequest.from}</TableCell>
                            <TableCell>{pullRequest.to}</TableCell>
                            <TableCell>{pullRequest.totalInstallations}</TableCell>
                            <TableCell>{pullRequest.totalRatedPower_kW}</TableCell>
                            <TableCell>{pullRequest.appProvider}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const AppProviderBoard = () => {
    const classes = useStyles();
    const [filter, setFilter] = useState('open');
    const [search, setSearch] = useState('');
    const handleFilterChange = event => {
        setFilter(event.target.value);
    };

    const handleSearchChange = event => {
        setSearch(event.target.value);
    };
  
    
    
    return (
        <div className={classes.root}>
         
            <div className={classes.filterContainer}>
                {<AppProviderStat />}
            </div>
        </div>
    );
};


AppProviderBoard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default AppProviderBoard;







