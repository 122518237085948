import gql from 'graphql-tag';
var GraphQlQuery = (function () {
    return {
        PRODUCTS_QUERY: gql`
query get_products($filter: String, $skip: Int, $first: Int) {
  productfeed(filter: $filter, skip: $skip, first: $first) {
    products {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      productType
      productLine {
        productLineId
        lineName
      }
      productWarranty {
        componentInYears
        performanceInYears
        componentWarrantyLink
        performanceWarrantyLink
        productWarrantyId
      }
      serialStats {
        failed
        installed
        pending
        totalRequests
      }
    }
    count
  }
}
`

        , ADMIN_PRODUCTS_QUERY: gql`
query get_products($manufacturerId: Int,$filter: String, $skip: Int, $first: Int) {
  admin(manufacturerId:$manufacturerId, filter: $filter, skip: $skip, first: $first) {
    products {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      productType
      productLine {
        productLineId
        lineName
      }
      productWarranty {
        componentInYears
        performanceInYears
        componentWarrantyLink
        performanceWarrantyLink
        productWarrantyId
      }
      serialStats {
        failed
        installed
        pending
        totalRequests
      }
    }
    count
  }
}
`
        , SUPPLIER_PRODUCTS_QUERY: gql`
query get_products($manufacturerId: Int,$filter: String, $skip: Int, $first: Int) {
  supplier(manufacturerId:$manufacturerId, filter: $filter, skip: $skip, first: $first) {
    products {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      productType
      productLine {
        productLineId
        lineName
      }
      productWarranty {
        componentInYears
        performanceInYears
        componentWarrantyLink
        performanceWarrantyLink
        productWarrantyId
      }
      serialStats {
        failed
        installed
        pending
        totalRequests
      }
    }
    count
  }
}
`,
        FAILED_PRODUCTS_SERIAL_QUERY: gql`
query getproductserials($filter: String, $skip: Int, $first: Int) {
  productfeed {
    failedSerials(filter: $filter, skip: $skip, first: $first) {
      failedSerial
      isParralelImport
      latitude
      logType
      longitude
      parralelImportComment
      productLogId
      requestedTime
      requesterAbn
      requesterName
      requesterType
      requestedBrand
      requesterAppName
      requestedModel
      resolvedOn
      status
      externalReferenceId
      image
    }
    failedSerialCount(filter: $filter)
  }
}
`,

        FAILED_ADMIN_PRODUCTS_SERIAL_QUERY: gql`
query getproductserials($manufacturerId: Int,$filter: String, $skip: Int, $first: Int) {
  admin(manufacturerId:$manufacturerId, filter: $filter, skip: $skip, first: $first) {
    failedSerials(filter: $filter, skip: $skip, first: $first) {
      failedSerial
      isParralelImport
      latitude
      logType
      longitude
      parralelImportComment
      productLogId
      requestedTime
      requesterAbn
      requesterName
      requesterType
      requestedBrand
      requesterAppName
      requestedModel
      resolvedOn
      status
      externalReferenceId
      image
    }
    failedSerialCount(filter: $filter)
  }
}
`,
        FAILED_DISTRIBUTOR_PRODUCTS_SERIAL_QUERY: gql`
query getproductserials($manufacturerId: Int,$filter: String, $skip: Int, $first: Int) {
  supplier(manufacturerId:$manufacturerId, filter: $filter, skip: $skip, first: $first) {
    failedSerials(filter: $filter, skip: $skip, first: $first) {
      failedSerial
      isParralelImport
      latitude
      logType
      longitude
      parralelImportComment
      productLogId
      requestedTime
      requesterAbn
      requesterName
      requesterType
      requestedBrand
      requesterAppName
      requestedModel
      resolvedOn
      status
      externalReferenceId
      image
    }
    failedSerialCount(filter: $filter)
  }
}
`,


        FAILED_ADMIN_GLOBAL_PRODUCTS_SERIAL_QUERY: gql`
query getproductserials($filter: String, $skip: Int, $first: Int) {
  admin(filter: $filter, skip: $skip, first: $first) {
    failedSerials(filter: $filter, skip: $skip, first: $first) {
      failedSerial
      isParralelImport
      latitude
      logType
      longitude
      parralelImportComment
      productLogId
      requestedTime
      requesterAbn
      requesterName
      requesterType
      requestedBrand
      requesterAppName
      requestedModel
      resolvedOn
      status
      externalReferenceId
      image
      manufacturerId
    }
    failedSerialCount(filter: $filter)
  }
}
`,

        ParallelImport_ADMIN_GLOBAL_PRODUCTS_SERIAL_QUERY: gql`
query getproductserials($filter: String, $skip: Int, $first: Int) {
  admin(filter: $filter, skip: $skip, first: $first) {
    parallelImportSerials(filter: $filter, skip: $skip, first: $first) {
      failedSerial
      isParralelImport
      latitude
      logType
      longitude
      parralelImportComment
      productLogId
      requestedTime
      requesterAbn
      requesterName
      requesterType
      requestedBrand
      requesterAppName
      requestedModel
      resolvedOn
      status
      externalReferenceId
      image
      manufacturerId
    }
    parallelImportCount(filter: $filter)
  }
}
`,




        PRODUCT_SERIAL_QUERY: gql`
query getproductserials($type:Int!, $filter: String, $skip: Int, $first: Int) {
  productfeed {
  productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
        image
        productSerialId
        batchId
        uploadId
      },
     productSerialCount(type:$type, filter:$filter)    
  }
}
`,

        PRODUCT_SERIAL_IMAGES_QUERY: gql`
query getproductserialimages($serial:String!, $productSerialId:Int) {
  productfeed {
  productSerialImages(serial: $serial, productSerialId: $productSerialId) {
        image
        status
        createdOn
        appName
        serial
      }
  }
}
`,


        SUPPLIER_PRODUCT_SERIAL_IMAGES_QUERY: gql`
query getproductserialimages($manufacturerId: Int!, $serial:String!, $productSerialId:Int) {
  supplier(manufacturerId : $manufacturerId) {
  productSerialImages(serial: $serial, productSerialId: $productSerialId) {
        image
        status
        createdOn
        appName
        serial
      }
  }
}
`,
        ADMIN_PRODUCT_SERIAL_IMAGES_QUERY: gql`
query getproductserialimages($manufacturerId: Int!, $serial:String!, $productSerialId:Int) {
  admin(manufacturerId : $manufacturerId) {
  productSerialImages(serial: $serial, productSerialId: $productSerialId) {
        image
        status
        createdOn
        appName
        serial
      }
  }
}
`,

        PRODUCT_SERIAL_ADMIN_QUERY: gql`
query getproductserials($manufacturerId: Int,$type:Int!, $filter: String, $skip: Int, $first: Int) {
  admin(manufacturerId:$manufacturerId) {
  productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
        image
        productSerialId
        batchId
        uploadId
      },
     productSerialCount(type:$type, filter:$filter)
    
  }
}
`,
        PRODUCT_SERIAL_DISTRIBUTOR_QUERY: gql`
query getproductserials($manufacturerId: Int,$type:Int!, $filter: String, $skip: Int, $first: Int) {
  supplier(manufacturerId:$manufacturerId) {
  productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
        image
        productSerialId
        batchId
        uploadId
      },
     productSerialCount(type:$type, filter:$filter)
    
  }
}
`,
        PRODUCT_SERIAL_ADMIN_GLOBAL_QUERY: gql`
query getproductserials($type:Int!, $filter: String, $skip: Int, $first: Int) {
  admin {
  productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
        image
        productSerialId
        manufacturerId
        batchId
        uploadId
      },
     productSerialCount(type:$type, filter:$filter)    
  }
}
`,
PRODUCT_INSTALLATION_QUERY : gql`
query get_product_installations($filter: String, $skip: Int, $first: Int) {
  productfeed {
    productInstallations(filter: $filter, skip: $skip, first: $first) {
      productInstallationId
      productSerialCount
      createdOn
      installationDate
      ownerEmail
      ownerFirstname
      address {
        addressId
        city
        country
        state
        isManualLocation
        latitude
        longitude
        postCode
        streetType
        unitType
      }
    
     
    }
    productInstallationCount(filter:$filter)
  }
}
`,

         PRODUCT_INSTALLATION_ADMIN_QUERY : gql`
query get_product_installations($manufacturerId: Int, $filter: String, $skip: Int, $first: Int) {
   admin(manufacturerId:$manufacturerId) { 
    productInstallations(filter: $filter, skip: $skip, first: $first) {
      productInstallationId
      productSerialCount
      createdOn
      installationDate
      ownerEmail
      ownerFirstname
      address {
        addressId
        city
        country
        state
        isManualLocation
        latitude
        longitude
        postCode
        streetType
        unitType
      }
    
     
    }
    productInstallationCount(filter:$filter)
  }
}
`
        ,
        PRODUCT_INSTALLATION_SUPPLIER_QUERY: gql`
query get_product_installations($manufacturerId: Int, $filter: String, $skip: Int, $first: Int) {
   supplier(manufacturerId:$manufacturerId) { 
    productInstallations(filter: $filter, skip: $skip, first: $first) {
      productInstallationId
      productSerialCount
      createdOn
      installationDate
      ownerEmail
      ownerFirstname
      address {
        addressId
        city
        country
        state
        isManualLocation
        latitude
        longitude
        postCode
        streetType
        unitType
      }
    
     
    }
    productInstallationCount(filter:$filter)
  }
}
`
        ,

         PRODUCT_INSTALLED_SERIALS_BY_PRODUCT_ID_QUERY : gql`
query getproductserials($id:Int!,$type:Int!, $filter: String, $skip: Int, $first: Int) {
  productfeed {
    product(id:$id) {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      productType
      productLine {
        lineName
      }
      productWarranty {
        componentInYears
        performanceInYears
        componentWarrantyLink
        performanceWarrantyLink
        productWarrantyId
      }
      serialStats {
        failed
        installed
        pending
        totalRequests
      }
      productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
      },
     serialCount(type:$type, filter:$filter)
    }
  }
}
`
        ,

        PRODUCT_INSTALLED_SERIALS_BY_PRODUCT_ID_SUPPLIER_QUERY: gql`
query getproductserials($id:Int!,$manufacturerId:Int!,$type:Int!, $filter: String, $skip: Int, $first: Int) {
  supplier {
    product(id:$id,manufacturerId:$manufacturerId) {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      productType
      productLine {
        lineName
      }
      productWarranty {
        componentInYears
        performanceInYears
        componentWarrantyLink
        performanceWarrantyLink
        productWarrantyId
      }
      serialStats {
        failed
        installed
        pending
        totalRequests
      }
      productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
      },
     serialCount(type:$type, filter:$filter)
    }
  }
}
`
        ,


        PRODUCT_PENDING_SERIALS_BY_PRODUCT_ID_QUERY : gql`
query getproductserials($id:Int!,$type:Int!, $filter: String, $skip: Int, $first: Int) {
  productfeed {
    product(id:$id) {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      productType
      productLine {
        lineName
      }
      productWarranty {
        componentInYears
        performanceInYears
        componentWarrantyLink
        performanceWarrantyLink
        productWarrantyId
      }
      serialStats {
        failed
        installed
        pending
        totalRequests
      }
      productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
      },
     serialCount(type:$type, filter:$filter)
    }
  }
}
`,
        PRODUCT_PENDING_SERIALS_BY_PRODUCT_ID_SUPPLIER_QUERY: gql`
query getproductserials($id:Int!,$manufacturerId:Int!,$type:Int!, $filter: String, $skip: Int, $first: Int) {
  supplier {
    product(id:$id,manufacturerId:$manufacturerId) {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      productType
      productLine {
        lineName
      }
      productWarranty {
        componentInYears
        performanceInYears
        componentWarrantyLink
        performanceWarrantyLink
        productWarrantyId
      }
      serialStats {
        failed
        installed
        pending
        totalRequests
      }
      productSerials(type:$type, filter:$filter,skip:$skip,first:$first) {
        serial
        model
        recordId
        uploadedOn
        ff
        isc
        pmax
        ipm
        voc
        vpm
        installationId
        supplier
      },
     serialCount(type:$type, filter:$filter)
    }
  }
}
`
        , PRODUCT_FAILED_SERIALS_BY_PRODUCT_ID_QUERY : gql`
query getproductserials($id:Int!, $filter: String, $skip: Int, $first: Int) {
  productfeed {
    product(id:$id) {
   
     productFailedSerials(filter:$filter,skip:$skip, first:$first){
      failedSerial
      isParralelImport
      latitude
      logType
      longitude
      parralelImportComment
      productLogId
      requestedTime
      requesterAbn
      requesterName
      requesterType
      resolvedOn
      status
      externalReferenceId

    }
    productFailedSerialsCount(type:$type, filter:$filter)
      
    }
  }
}
`
        , PRODUCT_FAILED_SERIALS_BY_PRODUCT_ID_SUPPLIER_QUERY: gql`
query getproductserials($id:Int!,$manufacturerId:Int!, $filter: String, $skip: Int, $first: Int) {
  supplier {
    product(id:$id,manufacturerId:$manufacturerId) {
   
     productFailedSerials(filter:$filter,skip:$skip, first:$first){
      failedSerial
      isParralelImport
      latitude
      logType
      longitude
      parralelImportComment
      productLogId
      requestedTime
      requesterAbn
      requesterName
      requesterType
      resolvedOn
      status
      externalReferenceId

    }
    productFailedSerialsCount(type:$type, filter:$filter)
      
    }
  }
}
`,
        HISTORY_MANUFACTURER_QUERY : gql`
query get_History($filter: String, $skip: Int, $first: Int){
  manufacturer {
    history(filter:$filter,skip:$skip, first:$first){
        uploadId
        batchId
        sourceFileName
        reportFileName
        totalRecords
        success
        failed
        type
        manufacturerId
        uploadedOn
         isDeleted
      deletedOn
      deletedBy
      restoredFrom
      user {
        firstName
        lastName
        email
      }
   },
   historyCount(filter:$filter)
  }
}
`,

        HISTORY_ADMIN_QUERY: gql`
query get_History($manufacturerId: Int!,$filter: String, $skip: Int, $first: Int) {
  admin(manufacturerId: $manufacturerId,filter:$filter,skip:$skip, first:$first) {
    history {
      uploadId
      batchId
      sourceFileName
      reportFileName
      totalRecords
      success
      failed
      type
      manufacturerId
      uploadedOn
      uploadedBy
      ipAddress
      distributorId
      systemFileName
      reportSystemFileName
      isDeleted
      deletedOn
      deletedBy
      restoredFrom
      user {
        firstName
        lastName
        email
      }
    },
    historyCount
  }
}
`,

        HISTORY_DISTRIBUTOR_QUERY: gql`
query get_History($manufacturerId:Int!,$filter: String, $skip: Int, $first: Int){
  supplier(manufacturerId:$manufacturerId,filter: $filter, skip:$skip, first:$first) {
    history{
        uploadId
        batchId
        sourceFileName
        reportFileName
        totalRecords
        success
        failed
        type
        manufacturerId
        uploadedOn
         isDeleted
      deletedOn
      deletedBy
      restoredFrom
      user {
        firstName
        lastName
        email
      }
   },
   historyCount
  }
}
`
        ,
        SERIAL_HISTORY_DISTRIBUTOR_QUERY: gql`
query get_HistoryForSerial($productSerialId:Int!,$manufacturerId:Int!){
  supplier {
    historyForSerial(productSerialid:$productSerialId,manufacturerId:$manufacturerId){
        uploadId
        batchId
        sourceFileName
        reportFileName
        totalRecords
        success
        failed
        type
        manufacturerId
        uploadedOn
         isDeleted
      deletedOn
      deletedBy
      restoredFrom
      user {
        firstName
        lastName
        email
      }
   }
  }
}
`,

        SERIAL_HISTORY_MANUFACTURER_QUERY : gql`
query get_HistoryForSerial($productSerialId:Int!){
  manufacturer {
    historyForSerial(productSerialid:$productSerialId){
        uploadId
        batchId
        sourceFileName
        reportFileName
        totalRecords
        success
        failed
        type
        manufacturerId
        uploadedOn
         isDeleted
      deletedOn
      deletedBy
      restoredFrom
      user {
        firstName
        lastName
        email
      }
   }
  }
}
`,

            SERIAL_HISTORY_ADMIN_QUERY: gql`
query get_HistoryForSerial($productSerialId:Int!,$manufacturerId:Int!){
  admin {
    historyForSerial(productSerialid:$productSerialId,manufacturerId:$manufacturerId){
      uploadId
      batchId
      sourceFileName
      reportFileName
      totalRecords
      success
      failed
      type
      manufacturerId
      uploadedOn
      uploadedBy
      ipAddress
      distributorId
      systemFileName
      reportSystemFileName
      isDeleted
      deletedOn
      deletedBy
      restoredFrom
      user {
        firstName
        lastName
        email
      }
    }
  }
}
`
,
      MANUFACTURER_PRODUCT_INSTALLATION_SERIALS_QUERY : gql`
query get_products($id: Int!) {
  productfeed {
    productInstallation(id: $id) {
      serials {
        ff
        installationId
        ipm
        isc
        model
        pmax
        productSerialId
        recordId
        serial
        brandName
      }
    }
  }
}
`,
        SUPPLIER_PRODUCT_INSTALLATION_SERIALS_QUERY: gql`
query get_products($manufacturerId: Int,$id: Int!) {
supplier(manufacturerId:$manufacturerId) {
    productInstallation(id: $id) {
      serials {
        ff
        installationId
        ipm
        isc
        model
        pmax
        productSerialId
        recordId
        serial
        brandName
      }
    }
}
}
`,

  ADMIN_PULL_REQUEST_INFO_QUERY : gql`
  query GetPullRequestInfo($filter: String!,$search: String!) {
      admin {
    mergeRequests(filter: $filter,search:$search) {
      number
      title
      description
      createdBy
      createdAt
      assignees
      status
      reviewers
      reviewApproved
      comments
      isMerged
      isClosed
    }
  }
  }
`,

        ADMIN_PRODUCT_INSTALLATION_SERIALS_QUERY : gql`
query get_products($manufacturerId: Int,$id: Int!) {
admin(manufacturerId:$manufacturerId) {
    productInstallation(id: $id) {
      serials {
        ff
        installationId
        ipm
        isc
        model
        pmax
        productSerialId
        recordId
        serial
        brandName
      }
    }
  }
}
`,

        Supplier_QUERY: gql`
query get_supplier {
  manufacturer {
    distributors{
       distributorId
       abn
       distributorNames
       distributorInternalCode
       isActive
       defaultName
       totalManufacturers
       userName
       distributorUserId
       manufacturerNames
       signingKey
   }
  }
}
`
        , ADMIN_Overall_Supplierlist_QUERY: gql`
query get_suppliers {
admin {
    distributors{
       distributorId
       abn
       distributorNames
       distributorInternalCode
       isActive
       defaultName
       totalManufacturers
       userName
       distributorUserId
       manufacturerNames
       signingKey
   }
  }
}
`
        , ADMIN_Overall_Supplierlist_WITH_FILTER_QUERY: gql`
query get_suppliers($filter: String!) {
admin {
    distributors(filter: $filter){
       distributorId
       abn
       distributorNames
       distributorInternalCode
       isActive
       defaultName
       totalManufacturers
       userName
       distributorUserId
       manufacturerNames
       signingKey
   }
  }
}
`   ,

        ADMIN_Supplier_QUERY: gql`
query get_supplier($manufacturerId: Int) {
admin(manufacturerId:$manufacturerId) {
    distributors{
       distributorId
       abn
       distributorNames
       distributorInternalCode
       isActive
       defaultName
       totalManufacturers
       userName
       distributorUserId
       manufacturerNames
       signingKey
       
   }
  }
}
`  ,
        ADMIN_WeeklySupplier_WITH_FILTER_QUERY: gql`
query get_weekly_suppliers($filter: String!) {
admin {
    weeklySuppliers(filter: $filter){
       importerName,
       importerABN
   }
  }
}
`  ,

        ADMIN_Logs_QUERY: gql`
query get_logs($filter: String, $skip: Int, $first: Int,$requestType: Int) {
admin {
    allLogs(filter: $filter, skip: $skip, first: $first , requestType : $requestType){
       productLogId
failedSerial
latitude
longitude
isParralelImport
parralelImportComment
requesterName
requesterAbn
requesterType
requestedTime
resolvedOn
status
logType
externalReferenceId
requestBatchId
requesterAppName
uploadBatchId
requestedBrand
requestedModel
       
   }
  }
}
`
        ,

        ADMIN_MANUFACTURER_LEADERSHIP_BOARD_QUERY: gql`
  query GetManufacturers($from: Date, $to: Date, $search: String) {
    admin {
      leadershipStats {
        manufacturers(from: $from, to: $to, search: $search) {
          from
          to
          totalInstallations
          totalRatedPower_kW
          displayName
        }
      }
    }
  }
`,
        ADMIN_APP_PROVIDER_BOARD_QUERY: gql`
  query GetAppLeadershipBoardQuery {
      admin {
    leadershipStats {
      appProviders {
        from
        to
        totalInstallations
        totalRatedPower_kW
        appProvider
      }
    }
  }
  }
`,
        ADMIN_CITY_LIST: gql`
 query GetCityList($search: CitySearchInput) {
    admin {
        cityList(search: $search) {
            cityName
            stateId
            createdOn
            updatedOn
            createdBy
            updatedBy
            postalCode
            stateName
        }
    }
}
`,
  GET_SUsers : gql`
  query GetSUser {
    admin { sUsers {
      id
      userName
      email
      lockoutEnd
      lockoutEnabled
    }
    }
  }
`
    };
});

export default GraphQlQuery;
