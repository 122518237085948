import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React from 'react';
export const AssignUsersToGroup = gql`
mutation assignUser($assignUserToGroupInput: AssignUserToGroupInput!) {
  assignNotificationUser(assignUserToGroupInput:$assignUserToGroupInput){
   status
  }
}
`;
const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',

    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        minWidth: 300,
        maxWidth: 400,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function TransferList(props) {
    
    const { assigned, available, groupId, manufacturerId } = props.data;
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(available);
    const [right, setRight] = React.useState(assigned);
    const leftIntArray = (left || []).map((item) => { return item.notificationUserId });
    const rightIntArray = (right || []).map((item) => { return item.notificationUserId });
    const leftChecked = intersection(checked, leftIntArray);
    const rightChecked = intersection(checked, rightIntArray);
    const [addUsersToGroup, { loading, error },] = useMutation(AssignUsersToGroup);
  
    const prepareMutationVariable = (rightItems) => {
        
        const rightItemsArray = rightItems.map(function (rItem) { return rItem.notificationUserId });

        let user = { "assignUserToGroupInput": { "userIds": rightItemsArray, "groupId": groupId, "manufacturerId": manufacturerId } };
        return user;
    }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = items => intersection(checked, items).length;

    const handleToggleAll = items => () => {
        const intItemArray = items.map((item) => { return item.notificationUserId });
        if (numberOfChecked(intItemArray) === items.length) {
            setChecked(not(checked, intItemArray));
        } else {
            setChecked(union(checked, intItemArray));
        }
    };
    const getItems = (itemsWithObject, intArray) => itemsWithObject.filter((item) => { return intArray.indexOf(item.notificationUserId) !== -1 });

    const handleCheckedRight = () => {
        const moveLeftItemToRight = getItems(left, leftChecked);
        const rightItems = right.concat(moveLeftItemToRight);
        setRight(rightItems);
        setLeft(getItems(left, not(leftIntArray, leftChecked)));
        setChecked(not(checked, leftChecked));
        addUsersToGroup({ variables: prepareMutationVariable(rightItems) });
    };

    const handleCheckedLeft = () => {
        const moveRightItemToLeft = getItems(right, rightChecked);
        setLeft(left.concat(moveRightItemToLeft))
        //setLeft(getItems(left,leftIntArray.concat(rightChecked)));
        const rightItems = getItems(right, not(rightIntArray, rightChecked));
        setRight(rightItems);
        setChecked(not(checked, rightChecked));
        addUsersToGroup({ variables: prepareMutationVariable(rightItems) });

    };

    const customList = (title, items , intIds) => (
        <Card key={title + '_header_' + items.length}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(intIds) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(intIds) !== items.length && numberOfChecked(intIds) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}

                    />
                }
                title={title}
                subheader={`${numberOfChecked(intIds)}/${items.length} selected`}
            />
            <Divider />
            <List key={title + '_' + items.length} className={classes.list} dense component="div" role="list">
                {items.map(value => {
                    const labelId = `transfer-list-all-item-${value.notificationUserId}-label`;

                    return (
                        <ListItem key={value.notificationUserId} role="listitem" button onClick={handleToggle(value.notificationUserId)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value.notificationUserId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.email}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
    console.log('left-' + groupId +''+ left.length);
    console.log('right-' + groupId +''+ right.length);
    return (
        <Grid key={"grid_item_" + groupId+''+ left.length+"_r_"+right.length} container spacing={2} justify="flex-start" alignItems="center" className={classes.root}>
            <Grid key={"grid_item_" + groupId + 'l'  + left.length} item>{customList('Available Contacts', left, left.map((item) => { return item.notificationUserId }))}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right">&gt;</Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left">&lt;</Button>
                </Grid>
            </Grid>
            <Grid key={"grid_item_" + groupId + 'r'+ right.length} item>{customList('Assigned Contacts', right, right.map((item) => { return item.notificationUserId }))}</Grid>
        </Grid>
    );
}
