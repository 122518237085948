import React, { useState, useEffect } from 'react';
import { TextField, Button,Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CreatePullRequest = () => {
    const [brandOptions, setBrandOptions] = useState([]);
    const [importerDetails, setImporterDetails] = useState({
        importerName: '',
        importerStatus: '',
        importerABN: '',
    });
    const [formData, setFormData] = useState({
        brand: '',
    });

    useEffect(() => {
        // Fetch brand options from API and update the brandOptions state
        fetchBrandOptions();
    }, []);

    const fetchBrandOptions = async () => {
        try {
            // Make API call to fetch brand options
            const response = await fetch('/api/brands'); // Replace with your API endpoint
            const data = await response.json();
            setBrandOptions(data);
        } catch (error) {
            console.error('Error fetching brand options:', error);
        }
    };

    const handleBrandChange = (e, value) => {
        setFormData((prevData) => ({
            ...prevData,
            brand: value || '',
        }));
        if (value) {
            fetchImporterDetails(value);
        } else {
            // Clear importer details if brand is cleared
            setImporterDetails({
                importerName: '',
                importerStatus: '',
                importerABN: '',
            });
        }
    };

    const fetchImporterDetails = async (brand) => {
        try {
            // Make API call to fetch importer details based on selected brand
            const response = await fetch(`/api/importers?brand=${brand}`); // Replace with your API endpoint
            const data = await response.json();
            setImporterDetails(data);
        } catch (error) {
            console.error('Error fetching importer details:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Make API call to save the application request with selected brand and importer combination
        saveApplicationRequest(formData.brand, importerDetails);
    };

    const saveApplicationRequest = (brand, importerDetails) => {
        // Make API call to save the application request
        // Use the selected brand and importerDetails to create the request payload
        console.log('Selected Brand:', brand);
        console.log('Importer Details:', importerDetails);
        // Reset the form
        setFormData({
            brand: '',
        });
        setImporterDetails({
            importerName: '',
            importerStatus: '',
            importerABN: '',
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Autocomplete
                        name="brand"
                        options={brandOptions}
                        getOptionLabel={(option) => option}
                        value={formData.brand}
                        onChange={handleBrandChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Brand" required />
                        )}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Importer Name"
                        value={importerDetails.importerName}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Importer Status"
                        value={importerDetails.importerStatus}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Importer ABN"
                        value={importerDetails.importerABN}
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
                <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreatePullRequest;