import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import ArrowBack from '@material-ui/icons/ArrowBack';

import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const styles = {
    root: {
        // marginBottom: theme.spacing(2),
    },
    button: {
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        '&:hover': {
            background: theme.palette.common.green
        }
    },
    customicon: {
        marginRight: theme.spacing(2),
    },
    btnCancel: {
        marginRight: 20,
    },
    paddingNone: {
        paddingBottom: 0,
        overflowY:"initial"
    },
     formControl: {
        width: '100%',
      
    },
    content: {
        paddingBottom: 0,
    },
    space: {
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    inputroot: {
        background: theme.palette.grey[200],

        "&:hover": {
            background: theme.palette.grey[100],
            borderBottom: 'none',
        },
        '&:after': {
            borderBottom: 'none',
        },
        '&:before': {
            borderBottom: 'none',
        },
        '&:focus': {
            borderBottom: 'none',
        },
    },
    align: {
        verticalAlign: "middle",
        marginLeft: theme.spacing(1),
    },
    titleContainer: {
        display:'flex'
    }
};

class detailHeader extends React.Component {
    state = {
        open: false, fullWidth: true,
        maxWidth: 'sm',
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes,manufacturer } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid className={classes.titleContainer} item xs={12} sm={6}>                   
                      <IconButton aria-label="ArrowForward" size="small" component={Link} to="/">
                            <ArrowBack fontSize="inherit" /> 
                        </IconButton>
                        <Typography variant="h1" inline className={classes.align}>
                            {manufacturer.displayName} 
                        </Typography>
                       
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Button variant="contained" size="large" onClick={this.handleClickOpen} color="primary" className={classes.button}>
                            <Customicon name="flash" width={19} fill="#fff" className={classes.customicon} />Add new member
                        </Button>                
                    </Grid> */}
                </Grid>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                >
                    <DialogTitle id="form-dialog-title">Add New Staff Member</DialogTitle>
                    <DialogContent className={classes.paddingNone}>
                        <Grid container spacing={16}>
                            <Grid item sm={6}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="name">Name</InputLabel>
                                    <FilledInput id="name" type="text" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>

                            </Grid>
                            <Grid item sm={6}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="Username">Email/Username</InputLabel>
                                    <FilledInput id="Username" type="email" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>

                            </Grid>
                            <Grid item sm={6}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="password">password</InputLabel>
                                    <FilledInput id="password" type="text" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>

                            </Grid>
                            <Grid item sm={6}>
                                <FormControl className={classes.formControl} variant="filled">
                                    <InputLabel htmlFor="component-password">Confrim password</InputLabel>
                                    <FilledInput id="component-password" type="text" classes={{ root: classes.inputroot, }} disableUnderline />
                                </FormControl>
                            </Grid>                  
                            <Grid item sm={12}></Grid>                       
                        </Grid>          
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" className={classes.btnCancel}>
                            Save
                         </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default withStyles(styles)(detailHeader);