import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState } from "react";
export default function ProgressButton(props) {
    const [isloading, setisloading] = useState(0);
    function loadingComplete() {
        setisloading(false);
    }


    return (
        <Button className={props.className} variant={props.variant} color={ props.color}  startIcon={props.startIcon}
          onClick={() => { setisloading(true); props.onAction(props.data, () => { loadingComplete(); });} } {...isloading === true ? { disabled: true } : ''} >
            {isloading ?
                <CircularProgress classes={{ root: props.classes.circleWhite }} size={19}  /> :
                <span>{ props.title}</span>
            }
        </Button>
    );
}