import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Snackbar,
    TextField
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input'

import FbEmptyResult from '../common/FbEmptyResult';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { refreshAppCerts, refreshInstallers, refreshManufacturerProducts, refreshProducts, refreshReferenceData, fetchCertInfo, fetchAnalyticsInfo } from '../../Helper'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flex:'1'
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: theme.spacing(1),
    },
    circularProgress: {
        margin: "0 10px"
    },
    listItemText: {
        marginBottom: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    approvedIcon: {
        color: theme.palette.success.main,
        marginRight: theme.spacing(1),
    },
    mergedIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    commentIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    closedIcon: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
    },
    assignees: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    filterContainer: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    filterLabel: {
        marginRight: theme.spacing(2),
    },
    searchInput: {
        marginLeft: theme.spacing(2),
    },
    metaData: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        marginTop:'15px',
        ' && > span': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            margin:'0px 10px'
        },
        ' && strong': {
            fontSize: 16,
            padding:'0px 5px'
        }
    },
    metaIcons: {
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flex:'1',
        ' && > span': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            margin: '0px 10px'
        }
    },
    circle: {
        fontSize:'0.9em'
    }
}));

const ActionItems = (props) => {
    const classes = useStyles();
    const items = ["CEC Installers", "CEC Products", "Manufacturer Products", "Reference Data", "Newly Approved Apps", "Cert Info","Download Analytics Report"];
    const itemDescription = {
        "CEC Installers": "This feature syncs the installers data from CEC API, use if installer data is missing/outdated",
        "CEC Products": "This feature syncs the overall list of brand/models from CEC API",
        "Manufacturer Products": 'This feature syncs the manufacturers\' brand/models from the CEC API. It should be used when brand/mode/expiry data is missing or outdated. \n Please note that this feature relies on the "CEC Products" data, so make sure to refresh the Product List before clicking on this button.',
        "Reference Data": "This feature syncs reference data changes when approved by CER pull requests.This file contains the list of participating MANUFACTURES, SUPPLIERS and APP PROVIDERS",
        "Newly Approved Apps": "This feature syncs reference data changes when CER approves pull requests for newly approved app providers. It resolves situations where new app providers complain about being unable to access the SPV Verification service. It breaks the cache and refreshes the App Provider List.",
        "Cert Info": "This feature reads the certificate from keyvault and displays the information",
        "Download Analytics Report": " Allow to download weekly or custom date based SPV installation data for analysis"
    };
    const itemAdditionalNotes = {
        "CEC Installers": "Sync operation completes between 60-120 seconds",
        "CEC Products": "Sync operation completes between 60-120 seconds",
        "Manufacturer Products": 'Sync operation completes between 60-120 seconds',
        "Reference Data": "Sync operation completes around in 30 seconds",
        "Newly Approved Apps": "Sync operation completes around in 30 seconds",
        "Cert Info": "Beneficial for raising MR",
        "Download Analytics Report": " Needed by internal team"
    };
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [result, setResult] = useState('');
    const [action, setAction] = useState('');
    const [currentLoader, setCurrentLoader] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [snackBarState, setSnackBarState] = useState(false);
    const showSnackbarAlert=(status)=>{
        setErrorMessage(status ? "Refreshed Succesfully" : "Some error occured, please try again");
        setSnackBarState(true);
    }
    const refreshAction = (action) => {
        setCurrentLoader([...currentLoader, action]);
        if (action == "CEC Installers") {

            refreshInstallers().then((data) => {
                removeElementByName(action);
                showSnackbarAlert(data.status);
                
            }).catch((error) => { removeElementByName(action); });
        }
        else if (action == "CEC Products") {
            refreshProducts().then((data) => {
                removeElementByName(action);
                showSnackbarAlert(data.status);
            }).catch((error) => { removeElementByName(action); });
        }
        else if (action == "Reference Data") {
            refreshReferenceData().then((data) => {
                removeElementByName(action);
                showSnackbarAlert(data.status);
            }).catch((error) => { removeElementByName(action); });
        }
        else if (action == "Manufacturer Products") {
            refreshManufacturerProducts().then((data) => {
                removeElementByName(action);
                showSnackbarAlert(data.status);
            }).catch((error) => { removeElementByName(action); });
        }
        else if (action == "Newly Approved Apps") {
            refreshAppCerts().then((data) => {
                removeElementByName(action);
                showSnackbarAlert(data.status);
            }).catch((error) => { removeElementByName(action); });
        }
        else if (action == "Cert Info") {

            setOpen(true);
            removeElementByName(action);
        }
        else if (action == "Download Analytics Report") {
            setAction(action);
            setOpen(true);
            removeElementByName(action);
        }

        
    }
    const fetchData = () => {
        if (action == "Download Analytics Report") {
            fetchAnalyticsInfo(from,to).then((data) => {
                setResult(JSON.stringify(data));

            }).catch(error => {

                console.error(error);
            }
            );
        }
        else {
            fetchCertInfo(text).then((data) => {
                setResult(JSON.stringify(data));

            }).catch(error => {

                console.error(error);
            }
            );
        }
    };
    const removeElementByName = (name) => {
        const newArray = currentLoader.filter((item) => item !== name);
        setCurrentLoader(newArray);
    };
    const itemGroup= items.length > 0 ? (
        items.map(action => (
            <Card className={classes.card} >
                <CardContent className={classes.cardContent}>
                    <Typography variant="h3" component="h3" gutterBottom>
                        {action}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {
                            
                            itemDescription[action].split('\n').map((line, index) => (
                                  <React.Fragment key={index}>
                                      {line}
                                      <br />
                                  </React.Fragment>
                              ))
                        }
                    </Typography>
                    <Typography variant="subtitle2" >
                        Note:{

                            itemAdditionalNotes[action]
                        }
                    </Typography>
                    <Typography variant="div" color="textSecondary" className={classes.metaData}>
                        <Button color="primary" size="small" variant="outlined" onClick={() => refreshAction(action)}>
                            Sync Now { currentLoader.includes(action) && (<CircularProgress className={classes.circularProgress} size={16} />) }
                        </Button>
                      
                    </Typography>
                    
                </CardContent>
            </Card>
        ))) : (
        <FbEmptyResult />
    );
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackBarState}
                autoHideDuration={3000}
                onClose={() => { setSnackBarState(false); }}
                message={errorMessage}
            />
            <Dialog open={open} onClose={() => { setOpen(false); setResult(''); }}>
                <DialogTitle>Dialog Title</DialogTitle>
                <DialogContent>
                    {action == "Download Analytics Report" && (<>
                           
                        <Input value={from} inputProps={{ 'aria-label': 'description', 'type': 'datetime-local' }} onChange={e => setFrom(e.target.value)} />
                        <Input value={to} inputProps={{ 'aria-label': 'description', 'type': 'datetime-local' }} onChange={e => setTo(e.target.value)} />
                            <Button onClick={fetchData} color="primary">
                                Fetch Data
                            </Button>
                    </>)
                    }
                    {action != "Download Analytics Report" && (<>
                        <TextField
                            label="Enter Cert Name"
                            value={text}
                            onChange={e => setText(e.target.value)}
                        />
                        <Button onClick={fetchData} color="primary">
                            Fetch Data
                        </Button>
                    </>)
                    }


                    <p>{result}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false); setResult(''); }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <>
                {itemGroup}
            </>
        </>);
    
}

const Miscellaneous = () => {
    const classes = useStyles();
  
    return (
        <div className={classes.root}>
            <Typography variant="h2" gutterBottom>
                Miscellaneous Operations
            </Typography>
            {<ActionItems />}
          
        </div>
    );
};


Miscellaneous.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Miscellaneous;







