import React from 'react';
class EmptyCell extends React.Component{

    
    render() {
            return  <td>&nbsp;</td>;
    }
}

export default EmptyCell;
