import React from 'react';
import _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    tableRow: {
        height: "auto"
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "top",
        padding: theme.spacing(2),
        height: "auto",
        whiteSpace: "nowrap",
    },

}))(TableCell);

class SerialHeader extends React.Component {


    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_header_row');

    }

    render() {
        const { classes } = this.props;
        return (

            <TableRow key={this._id} className={classes.tableRow}>
                <CustomTableCell>Serial Number</CustomTableCell>
                <CustomTableCell>Date</CustomTableCell>
                <CustomTableCell >Requested By</CustomTableCell>
                <CustomTableCell > Request Type</CustomTableCell>
                <CustomTableCell >Location</CustomTableCell>
                
                <CustomTableCell>Action</CustomTableCell>
            </TableRow>




        );




    }
}

export default withStyles(styles)(SerialHeader);
