import React, { useState } from 'react';
import { Paper } from '@material-ui/core/';
import { red,blue } from '@material-ui/core/colors';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles, alpha } from '@material-ui/core/styles';
import ManufacturerLeadershipBoardWrapper from './Stats/ManufacturerLeadershipBoardWrapper';
import AppProviderBoard from './Stats/AppProviderBoard';
import { useQuery } from '@apollo/react-hooks';
import {
    Toolbar,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}
const fontprimary = "1rem";
const fontsecondary = "0.9rem";
const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        background: theme.palette.grey[50],
    },
    contentPaper: {
        margin: theme.spacing(2),
        // padding: theme.spacing(2),
        background: theme.palette.common.white,
        // border: "1px solid",
        borderColor: theme.palette.common.borderColor, overflowX: 'auto',
    },

    fieldsection: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    listroot: {
        width: "320px",
        whiteSpace: "normal",
    },
    root: {
        padding: 0,
        display: 'block',
        minWidth:'1600px'
    },
    button: {
        marginRight: theme.spacing(2),
        boxShadow: "none"
    },

    cardinfo: {
        boxShadow: "none",
        background: "none",
        width: "250px",
        whiteSpace: "normal",
    },
    card: {
        boxShadow: "none",
        background: "none",
    },
    titleroot: {
        fontSize: "1.3rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    titleupload: {
        fontSize: "1rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    subtext: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: fontsecondary,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    userName: {
        color: "rgba(0, 0, 0, 0.87)"
    },
    AccessTime: {
        verticalAlign: "middle",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1) / 2,
    },
    rootCard: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: "1rem",
    },
    uploadFreq: {
        padding: theme.spacing(1),
        background: alpha(red[50], 1),
    },
    subCard: {
        fontSize: fontprimary,
    },
    subTitle: {
        fontSize: fontsecondary,
    },
    timeupload: {
        fontSize: fontsecondary,
        marginLeft: "4px",
        color: theme.palette.grey[600],
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    exclamation: {
        background: red[500],
        width: 34,
        height: 34,
    },
    scannedbg: {
        background: alpha(blue[50], 0.5),
    },
    titlescan: {
        fontSize: "1.3rem",
    },
    primarytext: {
        fontSize: fontprimary,
    },
    secondarytext: {
        fontSize: fontsecondary,
    },
    inline: {
        display: 'inline',
    },
    spaceLeft: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    formControl: {
        width: '100%',
    },
    paddingNone: {
        paddingBottom: 0,
        overflowY: "initial"
    },
    btnCancel: {
        marginRight: 20,
    },
    alert: {
        background: theme.palette.primary.main,
        fontSize: "1.3rem",
    },
    tabsRoot: {
        color: theme.palette.grey[700],
    },
    //tabsIndicator: {
    //    backgroundColor: 'transparent',
    //},
    tabRoot: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: "transparent",
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        textTransform: 'capitalize',
        fontSize: "18px",
        textAlign: "center",
        color: theme.palette.grey[800],
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        transition: "200ms ease",
        // marginRight: theme.spacing(1),

        //fontWeight: theme.typography.fontWeightRegular,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        //letterSpacing:"1px",
        '&:hover': {
            backgroundColor: theme.palette.common.tabHover,
            color: theme.palette.grey[900],


        },
        '&$tabSelected': {
            backgroundColor: theme.palette.grey[100],
            fontFamily: 'NHaasGroteskDSPro-65Md',
            color: theme.palette.grey[900],
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            borderColor: theme.palette.common.borderColor
        },
        '&:focus': {
            color: theme.palette.grey[900],
        },
    },
    tabSelected: {},
    padding: {
        padding: 0,
        minHeight: "auto",
    },
    staffContent: {
        padding: theme.spacing(2),
    }
});



const AdminStats = ({ classes }) => {

   
    const [activeTab, setActiveTab] = useState(0);
  
    const handleChange = (event, value) => {
        setActiveTab(value);
    };
    
    return (
        <div className={classes.root}>
            <Typography variant="h4" gutterBottom>
                SPV Leadership Board
            </Typography>
            <Paper elevation={0} className={classes.contentPaper}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                >
                    <Tab
                        value={0}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label={<Toolbar className={classes.padding} variant="dense" disableGutters>
                            <Typography variant="h3" className={classes.grow}>
                                Manufacturers
                            </Typography>
                        </Toolbar>}

                    />
                    <Tab
                        value={1}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label={
                            <Toolbar className={classes.padding} variant="dense" disableGutters>
                                <Typography variant="h3" className={classes.grow}>
                                    App Providers
                                </Typography>
                            </Toolbar>}
                    />
                    
                </Tabs>

                {activeTab === 0 && <TabContainer><ManufacturerLeadershipBoardWrapper /></TabContainer>}
                {activeTab === 1 && <TabContainer><AppProviderBoard /></TabContainer>}
            </Paper>
        </div>
    );
};


AdminStats.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminStats); ;







