import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router';
import { UserChangePassword } from '../../constants';
import AuthService from '../AuthService';
import theme from '../../theme';
const styles = theme => ({
    root: {
        flexGrow: 1,
        //height: "100%",
    },
    formControl: {
        width: "100%"
    },
    Paper: {
        padding: theme.spacing(1) * 1,
        background: theme.palette.common.logIn,
    },
    cssOutlinedInput: {
        // color: theme.palette.text.light
    },
    cssLabel: {
        // color: '#ffffff !important',
    },
    notchedOutline: {
        // borderColor: '#FFFFFF !important',
    },
    container: {
        height: '100%',        
    },
    button: {
        minWidth: 120,
        height: 42,
        //marginBottom: theme.spacing(2),
        //marginTop: theme.spacing(2),
        //paddingLeft: theme.spacing(1) * 8,
        //paddingRight: theme.spacing(1) * 8,
        //width: 275,
        //height: 50,
        //'&:disabled': {
        //    color: theme.palette.common.white,
        //    background: theme.palette.primary.main,
        //},
        //'&:hover': {
        //    '&:disabled': {
        //        color: theme.palette.common.white,
        //        background: theme.palette.primary.main,
        //    },
        //},
        //[theme.breakpoints.down('xs')]: {
        //    width: "100%",
        //},
    },
    formContent: {
        height: "calc(100vh - 130px)",
        minHeight: "600px",
        marginLeft: "30px",
        marginTop: "30px",
    },
    successMessage: {
        color: green[500],
    },

    logText: {
        marginBottom: "5px",
        textTransform: 'inherit',
        fontWeight: 'normal',
    },
    setPass: {
        textAlign: "left",
        marginTop: "16px",
    },
    center: {
        //marginTop: theme.spacing(1),
        textAlign: "center",
    },
   
    formOuterFrame: {
        width: "100%",
        height: '300px',
    },
});





class ChangePassword extends React.Component {

    constructor() {
        super();

        this.state = {
            failed: false, resolved: false, showPassword: false, Password: '', ConfirmPassword: '', isloading: false, errormessage: '', expired:false
        };
        this.Auth = new AuthService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.notifyMessage = this.notifyMessage.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.matchPassword = this.matchPassword.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }
    componentDidMount() {

        ValidatorForm.addValidationRule('isPassword', (value) => {
            return this.matchPassword(value);
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.Password) {
                return false;
            }
            return true;
        });
        const values = queryString.parse(this.props.location.search);
        this.user = {
            UserId: values.uid,
            Email: values.email,
            Token: values.token, Password: ''
        }

    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    handleSubmit = () => {
        const token = this.Auth.getToken();
        let data = { "Password": this.state.Password, "ConfirmPassword": this.state.ConfirmPassword };
        this.setState({ isloading: true });
        let context = this;
        fetch(UserChangePassword, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        }).then(res => {
            return res.json();
            }).then(res => {
                context.setState({ failed: !res.status, resolved: res.status, isloading: false, errormessage: res.message, });
                setTimeout(() => {
                    context.setState({ resolved: false }); }, 4000);
            })
            .catch(err => {context.setState({ failed: true, resolved: false, isloading: false, errormessage: err }); });
    }
    matchPassword(value) {
        
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$%*?&]{6,}$/.test(value)) {

            return true;
        }
        return false;
    }
    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value });
    }
    resetForm(classes) {

        const { ConfirmPassword, Password, isloading } = this.state;
        const requiredMessage = "* Password is required";
        return <ValidatorForm ref="form" instantValidate={true} onError={errors => console.log(errors)} onSubmit={this.handleSubmit}>
            <Grid container direction="row" justify="flex-start">
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <div className="loginForm">
                            <FormControl className={classes.formControl} error>
                                <TextValidator
                                    autoComplete='off'
                                    id="Password"
                                    name="Password"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Password*"
                                    value={Password}
                                    onChange={this.handleChange}
                                    className={classNames(classes.dense)}
                                    validators={['isPassword', 'required']}
                                    errorMessages={['Password must be combination of upper case,digit and special characters and should have minimum 6 characters', requiredMessage]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            </FormControl>
                        </div>
                        <div className="loginForm">
                            <FormControl className={classes.formControl} error>
                                <TextValidator
                                    autoComplete='off'
                                    id="ConfirmPassword"
                                    name="ConfirmPassword"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Confirm Password*"
                                    value={ConfirmPassword}
                                    onChange={this.handleChange}
                                    className={classNames(classes.dense)}
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['Confirm password did not match', requiredMessage]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.setPass}>        
                        <Button className={classes.button} variant="contained" color="primary" type="submit" alignitem='center'>
                            {isloading ?
                                <CircularProgress size={19} color={theme.palette.common.white} /> :
                                <span>Set new password</span>
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    }

    notifyMessage(classes, status, title, message) {
        if (status) {
            return (<Fragment><Typography variant="h2" className={classes.root}>
                {title}
            </Typography>
                <Typography variant="caption" className={classes.rootCaption} >
                    {message}
                </Typography></Fragment>);
        }
        else {
            return (<Fragment><Typography variant="h2" className={classes.root}>
                {title}
            </Typography>
                <Typography variant="caption" className={classes.rootCaption} >
                    {message} 
                </Typography></Fragment>);
        }
    }
    render() {
        const { classes } = this.props;
        const { failed, resolved, errormessage } = this.state;
        const title = "Change password";     
        const output = this.resetForm(classes);

        const resolved_message = resolved? (<Paper align="center" square={true} className={classes.Paper} elevation={0}>Password changed succesfully.</Paper>):null;
        const failed_message = failed ?(<Fragment><Paper align="center" square={true} className={classes.Paper} elevation={0}><Typography variant="title" className={classes.errorText}>{errormessage}</Typography></Paper> </Fragment>):null;
        const output_result_class = failed ? classes.errorMessage : resolved ? classes.successMessage : classes.root;

        return (<div className={classes.root}>

            <Grid xs={12} container direction="row" justify="flex-start"   className={classes.formContent} >
                <Grid item lg={3} >
                        <Grid item >
                            <Typography variant="h1" className={classes.logText}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.formOuterFrame}>
                            {output}
                            <Typography className={output_result_class}>
                                {resolved_message}
                                {failed_message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
  
        </div>
          
        );
    }
}

//export default Forgot;
ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(ChangePassword));

