import gql from 'graphql-tag';

export const GET_TOP_MANUFACTURERS = gql`
  query GetTopManufacturers($fromDate: DateTime, $toDate: DateTime) {
    admin { reports {
      topManufacturers(fromDate: $fromDate, toDate: $toDate) {
        displayName
        totalInstallations
      }
    } }
  }
`;

export const GET_INSTALLATIONS_BY_AREA = gql`
  query GetInstallationsByArea($fromDate: DateTime, $toDate: DateTime) {
    admin { reports {
      installationsByArea(fromDate: $fromDate, toDate: $toDate) {
        stateName
        totalInstallations
      }
    } }
  }
`;

export const GET_TOP_INSTALLERS = gql`
  query GetTopInstallers($fromDate: DateTime, $toDate: DateTime) {
    admin { reports {
      topInstallers(fromDate: $fromDate, toDate: $toDate) {
        firstName
        lastName
        totalInstallations
      }
    } }
  }
`;

export const GET_TOP_RETAILERS = gql`
  query GetTopRetailers($fromDate: DateTime, $toDate: DateTime) {
    admin { reports {
      topRetailers(fromDate: $fromDate, toDate: $toDate) {
        retailerName
        totalInstallations
      }
    } }
  }
`;

export const GET_INSTALLATIONS_BY_APP_PROVIDER = gql`
  query GetInstallationsByAppProvider($fromDate: DateTime, $toDate: DateTime) {
    admin { reports {
      installationsByAppProvider(fromDate: $fromDate, toDate: $toDate) {
        iRequesterAppName
        totalInstallations
      }
    } }
  }
`;



export const GET_MANUFACTURERS = gql`
  query GetManufacturers {
    admin { manufacturers {
      manufacturerId
      displayName
    }
    }
  }
`;

export const GET_STATES = gql`
  query GetStates {
   common{ states {
      stateId
      stateName
    }
    }
  }
`;

export const GET_CITIES = gql`
  query GetCities {
    admin { cityList {
      cityId
      cityName
    }
    }
  }
`;

export const GET_RETAILERS = gql`
  query GetRetailers {
    admin { reports { retailers {
      retailerId
      retailerName
    }
    }
    }
  }
`;

export const GET_INSTALLERS = gql`
  query GetInstallers {
    admin {installers {
      installerId
      firstName
      lastName
    }
    }
  }
`;

export const GET_FILTERED_INSTALLATIONS = gql`
  query GetFilteredInstallations($fromDate: DateTime, $toDate: DateTime, $manufacturerId: Int, $stateId: Int, $cityId: Int, $retailerId: String, $installerId: Int) {
    admin {
      reports {
        filteredInstallations(fromDate: $fromDate, toDate: $toDate, manufacturerId: $manufacturerId, stateId: $stateId, cityId: $cityId, retailerId: $retailerId, installerId: $installerId) {
          productInstallationId
          installationDate
          manufacturerName
          stateName
          cityName
          installerName
          retailerName
          totalRatedPower
        }
      }
    }
  }
`;


