import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { LINKS_PER_PAGE, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF } from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import theme from '../../theme';
import UserProfile from '../auth/UserProfile';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from "../common/Pagination";
import ModelSearch from "../ModelSearch";
import PanelDialog from './PanelDialog';
import InstallationHeader from './rows/header/InstallationHeader';
import InstallationRow from './rows/InstallationRow';
const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    Grid: {
        backgroundColor: theme.palette.grey[100],
       // borderLeftWidth: "1px",
      //  borderRightWidth: "1px",
      //  borderColor: theme.palette.grey[200],
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        
       // borderBottom: '1px solid #e8e8e8',
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "1px",
        borderColor: theme.palette.common.borderColor,
        minHeight: 49,
    }, table: {
        //borderLeftWidth: "1px",
        //borderRightWidth: "1px",
        //borderColor: theme.palette.common.borderColor,
    },
    paperTable: {
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,
        minHeight: "calc(100vh - 300px)",
        width: "100%",
        overflowX: 'auto',
        background: theme.palette.common.white,
    }

};
const FooterCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",

    },
}))(TableCell);


class InstallationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            pageNumber: 1, pageSize: LINKS_PER_PAGE, filter: null,
            open: false, installationId: 0
        };
        this.onPageChange = this.onPageChange.bind(this);
        this.showPanelDetails = this.showPanelDetails.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    onPageChange(newPage) {
        this.setState({
            pageNumber: newPage
        })
    }

    handleSearch(data) {
        this.setState({
            filter: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }
    _getQueryVariables(role, manufacturerId) {

        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const skip = (page - 1) * page_size;
        const filter = this.state.filter;
        const first = page_size;

        switch (role) {
            case Role_Admin:
              
                return { manufacturerId: parseInt(manufacturerId), first, skip, filter};
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { manufacturerId: parseInt(manufacturerId), first, skip, filter };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { filter, first, skip };
        }
 
    }

    _getData(data, role) {
        //adminView ? data.admin.products : data.productfeed.products
        switch (role) {
            case Role_Admin: 
                return { productInstallationCount: data.admin.productInstallationCount, productInstallations: data.admin.productInstallations };
            case Role_DISTRIBUTOR_STAFF:
            case Role_DISTRIBUTOR: return { productInstallationCount: data.supplier.productInstallationCount, productInstallations: data.supplier.productInstallations };
            case Role_MANUFACTURER:
            case Role_STAFF:
            default: return { productInstallationCount: data.productfeed.productInstallationCount, productInstallations: data.productfeed.productInstallations };


        }
    }

    showPanelDetails(installationId) {
        this.setState({ open: true, installationId: installationId });
    }




    handleClose() {
        this.setState({ open: false });
    };


    shouldComponentUpdate(nextProps, nextState) {

        let shouldUpdate = (this.state.pageNumber !== nextState.pageNumber || this.state.pageSize !== nextState.pageSize
            || this.state.filter !== nextState.filter || this.state.installationId !== nextState.installationId
            || this.state.open !== nextState.open || this.props.manufacturerId !== nextProps.manufacturerId);
        return shouldUpdate;
    }


    componentDidMount() {

    }
    render() {
        //alert('called installation');
      //  const { classes, adminView, manufacturerId } = this.props;
      //  const query = adminView ? ADMIN_PRODUCT_INSTALLATION_QUERY : PRODUCT_INSTALLATION_QUERY;

        const { classes } = this.props;
        let { manufacturerId } = this.props;
        const role = UserProfile.getRole();
        const adminView = role === Role_Admin;
        const query = QueryHelper().ProductInstallationListing();

        return (
            <Fragment>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.Grid}
                >
                    <Grid item></Grid>
                    <Grid item>
                        <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} />
                    </Grid>
                </Grid>
                <div className={classes.root}>
                    <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                        <Table className={classes.table}>
                            <Hidden smDown>
                    <TableHead>
                        <InstallationHeader />
                                </TableHead>
                            </Hidden>
                            <Query query={query} variables={this._getQueryVariables(role, manufacturerId)}  >
                        {({ loading, error, data }) => {
                                    if (loading) return <FbLoader isTableBodyRequired={true} colSpan={5} loaderStyle={'emptycell'} />
                                    if (error) return <FbError isTableBodyRequired={true} colSpan={5} errorStyle={'emptycell'} />
                                    //const productInstallationCount = adminView ? data.admin.productInstallationCount : data.productfeed.productInstallationCount;
                                   // const productInstallations = adminView ? data.admin.productInstallations : data.productfeed.productInstallations;

                                    const { productInstallationCount, productInstallations } = this._getData(data, role);
                                    const paging = (<Pagination Prefix="products" TotalRecords={productInstallationCount} PageSize={this.state.pageSize} PageNumber={this.state.pageNumber} ShowPreviousNext={true} onPageChange={this.onPageChange} ></Pagination>);
                                    const listModels = productInstallations.map((model, index) =>
                                (
                                    <InstallationRow showPanelDetails={this.showPanelDetails} key={index} installation={model} />

                                ));
                                    const result = (productInstallations !== 'undefined' && productInstallations.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} colSpan={5} errorStyle={'emptycell'} />;
                                    return (<Fragment><TableBody>{result}</TableBody><TableFooter><TableRow><FooterCell colSpan="5">{paging}</FooterCell></TableRow></TableFooter></Fragment>);
                        }}
                    </Query>
                        </Table>
                    </Paper>
                </div>
                <PanelDialog
                    installationId={this.state.installationId}
                    adminView={adminView}
                    manufacturerId={manufacturerId}
                    open={this.state.open}
                    onClose={this.handleClose}
                />
            </Fragment>
        );
    }
}

export default withStyles(styles)(InstallationContainer);
