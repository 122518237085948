import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: "relative",
    },
    OrderList: {
        paddingLeft: theme.spacing(2),
        paddingtop: theme.spacing(1) * 0.5,
        paddingbottom: theme.spacing(1) * 0.5,
    },
    heading: {
        fontSize: "22px",
        marginBottom: "18px",
    },

    toolbar: theme.mixins.toolbar,
});

const StyledOrderList = styled.ol`
        line-height:21px;
        padding:0 0 0 20px;
        color:#4e4e4e;
`
const SubParagraph = styled.p`
       color:#4e4e4e;
        font-size:16px;  
`
const StyledList = styled.li`
        line-height:21px;
        padding:4px;
        color:#4e4e4e;
        font-size:16px;
        strong{
            color: #222;
        }
        ol{ 
            padding-left:20px; 
        }
`

const SubHeading = styled.p`
        font-size:15px;
        margin:5px 0;
`


class Termsofuse extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
              {/*  <LegalHeader />
                <div className={classes.toolbar} /> */}
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">              
                    <Grid item lg={12}>
                      
                            <Typography component="h3" variant="h3" gutterBottom className={classes.heading}>
                               Terms of use
                            </Typography>
                            <StyledOrderList>
                                <StyledList><strong>Definitions and interpretation</strong>
                                    <StyledOrderList>
                                        <StyledList>Definitions
                                                <p>In this Agreement the following expressions have the following meanings:</p>
                                            <p> Business Day means a day that is not a Saturday, Sunday or any other
                                                day which is a public holiday in Sydney ,
                                                    New South Wales.</p>
                                            <p>Service means:</p>
                                           <StyledOrderList>
                                                <StyledList>The .php file that enables You to access the Software via the Website;</StyledList>
                                                <StyledList>Any upgrades or new versions of the Service available for use via the Website.</StyledList>
                                            </StyledOrderList>
                                            <p>Software means the computer software program known as "Formbay" accessible via the Service and for the avoidance
                                                    of doubt, Software does not include any third party software.</p>

                                            <p> Territory means Australia.</p>

                                            <p>Website means any URL via which the hosted Software is being served by FormBay to You.</p>
                                        </StyledList>
                                        <StyledList>
                                            Interpretation
                                               <StyledOrderList>
                                                <StyledList>A reference to a person includes any other entity recognised by law and vice versa.</StyledList>
                                                <StyledList>Headings, underlinings, marginal notes and indexes are only included for ease of reference and do not affect interpretation.</StyledList>
                                                <StyledList>A reference to any legislation or legislative provision includes any modifying, consolidating or replacing legislation or legislative provisions from time to time, and includes all regulations, rules and other statutory instruments issued under the legislation.</StyledList>
                                                <StyledList>A reference to a document (including these Terms of Use) is to that document as varied, novated, ratified or replaced from time to time.</StyledList>
                                                <StyledList>References to parties, clauses, schedules, annexures or exhibits are references to parties, clauses, schedules, annexures or exhibits to these Terms of Use unless otherwise stated.</StyledList>
                                                <StyledList>References to months and years mean calendar months and years.</StyledList>
                                                <StyledList>Words denoting the singular number include the plural, and vice versa.</StyledList>
                                                <StyledList>Where any word or phrase is given a defined meaning any other grammatical form of that word or phrase has a corresponding meaning.</StyledList>
                                                <StyledList>No rule of construction or interpretation applies to the disadvantage of a party because that party prepared these Terms of Use.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList>Severing any void term</StyledList>
                                        <p>If any part of these Terms of Use is void or unenforceable in any jurisdiction, then for the purposes of that jurisdiction if possible, that part is to be read down so as to be valid and enforceable. If this is not possible, and that part does not go to the essence of these Terms of Use, that part will be severed from these Terms of Use and the rest of these Terms of Use continues to have full force and effect so as to give effect to the intention of the parties.</p>
                                    </StyledOrderList>
                                </StyledList>
                                <StyledList><strong>Licence to use the Service</strong>
                                   <StyledOrderList>
                                        <StyledList>FormBay grants you a non-exclusive, non-transferable licence to access and use the Service for the purposes of processing "Small Scale Technology Certificates" under the Renewable Energy (Electricity) Act 2000 in the Territory, in accordance with these Terms of Use.</StyledList>

                                        <StyledList>Any documents or reports generated by Your use of the Service must only be used for the purpose outlined in clause 2.1.</StyledList>

                                        <StyledList>The Service must only be used in accordance with these Terms of Use.</StyledList>
                                    </StyledOrderList>
                                </StyledList>

                                <StyledList> <strong>Your obligations</strong>

                                    <StyledOrderList>
                                        <StyledList>You must:

                                               <StyledOrderList>
                                                <StyledList>ensure that any data entered onto the Service by You is accurate and reliable;</StyledList>

                                                <StyledList>keep up-to-date with the operation and any updates to the Service;</StyledList>

                                                <StyledList>be aware of, agree to and abide by terms and conditions of the Service and all variations as notified from time to time;</StyledList>

                                                <StyledList>maintain the confidentiality of the Service;</StyledList>

                                                <StyledList>act professionally, and not do anything that will or that is likely to bring FormBay into disrepute;</StyledList>

                                                <StyledList> promptly notify FormBay if You believe or suspect that the Service has been compromised or is being used in a manner that breaches these Terms of Use;</StyledList>

                                                <StyledList>maintain the confidentiality of Your Service username and password, and accept full responsibility for all activities that occur under Your username.Where the correct username and password are provided, FormBay is entitled to assume that the access is by You, or authorised by You, and You indemnify FormBay completely against any claim of loss or damage that results from wrongful, fraudulent or other access (using the correct username or password) to the Service;</StyledList>

                                                <StyledList>make Your own enquiries into the laws applying in each jurisdiction in which You use the Service to ensure Your use of the Service is lawful; and</StyledList>

                                                <StyledList>prevent other people from accessing or using the Service.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList>You must not do, and You must not authorise or encourage any third party to do, any of the following:
                                               <StyledOrderList>
                                                <StyledList>copy, redistribute or resell any element of the Service, or any object, item or material created by Your use of the Service, or permit or purport to permit any person to copy, redistribute or resell any element of the Service;</StyledList>

                                                <StyledList> use the Service for any illegal, fraudulent or inappropriate purpose;</StyledList>

                                                <StyledList>use the Service in any other way that violates any of the items as described in the list of ‘Prohibited Actions’ and ‘Spam’ as outlined in the Schedule;</StyledList>

                                                <StyledList> alter, remove or otherwise interfere with any permissions applied by another user;</StyledList>

                                                <StyledList> interfere with or construct Your own system to access the information on the Service; or</StyledList>

                                                <StyledList> act in any way that violates this Agreement.</StyledList>

                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList>You acknowledge and agree to each of the following:

                                               <StyledOrderList>
                                                <StyledList>the Service is only provided to You for so long as you abide by the Terms of Use;</StyledList>

                                                <StyledList> FormBay has the right to copy, use, manipulate or otherwise deal with any and all data provided to it by You
                                                    as part of Your use of the Service, and You warrant that FormBay's
                                                    use of the data
                                                    pursuant to this clause
                                                    does not infringe the rights
                                                        of any third party;</StyledList>

                                                <StyledList> the Service may be subject to technical limitations such as
                                                    network coverage and
                                                    third party service
                                                        availability;</StyledList>

                                                <StyledList> the Service is provided on an AS IS and AS AVAILABLE basis and
                                                    FormBay makes no
                                                    warranties or guarantees
                                                    in
                                                        respect of uptime for Your data, content or the Service;</StyledList>

                                                <StyledList> FormBay expressly assumes no responsibility for, without
                                                    limitation, backing up
                                                    Your data, damage to Your
                                                    data. You are responsible for ensuring that you have any necessary
                                                    back up
                                                        documentation;</StyledList>

                                                <StyledList> due to the nature of the Service, FormBay does not promise that it
                                                    will be
                                                    continuous, accessible at
                                                    all
                                                        times or fault-free;</StyledList>

                                                <StyledList> FormBay reserves the right to modify, suspend or discontinue any
                                                    part of the
                                                    Service with or without
                                                    notice
                                                        at any time and without any liability to You; and</StyledList>

                                                <StyledList> You are solely responsible for the content of any documents that
                                                    You upload to the
                                                    Service and FormBay
                                                    takes
                                                    no responsibility for the accuracy of that content of these
                                                        uploaded documents.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                    </StyledOrderList>
                                </StyledList>

                                <StyledList>
                                    <strong>Security</strong>
                                   <StyledOrderList>
                                        <StyledList>You must promptly notify FormBay of any actual or suspected breach of
                                            security related to the Services, including, but not limited to,
                                                unauthorised use of your password or account.</StyledList>

                                        <StyledList>You are responsible for the security of Your password, log-in or access
                                            details or other security measures.
                                            You are responsible for all electronic
                                            communications and other information entered through or under user IDs,
                                            passwords, access numbers or account
                                            numbers. You must not disclose any security measures
                                                to any other person and must keep those details confidential.</StyledList>

                                        <StyledList> To help ensure the security of Your password or account, You must sign out
                                            from Your
                                            account at the end of
                                            each session.
                                            </StyledList>
                                    </StyledOrderList>
                                </StyledList>

                                <StyledList><strong>Intellectual Property Rights</strong>
                                   <StyledOrderList>
                                        <StyledList>You acknowledge that FormBay owns all right, title and interest in and to
                                            the Service, including without limitation all intellectual property rights
                                            (the FormBay Rights), and the FormBay Rights are protected by intellectual
                                            property laws. Accordingly, You must not copy, reproduce, alter, modify,
                                            reverse engineer
                                            or create derivative works from the Service. You must not use any robot,
                                            spider, other automated device, or manual process to monitor any content
                                            from, hosted by or
                                            that is otherwise available using the Service. The FormBay Rights include
                                            rights to the Service developed and provided by FormBay, all software
                                            associated with the Service and the Brand Features (see below). The FormBay
                                            Rights do not
                                                include third-party content (such as Your data) used as part of Service.</StyledList>

                                        <StyledList> Any use of FormBay’s trade names, trade marks, service marks, logos,
                                            domain
                                            names, and
                                            other distinctive brand features (Brand Features) by You must
                                            be only in connection with the Service and in compliance with the
                                            Agreement.
                                            You must not
                                            alter any Brand Features or copyright notices that are posted by FormBay
                                                in connection with the Service.</StyledList>
                                    </StyledOrderList>
                                </StyledList>

                                <StyledList><strong>Disclosure of information</strong>

                                    <p> If disclosure of any information provided by You to FormBay, or by FormBay to
                                        You,
                                        is or
                                        may be required by law
                                        or regulatory authority, the party under the
                                        obligation must immediately notify the other party of that fact. If the other
                                        party
                                        objects
                                        to the disclosure on
                                        the basis that it is not required by law, the party must act in accordance with
                                        any
                                        reasonable written legal
                                        opinion provided to it by the other party, or the other party’s legal advisors.
                                        </p>
                                </StyledList>

                                <StyledList><strong>Suspension of the Website</strong>

                                    <p>FormBay may from time to time without notice suspend the Website or disconnect
                                        or
                                        deny you
                                        access to the Website
                                        during any technical failure, modification or
                                        maintenance involved in the Website. FormBay will use its reasonable endeavours
                                        to
                                        procure
                                        the resumption of the
                                        Website as soon as reasonably practicable.
                                        </p>
                                </StyledList>

                                <StyledList><strong>Warranties and indemnity</strong>
                                   <StyledOrderList>
                                        <StyledList>
                                            You warrant to the best of Your knowledge that the materials and
                                            information provided
                                            to FormBay for the
                                            purposes of providing the Services:
                                               <StyledOrderList>
                                                <StyledList> are true, correct and current;</StyledList>

                                                <StyledList> are able to be disclosed by You;</StyledList>

                                                <StyledList> does not infringe the rights of any third party;</StyledList>

                                                <StyledList> is able to be legally provided to FormBay and its disclosure does
                                                    not breach any
                                                    law or any obligation
                                                        (whether of confidence or otherwise) owed to any person;</StyledList>

                                                <StyledList> You have all necessary right, power and authority to enter into
                                                    these Terms of Use
                                                    and to perform the acts
                                                        required of You under these Terms of Use.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList>You acknowledge that You have not relied upon or been induced by any
                                            representation by
                                            FormBay and FormBay makes no warranty or representation as to:
                                               <StyledOrderList>
                                                <StyledList>the results that may be obtained through the Service;</StyledList>

                                                <StyledList> the accuracy, reliability or otherwise of any information obtained
                                                    through the
                                                        Service; or</StyledList>

                                                <StyledList> the Service or network being uninterrupted, timely or error free.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList> You indemnify and keep indemnified FormBay and its officers, employees,
                                            agents,
                                            subsidiaries and affiliates against all claims (including third party
                                            claims), demands, damages, costs (including legal costs), penalties or
                                            suits arising out of
                                            or consequential upon Your use of the Service and any breach of any
                                                warranty or other term of these Terms of Use.</StyledList>
                                    </StyledOrderList>
                                </StyledList>

                                <StyledList><strong> Limitation of Liability</strong>
                                    <StyledOrderList>
                                        <StyledList> To the extent permissible by law FormBay excludes any liability:
                                               <StyledOrderList>
                                                <StyledList>for any failure of performance, error, omission, interruption,
                                                    deletion,
                                                    defect or
                                                    delay in transmission or
                                                        operation, or</StyledList>

                                                <StyledList> for any injury, loss or damage arising out of provision of the
                                                    Services,
                                                    provided
                                                    that FormBay does not
                                                    exclude any such liability if it arises as a result of FormBay's
                                                    failure to
                                                    take
                                                    reasonable steps to guard
                                                    against and use its best
                                                    endeavours to immediately rectify any of the matters referred to in
                                                    this
                                                    clause 9.1
                                                        of which FormBay is aware.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList>To the fullest extent permissible at law, FormBay is not liable for any
                                            direct,
                                            indirect, punitive,
                                            incidental, special, consequential damages or any
                                            damages whatsoever including, without limitation, damages for loss of use,
                                            data or
                                            profits,
                                            arising out of or in
                                            anyway connected with the provision of or failure to provide the Service or
                                            otherwise
                                            arising out of the
                                            Service, whether based on contract, negligence, strict liability or
                                            otherwise, even
                                            if
                                            FormBay has been advised
                                                of the possibility of damages.</StyledList>

                                        <StyledList> You expressly agree that use of the Service at Your own risk. To the
                                            extent
                                            allowed
                                            by
                                            law, FormBay's
                                            liability for breach of a term implied into these
                                                Terms of Use by any law is excluded.</StyledList>

                                        <StyledList> FormBay gives no warranty in relation to the Service provided or supplied.
                                            Under
                                            no
                                            circumstances is FormBay
                                            or any of its carriers or suppliers liable
                                            or responsible in any way to You or any other person for any loss, damages,
                                            costs,
                                            expenses
                                            or other claims
                                            (including consequential damages and loss of profits or loss of revenues),
                                            as a
                                            result,
                                            direct or indirect of
                                            any defect, deficiency or discrepancy in the Service including its form,
                                            content
                                            and
                                            timeliness of delivery,
                                            including, without limitation, for and in relation to any of the following:
                                               <StyledOrderList>
                                                <StyledList>any Service supplied to You;</StyledList>

                                                <StyledList>any delay in supply of the Service;</StyledList>

                                                <StyledList>any failure to supply the Service;</StyledList>

                                                <StyledList>any technical or non-technical failure; or</StyledList>

                                                <StyledList>any content or information accessed via the Service with or without
                                                    Your
                                                        authorisation.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList> The Australian Competition and Consumer Act 2010 may imply certain
                                            conditions,
                                            guarantees and warranties
                                            into these Terms of Use. To the extent permitted
                                            by law those conditions or warranties are expressly excluded. Where they
                                            cannot be
                                            excluded
                                            and where liability
                                            can be limited, FormBay’s liability (if any) arising from the breach of
                                            those
                                            conditions,
                                            guarantees and
                                            warranties is limited, in FormBay’s sole discretion, to the supply of the
                                            Service
                                            again
                                            or
                                            cost of re-supplying
                                            the Service again.
                                            </StyledList>
                                    </StyledOrderList>
                                </StyledList>

                                <StyledList><strong> Waiver</strong>

                                    <p>A failure or delay by FormBay to exercise a power or right under these Terms of
                                        Use
                                        does
                                        not operate as a waiver
                                        of that power or right, and the exercise of a power
                                        or right by FormBay does not preclude its future exercise or the exercise of
                                        any
                                        other
                                            power or right.</p>
                                </StyledList>
                                <StyledList><strong>Notices</strong>
                                   <StyledOrderList>
                                        <StyledList>A notice or other communication connected with these Terms of Use (Notice)
                                            has
                                            no
                                            legal effect unless in
                                                writing.</StyledList>

                                        <StyledList>In addition to any other method of service provided by law, the Notice may
                                            be:
                                               <StyledOrderList>
                                                <StyledList> sent by prepaid ordinary mail to the address for services of the
                                                    addressee in the
                                                    Contract Details, if the
                                                    address is in Australia and the Notice is sent
                                                        from within Australia ;</StyledList>

                                                <StyledList> sent by email to the address stated in the Contract Details;</StyledList>

                                                <StyledList> sent by facsimile to the facsimile number of the addressee in the
                                                    Contract
                                                        Details;</StyledList>

                                                <StyledList> delivered to the address for service of the addressee in the
                                                    Contract
                                                        Details; or</StyledList>

                                                <StyledList> posted at the Website.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList>If the Notice is sent or delivered in a manner provided by clause 11.2 it
                                            must
                                            be
                                            treated as given to and
                                            received by the party to which it is addressed:

                                               <StyledOrderList>
                                                <StyledList>if mailed from within Australia to an address in Australia , on the
                                                    2nd
                                                    Business
                                                    Day (at the address to
                                                        which it is mailed) after mailing;</StyledList>

                                                <StyledList>if sent by email before 5.00pm on a Business Day at the place of
                                                    receipt, on the
                                                    day it is sent and
                                                        otherwise on the next Business Day at the place of receipt;</StyledList>

                                                <StyledList>if sent by facsimile before 5.00pm on a Business Day at the place
                                                    of
                                                    receipt, on
                                                    the day it is sent and
                                                        otherwise on the next Business Day at the place of receipt; or</StyledList>

                                                <StyledList>if otherwise delivered before 5.00pm on a Business Day at the place
                                                    of
                                                    delivery,
                                                    upon delivery, and
                                                        otherwise on the next Business Day at the place of delivery.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList>Despite clause 11.3
                                               <StyledOrderList>
                                                <StyledList> a facsimile is not treated as given or received unless at the end
                                                    of
                                                    the
                                                    transmission the sender’s facsimile
                                                    machine issues a report confirming the transmission of the number
                                                    of
                                                    pages in the
                                                        Notice; and</StyledList>

                                                <StyledList> a facsimile is not treated as given or received if it is not
                                                    received
                                                    in full and
                                                    in legible form and the
                                                    addressee notifies the sender of that fact within
                                                    3 hours after the transmission ends or by 12 noon on the Business
                                                    Day
                                                    on which it
                                                    would otherwise be treated as
                                                        given and received, whichever is later.</StyledList>

                                            </StyledOrderList>
                                        </StyledList>

                                        <StyledList> If a Notice is served by a method which is provided by law but is not
                                            provided
                                            by
                                            clause 11.2 , and the
                                            service takes place after 5.00pm on a Business Day,
                                            or on a day that is not a Business Day, it must be treated as taking place
                                            on
                                            the next
                                                Business Day.</StyledList>

                                        <StyledList>A Notice sent or delivered in a manner provided by clause 11.2 must be
                                            treated
                                            as
                                            validly given to and
                                                received by the party to which it is addressed even if:
                                           <StyledOrderList>
                                                <StyledList>the addressee has been liquidated or deregistered or is absent from
                                                    the
                                                    place at
                                                    which the Notice is
                                                        delivered or to which it is sent; or</StyledList>
                                                <StyledList>the Notice is returned unclaimed.</StyledList>
                                            </StyledOrderList>
                                        </StyledList>
                                        <StyledList> A party may change its address for service or facsimile number by giving
                                            Notice of
                                            that change to each
                                                other party.</StyledList>
                                    </StyledOrderList>
                                </StyledList>
                                <StyledList> <strong> Dispute Resolution</strong>

                                    <p> All questions or disputes relating to the Service including these terms should
                                        be
                                        submitted
                                        to FormBay in
                                        writing. FormBay will endeavour to resolve any disputes
                                        expeditiously. All interpretations of these Terms of Use of Service shall be at
                                        FormBay's
                                        sole discretion.
                                            FormBay's error is not a breach of these terms of Service.</p>
                                </StyledList>
                                <StyledList><strong>Amendments</strong>

                                   <StyledOrderList>
                                        <StyledList>FormBay may vary these terms at any time by general notice on a page of the
                                            internet
                                            referred to on the
                                                home page of the Website.</StyledList>

                                        <StyledList>These terms constitute the Agreement in its entirety and supersede prior
                                                agreements.</StyledList>
                                    </StyledOrderList>
                                </StyledList>
                                <StyledList><strong> Governing Law</strong>

                                    <p> The law in New South Wales governs this Terms of Service and the parties submit
                                        to
                                        the
                                        non-exclusive
                                            jurisdiction of the courts of New South Wales.</p>
                                </StyledList>
                            </StyledOrderList>
                            <StyledOrderList>
                                <p><strong>SCHEDULE</strong></p>
                                <SubHeading><strong>Prohibited Acts</strong></SubHeading>
                                <SubParagraph> In addition to those acts listed in these Terms of Use, the following acts are prohibited
                                in using or in connection with the Service:-</SubParagraph>
                                <StyledList>Send, upload, distribute or disseminate or offer to do the same with respect to any
                                unlawful, defamatory,
                                harassing, abusive, fraudulent, infringing,
                                    obscene, or otherwise objectionable content.</StyledList>
                                <StyledList> Intentionally distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes,
                                or any other items
                                    of a destructive or deceptive nature.</StyledList>
                                <StyledList>Transmit content or use the Service that in the opinion of FormBay acting reasonably may
                                be considered harmful
                                    to or inappropriate for minors.</StyledList>
                                <StyledList> Impersonate another person (via the use of an email address or account) or otherwise
                                misrepresent Yourself or
                                    the source of any email or communication.</StyledList>
                                <StyledList> Use FormBay to violate the legal rights (such as rights of privacy and publicity) of
                                    others.</StyledList>
                                <StyledList> Promote, encourage or participate in illegal or unlawful activity.</StyledList>
                                <StyledList> Interfere without cause in the reasonable enjoyment by other FormBay Users of the
                                    Service.</StyledList>
                                <StyledList> Create multiple user accounts, create user accounts by automated means or otherwise
                                engage in false, fraudulent, deceptive or misleading conduct in
                                    respect of user identity.</StyledList>
                                <StyledList> Sell, trade, resell, transfer or otherwise attempt to dispose of, or otherwise exploit
                                    any content on the Service for any unauthorised commercial purpose.</StyledList>
                                <StyledList> Modify, adapt, translate, or reverse engineer any portion of the Service</StyledList>
                                <StyledList> Remove any copyright, trade mark or other proprietary rights notices contained in or on
                                    the Service.</StyledList>
                                <StyledList> Reformat or frame any portion of any web page that is part of the Service.</StyledList>
                                <StyledList> Create third party software without the permission of FormBay.</StyledList>
                                <StyledList> Communicate with the Service through other users other than the FormBay approved users.</StyledList>
                                <StyledList> Misrepresent FormBay or the Service as being associated with content with another
                                website, web service,
                                    software service, software or other service without FormBay’s prior permission.</StyledList>
                            </StyledOrderList>
                            <StyledOrderList>
                                
                                <SubHeading><strong>Spam</strong></SubHeading> 
                                <SubParagraph>You are prohibited from generating or facilitating unsolicited commercial communication
                                    ("spam"). Such activity includes, but is not limited to</SubParagraph>
                                        <StyledList> sending communication in violation of Australia 's Spam Act 2003 or any other applicable
                                    anti-spam law;</StyledList>
                                        <StyledList>imitating or impersonating another person or email address or FormBay's account, or
                                    creating false accounts for the purpose of sending spam;</StyledList>
                                        <StyledList>data mining any web property (including FormBay) to find email addresses or accounts;</StyledList>
                                        <StyledList> sending unauthorised mail or communication via open, third-party servers;</StyledList>
                                        <StyledList>sending emails or communication to users who have requested to be removed from a mailing
                                    list or Your contact list;</StyledList>
                                        <StyledList>selling, exchanging or distributing to a third party the email addresses of any person
                                    without such person's knowing and continued consent to such disclosure;</StyledList>
                                        <StyledList> sending unsolicited emails or communications to significant numbers of email addresses or
                                    accounts belonging to individuals and/or entities with whom You have no pre-existing relationship.</StyledList>
                                    </StyledOrderList>                       
                </Grid>
                </Grid>
                {/*  <LegalFooter /> */}
            </div>


        );
    }
}

export default withStyles(styles)(Termsofuse);
