import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../assets/images/solar-logo.svg';
import theme from '../theme';


const styles = {
    root: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'none',       
        background: theme.palette.common.white,
    },
    paper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: theme.palette.common.white,
        borderBottom: "1px solid #dddddd",
    },
    grow: {
        flexGrow: 1,
    },
    button: {
        marginLeft: theme.spacing(1),
        background: theme.palette.common.white,        
    }
};


class LegalHeader extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Paper elevation={0} square className={classes.paper}>
                    <Toolbar disableGutters>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            <img src={logo} alt="logo" width="215" />
                        </Typography>
                       
                        <Button component={RouterLink} to="/login" color="primary" classes={{
                                root: classes.button
                            }}>Login </Button>
                   
                    
                        <Button component={RouterLink} to="/register" color="primary" classes={{
                                root: classes.button
                            }}>Register</Button>
                     
                    </Toolbar>
                </Paper>
            </AppBar>
        );
    }
}

export default withStyles(styles)(LegalHeader);