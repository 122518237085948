import { useMutation } from '@apollo/react-hooks';
import { Button, CircularProgress, FormControl, Grid, Icon, Paper, Typography } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import { AddNotificationUserHeaderTitle } from '../../StringConstants';
import theme from '../../theme';


export const SAVE_Member = gql`
mutation groupNew($group: NotificationGroupInput!) {
  saveGroup(group:$group){
   groupName,
   manufacturerId
  }
}
`;
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: "100%",
    },
    fieldsection: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: theme.palette.common.white,
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },

    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        width: "100%",
    },
    avatar: {
        borderRadius: 0,
        width: "100%",
        height: "172px",
        backgroundColor: theme.palette.background.default,
        color: "transparent",
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.background.lightbg,
        maxWidth: "158px",
        minWidth: "158px",
    },
    iconHover: {
        color: theme.palette.grey[500],
        verticalAlign: "middle"
    },
    gutterBottom: {
        marginBottom: theme.spacing(2)
    },
    control: {
        marginTop: theme.spacing(1),
    },
    update: {
        marginTop: theme.spacing(1)
    },
    button: {
        marginRight: '8px',
        minWidth: 120,
        height: 42,
    },
    profileImage: {
        width: "100%",
        height: "100%",
        objectFit: " cover",
        imageOrientation: 'from-image',

    },
    //titleBar: {
    //   background: "transparent",
    //},
    backanim: {
        transition: "400ms ease",
        marginLeft: "-5px"
        /*'&:hover': {
            marginLeft: "-5px",
        },*/
    },
    icon: {
        fontSize: 26,
        verticalAlign: "middle",
        marginTop: "-3px",
        transition: "400ms ease",
        '&:hover': {
            marginLeft: "-5px",
        },
    },
    marginy: {
        marginTop: theme.spacing(1) * 1,
        marginBottom: theme.spacing(1) * 1,
    },
    checkbox: {
        paddingRight: 5,
    },
    labelClass: {
        // textTransform:"lowercase"
    }
});



export default function AddNotificationMember(props) {
    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }
    
    const { manufacturer, group ,user} = props.location.state;
    const manufacturerId = manufacturer.manufacturerId;
    const initialObject = user ? { Email: user.email, Phone: user.phone, NotificationUserId: user.notificationUserId } : { Email:'', Phone: '', NotificationUserId:null };
    const classes = useStyles();
    const [values, setValues] = useState(initialObject);
    //const { loading, error, data } = useQuery(GET_TODOS);
    const [addNotificationUser, { loading, error },] = useMutation(SAVE_Member, {
        onCompleted(){
            props.history.push({ pathname: "/ManufacturerDetail/" + manufacturerId, state: { manufacturer: manufacturer ,from:'notification'} });
        }});
    const prepareMutationVariable = () => {
        const { Email, Phone,Id } = values;
        let user = { "notificationUser": { id: Id, "email": Email, "phone": Phone,"enabled": true, "deleted": false } };
        return user;
    }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (<div className={classes.root}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>SPV | Add Member</title>
        </Helmet>
        <div className={classes.fieldsection}>
            <Grid container direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.marginy}>
                <Grid item>
                    <Link to={{ pathname: "/ManufacturerDetail/" + manufacturerId, state: { manufacturer: manufacturer } }} title="back" >
                        <Typography color='primary' variant="h4">
                            <span className={classes.backanim}>
                                <Icon className={classes.icon} >keyboard_arrow_left</Icon>
                                Back to listing
                            </span>
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
            <Grid container direction="row"
                justify="space-between"
                alignItems="center">
                <Grid item>
                    <Typography variant="h1" >
                        {AddNotificationUserHeaderTitle}{group.groupName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.control}
                spacing={8} >
                <Grid item lg={5} md={7} sm={12}>

                    <ValidatorForm instantValidate={true} autoComplete="off" onSubmit={() => { addNotificationUser({ variables: prepareMutationVariable() }) }}>
                        <Paper elevation={0} className={classes.paper}>
                            <Grid container sm={6} direction="row" spacing={8}>
                                <Grid item  xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <TextValidator autoComplete="off"
                                            name="Email"
                                            label="*Email"
                                            placeholder="Email"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            value={values.Email}
                                            onChange={handleInputChange}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item  xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <TextValidator autoComplete="off"
                                            name="Phone"
                                            label="Phone"
                                            placeholder="Phone"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            value={values.Phone}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container direction="row" className={classes.update} >
                            <Grid item>
                                <Button className={classes.button} size="large" variant="contained" type="submit" alignitem='center' color="primary" {...loading === true ? 'disabled' : ''} tabIndex="3">
                                    {loading ?
                                        <CircularProgress color={theme.palette.common.white} size={19} /> :
                                        <span>Add</span>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Grid>
            </Grid>
        </div>
    </div>
    );
}
