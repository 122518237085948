import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/ArrowForward';
import classNames from 'classnames';
import React from 'react';
import theme from '../../theme';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ValidatorForm } from 'react-form-validator-core';
import { TextValidator } from 'react-material-ui-form-validator';
import { setTimeout } from 'timers';
import { ChangeMemberPasswordAdmin } from '../../constants';
import AuthService from '../AuthService';
const drawerWidth = 360;

const styles = {
    root: {
        display: "inline"
    },
    button: {
        boxShadow: "none"
    },
    btnCancel: {
        color: theme.palette.grey[700]
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    content: {
        paddingBottom: 0,
    },
    space: {
        //paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    inputroot: {
        background: theme.palette.grey[100],
        "&:hover": {
            background: theme.palette.grey[200], borderBottom: 'none',
        },
        '&:after': {
            borderBottom: 'none',
        },
        '&:before': {
            borderBottom: 'none',
        },
        '&:focus': {
           // borderBottom: 'none',

        },
    },

    paperwhite: {
        background: "#fff", width: drawerWidth,
        flexShrink: 0,    
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1) *2 ,
        color: theme.palette.grey[500],
    },
}

class AdminResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = { right: false, resetMessage: '', showPassword: false, Password: '', ConfirmPassword: '', isloading: false, errormessage: '' };
        this.tryChangePassword = this.tryChangePassword.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.Auth = new AuthService();
        this.handleChange = this.handleChange.bind(this);
        this.matchPassword = this.matchPassword.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.removeValidations = this.removeValidations.bind(this);
        this.attachValidations = this.attachValidations.bind(this);
       
    }
    componentDidMount() {
        
       
    }
    componentWillUnmount() {
        this.removeValidations();
    }
    attachValidations() {
        ValidatorForm.addValidationRule('isPassword', (value) => {
            return this.matchPassword(value);
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {

            if (value !== this.state.Password) {
                return false;
            }
            return true;
        });
    }
    removeValidations() {
        ValidatorForm.removeValidationRule('isPassword');
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    }

    matchPassword(value) {
        
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$%*?&]{6,}$/.test(value)) {

            return true;
        }
        return false;
    }
    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open
        });
        if (open === true) {
            this.attachValidations();
        }
        else {
            this.removeValidations();
        }
    };
    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value });
    }
    tryChangePassword(e) {
        
        e.preventDefault();
        if (this.state.isLoading) return;
        this.setState({ isLoading: true, isResetFailed: false });
        this.handleFormSubmit(e);
    }
    handleFormSubmit(e) {
        const token = this.Auth.getToken();
        const UserId = this.props.userId;

        const { ConfirmPassword, Password } = this.state;
       
        fetch(ChangeMemberPasswordAdmin, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`
                    },
            body: JSON.stringify({
                UserId,
                Password,
                ConfirmPassword
            })
                })
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        if (res.status === true) {
                            this.setState({ isLoading: false, isResetFailed: false, resetMessage:'Account Reset succesfully' });
                        }
                        else {
                            this.setState({ isLoading: false, isResetFailed: true });
                        }

                        setTimeout(() => {
                            this.setState({ resetMessage:'' })
                        },3000);
                       
                    });
    }
    render() {
        const { classes } = this.props;
        const requiredMessage = "* Password is required";
        const { isLoading, isResetFailed, resetMessage, Password, ConfirmPassword, right} = this.state;
        const DialogTitle = withStyles(styles)(props => {
            const { children, classes, } = props;
            return (
                <MuiDialogTitle disableTypography>
                    <Typography variant="h6">{children}</Typography>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={this.toggleDrawer('right', false)}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
            );
        });
        return (
            <div className={classes.root}>
                <Button variant="contained" size="small" color="default" className={classes.button} onClick={this.toggleDrawer('right', true)} disableRipple>
                    Reset password
                </Button>
                <Drawer
                    anchor="right"                 
                    open={right} onClose={this.toggleDrawer('right', false)}
                    classes={{ paper: classes.paperwhite }}
                >
                    <ValidatorForm ref="form" instantValidate={true} onError={errors => console.log(errors)} onSubmit={(e) => this.tryChangePassword(e)}>
             
                    <DialogTitle id="alert-dialog-title">Reset password</DialogTitle>
                    <DialogContent className={classes.content}>
                    
                        <FormControl className={classes.formControl} variant="filled">
                       
                                <TextValidator
                                    autoComplete='off'
                                    id="Password"
                                    name="Password"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Password*"
                                    value={Password}
                                    onChange={this.handleChange}
                                    className={classNames(classes.dense)}
                                    validators={['isPassword', 'required']}
                                    errorMessages={['Password must be combination of upper case,digit and special characters and should have minimum 6 characters', requiredMessage]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                               
                        </FormControl>
                        <FormControl className={classes.formControl} variant="filled">
                             

                                 <TextValidator
                                    autoComplete='off'
                                    id="ConfirmPassword"
                                    name="ConfirmPassword"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Confirm Password*"
                                    value={ConfirmPassword}
                                    onChange={this.handleChange}
                                    className={classNames(classes.dense)}
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['Confirm password did not match', requiredMessage]}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                            
                            </FormControl>

                            <br />
                            <br />
                            <div>
                                {resetMessage !== '' ? (<Paper align="center" square={true} className={classes.Paper} elevation={0}>
                                    <Typography align="center" color="primary">{resetMessage}</Typography>
                                </Paper>) : null}
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.space}>

                            {isResetFailed ?
                                <Paper align="center" square={true} className={classes.Paper} elevation={0}>
                                    <Typography align="center" color="error">The Username or Password you entered is incorrect.</Typography>
                                </Paper> : ''}
                            <Button className={classes.button} size="large" variant="contained" type="submit" color="primary" {...isLoading === true ? { disabled: true } : ''} tabIndex="3">
                                {isLoading ?
                                    <CircularProgress size={19} classes={{ root: classes.circleWhite }} /> :
                                    <span>Save</span>
                                    }
                                   

                            </Button>

                           <Button role="button" onClick={this.toggleDrawer('right', false)} color="default" className={classes.btnCancel} autoFocus>Cancel</Button>
                         
                         </DialogActions>
              
                      
                    </ValidatorForm>
 
                </Drawer>
            </div>

        )
    }

}


export default withStyles(styles)(AdminResetPassword);
