// src/components/Loader.js
import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';

const Loader = () => (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
    </Box>
);

export default Loader;


