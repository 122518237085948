const dev = {
    API_URL: 'https://localhost:5001',
    VSP_URL: 'https://localhost:6001'
};

//const prod = {
//    API_URL: 'https://demospvapi.azurewebsites.net'
//};
//https://spv-backend.test.formbay.com.au     testing server api
//https://spv.api.formbay.com.au  production api
//const prod = {
//    API_URL: 'https://spv-backend-api.azurewebsites.net',
//    VSP_URL: 'https://serialverification.test.formbay.com.au'
// };
const test = {
    API_URL: 'https://spv-backend-api.azurewebsites.net/',
    VSP_URL: 'https://serialverification.test.formbay.com.au'
};

const prod = {
     API_URL: 'https://spv.api.formbay.com.au',
    VSP_URL: 'https://serialverification.formbay.com.au'
};
const config = process.env.NODE_ENV === 'production'
    ? test
    : dev;

export default {
    ...config
}; 