import React from 'react';
import SerialTableHeaderCell from './RequiredHeaderCell';


class RequiredHeader extends React.Component{

    
    render() {
        
        const { requiredFields, headerCellCount,onRequiredFieldUpdate,onRequiredFieldRemoved,isAllRequiredFieldsMapped,onRequiredFieldUpdateAndRemove } = this.props;
        const options = [];
        options.push (<th key={"tr_" + -1} className={"counterCell"} >
            <span >
               
            </span>
        </th>);
        for (let i = 0; i < headerCellCount; i++) {

            options.push(<SerialTableHeaderCell key={"require"+i} onRequiredFieldUpdateAndRemove={onRequiredFieldUpdateAndRemove} requiredFields={requiredFields} isAllRequiredFieldsMapped={isAllRequiredFieldsMapped} onRequiredFieldUpdate={onRequiredFieldUpdate} onRequiredFieldRemoved={onRequiredFieldRemoved} index={i} />);
        }

       
        return options;
    }
}

export default RequiredHeader;
