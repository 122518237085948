import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CSVLink } from 'react-csv';

const DownloadCSV = ({ query, variables, type }) => {
    const { data, loading, error } = useQuery(query, { variables });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const headerKey = type === 0 ? { label: "Manufacturer", key: "displayName" } : { label: "App Provider", key: "appProvider" };

    // Format data for CSV
    const headers = [
        { label: "From", key: "from" },
        { label: "To", key: "to" },
        { label: "Total Installations", key: "totalInstallations" },
        { label: "Total Rated Power (kW)", key: "totalRatedPower_kW" },
        headerKey
    ];

    const mappedData = type === 0
        ? data.admin.leadershipStats.manufacturers.map(manufacturer => ({
            from: manufacturer.from,
            to: manufacturer.to,
            totalInstallations: manufacturer.totalInstallations,
            totalRatedPower_kW: manufacturer.totalRatedPower_kW,
            displayName: manufacturer.displayName
        }))
        : data.admin.leadershipStats.appProviders.map(appProvider => ({
            from: appProvider.from,
            to: appProvider.to,
            totalInstallations: appProvider.totalInstallations,
            totalRatedPower_kW: appProvider.totalRatedPower_kW,
            appProvider: appProvider.appProvider
        }));

    const csvReport = {
        filename: 'Report.csv',
        headers: headers,
        data: mappedData
    };

    return (
        <CSVLink {...csvReport}>Download CSV</CSVLink>
    );
};

export default DownloadCSV;
