import Button from '@material-ui/core/Button';
import orange from '@material-ui/core/colors/orange';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { FlashHeaderTitle, FailedFlashHeaderTitle } from '../../StringConstants';
import { ResolveModeConstant } from '../../constants';
const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});



const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    button: {
        marginLeft: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[600],
        },
    },
    icon: {
        marginRight: theme.spacing(1) * 1,
    },
    content: {
        padding: 0,
        overflowY:"hidden",
    },
    video: {
        width:"100%",
        position:" absolute",
        top: 0,
        left: 0,
        height: "100%",
    },
    videocontent: {
        overflowY: 'auto',
    },
    wrapper: {
        position:" relative",
        paddingBottom:" 56.25% ",/* 16:9 */
        height: 0,
    }
});


class HelpBox extends React.Component {
    //shouldComponentUpdate(nextProps, nextState) {
    //    return false;
    //}
    state = { open: false, maxWidth: 'lg', scroll: 'paper', fullWidth: true,};

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, mode } = this.props;
        const header = mode !== undefined && mode === ResolveModeConstant ? FailedFlashHeaderTitle : FlashHeaderTitle;
          
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" >
                            {header}
                        </Typography>

                    </Grid>
                    <Grid>
                        <Button variant="contained" size="large" component="button" color="default" onClick={this.handleClickOpen}>
                            <Icon className={classes.icon}  color="inherit" >
                               help_outline
                                 </Icon> Help
                        </Button>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
            >
                    <DialogTitle id="scroll-dialog-title" onClose={this.handleClose}>Help</DialogTitle>
                    <div className={classes.videocontent}>
                        <div className={classes.wrapper}>
                            <video playsInline muted loop autoPlay width="640px"  controls className={classes.video}>
                                <source src={require("../../assets/video/help.mp4")} type="video/mp4" />
                                <source src={require("../../assets/video/help.webm")} type="video/webm" />
                                <source src={require("../../assets/video/help.ogv")} type="video/ogv" />
                            </video>     
                            </div>
                        </div>
               </Dialog>
        
            </div>
        );
    }

}
export default withStyles(styles)(HelpBox);


