import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import theme from '../theme';

const styles = {
    
    iconButton: {
        padding: theme.spacing(1),
    },
    searchFloat: {
        float:"right",
        display:"inline-block"
    },
    searchBase: {
        display:"inline-flex",
        justifyContent: "right",      
        padding: theme.spacing(1) * 0.5,
        overflow: "hidden",
    },
    paper: {
        float:"right",
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        background: alpha(theme.palette.common.white, 0.75),
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,
        overflow:"hidden",
   
        '&:hover': {
            background: alpha(theme.palette.common.white, 0.90),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(1) * 5,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //width: theme.spacing(1) * 9,
        //height: '100%',
        //position: 'absolute',
        //pointerEvents: 'none',
        //display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',
        //border: "1px solid",
        //borderColor: theme.palette.grey,
        //background: theme.palette.primary.main,
        //color: theme.palette.common.white,
        //borderTopRightRadius: theme.shape.borderRadius,
        //borderBottomRightRadius: theme.shape.borderRadius,
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1) * 5,
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 250,
           // '&:focus': {
             //   width: 240,
         //   },
        },
    },
};

class ModelSearch extends React.Component {

    constructor(props) {
        super(props);
        //this.state = { filter: this.props.filterText };
        this.autoSearch = this.autoSearch.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
       
    }
    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = (this.props.filterText !== nextProps.filterText);
        return shouldUpdate;
    }
 
    getSearchField(type, value, containerRequired) {

        switch (type) {
            case 1: {
                return (<TextField
                    id="outlined-dense"
                    label="Search"
                    margin="dense"
                    variant="outlined"
                    value={value}
                    fullWidth onChange={(e) => this.autoSearch(e)}
                />);
            }
            case 2: {
                const { classes } = this.props;
                return (<div className={classes.searchBase}>
                    <Paper elevation={0}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                name="search" type="text" value={value} onChange={(e) => this.autoSearch(e)} />
                        </div>
                    </Paper>
                </div>)
            }
            default: {
                if (containerRequired) {
                    const { classes } = this.props;

                    return (<div className={classes.searchBase}>                    
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    name="search" type="text" value={value} onChange={(e) => this.autoSearch(e)} />
                            </div>   
                        </Paper>
                            </div>)
                }
                else {
                    const { classes } = this.props;
                    return (<div className={classes.searchFloat}>
                        <div className={classes.searchBase}>
                            <Paper elevation={0}>
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder="Search"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        name="search" type="text" value={value} onChange={(e) => this.autoSearch(e)} />
                                </div>
                            </Paper>
                        </div>
                    </div>
                        )
                }

            }
        }
    }
    autoSearch(e) {

        this.props.searchHandler(e.target.value);
    }
    render() {
        const { searchMode, containerRequired, filterText } = this.props;
        const text = filterText ? filterText : "";
        const doContainerRequired = containerRequired != null ? containerRequired : true;
        const searchBlock= this.getSearchField(searchMode, text, doContainerRequired);

        return ( searchBlock );

    }

}
export default withStyles(styles)(ModelSearch);