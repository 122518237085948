import { TableCell, TableRow } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React from 'react';
import { SerialImagePath } from '../../../constants';
import theme from '../../../theme';

import PropTypes from 'prop-types';
const styles = {
    paperBox: {
        boxShadow: "none",
        border: "1px solid #ddd",
    },
    serialToggle: {
        width: 265,
    },
    seialNum: {
        color: "#333",
        padding: "0",
        fontSize: "18px",
        lineHeight: "normal",
        alignItems: "baseline",
        "&:hover": {
            background: "transparent",
        },
    },
    supplier: {
        color: "#333",
        padding: "0",
        fontSize: "13px",
        fontWeight: "bold",
        lineHeight: "normal",
        alignItems: "baseline",
        maxWidth: '300px',
        overflow: "hidden",
        textOverflow: "ellipsis",

        "&:hover": {
            background: "transparent",
        },
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    Leftmargin: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        borderRadius: 0,
        width: 42,
        height: 42,
        color: 'tranparent',
        marginRight: '6px'
    },
    inline: {
        display: 'inline',
        marginLeft: theme.spacing(1) * 0.5,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        color: theme.palette.grey[700],
    },
    listClass: {
        display: "flex",
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: "inline-block",
        },
    },
    tag: {
        color: theme.palette.grey[900],
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    paddingZero: {       
        padding: 0,
        textAlign:"right",
    },
};

const InstallTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
        '&:last-child': {
            paddingRight: 16,
        },
        [theme.breakpoints.down('sm')]: {
     
            display: "block",
            textAlign: "right",
            '&:before': {
                content: "attr(data-label)",
                float: "left",
                fontWeight: "bold",
                textTransform: " uppercase",
                lineHeight: "24px",
                marginRight: "16px",
            },
        },
    },
}))(TableCell);


class RowInstalled extends React.Component {

    constructor(props) {
        super(props);
        this._id = _.uniqueId('s_installed');
    }
    state = {
        isSwitchOn: false,
        open: false,
    }

    handleDialogOpen = () => {
        this.setState({ open: !this.state.open });
    };

    fetchAndShowSerialImages = () => {
        const { serial: { serial: serialNumber, productSerialId, manufacturerId: manufacturerIdForSuperAdmin }, showSerialImagesCallback } = this.props;
        showSerialImagesCallback(serialNumber, productSerialId, manufacturerIdForSuperAdmin);
    }
    render() {

      
        const { classes, serial } = this.props;
       
        // const fallbackImage = require("../../../assets/images/solar-panel.jpg");
        const serialImage = serial.image ? SerialImagePath + serial.image : null;
        
        return (

            <TableRow className={classes.row} key={this._id}>
                <InstallTableCell data-label="Serial Number">
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        className={classes.serialToggle}
                    >
                        {/*todo : styling for fallback case*/}
                        <Grid item>
                            <Avatar onClick={serial.image && this.fetchAndShowSerialImages} alt="Serial image" src={serialImage} className={classes.avatar} style={serial.image && { cursor: "pointer" }}>
                                NA
                            </Avatar>
                        </Grid>
                      
                        <Grid item>
                            
                            <Typography variant="h4">
                                {serial.serial}
                            </Typography>
                        </Grid>
                    </Grid>
                </InstallTableCell>

               < InstallTableCell >
                    {serial.model}
                </InstallTableCell>
              
                <InstallTableCell>{serial.uploadedOn}</InstallTableCell>
                <InstallTableCell >      <Typography component="span" className={classes.supplier} color="textPrimary">
                    {serial.supplier ? serial.supplier : "Self supplier"}
                </Typography></InstallTableCell>
                <InstallTableCell>
                    <List className={classes.root} disablePadding classes={{
                        root: classes.listClass
                    }}>
                        <ListItem alignItems="flex-start" disableGutters={true}
                        >
                            <ListItemText                          
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Pmax:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.pmax}
                                        </Typography>
                                    </React.Fragment>
                                }
                                classes={{ root: classes.paddingZero }}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText                    
                                secondary={     
                                    <React.Fragment>
                                        <span className={classes.tag}>    {"FF:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.ff}
                                        </Typography>
                                    </React.Fragment>
                                }
                                classes={{ root: classes.paddingZero }}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Voc:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.voc}
                                        </Typography>
                                    </React.Fragment>
                                }
                                classes={{ root: classes.paddingZero }}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText
                               secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Isc:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.isc}
                                        </Typography>
                                    </React.Fragment>
                                }
                                classes={{ root: classes.paddingZero }}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText                      
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}>  {"Vpm:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.vpm}
                                        </Typography>
                                    </React.Fragment>
                                }
                                classes={{ root: classes.paddingZero }}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" disableGutters={true}>
                            <ListItemText                                  
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.tag}> {"Ipm:"}</span>
                                        <Typography component="span" className={classes.inline} color="textPrimary">
                                            {serial.ipm}
                                        </Typography>

                                    </React.Fragment>
                                }
                                classes={{ root: classes.paddingZero }}
                            />
                        </ListItem>
                    </List>
                </InstallTableCell>
               

            </TableRow>


        );




    }
}
RowInstalled.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RowInstalled);
