import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from "react";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { ProfileApi } from '../../constants';
import theme from '../../theme';
import AuthService from '../AuthService';
import UserProfile from './UserProfile';
import InstallationVideo from './installationVideo';
import FormFooter from './FormFooter';
import { Helmet } from "react-helmet";
import decode from 'jwt-decode';
import { UserContext } from '../../libs/UserContext';
import _ from 'lodash';
const styles = theme => ({
    root: {
        flexGrow: 1,
        height: "100%",      
    },
    formControl: {
        width: "100%"
    },
    Paper: {
        padding: theme.spacing(1) * 1,
        background: theme.palette.common.logIn,
    },
    container: {
        height: '100%',
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(1) * 8,
        paddingRight: theme.spacing(1) * 8,
        width:275,
        height: 50,
        '&:disabled': {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
        '&:hover': {
            '&:disabled': {
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down('xs')]: {
            width:"100%",
        },
    },
    formContent: {
        minHeight: "500px",
        height: "calc(100vh - 130px)",
    },

    forgot: {
        float: "right",
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        textDecoration: "none",
        marginRight: '-12px',
        
    },
    center: {
        textAlign:"center",
    },
    logText: {
        marginBottom: "30px",
        textAlign: "center",
        textTransform: 'inherit',
        fontWeight: 'normal',
    },
    headFrame: {
        width: "100%",
        height: '180px',
    },
    formOuterFrame: {
        width: "100%",
        height: '300px',
    },
    circleWhite: {
        color: theme.palette.common.white,
    }
});

const Logo = styled.div`
    padding: 30px 0 30px 0;
    text-align: center;
`;

const LeftWrapper = styled.div`
    float: left;
    width:25%;
    position: relative;
    height: 100%;
     background-color: #fff;
    display:table;
    padding:0 60px 0 60px;
    @media(max-width:1600px){
      width:30%;
    }
    @media(max-width:1400px){
      width:40%;
    }
    @media(max-width:1280px){
      padding:0 40px 0 40px;
    }
    @media(max-width:992px){
      width:100%;
      float:none;
    }
    @media(max-width:767px){
      padding:0 30px 0 30px;
    }
`;



const RightWrapper = styled.div`
    float: left;
    width: 75%;
    position: relative;  
    display:block;
    @media(max-width:1600px){
      width:70%;
    }
    @media(max-width:1400px){
      width:60%;
    }
    @media(max-width:992px){
        display:none;
    }
    &::before {
        content: "";
        position: fixed;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: -1;
    }
    .header {
        position: fixed;
        top: 0;
        bottom: 0;
        padding: 50px 50px 50px 50px;
        //width:75%;

        h1 {
            color: #fff;
            margin-bottom: 10px;
            font-weight: normal;
        }

        p {
            color: #fff;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .footer {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 50px 50px 30px 50px;

        .address-box {
            padding: 30px 0 0 0;
            border-top: 1px solid rgba(255, 255, 255, 0.3);

            h3 {
                color: #fff;
                margin-bottom: 5px;
            }

            p {
                color: #fff;
                font-size: $font-size-base;
            }
        }
    }
`;

class Login extends React.Component {

    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.tryLoging = this.tryLoging.bind(this);
        this.Auth = new AuthService();
        this.state = { isLoading: false, isLoginFailed: false, username: '', password: '' ,header: false, selectedIndex: 0};
        this.updateProfile = this.updateProfile.bind(this);
        this.setUserProfile = this.setUserProfile.bind(this);
        this.onNavigationChange = this.onNavigationChange.bind(this);
    }
    static contextType = UserContext;
    onNavigationChange(newIndex) {
        this.setState({ selectedIndex: newIndex });
    }
    componentDidMount() {

        if (this.props.location) {
            const values = queryString.parse(this.props.location.search);

            if (values.signout) {
                this.Auth.logout();
            }
        }

        if (this.Auth.loggedIn()) {
            let redirectPath = "/dashboard?v=1";
            if (UserProfile.isAdmin()) {
                redirectPath ="/AdminDashboard?v=1"
            }
            this.props.history.replace(redirectPath);
        }
           

        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('hasValue', (value) => {
            return value.length > 0;
        });
    }


    tryLoging(e) {
        e.preventDefault();
        if (this.state.isLoading) return;
        this.setState({ isLoading: true, isLoginFailed: false });
        this.handleFormSubmit(e);
    }
    updateProfile() {
        this.props.updateProfile();

    }
    handleChange(e) {

        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }
    handleFormSubmit(e) {

      
        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                
                const token = this.Auth.getToken();
                const decodeToken = decode(token);
                const role = decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                let isDistributorStaffWithoutManufacturer = false;
                if (role === "superadmin") {
                    this.setUserProfile({
                        manufacturerId: null, displayName: "Global Admin", isDistributor: false, userId: decodeToken["sub"], uploadFrequency: 0, address: "", googlePlaceId: "",
                        logo: "", profilePhoto: "", firstName: "Admin", lastName: "Admin", email: "spv@formbay.com.au", phoneNumber: "0000000000", isStaff: false,role: role
                    });
                    return;
                }
                else if (role === "supplier") { // todo : seems unused role
                    this.setUserProfile({
                        manufacturerId: null, displayName: "Supplier", isDistributor: false, userId: decodeToken["sub"], uploadFrequency: 0, address: "", googlePlaceId: "",
                        logo: "", profilePhoto: "", firstName: "Supplier", lastName: "Supplier", email: "spv@formbay.com.au", phoneNumber: "0000000000", isStaff: false, role: role
                    });
                    return;
                }
                else if (role === "distributor_staff") {
                    isDistributorStaffWithoutManufacturer = parseInt(decodeToken["ManufacturerIdList"].split(",")[0]) ? false : true;
                }

                fetch(ProfileApi, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`
                    },
                    body: null
                })
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        res.role = role;
                        res.isDistributorStaffWithoutManufacturer = isDistributorStaffWithoutManufacturer;
                        this.setUserProfile(res);
                    });



            })
            .catch(err => {
                this.setState({ isLoading: false, isLoginFailed: true });

            })
    }
    setUserProfile(res) {
        const { history } = this.props;
        UserProfile.setManufacturerId(_.defaultTo(res.manufacturerId,null));
        UserProfile.setDisplayName(_.defaultTo(res.displayName,null));
        UserProfile.setDistributor(_.defaultTo(res.isDistributor,null));
        UserProfile.setUserId(_.defaultTo(res.userId,null));
        UserProfile.setUploadFrequency(_.defaultTo(res.uploadFrequency,null));
        UserProfile.setAddress(_.defaultTo(res.address,null));
        UserProfile.setGooglePlaceId(_.defaultTo(res.googlePlaceId,null));
        UserProfile.setLogo(_.defaultTo(res.logo,null));
        UserProfile.setProfilePhoto(_.defaultTo(res.profilePhoto,null));
        UserProfile.setFirstName(_.defaultTo(res.firstName,null));
        UserProfile.setLastName(_.defaultTo(res.lastName,null));
        UserProfile.setEmail(_.defaultTo(res.email,null));
        UserProfile.setPhone(_.defaultTo(res.phoneNumber),null);
        UserProfile.setIsStaff(_.defaultTo(res.isStaff,null));
        UserProfile.setRole(_.defaultTo(res.role,null));
        UserProfile.setIsDistributorStaffWithoutManufacturer(res.isDistributorStaffWithoutManufacturer);

        if (res.role === "superadmin") {
            this.context.forceInvokeApp(true);
            history.replace('/AdminDashboard?v=1');
        }
        else {
            if (res.role === "madmin") { this.context.forceInvokeApp(true) }
            history.replace('/dashboard?v=1');
        }

    }
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    render() {
        
        const isloading = this.state.isLoading;
        const isLoginFailed = this.state.isLoginFailed;
        const { classes } = this.props;
        return (<div className={classes.root}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SPV | Login</title>
            </Helmet>
            <MuiThemeProvider theme={theme}>
                <LeftWrapper>
                    <Grid container direction="row" alignItems="center" justify="center" className={classes.formContent} >
                        <Grid item xs={12}>
                            <ValidatorForm instantValidate={true} debounceTime={100} onSubmit={(e) => this.tryLoging(e)}>
                                <Grid container direction="row" justify="center">
                                    <Grid item xs={12} className={classes.headFrame}>
                                        <Logo><img src={require("../../assets/images/solar-logo.svg")} width="250" alt="Formbay Logo" /></Logo>
                                        <Typography variant="h1" className={classes.logText}>
                                            Login to your account
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Grid container className={classes.formOuterFrame}>
                                            <Grid item xs={12}>
                                                <div className="loginForm">
                                                    <FormControl className={classes.formControl} error>
                                                        <TextValidator autoComplete="off"
                                                            fullWidth
                                                            id="username"
                                                            name="username"
                                                            label="Username"
                                                            placeholder="Username"
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.cssLabel,
                                                                    focused: classes.cssFocused,
                                                                },
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.cssOutlinedInput,
                                                                    focused: classes.cssFocused,
                                                                    notchedOutline: classes.notchedOutline,
                                                                },
                                                            }}
                                                            value={this.state.username}
                                                            onChange={(e) => this.handleChange(e)}
                                                            validators={['hasValue']}
                                                            errorMessages={['This field is required']}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="loginForm">
                                                    <FormControl className={classes.formControl} error>
                                                        <TextValidator id="password"
                                                            name="password"
                                                            tabIndex="2"
                                                            fullWidth
                                                            margin="normal"
                                                            variant="outlined"
                                                            type={this.state.showPassword ? 'text' : 'password'}
                                                            label="Password"
                                                            value={this.state.password}
                                                            placeholder="xxxxxxxxxxxx"
                                                            onChange={(e) => this.handleChange(e)}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.cssLabel,
                                                                    focused: classes.cssFocused,
                                                                },
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="Toggle password visibility"
                                                                            onClick={this.handleClickShowPassword}
                                                                            color="inherit"
                                                                        >
                                                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                classes: {
                                                                    root: classes.cssOutlinedInput,
                                                                    focused: classes.cssFocused,
                                                                    notchedOutline: classes.notchedOutline,                                                           
                                                                },

                                                            }}
                                                            validators={['hasValue']}
                                                            errorMessages={['This field is required']}
                                                            helperText={<Link component={RouterLink} to="/forgot-password" classes={{ root: classes.forgot }} title="Forgot Password?">
                                                                Forgot Password?
                                                                    </Link>}

                                                        />
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} className={classes.center}>
                                                {isLoginFailed ?
                                                    <Paper align="center" square={true} className={classes.Paper} elevation={0}>
                                                        <Typography align="center" color="error">The Username or Password you entered is incorrect.</Typography>
                                                    </Paper> : ''}
                                                <Button className={classes.button} size="large" variant="contained" type="submit" alignitem='center' color="primary" {...isloading === true ? { disabled: true } : ''} tabIndex="3">
                                                    {isloading ?
                                                        <CircularProgress size={19} classes={{ root: classes.circleWhite }} /> :
                                                        <span>Log in</span>
                                                    }
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} className={classes.center}>
                                                Don't have an account?&nbsp;<Link component={RouterLink} to="/register" title="Register here">
                                                    Register here
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>                              
                            </ValidatorForm>
                        </Grid>
                    </Grid>      
                    <FormFooter />
                </LeftWrapper>
                <Hidden xsDown>
                    <InstallationVideo />
                </Hidden>
                <RightWrapper>
                    <div className="header">
                        <h1>Solar Panel Validation (Formbay)</h1>
                        <p>The Solar Panel Validation Project is intending to establish a voluntary mechanism that allows businesses in the supply chain to validate the authenticity of solar panels.</p>
                    </div>
                    <div className="footer">
                        <div className="address-box">
                            <h3>Formbay Pty. Ltd.</h3>
                            <p>Level 1, 222 Clarence Street , NSW 2000</p>
                        </div>
                    </div>
                </RightWrapper>
            </MuiThemeProvider>
            </div>
        );
    }



};

//export default Login;

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);