import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import LocationIcon from '@material-ui/icons/LocationOnTwoTone';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import theme from '../../../theme';
import { SerialImagePath } from '../../../constants';

const styles = {
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
    },
    avatar: {
        borderRadius: 0,
        width:42,
        height: 'auto',
        color: 'tranparent'
    },
    avatar2: {
        borderRadius: 0,
        width: 62,
        height: 'auto',
        color: 'tranparent'
    },
    Rightmargin: {
        marginRight: theme.spacing(1) * 6,
    },
    paddingReset: {
        paddingRight: 0
    },
    iconSmall: {
        fontSize: 20,
    },
    linkBtn: {
        padding: 0,
        transition: "200ms ease",
        justifyContent: "left",
        '&:hover': {
            background: "transparent",
            texSerialTableCellecoration: "underline",
        }
    },
    button: {
        lineHeight: "13px",
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    action: {
        width: 140,
        color: "#4e4e4e",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    marginBtm: {
        marginBottom: theme.spacing(1) / 2
    }
}

const SerialTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14, whiteSpace: " nowrap",
    },
}))(TableCell);


class FailedSerialRow extends React.Component {


    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_row');
        this.state = { copySuccess: '' }
        this.copyToClipboard = this.copyToClipboard.bind(this);

    }
    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        this.setState({ copySuccess: 'Copied!' });
        setTimeout(() => { this.setState({ copySuccess: '' }); }, 3000);
    };

    render() {
        const { classes } = this.props;
        const serial = this.props.serial;
        const requestType = (serial.requesterType === 1) ? "Installation" : ((serial.requesterType === 2) ? "Product" : "Serial");
        const serialImage = serial.image ? SerialImagePath + serial.image : null;
        return (

            <TableRow className={classes.row} key={this._id}>
                <SerialTableCell >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={16}
                    >
                        <Grid item>
                            <Avatar onClick={serial.image && this.fetchAndShowSerialImages} alt="Serial image" src={serialImage} className={classes.avatar} style={serial.image && { cursor: "pointer" }}>
                                NA
                            </Avatar>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="h4">
                                {serial.failedSerial}
                                <IconButton className={classes.button} aria-label="copy" color="primary" onClick={() => { this.copyToClipboard(serial.failedSerial) }}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Typography>
                        
                         
                            {this.state.copySuccess}
                        </Grid>
                    </Grid>    
                </SerialTableCell>
                <SerialTableCell>{serial.requestedTime}</SerialTableCell>
                <SerialTableCell>              
                        {serial.requesterName}
                </SerialTableCell>
                <SerialTableCell>
                    {requestType}
                </SerialTableCell>
                <SerialTableCell>
                    {serial.longitude &&
                        <Button color='primary' variant='outlined' size="small" className={classNames(classes.view)} rel="noopener noreferrer" href={`http://www.google.com/maps/place/${serial.latitude},${serial.longitude}`} target="_blank">
                            <LocationIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                            View
                    </Button>
                    }
                    {!serial.longitude &&

                        ("Location Not Available")

                    }
                </SerialTableCell>
                
                <SerialTableCell >
                    <div>
                        <Button size="small" variant="outlined" className={classNames(classes.action, classes.marginBtm)} color='default' href='/flash'>
                            Upload Flash Data
                    </Button>
                    </div>
                    <Button color='primary' variant="outlined" className={classes.action} size="small" href='' disabled>
                        Parallel Import?
                    </Button>      
                </SerialTableCell> 
            </TableRow>
           

        );

       


    }
}

export default withStyles(styles)(FailedSerialRow);
