import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, makeStyles, TextField, Button, FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import QueryHelper from '../../libs/QueryHelper';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import AddCityDialog from './AddCityDialog'; // Adjust the import path as needed

const useStyles = makeStyles((theme) => ({
    addButton: {
        marginLeft: 'auto',
        marginBottom: theme.spacing(2),
    },
    addButtonContainer: {
        display: 'flex',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexItem: { flex: 1 },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    searchContainer: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    searchButton: {
        alignSelf: 'center',
    },
}));

const CityList = () => {
    const classes = useStyles();
    const searchInitialValue = {
        cityName: '',
        stateId: '',
        postalCode: '',
        freeText: ''
    };
    const [searchCriteria, setSearchCriteria] = useState(searchInitialValue);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState(null);

    

    const { loading, error, data, refetch } = useQuery(QueryHelper().CityList(), {
        variables: { search: search }
    });
    const clearSearch = () => {
        setSearch(null);
        setSearchCriteria(searchInitialValue);
    }
    const handleSearch = (obj) => {

        const searchCriteriaUpdated = obj != null ? obj : searchCriteria;
        const newSearch = {
            cityName: !searchCriteriaUpdated.cityName ? null : searchCriteriaUpdated.cityName,
            stateId: !searchCriteriaUpdated.stateId ? null : parseInt(searchCriteriaUpdated.stateId),
            postalCode: !searchCriteriaUpdated.postalCode ? null : searchCriteriaUpdated.postalCode,
            freeText: !searchCriteriaUpdated.freeText ? null : searchCriteriaUpdated.freeText
        };
        setSearch(newSearch);
        refetch({ search: newSearch });
        setOpen(false);
    };

    const handleAddCity = (newCity) => {
        console.log('New City Added:', newCity);
        setSearchCriteria(prevState => ({
            ...prevState,
            ...newCity
        }));
        
        handleSearch(newCity); // Refresh the list after adding a new city
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchCriteria(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
 
    if (loading) return <FbLoader loaderStyle={'center'} />;
    if (error) return <FbError errorStyle={'default'} message='Some error occured' />;
    if (!data || !data.admin.cityList) return <FbEmptyResult />;

    return (
        <div className={`${classes.container} ${classes.flexItem}`} >
            <div className={classes.searchContainer}>
                <TextField
                    label="City Name"
                    name="cityName"
                    value={searchCriteria.cityName}
                    onChange={handleChange}
                />
                <FormControl className={classes.formControl}>
                    <InputLabel>State</InputLabel>
                    <Select
                        name="stateId"
                        value={searchCriteria.stateId}
                        onChange={handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value={4122}>Australian Capital Territory</MenuItem>
                        <MenuItem value={4123}>Northern Territory</MenuItem>
                        <MenuItem value={4125}>South Australia</MenuItem>
                        <MenuItem value={4126}>Western Australia</MenuItem>
                        <MenuItem value={4127}>New South Wales</MenuItem>
                        <MenuItem value={4128}>Queensland</MenuItem>
                        <MenuItem value={4129}>Victoria</MenuItem>
                        <MenuItem value={4130}>Tasmania</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Postal Code"
                    name="postalCode"
                    value={searchCriteria.postalCode}
                    onChange={handleChange}
                />
                <TextField
                    label="Free Text"
                    name="freeText"
                    value={searchCriteria.freeText}
                    onChange={handleChange}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={()=>handleSearch()}
                    className={classes.searchButton}
                >
                    Search
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={clearSearch}
                    className={classes.searchButton}
                >
                    Clear
                </Button>
            </div>
            <div className={`${classes.addButtonContainer} ${classes.flexItem}`} >
                <IconButton
                    className={classes.addButton}
                    color="primary"
                    onClick={() => setOpen(true)}
                >
                    <AddIcon />
                </IconButton>
            </div>

            <AddCityDialog open={open} handleClose={() => setOpen(false)} addCity={handleAddCity}  />

            <TableContainer component={Paper} className={classes.flexItem}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>State</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Postal Code</TableCell>
                            <TableCell>Created On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.admin.cityList.map((city, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{city.stateName}</TableCell>
                                <TableCell>{city.cityName}</TableCell>
                                <TableCell>{city.postalCode}</TableCell>
                                <TableCell>{city.createdOn}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default CityList;
