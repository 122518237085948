// src/components/ManufacturerLeadershipBoard.js
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FbEmptyResult from '../../common/FbEmptyResult';
import FbError from '../../common/FbError';
import FbLoader from '../../common/FbLoader';
import DownloadCSV from './DownloadCSV';
import QueryHelper from '../../../libs/QueryHelper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flex: '1'
    },
    filterContainer: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    datePicker: {
        marginLeft: theme.spacing(2),
    }
}));

const ManufacturerLeadershipBoard = ({
    search,
    fromDate,
    onFromDateChange,
    toDate,
    onToDateChange
}) => {
    const classes = useStyles();
    const [loadingState, setLoadingState] = useState(false);

    const [loadManufacturers, { loading, error, data }] = useLazyQuery(QueryHelper().ManufacturerLeadershipBoard(), {
        onCompleted: () => setLoadingState(false),
        onError: () => setLoadingState(false),
    });

    useEffect(() => {
        setLoadingState(true);
        loadManufacturers({
            variables: {
                search, from: fromDate.format("YYYY-MM-DD")
                , to: toDate.format("YYYY-MM-DD") } });
    }, [search, fromDate, toDate, loadManufacturers]);

    if (loading || loadingState) return <FbLoader loaderStyle={'center'} />;
    if (error) return <FbError errorStyle={'default'} message='No pull request available' />;
    if (!data || !data.admin.leadershipStats || !data.admin.leadershipStats.manufacturers || data.admin.leadershipStats.manufacturers.length === 0) {
        return <FbEmptyResult />;
    }

    const variables = {
        search, from: fromDate.format("YYYY-MM-DD"), to: toDate.format("YYYY-MM-DD") };

    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>Total Installations</TableCell>
                            <TableCell>Total Rated Power (kW)</TableCell>
                            <TableCell>Manufacturer <DownloadCSV query={QueryHelper().ManufacturerLeadershipBoard()} variables={variables} type={0} /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.admin.leadershipStats.manufacturers.map((manufacturer, index) => (
                            <TableRow key={index}> {/* Ensure each manufacturer has a unique key */}
                                <TableCell>{manufacturer.from}</TableCell>
                                <TableCell>{manufacturer.to}</TableCell>
                                <TableCell>{manufacturer.totalInstallations}</TableCell>
                                <TableCell>{manufacturer.totalRatedPower_kW}</TableCell>
                                <TableCell>{manufacturer.displayName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ManufacturerLeadershipBoard;
