import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { LINKS_PER_PAGE, Role_Admin, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF, Role_MANUFACTURER, Role_STAFF, SERIAL_TYPE } from '../../constants';
import QueryHelper from '../../libs/QueryHelper';
import theme from '../../theme';
import UserProfile from '../auth/UserProfile';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from "../common/Pagination";
import SerialHeader from './rows/header/HeaderInstalled';
import SerialRow from "./rows/RowInstalled";

const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        //borderLeftWidth: "1px",
        //borderRightWidth: "1px",
        //borderColor: theme.palette.common.borderColor
    },
    paperTable: {
        border: "1px solid",
        borderColor: theme.palette.common.borderColor,
        minHeight: "calc(100vh - 300px)",
        width: "100%",
        overflowX: 'auto',
        background: theme.palette.common.white,

    }
};

const FooterCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);
class InstalledTab extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { classes, globalView } = this.props;
        let { manufacturerId, showSerialImagesCallback, filter, serials } = this.props;



        //const query = ProductSerialListing adminView && globalView ? ADMIN_GLOBAL_PRODUCTS_QUERY : adminView ? ADMIN_PRODUCTS_QUERY : PRODUCTS_QUERY;
        return (
            <Fragment>
                <div className={classes.root}>
                    <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                        <Table className={classes.table}>
                            <Hidden xsDown>
                                <TableHead>
                                    <SerialHeader />
                                </TableHead>
                            </Hidden>
                            <TableBody>
                                {!serials || serials.length == 0 && <FbEmptyResult isTableBodyRequired={false} colSpan={5} errorStyle={'emptycell'} />}
                                {serials && serials.length > 0 &&
                                    (serials.map((serial, index) =>
                                    (
                                        <SerialRow showSerialImagesCallback={showSerialImagesCallback} key={index} serial={serial} index={index} />
                                    )))
                                }
                            </TableBody>

                        </Table>
                    </Paper>
                </div>
            </Fragment>
        );

    }
}

export default withStyles(styles)(InstalledTab); 
