import { Paper,Grid,Typography } from '@material-ui/core/';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { withStyles,alpha } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import React from 'react';

//import AdminResetPassword from '../admin/AdminResetPassword';
//import Editstaff from '../admin/Editstaff';
import Installations from "../installations/InstallationContainer";
import Model from "../product/Product";
import Serials from "../serial/SerialContainer";
import Staff from "../staff/Staff";
import NotificationContainer from "../notification/NotificationContainer";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import DetailHeader from '../../components/admin/DetailHeader';
import UploadHistoryContainer from '../uploadhistory/UploadHistoryContainer';
import SupplierListing from '../supplier/SupplierListing';
import  SerialContainerWithProvider  from '../serial/SerialContainer';
const fontprimary = "1rem";
const fontsecondary = "0.9rem";
function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

const styles = theme => ( {
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        background: theme.palette.grey[50],
    },
    contentPaper: {
        margin: theme.spacing(2),
        // padding: theme.spacing(2),
        background: theme.palette.common.white,
        // border: "1px solid",
        borderColor: theme.palette.common.borderColor, overflowX: 'auto',
    },

    fieldsection: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    listroot: {
        width: "320px",
        whiteSpace: "normal",
    },
    root: {
        padding: 0,
        width:'100%'
    },
    button: {
        marginRight: theme.spacing(2),
        boxShadow: "none"
    },

    cardinfo: {
        boxShadow: "none",
        background: "none",
        width: "250px",
        whiteSpace: "normal",
    },
    card: {
        boxShadow: "none",
        background: "none",
    },
    titleroot: {
        fontSize: "1.3rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    titleupload: {
        fontSize: "1rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    subtext: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: fontsecondary,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    userName: {
        color: "rgba(0, 0, 0, 0.87)"
    },
    AccessTime: {
        verticalAlign: "middle",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1) / 2,
    },
    rootCard: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: "1rem",
    },
    uploadFreq: {
        padding: theme.spacing(1),
        background: alpha(red[50], 1),
    },
    subCard: {
        fontSize: fontprimary,
    },
    subTitle: {
        fontSize: fontsecondary,
    },
    timeupload: {
        fontSize: fontsecondary,
        marginLeft: "4px",
        color: theme.palette.grey[600],
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    exclamation: {
        background: red[500],
        width: 34,
        height: 34,
    },
    scannedbg: {
        background: alpha(blue[50], 0.5),
    },
    titlescan: {
        fontSize: "1.3rem",
    },
    primarytext: {
        fontSize: fontprimary,
    },
    secondarytext: {
        fontSize: fontsecondary,
    },
    inline: {
        display: 'inline',
    },
    spaceLeft: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    formControl: {
        width: '100%',
    },
    paddingNone: {
        paddingBottom: 0,
        overflowY: "initial"
    },
    btnCancel: {
        marginRight: 20,
    },
    alert: {
        background: theme.palette.primary.main,
        fontSize: "1.3rem",
    },
    tabsRoot: {
        color: theme.palette.grey[700],
    },
    //tabsIndicator: {
    //    backgroundColor: 'transparent',
    //},
    tabRoot: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: "transparent",
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        textTransform: 'capitalize',
        fontSize: "18px",
        textAlign: "center",
        color: theme.palette.grey[800],
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        transition: "200ms ease",
        // marginRight: theme.spacing(1),

        //fontWeight: theme.typography.fontWeightRegular,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        //letterSpacing:"1px",
        '&:hover': {
            backgroundColor: theme.palette.common.tabHover,
            color: theme.palette.grey[900],


        },
        '&$tabSelected': {
            backgroundColor: theme.palette.grey[100],
            fontFamily: 'NHaasGroteskDSPro-65Md',
            color: theme.palette.grey[900],
            borderTopWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            borderColor: theme.palette.common.borderColor
        },
        '&:focus': {
            color: theme.palette.grey[900],
        },
    },
    tabSelected: {},
    padding: {
        padding: 0,
        minHeight: "auto",
    },
    staffContent: {
        padding: theme.spacing(2),
    }
});




class ManufacturerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: 1,tabChanged:false};     
    } 



    handleChange = (event, value) => {
        this.setState({ value, tabChanged:true});
    };

    renderTab(props) {
        const tabList = props.tabs.map((tab, index) =>
            (
                <li key={index}>
                    <a href={tab.path}>
                        <h3>{tab.tabname}</h3>
                        <span className={tab.badgeIcon}>{tab.number}</span>
                        <p>{tab.modelname}</p>
                    </a>
                </li>
            ));

        return (
            <ul> {tabList} </ul>
        );
    }



    render() {
        
        const { classes } = this.props;
        const { value, tabChanged} = this.state;
        let activeIndex = value;
        const { manufacturer, from, activeIndexRestore } = this.props.location.state;
        if (activeIndexRestore && activeIndexRestore > 0) {
            activeIndex = activeIndexRestore;
        }
        if (!tabChanged && from && from === 'notification') { activeIndex = 5;}
        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.fieldsection}
                >
                    <Grid item sm={12} xs={12}>
                        <DetailHeader manufacturer={manufacturer} />
                    </Grid>
                </Grid>

            <Paper elevation={0} className={classes.contentPaper}>
                <Tabs
                        value={activeIndex}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                >
                    <Tab
                        value={1}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label={<Toolbar className={classes.padding} variant="dense" disableGutters>
                            <Typography variant="h3" className={classes.grow}>
                                Models
                            </Typography>
                        </Toolbar>}

                    />
                    <Tab
                        value={2}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label={
                            <Toolbar className={classes.padding} variant="dense" disableGutters>
                                <Typography variant="h3" className={classes.grow}>
                                    Serials
                           </Typography>
                            </Toolbar>}
                    />
                    <Tab
                        value={3}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label={
                            <Toolbar className={classes.padding} variant="dense" disableGutters>
                                <Typography variant="h3" className={classes.grow}>
                                    Installation(verified)
                                </Typography>
                            </Toolbar>}
                    />
                    <Tab
                        value={4}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label={
                            <Toolbar className={classes.padding} variant="dense" disableGutters>
                                <Typography variant="h3" className={classes.grow}>
                                    Staff
                                </Typography>
                            </Toolbar>}
                        />
                        <Tab
                            value={5}
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <Toolbar className={classes.padding} variant="dense" disableGutters>
                                    <Typography variant="h3" className={classes.grow}>
                                        Notifications
                                </Typography>
                                </Toolbar>}
                        />
                        <Tab
                            value={6}
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <Toolbar className={classes.padding} variant="dense" disableGutters>
                                    <Typography variant="h3" className={classes.grow}>
                                        Upload History
                                </Typography>
                                </Toolbar>}
                        />
                        <Tab
                            value={7}
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={
                                <Toolbar className={classes.padding} variant="dense" disableGutters>
                                    <Typography variant="h3" className={classes.grow}>
                                        Suppliers
                                    </Typography>
                                </Toolbar>}
                        />
                </Tabs>

                    {activeIndex === 1 && <TabContainer><Model manufacturerId={manufacturer.manufacturerId} adminView={true} /></TabContainer>}
                    {activeIndex === 2 && <TabContainer>

                       {/* <Serials manufacturerId={manufacturer.manufacturerId} name={manufacturer.displayName} adminView={true} />*/}
                        <SerialContainerWithProvider manufacturerId={manufacturer.manufacturerId} name={manufacturer.displayName} adminView={true} />
                    </TabContainer>}
                    {activeIndex === 3 && <TabContainer><Installations manufacturerId={manufacturer.manufacturerId} adminView={true} /></TabContainer>}
                    {activeIndex === 4 && <TabContainer><div className={classes.staffContent}><Staff manufacturer={manufacturer} adminView={true} /></div></TabContainer>}
            
                    {activeIndex === 5 && <TabContainer><div className={classes.staffContent}><NotificationContainer manufacturer={manufacturer} adminView={true} /></div></TabContainer>}

                    {activeIndex === 6 && <TabContainer><div className={classes.staffContent}><UploadHistoryContainer manufacturerId={manufacturer.manufacturerId} adminView={true}  /></div></TabContainer>}
                    {activeIndex === 7 && <TabContainer><div className={classes.staffContent}><SupplierListing manufacturerId={manufacturer.manufacturerId} isAdmin={true} showNavigation={false} overAllListing={ false} /></div></TabContainer>}

              

            </Paper>
            </div>
        )
    }
}
ManufacturerDetail.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ManufacturerDetail);
