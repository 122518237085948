import { TableCell, TableRow } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const fontsecondary = "0.9rem";

const styles = {
    subtext: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: fontsecondary,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
}




const CustomTableCell = withStyles(theme => ({
    head: {
        background: theme.palette.grey[100],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 18,
        height: "auto",
        whiteSpace: " nowrap",
        lineHeight: "1",
        padding: "4px 16px 4px 16px"
    },
    body: {
        borderBottomColor: theme.palette.grey[200],
        fontSize: 16,
        whiteSpace: " nowrap",
        padding: "4px 16px 4px 16px"
    },
}))(TableCell);
class AdmintableHead extends React.Component {


    render() {

        const { classes, AllowedToActivate } = this.props;      
        return (
            <TableRow>
                {/*  {props.cols.map(col =>
                    <CustomTableCell key={col.name}>{col.header}</CustomTableCell>
                )} */}
                <CustomTableCell>#</CustomTableCell>
                <CustomTableCell>Company Info
                   {/* <br />
                    <span className={classes.subtext}>Address / ABN / Email / Phone</span>  */}
                </CustomTableCell>
                <CustomTableCell>Serials                           <br />
                    <span className={classes.subtext}>Failed / Total Requests</span> 
                </CustomTableCell>
                <CustomTableCell>Last upload
                    {/* <br />
                    <span className={classes.subtext}>Expected Frequency</span>
                    */}
                </CustomTableCell>
                { AllowedToActivate && (
                    <CustomTableCell >Action </CustomTableCell>
                )}
                
                <CustomTableCell colSpan={2} >Access </CustomTableCell>
            </TableRow>
            );
    }


}

AdmintableHead.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(AdmintableHead);