import Link from '@material-ui/core/Link';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import styled from 'styled-components';

const Footer = styled.footer`
    width: 100%;
    float: left;
    height: 100px;
    margin: 30px 0 0 0;
    padding: 20px 0 0;
    border-top: 1px solid #e5e5e5;
    background: none;
    ul {

        text-align: center;
    }
    li {
        display: inline-block;
        margin: 0 15px 0 15px;
        a {
            font-family: 'NHaasGroteskDSPro-55Rg';
            font-size: 15px;
            color:#0d4add;
            text-decoration: none;
            &:hover{
                color: #0d4add;
                text-decoration: underline;
            }
        }
    }
    p{
        text-align: center;
        font-size: 14px;
        color:#333;
        font-family: 'NHaasGroteskDSPro-55Rg';
        padding: 15px 0 10px 0;
    }   
`;
class FormFooter extends React.Component {
    state = {
        selectedIndex: 0,
    };

    handleListItemClick = (event, index) => {
        this.setState({ selectedIndex: index });
    };
    render() {
        var links = [
            { name: 'Terms of use', path: '/legal#termUse' },
            { name: 'Privacy', path: '/legal#privacy' },
            { name: 'About', path: '/legal#about' }
        ];
        const listItems = links.map((link, index) =>
            <li key={index}>
                <Link component={RouterLink} title={link.name} to={link.path}>{link.name}</Link>
            </li>
        );
        return (
            <Footer>
                {/*<AnchorLink href='/legal' offset='300' >
                    <ListItem
                        disableRipple
                        button
                        component={Link} to="/legal"
                        selected={this.state.selectedIndex === 0}
                        onClick={event => this.handleListItemClick(event, 0)}
                    >
                        <ListItemText primary="Terms of use" disableTypography />
                    </ListItem>
                </AnchorLink>
                <AnchorLink  >
                    <ListItem
                        disableRipple
                        button
                        component={Link} to="/legal#privacy"
                        selected={this.state.selectedIndex === 1}
                        onClick={event => this.handleListItemClick(event, 1)}
                        
                    >
                        <ListItemText primary="Privacy" disableTypography />
                    </ListItem>
                </AnchorLink>
                <AnchorLink  offset="125" >
                    <ListItem
                        disableRipple
                        button
                        component={Link} to="/legal"
                        selected={this.state.selectedIndex === 2}
                        onClick={event => this.handleListItemClick(event, 2)}

                    >
                        <ListItemText primary="About" disableTypography />
                    </ListItem>
                </AnchorLink>*/}
                <ul>{listItems}</ul>
                <p>Copyright &copy;2020 Formbay, Inc. All rights reserved.</p>
            </Footer>
        );
    }
}

//export default withStyles(styles)(FormFooter);
export default FormFooter;