import { Button, Card, Checkbox, CardHeader, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core/';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles, alpha } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import PropTypes from "prop-types";
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { LINKS_PER_PAGE } from '../../constants';
import theme from '../../theme';
import AdminInstHeader from '../admin/AdminInstHeader';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import Pagination from "../common/Pagination";
import ModelSearch from "../ModelSearch";
import { handleInstallersDownload } from "../../Helper";

const Installer_QUERY = gql`
query get_installers($filter: String, $skip: Int, $first: Int,$additionalFilter: String) {
  admin(filter: $filter, skip: $skip, first: $first,additionalFilter: $additionalFilter) {
    installerUpdatedStatus ,
    installers {
      installerId
      accCode
      fullAwardDate
      expiryDate
      status
      accountName
      email
      firstName
      lastName
      mobile
      phone
      postalCode
      status
      city
      state
      totalDataPack
      totalFailed
      totalRequest
      updatedOn
      sAACode
      sAAAwardDate
      sAAExpiryDate
      sAAStatus
    }
    installerCount
  }
}
`;



const fontprimary = "1rem";
const fontsecondary = "0.9rem";


//function TransitionRight(props) {
//    return <Slide {...props} direction="left" />;
//}

const styles = {
    root: {
        width: "100%",
        padding: 0,

    },

    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1), background: theme.palette.grey[50],


    },
    card: {
        boxShadow: "none",
        background: "none",
    },
    rootCard: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: fontprimary,
    },
    subCard: {
        fontSize: fontprimary,
    },
    titleroot: {
        fontSize: "1.3rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    subtext: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "14px",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
    },
    userName: {
        color: "rgba(0, 0, 0, 0.87)"
    },
    contentPaper: {
        margin: theme.spacing(2),
        // padding: theme.spacing(2),
        background: theme.palette.common.white,
        border: "1px solid",
        borderColor: theme.palette.common.borderColor, overflowX: 'auto',
    },
    fieldsection: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    progress: {
        position: "relative",
        // minWidth:200,
    },
    linearColorPrimary: {
        height: 26,
        background: theme.palette.common.borderColor,
    },
    linearBarColorPrimary: {
        background: theme.palette.common.green

    },
    linearBarColorSecondary: {
        background: theme.palette.common.red
    },
    linearBarColorWarning: {
        background: theme.palette.common.warning
    },
    span: {
        position: "absolute",
        top: 0,
        color: theme.palette.common.white,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        left: theme.spacing(1),
        lineHeight: "26px",
        fontSize: "1rem",
    },
    chip: {
        backgroundColor: alpha(red[50], 0.45),
        fontFamily: 'NHaasGroteskDSPro-65Md',
        borderRadius: theme.shape.borderRadius,
        color: red[500],
        fontSize: "1rem"
    },
    chipSuccess: {
        backgroundColor: alpha(green[50], 0.45),
        fontFamily: 'NHaasGroteskDSPro-65Md',
        borderRadius: theme.shape.borderRadius,
        color: green[500],
        fontSize: "1rem"
    },
    btn: {
        marginBottom: 24,
        marginLeft: 8,
    },
    action: {
        paddingRight: 20,
    },
    contentModalText: {
        fontSize: "1.2rem",
    },
    removed: {
        color: red[400],
    },
    primarytext: {
        fontSize: fontprimary,
    },
    secondarytext: {
        fontSize: fontsecondary,
    },
    scannedbg: {
        background: alpha(blue[50], 0.5),
    },
    titlescan: {
        fontSize: "1.3rem",
    },
    alert: {
        background: theme.palette.primary.main,
        fontSize: "1rem",

    },
    button: {
        color: theme.palette.grey[500],
    },
    index: {
        paddingTop: 15,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: "1.3rem",
        verticalAlign: "top"
    },
    lbl: {
        display: "inline-block",
        minWidth: "94px",
        marginRight: 4,
        textAlign: "right"
    },
    userId: {
        color: theme.palette.grey[900],
        minWidth: "90px",
        textAlign: "left",
        marginLeft: "5px"
    },

}
const FooterCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
        whiteSpace: " nowrap",
    },
}))(TableCell);

const CustomTableCell = withStyles(theme => ({
    head: {
        background: theme.palette.grey[100],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 18,
        height: "auto",
        whiteSpace: " nowrap",
        lineHeight: "1",
        padding: "16px"
    },
    body: {

        borderBottomColor: theme.palette.grey[200],
        fontSize: 16,
        whiteSpace: " nowrap",
        padding: "8px 16px 8px 16px"
    },
}))(TableCell);



//let installersCount = 0;
//function createData(index, name, AccCode, expiry, status, username, address, phone, mobile, datapack, failed, total,) {
//    installersCount += 1;
//    return { installersCount, index, name, AccCode, expiry, status, username, address, phone, mobile, datapack, failed, total,};
//}


class AdminInstaller extends React.Component {
    constructor(props) {
        super(props);
        this.lastUpdatedRef = React.createRef();
        this.totalRecordsRef = React.createRef();
        this.handleQueryCompleted = this.handleQueryCompleted.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.downloadData = this.downloadData.bind(this);
        this.state = {
            pageNumber: 1, pageSize: LINKS_PER_PAGE, filter: null, open: false, pLinePageNumber: 1, pLinePageSize: LINKS_PER_PAGE,
            scroll: 'paper',
            fullWidth: true,
            additionalFilter: { installer_type: "0", status: "0", updated_today: "0" }, isloading: false,
            //  open: false, 
            maxWidth: 'sm',
            lastUpdatedTime:""
        };
        this.onPageChange = this.onPageChange.bind(this);


    }
    onPageChange(newPage) {
        this.setState({
            pageNumber: newPage
        })
    }
    _getQueryVariables() {

        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const skip = (page - 1) * page_size;
        const filter = this.state.filter;
        const first = page_size;
        //   const random = this.state.random;
        return { first, skip, filter, additionalFilter: JSON.stringify(this.state.additionalFilter) }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleSearch(data) {
        this.setState({
            filter: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }
    handleQueryCompleted = (data) => {
        // Update state with the data from the query
        if (this.lastUpdatedRef.current) {
            this.lastUpdatedRef.current.textContent = data.admin.installerUpdatedStatus;
        }
        if (this.totalRecordsRef.current) {
            this.totalRecordsRef.current.textContent = data.admin.installerCount;
        }
    };
    downloadData() {
        this.setState({ isloading: true });
        const { filter, additionalFilter } = this.state;
        handleInstallersDownload(filter, additionalFilter).then(() => { this.setState({ isloading: false }); }).catch((error) => {
            alert(error);
            this.setState({ isloading: false });
        })
    }
    render() {
        const { classes, } = this.props;

        const { additionalFilter, isloading, lastUpdatedTime } = this.state;
        return (
            <div className={classes.root}>
                <AdminInstHeader />
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    className={classes.fieldsection}
                >
                    <Grid item sm={3} xs={12} style={{ alignSelf: "end"} }>
                        
                        <p><label className={classes.lbl} style={{ textAlign: "left" }}>Last Updated:</label><span ref={this.lastUpdatedRef} className={classes.userId}></span></p>
                        <p><label className={classes.lbl} style={{ textAlign: "left" }}>Total Records:</label><span ref={this.totalRecordsRef} className={classes.userId}></span></p>
                      
                    </Grid>
                    <Grid item sm={2} xs={12}>

                        <FormControlLabel
                            control={<Checkbox checked={additionalFilter.updated_today == "1"} name="updated_today" onChange={(e) => { this.setState({ additionalFilter: { ...additionalFilter, updated_today: e.target.checked ? "1" : "0" } }); }} />}
                            label="Updated Today"
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>

                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup row={true} aria-label="installer_type" name="installer_type" value={additionalFilter.installer_type}
                            onChange={(e) => { this.setState({ additionalFilter: { ...additionalFilter, installer_type: e.target.value } }); }}>
                            <FormControlLabel value="2" control={<Radio />} label="SAA Only" />
                            <FormControlLabel value="1" control={<Radio />} label="CEC Only" />
                            <FormControlLabel value="0" control={<Radio />} label="All" />
                        </RadioGroup>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup row={true} aria-label="status" name="status" value={additionalFilter.status} onChange={(e) => { this.setState({ additionalFilter: { ...additionalFilter, status: e.target.value } }); }}>
                            <FormControlLabel value="2" control={<Radio />} label="Current" />
                            <FormControlLabel value="1" control={<Radio />} label="Expired" />
                            <FormControlLabel value="0" control={<Radio />} label="All" />
                        </RadioGroup>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <ModelSearch filterText={this.state.filter} searchHandler={this.handleSearch} type={2} />
                        <Button style={{ marginBottom: '4px' }} onClick={() => { this.downloadData(); }}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<GetAppIcon />}
                        >

                            {isloading ?
                                <CircularProgress size={19} classes={{ root: classes.circleWhite }} /> :
                                <span>Download</span>
                            }
                        </Button>
                    </Grid>

                </Grid>


                <Paper elevation={0} className={classes.contentPaper}>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>#</CustomTableCell>
                                <CustomTableCell>Installer Info
                                </CustomTableCell>
                                <CustomTableCell>CEC INFO</CustomTableCell>
                                <CustomTableCell>SAA INFO</CustomTableCell>
                          
                                <CustomTableCell>Contact</CustomTableCell>
                                <CustomTableCell>Data Packs</CustomTableCell>
                                <CustomTableCell>Failed / Total Request</CustomTableCell>
                                <CustomTableCell>Access</CustomTableCell>
                            </TableRow>
                        </TableHead>

                        <Query query={Installer_QUERY} variables={this._getQueryVariables()} fetchPolicy="no-cache" onCompleted={this.handleQueryCompleted}>
                            {({ loading, error, data }) => {

                                if (loading) return <FbLoader isTableBodyRequired={true} colSpan={10} loaderStyle={'emptycell'} />
                                if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} />
                                const paging = (<Pagination Prefix="products" TotalRecords={data.admin.installerCount} PageSize={this.state.pageSize} PageNumber={this.state.pageNumber} ShowPreviousNext={true} onPageChange={this.onPageChange} ></Pagination>);
                                const listModels = data.admin.installers.map((row, index) =>
                                (
                                    <TableRow className={classes.row} key={index}>
                                        <CustomTableCell component="th" scope="row" className={classes.index}>
                                            {row.installerId}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Card className={classes.card}>
                                                <CardHeader
                                                    title={row.firstName + ' ' + row.lastName}
                                                    subheader={<Fragment>

                                                        
                                                        <p><label className={classes.lbl}>Last Updated:</label><span className={classes.userId}>{row.updatedOn ?? "NA"}</span></p>
                                                        <p><label className={classes.lbl} >Email:</label><span className={classes.userId}>{row.email ?? "NA"}</span></p>

                                                    </Fragment>

                                                    }
                                                    classes={{ title: classes.titleroot, root: classes.rootCard, subheader: classes.subCard }}
                                                />
                                            </Card>
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <p>
                                                <label className={classes.lbl}>Code:</label>
                                                <span className={classes.userId}>{row.accCode ?? "NA"}</span> </p>
                                            <p>
                                                <label className={classes.lbl}>Status:</label>
                                                <span className={classes.userId}>
                                                    <Chip label={row.status ?? "NA"} className={row.status && row.status == "Current" ? classes.chipSuccess : classes.chip} variant="default" />
                                                </span>

                                            </p>
                                            <p>  <label className={classes.lbl}>Award Date:</label>
                                                <span className={classes.userId}>{row.fullAwardDate ?? "NA"}</span> </p>
                                            <p>   <label className={classes.lbl}>Expiry Date:</label>
                                                <span className={classes.userId}>{row.expiryDate ?? "NA"}</span>
                                            </p>
                                           
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <p>
                                                <label className={classes.lbl}>Code:</label>
                                                <span className={classes.userId}>{row.sAACode ?? "NA"}</span> </p>
                                            <p>
                                                <label className={classes.lbl}>Status:</label>
                                                <span className={classes.userId}>
                                                    <Chip label={row.sAAStatus??"NA"} className={row.sAAStatus && row.sAAStatus == "Current" ? classes.chipSuccess : classes.chip} variant="default" />
                                                </span>
                                            </p>

                                            <p>   <label className={classes.lbl}>Award Date:</label>
                                                <span className={classes.userId}>{row.sAAAwardDate ?? "NA" }</span> </p>
                                            <p>   <label className={classes.lbl}>Expiry Date:</label>
                                                <span className={classes.userId}>{row.sAAExpiryDate ?? "NA"}</span>
                                            </p>
                                            
                                           
                                        </CustomTableCell>
                                       
                                        <CustomTableCell>
                                            <List disablePadding>
                                                <ListItem className={classes.root}>
                                                    <ListItemText primary={<span><label className={classes.lbl}>Address:</label>{(row.city ?? "NA") + ', ' + (row.state ?? "NA")}</span>} secondary={<span><label className={classes.lbl}>Phone:</label>{row.phone ?? "NA"}</span>} classes={{
                                                        primary: classes.primarytext, secondary: classes.secondarytext
                                                    }} />

                                                </ListItem>
                                            </List>
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Card className={classes.card}>
                                                <CardHeader
                                                    title={row.totalDataPack}
                                                    classes={{ title: classes.titlescan, root: classes.scannedbg, }}
                                                />
                                            </Card>
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <div className={classes.progress}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={Math.floor(row.totalFailed / row.totalRequest * 100)}
                                                    classes={{
                                                        determinate: classes.linearColorPrimary,
                                                        bar1Determinate: row.totalRequest === 0 || Math.floor(row.totalFailed / row.totalRequest * 100) < 5 ? classes.linearBarColorPrimary
                                                            : Math.floor(row.totalFailed / row.totalRequest * 100) < 40 ? classes.linearBarColorWarning : classes.linearBarColorSecondary
                                                    }}
                                                />
                                                <span className={classes.span}>{row.totalFailed}/{row.totalRequest}</span>
                                            </div>
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Button color="primary" onClick={this.handleClickOpen}>
                                                {row.IsDisabled ? "Deactive" : "Active"}
                                            </Button>
                                        </CustomTableCell>
                                    </TableRow>

                                ));
                                const result = (data.admin.installers !== 'undefined' && data.admin.installers.length > 0) ? listModels : <FbEmptyResult isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} />;
                                const pagination_content = (data.admin.installers !== 'undefined' && data.admin.installers.length > 0 && data.admin.installerCount > this.state.pageSize) ? <TableFooter ><TableRow ><FooterCell colSpan="10">{paging}</FooterCell ></TableRow ></TableFooter > : null;
                                return (<Fragment><TableBody>{result}</TableBody>{pagination_content}</Fragment>);
                            }}
                        </Query>




                    </Table>
                </Paper>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Access account</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to disable the account ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} className={classes.button} color="default">
                            Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )

    }
}
AdminInstaller.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(AdminInstaller);

