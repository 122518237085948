import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import NavigateNext from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import theme from '../../theme';
import WarrantyCell from './WarrantyCell';
const styles = {
    space: {
        padding: 0,
    },
    headerTitle: {
        fontFamily: 'NHaasGroteskDSPro-65Md', padding: 0,
    },
    avatar: {
        borderRadius: 0,
        width:42,
        height:"auto",
        color: 'tranparent'
    },
 
    row: {
        [theme.breakpoints.down('sm')]: {
            height:"auto",
          //  boxShadow:"0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)",
        },
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],

        },
    
    },
    progress: {
        position: "relative",
    },
    linearColorPrimary: {
        height: 21,
        background: theme.palette.background.lightbg,
    },
    linearBarColorPrimary: {
        right: 0,
        transformOrigin: "right",
        background: theme.palette.common.green,
        left: "auto",
    },
    linearFailedPrimary: {
        height: 21,
        background: theme.palette.background.lightbg,
    },
    linearFailedBarColorPrimary: {
        background: theme.palette.common.red,
      
    },
    span: {
        position: "absolute",
        top: 0,       
        color: theme.palette.grey[900],
        fontFamily: 'NHaasGroteskDSPro-65Md',
        right: theme.spacing(1),
        lineHeight: "22px",
    },
    spanFailed: {
        position: "absolute",
        top: 0,
        left: theme.spacing(1),
        color: theme.palette.common.white,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        lineHeight: "22px",
        [theme.breakpoints.down('sm')]: {
            right: theme.spacing(1),
        },
    },

    divide: {
        width: 1,
        height: 40,
        margin:"auto"
    },
    smButton: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: "0.7rem",
        margin: 0,     
    },
    link: {
        fontSize: 14,
        color: theme.palette.grey[900]
    },
    linkBtn: {
        padding: 0,
        transition: "200ms ease",
        justifyContent: "left", 
        textAlign:"left",
        textTransform: 'capitalize',
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        whiteSpace: "normal",
        maxWidth: "250px",
        [theme.breakpoints.down('xs')]: {
            width: "auto", 
        },
        '&:hover': {
            background: "transparent",
            textDecoration: "none",
        }
     
    },
    uploadColor: {
        color: theme.palette.primary.main,
        fontSize: 12,
        textDecoration: "underline",     
        marginTop: 4,
        '&:hover': {
            background: "transparent",
            textDecoration: "none",
        }
    },
    iconNext: {
        verticalAlign: "middle",
    },

    button: {
        background: theme.palette.common.assign,
        color: theme.palette.primary.contrastText,
        border: "1px solid",
        borderColor: theme.palette.common.assign,
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        boxShadow:"none",
         '&:hover': {
            background: "transparent", 
            border: "1px solid",
            borderColor: theme.palette.common.assign,
            color: theme.palette.common.assign,
        },

    },
    wordBreak :{
        whiteSpace:"normal",
    },
    bottom: {
        margin:"5px 0",
    },
  //  htmlPopper: arrowGenerator('#dadde9'),
    htmlTooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        '& b': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
};

const RefineTableCell = withStyles(theme => ({
  
       body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.common.borderColor,
        fontSize: 14,
           whiteSpace: " nowrap",
         
          
        [theme.breakpoints.down('sm')]: {
            display: "block",
            textAlign: "right",  
            minHeight: " 73px",
            lineHeight: "42px",
            borderBottom:"none",
            '&:before': {
                content: "attr(data-label)",
                float:"left",
                fontWeight:"bold",
                textTransform: " uppercase",
                lineHeight: "42px",
                marginRight: "16px",
            },
        },
    },
}))(TableCell);



const navText = `
Click to see more details
`;
const ResolveText = `
Resolve the failed serials under this model.
`;
const uploadText = `
Upload more serials under this model.
`;
class ProductRow extends React.Component {
    constructor(props) {
        super(props);
        this._id = _.uniqueId('model_row');
        this.handleProductLineAssignment = this.handleProductLineAssignment.bind(this);

    }

    handleClose = () => {
        this.setState({ open: false });
    };
 
    _getQueryVariables() {

        const page = this.state.pageNumber;
        const page_size = this.state.pageSize;
        const skip = (page - 1) * page_size;
        const filter = this.state.filter;
        const first = page_size;
        return { first, skip, filter }
    }
    handleProductLineAssignment() {        
        const {  model } = this.props;
        if (typeof this.props.shouldOpenDialog === 'function') {
            this.props.shouldOpenDialog('body', model);
        }
        return false;
    }
   
    render() {
        const { classes, model, adminView, isDistributor} = this.props;
        const warranty = model.productWarranty;
        
        const productLine = (model.productLine ?
                                                    isDistributor ?  model.productLine.lineName  : (<Button size="small" color="primary" disableRipple className={classes.linkBtn} onClick={() => this.handleProductLineAssignment()}>
                {model.productLine.lineName}
            </Button>) :
            isDistributor ?"No Product Line Assigned":( <Button variant="contained" className={classes.button} onClick={() => this.handleProductLineAssignment()}>Assign</Button>)
                 
                
        );
        const serialStats = model.serialStats != null ? model.serialStats : { "installed": 0, "failed": 0, "pending": 0, "totalRequests": 0 };
        const navigation_row = this.props.hideDetailNavigation ? <RefineTableCell></RefineTableCell> : (<RefineTableCell data-label="View more details" >
            <Tooltip placement="bottom-start"
                classes={{
                    popper: classes.htmlPopper,
                    tooltip: classes.htmlTooltip,
                    tooltipPlacementBottom: classes.bottom
                }}              
                title={navText}
                interactive>
                <IconButton component={Link} to={{ pathname: "/model/" + model.productId, state: { model: model } }} className={classes.margin}>
                    <NavigateNext className={classes.iconNext} />                  
                </IconButton>
            </Tooltip>
        </RefineTableCell>);
        const productLineContent = productLine;
        
        const { totalRequests, failed, installed, pending } = serialStats;
        const failedWidth = serialStats && totalRequests === 0 ? 0 : failed > 0 ? Math.max(Math.floor((failed / totalRequests) * 100),1) : 0;
        
        const availableWidth = serialStats && pending === 0 && serialStats.installed === 0 ? 0 : Math.floor((pending / (installed + pending)) * 100);
        const resolveLink = this.props.hideDetailNavigation ? null : (<Tooltip title={ResolveText} placement="bottom-start" classes={{
            popper: classes.htmlPopper,
            tooltip: classes.htmlTooltip,
            tooltipPlacementBottom: classes.bottom
        }} >

            <Button className={(classNames(classes.uploadColor, classes.linkBtn))}  component={Link} to={{ pathname: "/model/" + model.productId, state: { model: model } }}>
                Resolve
            </Button>

           </Tooltip>);

        const uploadlink = this.props.hideDetailNavigation ? null : (<Tooltip title={uploadText} placement="bottom-start" classes={{
            popper: classes.htmlPopper,
            tooltip: classes.htmlTooltip,
            tooltipPlacementBottom: classes.bottom
        }} >
            <Button color="primary" className={classNames(classes.linkBtn, classes.uploadColor)} component={Link} to={{ pathname: "/flash" }}>
                Upload more serials
            </Button>
        
        </Tooltip>);

        const faildSection = failedWidth > 0 ? (<Fragment>
            <div className={classes.progress}>
            <LinearProgress variant="determinate" value={failedWidth} classes={{
                determinate: classes.linearFailedPrimary,
                bar1Determinate: classes.linearFailedBarColorPrimary,
            }} />
            <span className={classes.spanFailed}>{failed}/{totalRequests}</span>
            </div>
            {adminView ? null : ( resolveLink)}  </Fragment>) : (<Fragment><span className="failedRequest">No failed request</span></Fragment>);
           
        return (
            <TableRow className={classes.row}> 
                <RefineTableCell data-label="Models">
                    <CardHeader
                        avatar={
                            <Avatar aria-label="Recipe" src={require("../../assets/images/solar-panel.jpg")} className={classes.avatar}>
                                R
                             </Avatar>
                        }
                        title={model.model}
                        subheader={model.wattage}
                        classes={{ root: classes.space, title: classes.headerTitle, subheader: classes.headerTitle, }}
                    />
                </RefineTableCell>
                <RefineTableCell data-label="Product line">
                    {productLineContent}
                </RefineTableCell>
                <RefineTableCell data-label="Approval date">
                    {model.approvedDate}
                </RefineTableCell>
                <RefineTableCell data-label="Expiry date">
                    {model.expiryDate}
                </RefineTableCell>
                <RefineTableCell data-label="Component Warranty">
                    <WarrantyCell isDistributor={isDistributor}
                        handleProductLine={this.handleProductLineAssignment}
                        iswarrantyExists={warranty != null && (warranty.componentInYears != null || warranty.componentWarrantyLink != null)}
                        warrantyInYears={warranty != null ? warranty.componentInYears : 0}
                        warrantyLinks={warranty != null ? warranty.componentWarrantyLink : null} />
                </RefineTableCell>
                <RefineTableCell  data-label="Performance Warranty">
                    <WarrantyCell isDistributor={isDistributor} handleProductLine={this.handleProductLineAssignment} iswarrantyExists={warranty != null && (warranty.performanceInYears != null || warranty.performanceWarrantyLink != null)} warrantyInYears={warranty != null ? warranty.performanceInYears : 0} warrantyLinks={warranty != null ? warranty.performanceWarrantyLink : null} />
                </RefineTableCell>
                <RefineTableCell align="right" data-label="Available Serials">
                    <div className={classes.progress}>
                        <LinearProgress variant="determinate" value={availableWidth} classes={{
                        determinate: classes.linearColorPrimary,
                        bar1Determinate: classes.linearBarColorPrimary,
                        }} />
                        <span className={classes.span}>{pending}</span>
                    </div>
                    {adminView ? null : ( uploadlink )}             
                </RefineTableCell>
                <Hidden xsDown>
                    <RefineTableCell align="center"><Divider className={classes.divide} /></RefineTableCell>
                </Hidden>
                <RefineTableCell data-label="Failed Requests">
                    {faildSection}
                </RefineTableCell>
                {adminView ? null : ( navigation_row )}   
            </TableRow>

        );




    }
}

export default withStyles(styles)(ProductRow);  
