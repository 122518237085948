import Typography from '@material-ui/core/Typography';
import React from 'react';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    icon: {
        float: "right",
        color: "#5c9a5c",
    },
 }


class FieldMappingView extends React.Component {

    constructor(props) {
        super(props);
        this.state = { uploadData: null }
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd1 = this.onDragEnd1.bind(this);
     
    }
    onDragStart = (e, data) => {
   
        e.dataTransfer.effectAllowed = "move";
        var jsonString = JSON.stringify(data);
        e.dataTransfer.setData("dropableField", jsonString);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };
    onDragEnd1 = (e,index) => {
        // this.props.onRequiredFieldUpdate(index);
    };

    render() {
        const { classes } = this.props;
        const models = this.props.requiredFields;
        const requiredfields = models.map((field,index) => {
            if (!field.optional) {
                return (field.index != null ? <li key={"mapping_" + index}><a href="javascript:void(0);" className="disabled" >{field.fieldName}    <Check className={classes.icon} /></a></li>
                    : <li key={"mapping_" + index}><a href="javascript:void(0);" draggable onDragEnd={(e, index) => {
                    this.onDragEnd1(e, field.index
                    );
                }} onDragStart={(e) => { this.onDragStart(e, field); }} >{field.fieldName}</a></li>)
            }
            return null;
        });
        const optionalfields = models.map((field,index) => {
            if (field.optional) {
                return (field.index != null ? <li key={"mapping_optional_" + index} ><a href="javascript:void(0);" className="disabled" >{field.fieldName}  <Check className={classes.icon} /></a></li> :
                    <li key={"mapping_optional_" + index}><a href="javascript:void(0);" draggable onDragEnd={(e, index) => {
                    this.onDragEnd1(e, field.index
                    );
                }} onDragStart={(e) => { this.onDragStart(e, field); }} >{field.fieldName}</a></li>)
            }
            return null;
        });
        return (
            <div className="headers">
                <Typography variant="subtitle2" gutterBottom>
                    <span>*</span> Requirements
                 </Typography>      
                <ul>
                    {requiredfields}
                </ul>
                <Typography variant="subtitle2" gutterBottom>
                 Optional Fields
                 </Typography>    
                <ul>
                    {optionalfields}
                </ul>
            </div>
        );
    }
}
export default withStyles(styles)(FieldMappingView);
