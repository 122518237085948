import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { WarrantyHeaderTitle} from '../../StringConstants'
import { Helmet } from "react-helmet";
import Customicon from '../../Customicon';

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(1) * 3,
    },
    button: {
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        //'&:hover': {
        //    background: theme.palette.common.green,

        //}
    },
    customicon: {
            marginRight: theme.spacing(1) * 1,
         }
});

class AddProductLine extends React.Component {
   
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>SPV | Product Line</title>
                </Helmet>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item xs={12} sm={6} >
                        <Typography variant="h1" >                           
                            {WarrantyHeaderTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* to="/addwarranty"  */}
                        <Button variant="contained" size="large" component={Link} to="/addproduct" color="primary" className={classes.button}>
                            <Customicon name="warranty" width={21} fill="#fff" className={classes.customicon} />Add Product Line
                        </Button>
                    </Grid>

                </Grid>

            </div>



            //<div className="twobtns">
            //    <a href="/addwarranty" title="Add Product"><img src={require("../../assets/images/add.svg")} alt="Add Warranty" width="17" />Add Warranty</a>
            //</div>
        );
    }
}

export default withStyles(styles)(AddProductLine);
