import { Card, CardHeader, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles,alpha } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from "prop-types";
import React from 'react';
import theme from '../../theme';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase'; import SearchIcon from '@material-ui/icons/Search';


const styles = {
    root: {
        width: "100%"
    },

    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1), background: theme.palette.grey[50],


    },
    card: {
        boxShadow: "none",
        background: "none",
    },
    rootCard: {
        paddingLeft: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    titleroot: {
        fontSize: "1rem", fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    subtext: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "13px",
        fontWeight: "normal",
    },
    userName: {
        color: "rgba(0, 0, 0, 0.87)"
    },
    contentPaper: {
        margin: theme.spacing(2),
       // padding: theme.spacing(2),
        background: theme.palette.common.white,
        border: "1px solid",
        borderColor: theme.palette.common.borderColor, overflowX: 'auto',
    },
    button: {
        marginRight: theme.spacing(2),
        boxShadow: "none"
    },
    fieldsection: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: "50px",
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: "50px",
        transition: theme.transitions.create('width'),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            width: "auto",
        },
    },
    serachContent: {
        border: "1px solid #e2e2e1",
        background: "#fcfcfb",
        borderRadius: theme.shape.borderRadius,
     
    },

}
const CustomTableCell = withStyles(theme => ({
    head: {
        background: theme.palette.grey[100],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 18,
        height: "auto",
        whiteSpace: " nowrap",
        lineHeight: "1"
    },
    body: {

        borderBottomColor: theme.palette.grey[200],
        fontSize: 16,
        whiteSpace: " nowrap",
    },
}))(TableCell);
const actionsStyles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2),
    },
});
class TablePaginationActions extends React.Component {
    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };

    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;
        return (
            <div className={classes.root}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }
}
const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);
let installersCount = 0;
function createData(index, name, date, state, city) {
    installersCount += 1;
    return { installersCount, index, name, date, state, city };
}
TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
};


class Installations extends React.Component {
    state = {
        rows: [
            createData(5485, 'Solar Juice Pty Ltd', '05-01-2012', 'Australian Capital Territory','Sydney (state capital)'  ),
            createData(15484, 'Jinko Solar', '19-09-2013', 'New South Wales','City of Blacktown',),
            createData(25484, 'Acsis Electrical', '18-07-2019', 'Northern Territory', 'Dubbo'),
            createData(35484, 'Larry Lin', '30-01-2015', 'Queensland', 'City of Lismore'),
            createData(45484, 'ACT Hybrid Solar', '13-07-2011', 'South Australia', 'City of Maitland'),
            createData(55484, '12 Volt Guy', '05-01-2012', 'Tasmania','Lithgow'),
            createData(65484, 'Mylan Pierre', '19-09-2013', '	Victoria','Bathurst'),
            createData(75484, '24 Hour Solar Power', '18-07-2019', 'Western Australia', 'Newcastle' ),
            createData(85484, '3 Phase Solar', '30-01-2015', 'Australian Capital Territory','Queanbeyan'),
            createData(95484, '3 Way Electrical Services', '13-07-2011', 'Australian Capital Territory', 'Wagga Wagga'),
            createData(105484, '4 Front Services', '05-01-2012', 'Australian Capital Territory','Lithgow'  ),
            createData(115484, '5th Star Services', '19-09-2013', 'Australian Capital Territory', 'Broken Hill'  ),
            createData(125484, '888 Solar Tek', '18-07-2019', 'Australian Capital Territory', 'Bathurst'),
            createData(135484, 'A-Phase Electrical', '30-01-2015', 'Australian Capital Territory', 'Bathurst' ),
            createData(145484, 'A1 Solar and Electrical', '13-07-2011', 'Australian Capital Territory', 'Bathurst'),
            createData(155484, 'A1 Highways', '05-01-2012', 'Australian Capital Territory', 'Bathurst'),
            createData(165484, 'Mylan Pierre', '19-09-2013', 'Australian Capital Territory', 'Bathurst' ),
            createData(175484, 'Regan Rosen', '18-07-2019', 'Australian Capital Territory', 'Bathurst' ),
            createData(185484, 'Larry Lin', '30-01-2015', 'Australian Capital Territory', 'Bathurst' ),
            createData(195484, 'Britney Cooper', '13-07-2011', 'Australian Capital Territory', 'Bathurst' ),
        ],
        page: 0,
        rowsPerPage:9,
    };
    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    render() {
        const { classes } = this.props;
        const { rows, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
        return (
            <div className={classes.root}>
                <Paper elevation={0} className={classes.paper} square>
                    <Typography variant="h1">
                        Installations
                 </Typography>
                </Paper>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.fieldsection}

                >
                    <Grid item sm={4} xs={12} className={classes.title}>
                        <Paper elevation={0} className={classes.serachContent}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                />
                            </div>
                        </Paper>
                    </Grid>
     
                </Grid>

                <Paper elevation={0} className={classes.contentPaper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>#</CustomTableCell>
                                <CustomTableCell>Manufacturer
                                <br />
                                    <span className={classes.subtext}>Installation Date</span>
                                </CustomTableCell>

                                <CustomTableCell>City
                                      <br />
                                    <span className={classes.subtext}>State</span>
                                    </CustomTableCell>                                
                                <CustomTableCell>Action</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                <TableRow className={classes.row} key={row.installersCount}>
                                    <CustomTableCell component="th" scope="row">
                                        {row.index}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Card className={classes.card}>
                                            <CardHeader
                                                title={row.name}
                                                subheader={<span><p>{row.date}</p>
                                             </span>
                                                }
                                                classes={{ title: classes.titleroot, root: classes.rootCard }}
                                            />
                                        </Card>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Card className={classes.card}>
                                            <CardHeader
                                                title={row.city}
                                                subheader={<span>
                                                    <p>{row.state}</p>
                                                   
                                                </span>
                                                }
                                                classes={{ title: classes.titleroot, root: classes.rootCard }}
                                            />
                                        </Card>
                                    </CustomTableCell>                 
                                    <CustomTableCell>
                                        <Button color="primary" size="small" variant="contained" className={classes.button}>
                                            View
                                         </Button>
                                    </CustomTableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 57 * emptyRows }}>
                                    <TableCell colSpan={11} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={11}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: false,
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>


                </Paper>

            </div>
        )

    }
}
Installations.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Installations);

