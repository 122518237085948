import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import About from '../legal/About';
import LegalFooter from '../legal/LegalFooter';
import LegalHeader from '../legal/LegalHeader';
import Privacy from '../legal/Privacy';
import Termsofuse from '../legal/Termsofuse';
import classNames from 'classnames';
const styles = theme => ({
    root: {
        flexGrow: 1,
        position: "relative",
    },
    paper: {
        marginTop:36,
        background: theme.palette.common.white,
        padding: "10px 69px 0 290px",
        [theme.breakpoints.only('sm')]: {
            padding: "150px 16px 0 14px",
        },
        [theme.breakpoints.only('xs')]: {
            padding: "150px 16px 0 14px",
        },
    },

    border: {
        borderRight: '1px solid',
        borderColor: "#dddddd",
    },
    legal: {
        fontSize: "42px",
        position: "fixed",
        //margin: '0 0 36px 0',
        [theme.breakpoints.only('sm')]: {
            margin: '16px 0 24px 0',
        },
        [theme.breakpoints.only('xs')]: {
            margin: '16px 0 24px 0',
        },
    },
    listRoot: {
        borderLeft: "5px solid transparent",
        fontSize: "18px",
        backgroundColor: "transparent",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        [theme.breakpoints.only('sm')]: {
            borderLeft: "0px solid transparent",
        },
        '&:hover': {
            backgroundColor: "transparent",
            opacity: 1,
        },
        '&:focus': {
            backgroundColor: "transparent",
        },
        "&$selected": {
            backgroundColor: "transparent",
            borderLeft: "5px solid #ddd",
            color: "#0d4add",
            '&:hover': {
                backgroundColor: "transparent",
                opacity: 1,
            },
            '&:focus': {
                backgroundColor: "transparent",
            },
            [theme.breakpoints.only('sm')]: {
                borderLeft: "0px solid transparent",
                borderBottom: "5px solid #ddd",
                borderRight: "0px solid transparent",
            },
            [theme.breakpoints.only('xs')]: {
                borderLeft: "0px solid transparent",
                borderBottom: "5px solid #ddd",
                borderRight: "0px solid transparent",
            },
        },

    },
    selected: {},
    middle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    fixed: {
        position: "fixed",
        //borderRight:" 1px solid #ddd",
        height: "100%",
        marginTop: "80px",
        paddingRight: "85px",

        [theme.breakpoints.down('sm')]: {        
            borderRight: "none",
            height: "auto",
            paddingLeft: "16px",
            background: "#fff",
            zIndex: 99,
            width: "100%",
        }, 
    },
    rootContent: {
        width:" 70%",
        margin: "56px auto 0 auto",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },      
    },
    link: {
        display: "block",
        [theme.breakpoints.down('sm')]: {
           display:"inline-block",
        },   
    },
    strap: {
        background: "#fff",
        [theme.breakpoints.down('sm')]: {
            display:"none",
        },
        [theme.breakpoints.down('xs')]: {
            display: "none",
        }, 
    },
    toolbar: theme.mixins.toolbar,
});


class legal extends React.Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();   // Create a ref object 
        this.myRefabout = React.createRef();
        this.state = {
            selectedIndex: 0,
        };
    }
    componentDidMount = () => {
        const scrollTo = this.myRef.current.offsetTop - 200;
        const scrollToAbout = this.myRefabout.current.offsetTop;
        //console.log('scrollTo', scrollTo)
        //window.scrollTo(0, scrollTo); 
        if (window.location.hash === "#privacy") {
            this.setState({ selectedIndex: 1 });
            window.scrollTo(0, scrollTo);
        }
        else if (window.location.hash === "#about") {
            this.setState({ selectedIndex: 2 });
            window.scrollTo(0, scrollToAbout)
        }
        else {
        }

    }
    handleListItemClick = (event, index) => {
        this.setState({ selectedIndex: index });
    };
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <LegalHeader />             
                <div className={classNames(classes.toolbar, classes.strap)} /> 
                    <div className={classes.rootContent}>
                        <Typography variant="h1" className={classes.legal}>
                            Legal
                        </Typography>
                        <List component="nav" classes={{ root: classes.fixed }} disablePadding>

                            <AnchorLink href='#termUse' offset='350' className={classes.link}>
                                <ListItem
                                    disableRipple
                                    button                                    
                                    selected={this.state.selectedIndex === 0}
                                    onClick={event => this.handleListItemClick(event, 0)}
                                    classes={{ root: classes.listRoot, selected: classes.selected }}

                                >
                                <ListItemText primary="Terms of use" disableTypography />
                                </ListItem>
                            </AnchorLink>
                            <AnchorLink href='#privacy' offset='125' className={classes.link}>
                                <ListItem
                                    disableRipple
                                    button                                    
                                    selected={this.state.selectedIndex === 1}
                                    onClick={event => this.handleListItemClick(event, 1)}
                                    classes={{ root: classes.listRoot, selected: classes.selected }}
                                >
                                <ListItemText primary="Privacy" disableTypography />
                                </ListItem>
                            </AnchorLink>
                            <AnchorLink href='#about' offset="125" className={classes.link}>
                                <ListItem
                                    disableRipple
                                    button
                                    selected={this.state.selectedIndex === 2}
                                    onClick={event => this.handleListItemClick(event, 2)}
                                    classes={{ root: classes.listRoot, selected: classes.selected }}
                                >
                                <ListItemText primary="About" disableTypography />
                                </ListItem>
                            </AnchorLink>
                        </List>
                        <Paper elevation={0} className={classes.paper}>
                            <section id='termUse'>
                                <Termsofuse />   
                            </section>
                            <div className={classes.middle}>&nbsp;</div>
                        <section ref={this.myRef}  id='privacy'>
                                <Privacy />
                            </section>
                            <div className={classes.middle}>&nbsp;</div>
                        <section ref={this.myRefabout}  id='about'>
                                <About />
                            </section>
                        </Paper>
                    </div>
                    <div className={classes.toolbar} />
              
                    <div className={classes.toolbar} />
                <LegalFooter />
            </div>


        );
    }
}

export default withStyles(styles)(legal);