import React, { useState, useCallback, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Chip, Table, TableBody, TableHead, TableCell, TableFooter, TableRow, Button, Modal } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import UserProfile from '../auth/UserProfile';
import FbEmptyResult from '../common/FbEmptyResult';
import Pagination from '../common/Pagination';
import SerialHeader from './rows/header/HeaderFailed';
import FailedSerialRow from './rows/RowFailed';
import FbButton from '../common/FbButton';
import { Link } from 'react-router-dom';
import AuthService from '../AuthService';
import { handleFailDownload } from '../../Helper';
import red from '@material-ui/core/colors/red';
import theme from '../../theme';
const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    paperTable: {
        border: '1px solid',
        borderColor: theme.palette.common.borderColor,
        minHeight: 'calc(100vh - 300px)',
        width: '100%',
        overflowX: 'auto',
        background: theme.palette.common.white,
    },
    downloadcsv: {
        color: 'white',
        background: red[500],
        '&:hover': {
            background: red[600],
            color: 'white',
        },
    },
    button: {
        marginRight: '5px',
        [theme.breakpoints.down('xs')]: {
            float: 'left',
            marginTop: theme.spacing(2),
        },
    },
    paper: {
        position: 'absolute',
        width: 300,
        minHeight: 100,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '15px 15px',
    },
    chip: {
        margin: '20px 10px',
        textAlign: 'center',
        padding: '10px',
    },
};

const FailedTab = ({ classes, adminView, globalView, manufacturerId, showSerialImagesCallback, totalSerials,serials }) => {

    const [loadingCsv, setLoadingCsv] = useState(false);
    const [open, setOpen] = useState(false);
    const Auth = new AuthService();

    const rand = () => -5;

    const getModalStyle = () => {
        const top = 50 + rand();
        const left = 50 + rand();
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onFailDownload = useCallback((format) => {
        handleFailDownload(
            () => handleClose(),
            () => handleClose(),
            UserProfile.getManufacturerId(),
            null,
            format
        );
    }, []);

   
    const role = UserProfile.getRole();
    const compositeClass = `${classes.button} ${classes.downloadcsv}`;

    return (
        <Fragment>
            <div className={classes.root}>
                <Paper elevation={0} square classes={{ root: classes.paperTable }}>
                   
                                <Fragment>
                                    {!adminView && totalSerials > 0 && (
                                        <Alert severity="error" style={{ padding: '12px' }}>
                                            <AlertTitle>
                                                <h3 style={{ marginBottom: '10px' }}>Below listed failed serials require your immediate attention. Please upload them now to quickly resolve issues and assist your installers and customers.</h3>
                                                <Button variant="contained" size="large" component={Link} to="/flash" color="primary" className={classes.button}>
                                                    Upload Now
                                                </Button>
                                                <FbButton loading={loadingCsv} type="button" title={"Download failed serials"} className={compositeClass} onClick={handleOpen} />
                                            </AlertTitle>
                                        </Alert>
                                    )}

                                    <Table className={classes.table}>
                                        <TableHead>
                                            <SerialHeader adminView={adminView} />
                                        </TableHead>
                                        <TableBody>
                                            {serials && serials.length > 0 ? (
                                                serials.map((serial, index) => (
                                                    <FailedSerialRow
                                                        showSerialImagesCallback={showSerialImagesCallback}
                                                        key={index}
                                                        serial={serial}
                                                        index={index}
                                                        adminView={adminView}
                                                    />
                                                ))
                                            ) : (
                                                <FbEmptyResult isTableBodyRequired={false} colSpan={8} errorStyle={'emptycell'} />
                                            )}
                                        </TableBody>
                                        
                                    </Table>
                                </Fragment>
                         
                </Paper>
            </div>
            <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
                <div style={getModalStyle()} className={classes.paper}>
                    <Typography variant="h4">Download failed serials</Typography>
                    <Typography variant="body1">choose download format</Typography>
                    <Chip className={classes.chip} label="CSV" onClick={() => onFailDownload('csv')} />
                    <Chip className={classes.chip} color="primary" label="XLSX" onClick={() => onFailDownload('xlsx')} />
                </div>
            </Modal>
        </Fragment>
    );
};

export default withStyles(styles)(FailedTab);
