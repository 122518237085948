import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
//write component here to import weekly supplier list, Interface will have option to select file and upload it, show the result if it was successful or not
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';  
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';



const useStyles = makeStyles((theme) => ({  
    root: { 
        flexGrow: 1,
    },

                    button: {
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        '&:hover': {
            background: theme.palette.common.green
        }
    },
    customicon: {
        marginRight: theme.spacing(2),
    },
    btnCancel: {
        marginRight:20,
    },
    paddingNone: {
        paddingBottom:0,
    },
    dropzone: {
        height: 100,
    },
    dropzoneText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    dropzoneArea: {
        height: 100,
    },
    dropzoneText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));
export const UPLOAD_FILE = gql`
mutation weeklySupplierList($file: Upload!) {
weeklySupplierList(file: $file) {
status
}
}
`;
const ImportWeeklySupplierList = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState([]);
    const [uploadFile] = useMutation(UPLOAD_FILE);
    const onDrop = (files) => {
        setFiles(files);
    }
    const handleUpload = async () => {
        try {

            uploadFile({ variables: { "file": files[0] } })
                .then((response) => {
                    // Handle success
                    console.log('File uploaded successfully!', response);
                })
                .catch((error) => {
                    // Handle error
                    console.error('Error uploading file', error);
                });

            //const response = await uploadWeeklySupplierList(files[0]);
            //if (response.status === 200) {
            //    enqueueSnackbar('Upload Successful', { variant: 'success' });
            //}
        } catch (error) {
            enqueueSnackbar('Upload Failed', { variant: 'error' });
        }
    }
    return (
        <div className={classes.root}>
            <Grid container direction="row"
                justify="flex-end"
                alignItems="flex-end">
                <Grid item xs={12} sm={6}>
                    <DropzoneArea
                        dropzoneClass={classes.dropzoneArea}
                        dropzoneText="Drag and drop a file here or click"
                        filesLimit={1}
                        showPreviews={false}
                        dropzoneParagraphClass={classes.dropzoneText}
                        onDrop={onDrop}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleUpload}
                    >
                        Upload
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
export default ImportWeeklySupplierList;







