import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import UserProfile from './UserProfile';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import gql from 'graphql-tag';
import { white } from 'material-ui/styles/colors';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { UserContext } from '../../libs/UserContext';
import theme from '../../theme';
import FbError from '../common/FbError';


const Manufacturer_QUERY = gql`
query getmanufacturers {
  supplier {
  
       manufacturers{
         isDistributor
      address
      displayName
      logo
      manufacturerId
      profilePhoto
      userName
      uploadFrequency
      email

      userId
      isActive
    }

  }
}
`;

const styles = {
   
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectText: {
        color: white
    },
};

class DistributorManufacturerList extends React.Component {
    state = {
        anchorEl: null
    };
    handleChange = (e,setMan) => {
        
        UserProfile.setManufacturerId(e.target.value);
        setMan(e.target.value);

    }
    render() {
        const { classes } = this.props;
        const isDistributor = UserProfile.isDistributor();
        let mid=UserProfile.getManufacturerId();
         

        return (

            isDistributor && ( <FormControl justify="center"
                 align="center" className={classes.formControl}>

                <UserContext.Consumer>
                    {({ setManufacturerId }) => (

                        <Query query={Manufacturer_QUERY} fetchPolicy="no-cache">


                            {({ loading, error, data }) => {


                                if (loading) return null; // Otherwise remains invisible & increases header height
                                if (error) return <FbError isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} />
                                if (_.isNil(mid) && !_.isNil(data.supplier.manufacturers)) {
                                    
                                    var manId = data.supplier.manufacturers[0].manufacturerId;
                                    UserProfile.setManufacturerId(manId);
                                    mid = manId; //set first manufacturer as default
                                    setManufacturerId(mid);

                                }
                                const listModels = data.supplier.manufacturers.map((row) =>
                                    (<MenuItem key={"suppler" + row.manufacturerId} value={row.manufacturerId}>{row.displayName}</MenuItem>));
                                return (<Fragment> <Select className={classes.selectText}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={mid}
                                    onChange={(e) => this.handleChange(e, setManufacturerId)}
                                >{listModels}</Select></Fragment>);
                            }



                            }
                        </Query>

                    )}
                    </UserContext.Consumer>
            </FormControl>)

            )
    }
}

DistributorManufacturerList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DistributorManufacturerList);