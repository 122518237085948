import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CirclePicker } from 'react-color';
import me from '../assets/images/solar-logo.svg';
import { SettingHeaderTitle } from '../StringConstants';
import theme from '../theme';

const styles = {
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),      
        //color: theme.palette.text.secondary,
    },
    control: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    menuposition: {
        marginTop: theme.spacing(2),
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    picker: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),   
    },
    update: {
        marginTop: theme.spacing(2),
    },
    border: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        border: "1px dashed",
        color: theme.palette.common.black,
        borderRadius:4,
    },
    input: {
        display: 'none',
    },
    button: {
        boxShadow:"none"
    },
    avatar: {
        borderRadius:0,
        width: "auto",
        verticalAlign: "middle",
        backgroundColor: theme.palette.background.default,
    }
};

class Settings extends React.Component {
    state = {
        value: 'left',
        background: '#fff',
    };
    handleChange = event => {
        this.setState({ value: event.target.value });
    };
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" >
                            {SettingHeaderTitle}
                        </Typography>
                    </Grid>            
                </Grid>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.control}
                    spacing={8}
                   
                >
                    <Grid item lg={4}>
                        <Paper elevation={0} className={classes.paper}>
                            <Grid container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"                            
                                spacing={8}>
                                <Grid item lg={12}>
                                    <Typography variant="h3" >
                                        Company Logo
                                      </Typography>
                                </Grid>
                                <Grid item lg={12}>
                                    <Paper className={classes.border} elevation={1}>
                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="flex-start">
                                            {/* logo update button input type file*/}
                                            <Grid item>
                                                <input
                                                    accept="image/*"
                                                    className={classes.input}
                                                    id="contained-button-file"
                                                    type="file"
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button variant="contained" component="span" color="default" className={classes.button}>
                                                        Upload
                                                 </Button>
                                                </label>
                                            </Grid>
                                            <Grid item>
                                                {/* logo logo preview section */}
                                                <Avatar alt="Remy Sharp" src={me} className={classes.avatar} />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                          
                                    
                                    
                                </Grid>
                                <Grid item lg={12}
                                    className={classes.marginTop}
                                >
                                    <Typography variant="h3" >
                                        Company Details
                                      </Typography>
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        label="Company name"
                                        placeholder="Company name"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                      
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        label="ABN"
                                        placeholder="ABN"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        className={classNames(classes.dense)}
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <TextField
                                        label="Address"
                                        placeholder="Address"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                      
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        label="Email"
                                        placeholder="Email"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        className={classNames(classes.dense)}
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        label="Phone"
                                        placeholder="Phone"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                     
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <Typography variant="h3" className={classes.marginTop}>
                                        Theme Options
                                      </Typography>
                                </Grid>
                                <Grid item lg={6}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel component="legend" className={classes.menuposition}>Menu Position</FormLabel>
                                        <RadioGroup
                                            aria-label="navigationr"
                                            name="navigation"
                                            className={classes.group}
                                            value={this.state.value}
                                            onChange={this.handleChange}
                                        >
                                            <FormControlLabel value="left" control={<Radio color="primary" />} label="Left navigation" />
                                            <FormControlLabel value="top" control={<Radio color="secondary" />} label="Top navigation" />                                         
                                            <FormControlLabel
                                                value="disabled"
                                                disabled
                                                control={<Radio />}
                                                label="(Disabled option)"
                                            />
                                        </RadioGroup>
                                    </FormControl>             
                                </Grid>
                                <Grid item lg={6}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel component="legend" className={classes.menuposition}>Theme Colour</FormLabel>
                                        <Paper className={classes.picker} elevation={1}>

                                            {/* color picker section section */}
                                            <CirclePicker
                                                color={this.state.background}
                                                onChangeComplete={this.handleChangeComplete}
                                            />
                                        </Paper>
                                    </FormControl>

                                </Grid>
                          
                            </Grid>
                        
                        </Paper>
                        <Grid container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end"
                            className={classes.update}
                        >
                            
                            <Grid item>
                                <Button variant="contained" size="large" color="primary" className={classes.button}>
                                    Update
                                     </Button>
                            </Grid>

                        </Grid>
                    </Grid> 
            
                </Grid>

            </div>
            )

    }
}
Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Settings);