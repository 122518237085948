import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    Card,
    CardContent,
    Typography,
    Avatar,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Chip,
    TextField,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Schedule as ScheduleIcon, CheckCircle as CheckCircleIcon, Person as PersonIcon, Comment as CommentIcon, FiberManualRecord as FilledCircleIcon } from '@material-ui/icons';
import FbEmptyResult from '../common/FbEmptyResult';
import FbError from '../common/FbError';
import FbLoader from '../common/FbLoader';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import QueryHelper from '../../libs/QueryHelper';
import _ from 'lodash';



const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flex:'1'
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    cardContent: {
        paddingBottom: theme.spacing(1),
    },
    listItemText: {
        marginBottom: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    approvedIcon: {
        color: theme.palette.success.main,
        marginRight: theme.spacing(1),
    },
    mergedIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    commentIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    closedIcon: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
    },
    assignees: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    filterContainer: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    filterLabel: {
        marginRight: theme.spacing(2),
    },
    searchInput: {
        marginLeft: theme.spacing(2),
    },
    metaData: {
        display: 'flex',
        flexDirection:'row',
        alignItems: 'center',
        ' && > span': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            margin:'0px 10px'
        },
        ' && strong': {
            fontSize: 16,
            padding:'0px 5px'
        }
    },
    metaIcons: {
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flex:'1',
        ' && > span': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            margin: '0px 10px'
        }
    },
    circle: {
        fontSize:'0.9em'
    }
}));

const PullRequestItems = (props) => {
    const classes = useStyles();
    const { loading, error, data } = useQuery(QueryHelper().PullRequests(), {
        variables: { filter: props.filter, search:props.search }
    });

    if (loading) return <FbLoader loaderStyle={'center'} />;
    if (error) return <FbError errorStyle={'default'} message='No pull request available' />;
    return data.admin.mergeRequests && data.admin.mergeRequests.length > 0 ? (
        data.admin.mergeRequests.map(pullRequest => (
            <Card className={classes.card} key={pullRequest.id}>
                <CardContent className={classes.cardContent}>
                    <Typography variant="h6" component="h2">
                        {pullRequest.title}
                    </Typography>
                    <Typography variant="div" color="textSecondary" className={classes.metaData}>
                        <span>
                            <strong>#{pullRequest.number}</strong>  on {pullRequest.createdAt} by <strong>{pullRequest.createdBy}</strong>
                            <br /><FilledCircleIcon className={classes.circle} />{pullRequest.status}
                        </span>
                        <span className={classes.metaIcons}>
                            <span >
                            {pullRequest.assignees.map(assignee => (
                                <Chip key={assignee} label={assignee} />
                            ))}
                        </span>
                        {pullRequest.status === 'Approved' && (
                            <span>
                                <CheckCircleIcon className={classes.approvedIcon} />
                                <Typography variant="span" color="textSecondary">
                                    Approved
                                </Typography>
                            </span>
                        )}
                        {pullRequest.isMerged && (
                            <span>
                                <CheckCircleIcon className={classes.mergedIcon} />
                                <Typography variant="span" color="textSecondary">
                                    Merged
                                </Typography>
                            </span>
                        )}
                        {pullRequest.isClosed && (
                          <span>
                                <CheckCircleIcon className={classes.closedIcon} />
                                <Typography variant="span" color="textSecondary">
                                    Closed
                                </Typography>
                            </span>
                        )}
                        {pullRequest.comments > 0 && (
                            <span>
                                <CommentIcon className={classes.commentIcon} />
                                <Typography variant="span" color="textSecondary">
                                    Comments{pullRequest.comments}
                                </Typography>
                            </span>
                        )}
                        </span>
                    </Typography>
                    
                </CardContent>
            </Card>
        ))) : (
        <FbEmptyResult />
    );
}

const PullRequests = () => {
    const classes = useStyles();
    const [filter, setFilter] = useState('open');
    const [search, setSearch] = useState('');
    const handleFilterChange = event => {
        setFilter(event.target.value);
    };

    const handleSearchChange = event => {
        setSearch(event.target.value);
    };
  
    
    
    return (
        <div className={classes.root}>
            <Typography variant="h4" gutterBottom>
                Github: CER Pull Requests Listing
            </Typography>
            <div className={classes.filterContainer}>
                <Typography variant="subtitle1" className={classes.filterLabel}>
                    Filter:
                </Typography>
                <FormControl>
                    <Select value={filter} onChange={handleFilterChange}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Search"
                    variant="outlined"
                    className={classes.searchInput}
                    value={search}
                    onChange={handleSearchChange}
                />
            </div>
            {<PullRequestItems filter={filter} search={search} />}
        </div>
    );
};


PullRequests.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default PullRequests;







