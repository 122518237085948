import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import { RandomText, Role_DISTRIBUTOR, Role_DISTRIBUTOR_STAFF } from '../constants';
import Customicon from '../Customicon';
import { DashboardCaption, FlashDataCaption, HistoryCaption, StaffCaption, WarrantyCaption } from '../StringConstants';
import UserProfile from './auth/UserProfile';
const drawerWidth = 90;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    rootList: {
        width: '100%',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
    },
    customicon: {
       // height: "auto",
        margin: 'auto',
        padding: 0,
        justifyContent:'center'
    },
    direction: {
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar,
});

class ClippedDrawer extends React.Component {
  
    render() {
        const { classes, onNavigationChange, selectedIndex } = this.props;
        const role = UserProfile.getRole();
        const isDistributorStaffWithoutManufacturer = UserProfile.isDistributorStaffWithoutManufacturer();

        const navigationList = (<div className={classes.rootList}>
            <List component="nav" disablePadding>
                <ListItem
                    button
                    disableGutters
                    component={Link} to="/Dashboard"
                    selected={selectedIndex === 0}
                    onClick={event => onNavigationChange(0)}                   
                    classes={{
                        root: classes.direction
                    }}
                >
                    <ListItemIcon classes={{ root: classes.customicon }}>
                        <Customicon name="dashboard" width={24} />
                    </ListItemIcon>
                    <ListItemText primary={DashboardCaption} classes={{
                        root: classes.customicon
                    }}/>
                </ListItem> <Divider light />
                <ListItem
                    button
                    disableGutters
                    component={Link} to={"/flash?stamp=" + RandomText(6)}
                    selected={selectedIndex === 1}
                    onClick={event => onNavigationChange(1)}                 
                    classes={{
                        root: classes.direction
                    }}
                >
                    <ListItemIcon classes={{ root: classes.customicon }}>
                        <Customicon name="flash" width={24} />
                    </ListItemIcon>
                    <ListItemText primary={FlashDataCaption} classes={{
                        root: classes.customicon
                    }} />
                </ListItem> <Divider light />
                <ListItem
                    button
                    disableGutters
                    component={Link} to={"/history"}
                    selected={selectedIndex === 2}
                    onClick={event => onNavigationChange(2)}
                    classes={{
                        root: classes.direction
                    }}
                >
                    <ListItemIcon classes={{ root: classes.customicon }}>
                        <Customicon name="history" width={24} />
                    </ListItemIcon>
                    <ListItemText primary={HistoryCaption} classes={{
                        root: classes.customicon
                    }} />
                </ListItem> <Divider light />
                
                {(role !== Role_DISTRIBUTOR && role !== Role_DISTRIBUTOR_STAFF) && <ListItem
                    button
                    disableGutters
                    component={Link} to="/productline"
                    selected={selectedIndex === 3}
                    onClick={_event => onNavigationChange(3)}
                    classes={{
                        root: classes.direction
                    }}
                >
                    <ListItemIcon classes={{ root: classes.customicon }}>
                        <Customicon name="warranty" width={24} />
                    </ListItemIcon>
                    <ListItemText primary={WarrantyCaption} classes={{
                        root: classes.customicon
                    }} />
                </ListItem>} <Divider light />
                {role !== Role_DISTRIBUTOR_STAFF && <ListItem
                    component={Link} to="/Staff"
                    button
                    disableGutters
                    selected={selectedIndex === 4}
                    onClick={_event => onNavigationChange(4)}
                    classes={{
                        root: classes.direction
                    }}
                >
                    <ListItemIcon classes={{ root: classes.customicon }}>
                        <Customicon name="staff" width={24} />
                    </ListItemIcon>
                    <ListItemText primary={StaffCaption} classes={{
                        root: classes.customicon
                    }} />
                </ListItem>}  <Divider light />
        
            </List>      
        </div>
            
        );
        return (
            isDistributorStaffWithoutManufacturer ? null :
                <div className={classes.root}>
                    <CssBaseline />
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <Hidden xsDown>    <div className={classes.toolbar} /> </Hidden>
                        {navigationList}
                    </Drawer>
                </div>
                
        );
    }

}

ClippedDrawer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClippedDrawer);
