import React, { useEffect, useState } from 'react';
import config from '../../config';
import {
    AppBar, Tabs, Tab, TextField, Container, Box, Typography, Card, CardContent, Grid,
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: '200px',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        width: '100%',
        maxWidth: '100%',
    },
    supplierSummary: {
        marginTop: theme.spacing(1),
    },
    importantLabel: {
        fontWeight: 'bold',
        display: 'block',
        marginBottom: theme.spacing(1),
    },
}));

const ReferenceDataPresenter = () => {
    const classes = useStyles();
    const [data, setData] = useState({ manufacturers: [], endpoints: [], apps: [] });
    const [filteredData, setFilteredData] = useState({ manufacturers: [], endpoints: [], apps: [] });
    const [search, setSearch] = useState({ manufacturers: '', endpoints: '', apps: '' });
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.VSP_URL}/api/endpoint`);
                const result = await response.json();
                setData(result);
                setFilteredData(result);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    const handleSearch = (e, key) => {
        const value = e.target.value.toLowerCase();
        setSearch({ ...search, [key]: value });

        const filtered = data[key].filter(item => {
            if (key === 'manufacturers') {
                return item.name.toLowerCase().includes(value) || item.suppliers.some(supplier => supplier.name.toLowerCase().includes(value));
            } else {
                return item.serviceadministrator.toLowerCase().includes(value);
            }
        });
        setFilteredData({ ...filteredData, [key]: filtered });
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = (manufacturer) => {
        setSelectedManufacturer(manufacturer);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedManufacturer(null);
    };

    return (
        <Container className={classes.container}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Manufacturers" />
                    <Tab label="VSPs" />
                    <Tab label="App Providers" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <TextField
                    label="Search Manufacturers"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={search.manufacturers}
                    onChange={(e) => handleSearch(e, 'manufacturers')}
                />
                <Grid container spacing={3}>
                    {filteredData.manufacturers.map((manufacturer, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6">{manufacturer.name}</Typography>
                                    <Box className={classes.supplierSummary}>
                                        <Typography variant="body2">
                                            Total Suppliers: {manufacturer.suppliers.length}
                                        </Typography>
                                        {manufacturer.suppliers.slice(0, 3).map((supplier, idx) => (
                                            <Typography key={idx} variant="body2">{supplier.name}</Typography>
                                        ))}
                                    </Box>
                                    <Button variant="outlined" color="primary" onClick={() => handleClickOpen(manufacturer)}>
                                        View Details
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TextField
                    label="Search VSPs"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={search.endpoints}
                    onChange={(e) => handleSearch(e, 'endpoints')}
                />
                <Grid container spacing={3}>
                    {filteredData.endpoints.map((endpoint, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6">Service Administrator: {endpoint.serviceadministrator}</Typography>
                                    <Typography className={classes.importantLabel}>
                                        Product Verification
                                    </Typography>
                                    <Typography className={classes.importantLabel}>
                                        Install Verification
                                    </Typography>
                                    <Typography className={classes.importantLabel}>
                                        Serial to Model Lookup
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TextField
                    label="Search App Providers"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={search.apps}
                    onChange={(e) => handleSearch(e, 'apps')}
                />
                <Grid container spacing={3}>
                    {filteredData.apps.map((app, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6">App Name: {app.appname}</Typography>
                                    <Typography variant="body2">Service Administrator: {app.serviceadministrator}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
            {selectedManufacturer && (
                <Dialog open={open} onClose={handleClose} aria-labelledby="supplier-details-dialog">
                    <DialogTitle id="supplier-details-dialog">Supplier Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="h6">{selectedManufacturer.name}</Typography>
                            {selectedManufacturer.suppliers.map((supplier, idx) => (
                                <Box key={idx} mt={2}>
                                    <Typography variant="body1">Supplier: {supplier.name}</Typography>
                                    <Typography variant="body2">ID: {supplier.id}</Typography>
                                    <Typography variant="body2">Start Date: {supplier.startdate}</Typography>
                                    <Typography variant="body2">End Date: {supplier.enddate}</Typography>
                                    <Typography variant="body2">Endpoint ID: {supplier.endpointid}</Typography>
                                </Box>
                            ))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

export default ReferenceDataPresenter;
