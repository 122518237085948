import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Person from '@material-ui/icons/Person';
import { white } from 'material-ui/styles/colors';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withRouter,Link } from 'react-router-dom';
import Background from '../assets/images/user.png';
import theme from '../theme';
import DistributorManufacturerList from './auth/DistributorManufacturerList';
import UserProfile from './auth/UserProfile';
const styles = {
    actions: {
        paddingTop: theme.spacing(1) * 0,
    },
    //typography: {
    //    margin: theme.spacing(1) * 1,

    //},
    root: {
        justifyContent: 'flex-end',
        flex:3
    },
    cursor: {
        cursor: "pointer",
    },
    nameTypo: {
        marginLeft: theme.spacing(1) * 1,
        color: theme.palette.common.white,   
        fontSize: 14,
    },
    icon: {
        fontSize:36,
        color: theme.palette.common.white,
        verticalAlign: "middle",      

    },
    avatar: {
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.background.default,
        backgroundSize: "cover",
        color:"transparent",
    },
    bigAvatar: {
        width: 154,
        height: 160,
        borderRadius: 0,
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.background.default,
        backgroundSize: "cover",
        color: "transparent",

    },
    paragraphFonts: {
        fontSize: 14,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectText: {
        color: white
    },
};



class Profile extends React.Component {
    state = {
        anchorEl: null
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };
    logout=(e)=> {

        this.props.history.push({
            pathname: "/Login/",
            search: "?signout",
            state: { signout: true }
        });
    }
 
    render() {
        const { classes, adminView } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const showDistributorManufacturerList = UserProfile.isDistributor()
            && !['staff', 'addmember'].some(uriPart => window.location.pathname.toLowerCase().includes(uriPart));

        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"  className={classes.cursor}>
                    {adminView ? (<Grid>
                        <Avatar alt={UserProfile.getFullName()} src={UserProfile.getProfileImageUrl()} className={classes.avatar} />
                    </Grid>) : null}
                    {showDistributorManufacturerList
                        && <Fragment><Grid item align="middle" style={{"align-self":"middle"}}>
                        <Typography   component="span" align="center" >Manufacturer </Typography>
                        </Grid>
                    <Grid  item  >
                        <DistributorManufacturerList />
                      
                    </Grid></Fragment>
                        }
                    <Grid aria-owns={open ? 'simple-popper' : undefined}
                        aria-haspopup="true" item onClick={this.handleClick}>
                        {adminView ? (<Typography variant="subtitle1" className={classes.nameTypo}>
                            {UserProfile.getFullName()} <Icon className={classes.icon} >expand_more</Icon>
                        </Typography>) : (<Typography variant="subtitle1"><Person className={classes.icon} /> </Typography>)}
                        
                    </Grid>

                </Grid>
                <Popover
                    id="simple-popper"
                    disablePortal={true}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    TransitionComponent={Zoom}
                    modifiers={{
                        preventOverflow: {
                            enabled: false,
                            boundariesElement: 'scrollParent',
                        }
                    }}
                    className={classes.dropdown}
                >
                    <List className={classes.actions} >
                        <ListItem >
                            <Avatar alt={UserProfile.getFullName()} src={UserProfile.getProfileImageUrl()} className={classes.bigAvatar} />                            
                        </ListItem>                   
                        {!UserProfile.isDistributor() && <Fragment> <ListItem button component={Link} to="/Myaccount" onClick={this.handleClose} >
                            <ListItemText primary="My Account" className={classes.paragraphFonts} />
                        </ListItem></Fragment>
                        }
                        <ListItem button component={Link} to="/ChangePassword" onClick={this.handleClose} >
                            <ListItemText primary="Change Password" className={classes.paragraphFonts} />
                        </ListItem>
                        <ListItem component={Link} button to={{
                            pathname: '/Login',
                            search: "?signout=true",
                            state: { signout: true }
                        }} onClick={this.logout}>
                            <ListItemText primary="Log Out" className={classes.paragraphFonts} /> 

                        </ListItem>
                    </List>

                </Popover>
            </div>
        );
    }
    
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Profile));
