import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { ActivateApi } from '../../constants';
import theme from '../../theme';
import AccountFooter from './AccountFooter';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    cssLabel: {
        color: '#ffffff !important',
    },

    notchedOutline: {
        borderColor: '#FFFFFF !important',
    },
    container: {
        height: '100%',
    },
    button: {
        width: '300px',
        height: '50px',
        backgroundColor: '#0d4add',
        margin: '17px 0 17px 0',
        '&:hover': {
            backgroundColor: '#0d4add',
        }
    },

    buttonContainer: {
        textAlign: 'center',
        width: '100%',

        marginBottom: '5px'
    },
    errorPaperBackground: {
        width: '100%',
        background: "rgba(255, 255, 255, 1)",
        margin: "8px auto 8px auto",
        padding: "10px 0",
        textAlign: "center",
    }
    , errorMessage: {
        width: '100%',
        background: "rgba(255, 255, 255, 0.8)",
        margin: "8px auto 8px auto",
        padding: "10px 0",
        textAlign: "center",
        color: 'rgb(252, 20, 20)'
    }
    , errorText: {

        color: 'rgb(252, 20, 20)'
    }
    , successMessage: {
        width: '100%',

        margin: "8px auto 8px auto",
        padding: "10px 0",
        textAlign: "center",
        color: '#ffffff'
    },
    centerLoader:{
        textAlign: 'center'
    }
});
const AuthWrapper = styled.div`
    background: rgba(0, 0, 0, 0.5);
    padding: 40px 40px 40px 40px;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 600px;
    float: left;
   transform: translate(-50%, -50%);
    @media (max-width: 640px) {
        padding: 20px 20px 20px 20px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        float: left;
        margin: 0;
    }    
`;
const Title = styled.h1`
    width: 100%;
    float: left;
    font-family: 'NHaasGroteskDSPro-55Rg';
    color: #181818;
    text-transform: uppercase;
    font-weight: normal;
    margin: 25px 0px 17px 0;
    text-align: center;
`;
const Logo = styled.div`
    text-align: center;
`;


const LoginAnchor = styled.a`
    font-family: 'NHaasGroteskDSPro-55Rg';
    text-align: center;
    font-size: 18px;
    text-decoration: underline;
    color: #0d4add; 
   
    &:hover {
        text-decoration: none;
          color: #0d4add; 
    }
`;


class Activate extends React.Component {

    constructor() {
        super();
        this.state = { isloading: false, status:false,message:'' };
        this.activateAccount = this.activateAccount.bind(this);
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.user = {
            UserId: values.uid,
            Token: values.token
        }

        this.activateAccount(this.user);
    }

    activateAccount = (user) => {

        this.setState({ isloading: true })
        fetch(ActivateApi, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.user)
        }).then(res => {
            return res.json();
        }).then(res => {
            this.setState({ isloading: false,status:res.status,message:res.message });
        }).catch(err => {
            this.setState({ isloading: false, status: err.status, message:err.message });
        });
    }

    render() {


        const { classes } = this.props;
        const { isloading,status,message } = this.state;
        const title = "Account Activation";
        const loginLink = <LoginAnchor as="a" href="/login" title="Login">Login</LoginAnchor>;
        const messageLink = <Typography variant="title" className={classes.rootCaption} > Account activated succesfully, please {loginLink} to start uploading the product serials</Typography>
        const output = isloading ? (<CircularProgress color={theme.palette.common.white} size={19} style={styles.root} />) : (status ? messageLink :message) ;
       
        return (
            <Grid className={classes.container}>
                <video playsInline muted loop autoPlay className="bg-video" poster={require("../../assets/images/video-poster.jpg")}
                    style={{
                        maxWidth:'100%',
                        height: 'auto',
                    }}
                >
                    <source src={require("../../assets/video/video.mp4")} type="video/mp4" />
                    <source src={require("../../assets/video/video.ogv")} type="video/ogg" />
                    <source src={require("../../assets/video/video.webm")} type="video/webm" />
                </video>
                <AuthWrapper>
                    <Logo><img src={require("../../assets/images/logo.svg")} width="200" alt="Formbay Logo" /></Logo>
                    <Title>{title}</Title>

                    <Grid item xs={12} className={classes.centerLoader} >
                   
                    </Grid>
                    <Grid item xs={12} className={classes.errorPaperBackground} >
                        {output}
                    </Grid>
                    <AccountFooter />
                </AuthWrapper>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(Activate));
