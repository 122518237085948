import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import theme from '../theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FileCopy from '@material-ui/icons/FileCopy';
import classNames from 'classnames';
import MailOutline from '@material-ui/icons/MailOutline';
import IconButton from '@material-ui/core/IconButton';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
        verticalAlign: "top",
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "auto"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const SubTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[600],
        border: "none",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        fontSize: 13,
        verticalAlign: "top",
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "auto",
        //lineHeight:"12px",
    },

}))(TableCell);
const FailTableCell = withStyles(theme => ({
    body: {
        padding: theme.spacing(2),
        borderBottomColor: theme.palette.grey[200],
        fontSize: 14,
    },
}))(TableCell);

const styles = {
    backanim: {
        transition: "400ms ease",
        '&:hover': {
            marginLeft: "-5px",
        },
    },
    icon: {
        fontSize: 26,
        verticalAlign: "middle",
        marginTop: "-3px",
        transition: "400ms ease",

        '&:hover': {
            marginLeft: "-5px",
        },
    },
    marginy: {
        marginTop: theme.spacing(1) * 1,
        marginBottom: theme.spacing(1) * 1,
    },
    failedCount: {
        background: red[400],
        padding: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.common.white,
        fontFamily: 'NHaasGroteskDSPro-65Md',
        borderRadius: theme.shape.borderRadius
    },
    tableRow: {
        height: "auto"
    },
    table: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: theme.palette.grey[200],
        marginTop: theme.spacing(2),
    },
    avatar: {
        borderRadius: 0,
        width: 76,
        height: 42,
        color: 'tranparent'
    },
    space: {
        padding: 0,
    },
    model: {
        fontFamily: 'NHaasGroteskDSPro-65Md',
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1) * 0.5,
    },
    ripple: {
        padding: 0,
        '&:hover': {
            background: "transparent",
            textDecoration: "underline",
        },
    },
    button: {
        marginLeft: theme.spacing(1),
        lineHeight: " 15px",
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,

        },
    },
    serialnNum: {
        fontFamily: 'NHaasGroteskDSPro-65Md',
        fontSize: 14,
    },
    img: {
        display: 'block',
        width: 72,
        height: "auto",
    },
    image: {
        width: 128,
        height: "auto",
    },
    linkBtn: {
        padding: 0,
        transition: "200ms ease",
        justifyContent: "left",
        '&:hover': {
            background: "transparent",
            textDecoration: "underline",
        }
    },
    action: {
        width: 140,
        color: "#4e4e4e",
        fontFamily: 'NHaasGroteskDSPro-55Rg',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    marginBtm: {
        marginBottom: theme.spacing(1) / 2
    }
};


class Serialfailed extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.marginy}>
                    <Grid item>
                        <Link to="/dashboard" title="back to productline" >
                            <Typography color='primary' variant="h4">
                                <span className={classes.backanim}><Icon className={classes.icon} >keyboard_arrow_left</Icon>Back to Dashboard</span>
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={16}
                >
                    <Grid item>
                        <Typography variant="h1">
                            Serials not found on service
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.failedCount}>545</div>
                    </Grid>
                </Grid>

                <div className="inner-wrapper">
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <CustomTableCell rowSpan="2">Models</CustomTableCell>
                                <CustomTableCell rowSpan="2">Serial Number</CustomTableCell>
                                <CustomTableCell rowSpan="2">Date of Scan</CustomTableCell>
                                <CustomTableCell colspan="2">Data pack provider</CustomTableCell>
                                <CustomTableCell rowSpan="2">Job Ref.</CustomTableCell>
                                <CustomTableCell rowSpan="2">Email</CustomTableCell>
                                <CustomTableCell rowSpan="2">Action</CustomTableCell>
                            </TableRow>
                            <TableRow className={classes.tableRow}>
                                <SubTableCell>Serial Barcode</SubTableCell>
                                <SubTableCell>Image</SubTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <FailTableCell className="failedline">
                                    <CardHeader
                                        className={classes.space}
                                        avatar={
                                            <Avatar aria-label="Recipe" src={require("../assets/images/solar-panel.jpg")} className={classes.avatar}>
                                                R
                             </Avatar>
                                        }
                                        title={<span className={classes.model}>655WMpp</span>}
                                        subheader="420W"
                                    />

                                </FailTableCell>
                                <FailTableCell>
                                    <Typography variant="subtitle1" className={classes.serialnNum}>
                                        5854rFailTableCellF55Wbg565ad55
                                        <Button color="primary" size="small" variant="outlined" className={classes.button}>
                                            <FileCopy className={classNames(classes.leftIcon, classes.iconSmall)} />Copy
                                        </Button>
                                    </Typography>
                                </FailTableCell>
                                <FailTableCell>
                                    Today
                                </FailTableCell>
                                <FailTableCell>
                                    <Grid container
                                        direction="column"
                                        justify="center"
                                        alignItems="flex-start"
                                        spacing={8}
                                    >
                                        <Grid item>
                                            <img className={classes.img} alt="complex" src={require("../assets/images/formbay-logo.png")} />
                                        </Grid>
                                        <Grid item className={classes.image}>
                                            <img className={classes.img} alt="complex" src={require("../assets/images/barcode.svg")} />
                                        </Grid>

                                    </Grid>

                                    {/*   <img src={require("../assets/images/barcode.svg")} alt="Barcode" width="55" />
                                    <ul className="datalogos">
                                        <li><img src={require("../assets/images/formbay-logo.png")} alt="Formbay" width="120" />
                                        </li>
                                    </ul> */}
                                </FailTableCell>
                                <FailTableCell>
                                    <img src={require("../assets/images/solar-panel3.jpg")} alt="Solar Panel" width="60" /></FailTableCell>
                                <FailTableCell>
                                    <span>PV62345</span>
                                </FailTableCell>
                                <FailTableCell>
                                    <IconButton color="primary" title="Email" className={classes.ripple} aria-label="Email">
                                        <MailOutline />
                                    </IconButton>
                                </FailTableCell>
                                <FailTableCell>
                                    <div>
                                        <Button size="small" variant="outlined" className={classNames(classes.action, classes.marginBtm)} color='primary' href='/flash'>
                                            Upload Flash Data
                                       </Button>
                                    </div>
                                    <Button color='primary' variant="outlined" className={classNames(classes.action)} size="small" href='' >
                                        Parallel Import?
                                      </Button>
                                </FailTableCell>
                            </TableRow>

                            {/*
                            <tr>
                                <FailTableCell className="failedline"><img src={require("../assets/images/solar-panel2.jpg")} alt="Solar Panel"
                                        width="40" />
                                    <strong>789WMpp</strong>
                                    <br />8900W
                                </FailTableCell>
                                <FailTableCell><strong className="serialnum ">781235gdd2d14f1212f</strong>
                                    <div className="copyserial">
                                        <a href="javascript:void(0)"><img src={require("../assets/images/copy.svg")} alt="Copy Serial"
                                                width="12" /> Copy Serial</a>
                                    </div>
                                </FailTableCell>
                                <FailTableCell>20-10-11</FailTableCell>
                                <FailTableCell><img src={require("../assets/images/barcode.svg")} alt="Barcode" width="55" />
                                    <ul className="datalogos">
                                        <li><img src={require("../assets/images/bridgeselect.jpg")} alt="Formbay" width="120" />
                                        </li>
                                    </ul>
                                </FailTableCell>
                                <FailTableCell><img src={require("../assets/images/solar-panel.jpg")} alt="Solar Panel" width="60" /></FailTableCell>
                                <FailTableCell><span className="jobref redbg">PV62345</span>
                                </FailTableCell>
                                <FailTableCell>
                                    <img src={require("../assets/images/mail.svg")} alt="email" width="20" />
                                </FailTableCell>
                                <FailTableCell>
                                    <ul className="actions">
                                        <li><a href="javascript:void(0)">Parallel Import?</a></li>
                                        <li><a href="flash-data.html">Upload Flash Data</a></li>
                                    </ul>
                                </FailTableCell>
                            </tr>

                            <tr>
                                <FailTableCell className="failedline"><img src={require("../assets/images/solar-panel3.jpg")} alt="Solar Panel"
                                        width="40" />
                                    <strong>275WMpp</strong>
                                    <br />550W
                                </FailTableCell>
                                <FailTableCell><strong className="serialnum ">5842Fgde345D445a1he1</strong>
                                    <div className="copyserial">
                                        <a href="javascript:void(0)"><img src={require("../assets/images/copy.svg")}
                                                width="12" /> Copy Serial</a>
                                    </div>
                                </FailTableCell>
                                <FailTableCell>20-10-11</FailTableCell>
                                <FailTableCell><img src={require("../assets/images/barcode.svg")} alt="Barcode" width="55" />
                                    <ul className="datalogos">
                                        <li><img src={require("../assets/images/solarscope.png")} alt="Formbay" width="120" />
                                        </li>
                                    </ul>
                                </FailTableCell>
                                <FailTableCell><img src={require("../assets/images/solar-panel2.jpg")} alt="Solar Panel" width="60" /></FailTableCell>
                                <FailTableCell><span className="jobref">PV123532</span>
                                </FailTableCell>
                                <FailTableCell>
                                    <img src={require("../assets/images/mail.svg")} alt="email" width="20" />
                                </FailTableCell>
                                <FailTableCell>
                                    <ul className="actions">
                                        <li><a href="javascript:void(0)">Parallel Import?</a></li>
                                        <li><a href="flash-data.html">Upload Flash Data</a></li>
                                    </ul>
                                </FailTableCell>
                            </tr>

                            <tr>
                                <FailTableCell className="failedline"><img src={require("../assets/images/solar-panel4.jpg")} alt="Solar Panel"
                                        width="40" />
                                    <strong>126WMpp</strong>
                                    <br />550W
                                </FailTableCell>
                                <FailTableCell><strong className="serialnum ">89fg234DH563FG45825</strong>
                                    <div className="copyserial">
                                        <a href="javascript:void(0)"><img src={require("../assets/images/copy.svg")} alt="Copy Serial"
                                                width="12" /> Copy Serial</a>
                                    </div>
                                </FailTableCell>
                                <FailTableCell>20-10-11</FailTableCell>
                                <FailTableCell><img src={require("../assets/images/barcode.svg")} alt="Barcode" width="55" />
                                    <ul className="datalogos">
                                        <li><img src={require("../assets/images/bridgeselect.jpg")} alt="Formbay" width="120" />
                                        </li>
                                    </ul>
                                </FailTableCell>
                                <FailTableCell>NA</FailTableCell>
                                <FailTableCell><span className="jobref">PV890876</span>
                                </FailTableCell>
                                <FailTableCell>
                                    <img src={require("../assets/images/mail.svg")} alt="email" width="20" />
                                </FailTableCell>
                                <FailTableCell>
                                    <ul className="actions">
                                        <li><a href="javascript:void(0)">Parallel Import?</a></li>
                                        <li><a href="flash-data.html">Upload Flash Data</a></li>
                                    </ul>
                                </FailTableCell>
                            </tr>

                            <tr>
                                <FailTableCell className="failedline"><img src={require("../assets/images/solar-panel.jpg")} alt="Solar Panel" width="40" />
                                    <strong>275WMpp</strong>
                                    <br />550W
                                </FailTableCell>
                                <FailTableCell><strong className="serialnum ">542gFailTableCelldsDFgh2346fcr68</strong>
                                    <div className="copyserial">
                                        <a href="javascript:void(0)"><img src={require("../assets/images/copy.svg")} alt="Copy Serial"
                                                width="12" /> Copy Serial</a>
                                    </div>
                                </FailTableCell>
                                <FailTableCell>20-10-11</FailTableCell>
                                <FailTableCell><img src={require("../assets/images/barcode.svg")} alt="Barcode" width="55" />
                                    <ul className="datalogos">
                                        <li><img src={require("../assets/images/formbay-logo.png")} alt="Formbay" width="120" />
                                        </li>
                                    </ul>
                                </FailTableCell>
                                <FailTableCell>NA</FailTableCell>
                                <FailTableCell><span className="jobref">PV22334</span>
                                </FailTableCell>
                                <FailTableCell>
                                    <img src={require("../assets/images/mail.svg")} alt="email" width="20" />
                                </FailTableCell>
                                <FailTableCell>
                                    <ul className="actions">
                                        <li><a href="javascript:void(0)">Parallel Import?</a></li>
                                        <li><a href="flash-data.html">Upload Flash Data</a></li>
                                    </ul>
                                </FailTableCell>
                            </tr> */}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Serialfailed);
