import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import { NotificationHeaderTitle } from '../StringConstants';
const styles = theme => ({
    root: {
        width: '100%',

    },
    notifications: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2),
    }
    });

class Notification extends React.Component {
    state = {
        selectedIndex: 0,
    };

    handleListItemClick = (event, index) => {
        this.setState({ selectedIndex: index });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h1" >
                    {NotificationHeaderTitle}
                </Typography>
                <Grid container>
                    <Grid items sm={12} className={classes.notifications} >
                        <List component="nav">
                            <ListItem
                                button
                                selected={this.state.selectedIndex === 0}
                                onClick={event => this.handleListItemClick(event, 0)}
                                disableTouchRipple={true}
                            >
                                <Avatar>
                                    <Icon className={classes.icon} color="inherit" >
                                       star_rate
                                     </Icon>
                                </Avatar>
                                <ListItemText primary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tristique pharetra efficitur. Maecenas mi lorem, elementum sed tortor ut, " />
                            </ListItem>
                            <ListItem
                                button
                                selected={this.state.selectedIndex === 1}
                                onClick={event => this.handleListItemClick(event, 1)}
                                disableTouchRipple={true}
                            >
                                <Avatar>
                                    <Icon className={classes.icon}  color="inherit" >
                                            event
                                     </Icon>
                                 </Avatar>
                                <ListItemText primary="Cras non nisi condimentum, hendrerit felis sit amet, bibendum enim. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. " />
                            </ListItem>
                            <ListItem
                                button
                                selected={this.state.selectedIndex === 2}
                                onClick={event => this.handleListItemClick(event, 2)}
                                disableTouchRipple={true}
                            >
                                <Avatar>
                                    <Icon className={classes.icon} color="inherit" >
                                        report_proble...
                                     </Icon>
                                </Avatar>
                                <ListItemText primary="Cras non nisi condimentum, hendrerit felis sit amet, bibendum enim. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. " />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>


            </div>
        );
    }
}

export default withStyles(styles)(Notification);
