import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Customicon from '../../Customicon';
import theme from '../../theme';
import ImportInstallerData from './ImportInstallerData';
const styles = {
    root: {
       // marginBottom: theme.spacing(2),
    },
    button: {
        float: "right",
        [theme.breakpoints.down('xs')]: {
            float: "left",
            marginTop: theme.spacing(2),
        },
        '&:hover': {
            background: theme.palette.common.green
        }
    },
    customicon: {
        marginRight: theme.spacing(2),
    },
    btnCancel: {
        marginRight:20,
    },
    paddingNone: {
        paddingBottom:0,
    }

};

class InstallerHeader extends React.Component {
    state = {
        open: false, fullWidth: true,
        maxWidth: 'sm',
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction="row"
                    justify="flex-end"
                    alignItems="flex-end">
                 
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" size="large" onClick={this.handleClickOpen} color="primary" className={classes.button}>
                            <Customicon name="flash" width={19} fill="#fff" className={classes.customicon} />Import Migrated Installer CSV
                        </Button>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                >
                    <DialogTitle id="form-dialog-title">Import installer data</DialogTitle>
                    <DialogContent className={classes.paddingNone}>
                        <ImportInstallerData />       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" className={classes.btnCancel}>
                            Close
                         </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default withStyles(styles)(InstallerHeader);