
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { AssignProductLineApi, handleErrors, handleHttpException, LINKS_PER_PAGE, UnAssignProductlineApi } from '../constants';
import theme from '../theme';
import AuthService from './AuthService';
import FbError from './common/FbError';
import FbLoader from './common/FbLoader';
import Pagination from "./common/Pagination";
import ModelSearch from "./ModelSearch";
import ProductLineHeader from './productline/ProductLineHeader';
import ProductLineRow from './productline/ProductLineRow';

const PRODUCT_LINE_ASSIGNED = gql`
query get_available_products($filterAssigned: String,$skipAssigned: Int,$firstAssigned: Int,$productLineId:Int) {
  manufacturer {
     productsByProductline(filter: $filterAssigned, skip: $skipAssigned, first: $firstAssigned,id:$productLineId) {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
      
    }
 productsByProductlineCount(filter: $filterAssigned,id:$productLineId) 
  }
}
`;

const PRODUCT_LINE_PENDING = gql`
query get_available_products($filterAvailable: String,$skipAvailable: Int,$firstAvailable: Int) {
  manufacturer {
     productNotHavingProductline(filter: $filterAvailable, skip: $skipAvailable, first: $firstAvailable) {
      productId
      approvedDate
      expiryDate
      fireTested
      model
      wattage
    }
 productNotHavingProductlineCount(filter: $filterAvailable) 
  }
}
`;


const PRODUCT_LINE = gql`
query get_productline($productLineId:Int) {
  manufacturer {

 productLine(id:$productLineId) {
       productLineId,
       logo,
      lineName
      updatedOn
      totalProduct
      productWarranty {
        componentInYears
        componentWarrantyLink
        performanceInYears
        performanceWarrantyLink
        productWarrantyId
      }
    }
  }
}
`;

const styles = {
    root: {
        flexGrow: 1,
        height: "100%"
    },
    cardHeader: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    control: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    paper2: {
        background: theme.palette.background.default,
        position: "relative",
        marginBottom: theme.spacing(1) * 1,
    },
    gridConatainer: {
        marginBottom: theme.spacing(1) * 1,
    },
    buttons: {
        float: "right",
    },
    //model listing section styling
    button: {
        width: "100%",
    },
    card: {
        minWidth: "360px",
    },
    warranty: {
        marginTop: theme.spacing(1) * 1,
        borderRadius: "8px",
    },
    wCard: {
        boxShadow: "none",
        border: "1px solid #a4a4a4",
        padding: theme.spacing(1) * 1,
        display: "inline-block"

    },
    wContent: {
        paddingBottom: theme.spacing(1) * 0,
    },
    customicon: {
        marginLeft: "10px",
        marginRight: "10px",
        width: "12px",
        height: "auto"
    },
    innerCard: {
        //border: "1px solid #a4a4a4",
        //padding: theme.spacing(1) * 1,
        boxShadow: "none",
    },
    comPer: {
        fontSize: "16px",
        lineHeight: "16px",
        verticalAlign: "top",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRight: "1px solid #a4a4a4"
    },
    media: {
        textAlign: "center",
        background: theme.palette.background.default,
    },
    backanim: {
        transition: "400ms ease",
        '&:hover': {
            marginLeft: "-5px",
        },
    },
    icon: {
        fontSize: 26,
        verticalAlign: "middle",
        marginTop: "-3px",
    },
    marginy: {
        marginTop: theme.spacing(1) * 1,
        marginBottom: theme.spacing(1) * 1,
    },
    total: {
        float: "left",
        lineHeight: "38px"
    },
    selectAllListItem: {
        backgroundColor:'lightgray'
    },
    span: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1) * 1,
        marginLeft: theme.spacing(1) * 0.5,
    },
    PaperTable: {
        borderTopWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        
        borderColor: theme.palette.common.borderColor,
        marginTop: theme.spacing(1),
    },
};
class AssignProductLine extends React.Component {

    constructor(props) {
        super(props);
        this.handleSearchAvailable = this.handleSearchAvailable.bind(this);
        this.handleSearchAssigned = this.handleSearchAssigned.bind(this);
        this.state = {
            uploading: false, pageNumberAvailable: 1, pageSizeAvailable: 8, pageNumberAssigned: 1, pageSizeAssigned: 8,
            filterAssigned: null, filterAvailable: null, productLineId: null, selectedProductIds: [], removalProductIds: [], random: null, selectAll: false, UnAssignedAll:false
        };
        //this.onPageChange = this.onPageChange.bind(this);
        this.ModifyProductList = this.ModifyProductList.bind(this);
        this.AssignProductLine = this.AssignProductLine.bind(this);
        this.UnAssignProductline = this.UnAssignProductline.bind(this);
        this.Auth = new AuthService();
        this.makeid = this.makeid.bind(this);
        this.onPageChangeAvailable = this.onPageChangeAvailable.bind(this);
        this.onPageChangeAssigned = this.onPageChangeAssigned.bind(this);
        this.ModifyRemovalProductLineList = this.ModifyRemovalProductLineList.bind(this);
        this.CheckAll = this.CheckAll.bind(this);
        this.UnAssignAll = this.UnAssignAll.bind(this);
    }

    onPageChangeAvailable(newPage) {
        this.setState({
            pageNumberAvailable: newPage
        })
    }

    onPageChangeAssigned(newPage) {
        this.setState({
            pageNumberAssigned: newPage
        })
    }

    handleSearchAvailable(data) {
        this.setState({
            filterAvailable: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }
    handleSearchAssigned(data) {
        this.setState({
            filterAssigned: data, pageNumber: 1, pageSize: LINKS_PER_PAGE
        })
    }
    makeid(length) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    AssignProductLine() {
        console.log(this.state.selectedProductIds);
        const { selectedProductIds: list, selectAll } = this.state;
        if ((list === null || list.length === 0) && !selectAll) { return; }
        const token = this.Auth.getToken();


        fetch(AssignProductLineApi, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "AssignAll": selectAll, "ProductLineId": this.id, "ProductId": list
            })
        }).then(res => {
            return handleErrors(res);

        }).then(res => {

            this.setState({ random: this.makeid(8) });


        }).catch(err => {

            handleHttpException(err).then(result => {

                this.setState({ uploading: false, error: true, errorMessage: result });
            });;
        });
    }
    UnAssignProductline() {

        const { removalProductIds: list, UnAssignedAll } = this.state;
       
        if ((list === null || list.length === 0) && !UnAssignedAll) { return; }
        const token = this.Auth.getToken();


        fetch(UnAssignProductlineApi, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "ProductLineId": this.id, "ProductId": list, "UnAssignAll": UnAssignedAll
            })
        }).then(res => {
            return handleErrors(res);

        }).then(res => {

            this.setState({ random: this.makeid(9) });


        }).catch(err => {

            handleHttpException(err).then(result => {

                this.setState({ uploading: false, error: true, errorMessage: result });
            });;
        });
    }

    ModifyRemovalProductLineList(e, id) {
        var list = this.state.removalProductIds;
        if (e.target.checked) {
            if (list.indexOf(id) === -1) {
                list.push(id);
            }
        }
        else {
            var index = list.indexOf(id);
            if (index !== -1) {
                list.splice(index, 1);
            }
        }
        this.setState({ removalProductIds: list });
    }
    ModifyProductList(e, id) {
        var list = this.state.selectedProductIds;
        if (e.target.checked) {
            if (list.indexOf(id) === -1) {
                list.push(id);
            }
        }
        else {
            var index = list.indexOf(id);
            if (index !== -1) {
                list.splice(index, 1);
            }
        }
        this.setState({ selectedProductIds: list });
    }

    CheckAll(e) {
        
        this.setState({ selectAll: e.target.checked });
        this.refetch();
        
    }

    UnAssignAll(e) {

        this.setState({ UnAssignedAll: e.target.checked });
        this.refetch();

    }

    _getQueryVariables() {

        const { pageNumberAvailable, pageSizeAvailable, pageNumberAssigned, pageSizeAssigned, filterAvailable, filterAssigned, random } = this.state;
        const { id } = this.props.match.params;
        this.id = parseInt(id, 10);
        const skipAvailable = (pageNumberAvailable - 1) * pageSizeAvailable;
        const firstAvailable = pageSizeAvailable;
        const skipAssigned = (pageNumberAssigned - 1) * pageSizeAssigned;
        const firstAssigned = pageSizeAssigned;
        const productLineId = this.id;
        return { firstAvailable, skipAvailable, firstAssigned, skipAssigned, filterAvailable, filterAssigned, productLineId, random }
    }
    render() {
        const { pageNumberAvailable, pageSizeAvailable, pageNumberAssigned, pageSizeAssigned, filterAvailable, filterAssigned, selectedProductIds, removalProductIds, selectAll, UnAssignedAll } = this.state;
        const { classes } = this.props;
        const isSelected = selectedProductIds.length > 0 || selectAll;
        const activeButtonColor = isSelected ? "primary" : "grey[400]";

        const isRemovedSelected = removalProductIds.length > 0 || UnAssignedAll;
        const activeRemovedButtonColor = isRemovedSelected ? "secondary" : "grey[400]";
        console.log(selectAll);
        return (

            <Query query={PRODUCT_LINE} variables={this._getQueryVariables()} fetchPolicy="no-cache"  >
                {({ loading, error, data }) => {
                    if (loading) return <FbLoader isTableBodyRequired={true} loaderStyle={'center'} />
                    if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} message='Product Line Not Found' />

                    return (<div className={classes.root}>
                        <Grid container direction="row"
                            justify="space-between"
                            alignItems="center"
                            className={classes.marginy}>
                            <Grid item>
                                <Link to="/productline" title="back to product line" >
                                    <Typography color='primary' variant="h4">
                                        <span className={classes.backanim}><Icon className={classes.icon} >keyboard_arrow_left</Icon> Back to Product Line</span>
                                    </Typography>
                                </Link>
                            </Grid>

                        </Grid>
                        <Grid container direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item>
                                <Typography variant="h1">All Models</Typography>
                            </Grid>
                        </Grid>
                        <Paper square elevation={0} className={classes.PaperTable}>
                            <Table className={classes.table}>
                                <ProductLineHeader rowType={2} show_action={true}/>
                                <TableBody>
                                    <ProductLineRow key={0} data={data.manufacturer.productLine} index={0} rowType={2} show_action={true}/>
                                </TableBody>
                            </Table>
                        </Paper>
                        <Grid container
                            direction="row"
                            justify="flex-start"
                            alignItems="stretch"
                            spacing={16}
                            className={classes.control}>

                            <Grid item sm>
                                <Paper elevation={0} className={classes.paper}>
                                    <Grid container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="flex-start"
                                        spacing={32}>
                                        <Grid item lg={6} xs={12}>
                                            <Typography variant="button" gutterBottom>
                                                Unassigned Models
                                     </Typography>

                                            <ModelSearch filterText={filterAvailable} searchMode={1} containerRequired={false} searchHandler={this.handleSearchAvailable} />
                                            <Paper className={classes.paper2} elevation={0}>
                                               
                                                <Query query={PRODUCT_LINE_PENDING} variables={this._getQueryVariables()} fetchPolicy="no-cache"   >
                                                    {({ loading, error, data, refetch }) => {
                                                        this.refetch = refetch;
                                                        if (loading) return <FbLoader isTableBodyRequired={true} loaderStyle={'assignModel'} />
                                                        if (error) return <FbError isTableBodyRequired={true} colSpan={10} errorStyle={'emptycell'} message='Product Line Not Found' />
                                                        const listAvailableModels = data.manufacturer.productNotHavingProductline.map((model, index) =>
                                                            (
                                                                <Fragment>
                                                                    <ListItem>
                                                                        <ListItemText primary={model.model} />
                                                                        <ListItemSecondaryAction>

                                                                            <Checkbox checked={selectAll || (selectedProductIds != null && selectedProductIds.indexOf(model.productId) !== -1)} onChange={(e) => { this.ModifyProductList(e, model.productId) }} icon={<Icon className={classes.iconHover}
                                                                                color="default" style={{ fontSize: 24 }}> add_circle_outline</Icon>}
                                                                                checkedIcon={<Icon className={classes.iconHover} color="primary" style={{ fontSize: 24 }}
                                                                                    >
                                                                                    add_circle
                                                                                </Icon>} />
                                                                            
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </Fragment>
                                                            ));
                                                        const paging = (<Pagination Prefix="products" TotalRecords={data.manufacturer.productNotHavingProductlineCount}
                                                            PageSize={pageSizeAvailable} PageNumber={pageNumberAvailable} ShowPreviousNext={true} onPageChange={this.onPageChangeAvailable} ></Pagination>);
                                                        return (<Fragment>
                                                            <List dense className={classes.root}>
                                                                <Fragment>
                                                                    <ListItem className={classes.selectAllListItem} >
                                                                        <ListItemText primary="Select All"  />
                                                                        <ListItemSecondaryAction>
                                                                            <Checkbox onChange={(e) => { this.CheckAll(e) }} color="blue"
                                                                                edge="start"
                                                                                checked={selectAll}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': 'Select All' }}
                                                                            />
                                                                        

                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </Fragment>
                                                                {listAvailableModels}
                                                            </List>
                                                            {paging}

                                                            <Typography variant="subtitle1" className={classes.total} gutterBottom><span className={classes.span}>Total Unassigned Models:</span>{data.manufacturer.productNotHavingProductlineCount}
                                                            </Typography>
                                                        </Fragment>);
                                                    }}
                                                </Query>


                                            </Paper>
                                            <Button variant="contained" color={activeButtonColor}  {...isSelected !== true ? { disabled: true } : ''} onClick={(e) => this.AssignProductLine()} className={classes.buttons}>Assign</Button>
                                        </Grid>
                                        <Grid item lg={6} xs={12}>
                                            <Typography variant="button" gutterBottom>
                                                Assigned Models
                                     </Typography>
                                            <ModelSearch filterText={filterAssigned} searchMode={1} containerRequired={false} searchHandler={this.handleSearchAssigned} />
                                            <Paper className={classes.paper2} elevation={0}>

                                                <Query query={PRODUCT_LINE_ASSIGNED} variables={this._getQueryVariables()} fetchPolicy="no-cache"  >
                                                    {({ loading, error, data }) => {
                                                        if (loading) return <FbLoader isTableBodyRequired={false} colSpan={10} loaderStyle={'assignModel'} />
                                                        if (error) return <FbError isTableBodyRequired={false} colSpan={10} errorStyle={'emptycell'} message='Product Line Not Found' />

                                                        const listAssignedModels = data.manufacturer.productsByProductline.map((model, index) =>
                                                            (
                                                                <Fragment>
                                                                    <ListItem>
                                                                        <ListItemText primary={model.model} />
                                                                        <ListItemSecondaryAction>
                                                                            <Checkbox
                                                                                checked={UnAssignedAll || (removalProductIds != null && removalProductIds.indexOf(model.productId) !== -1)}
                                                                                onChange={(e) => { this.ModifyRemovalProductLineList(e, model.productId) }} icon={<Icon className={classes.iconHover}
                                                                                    color="default" style={{ fontSize: 24 }}>
                                                                                    remove_circle_outline</Icon>} checkedIcon={<Icon className={classes.iconHover} color="secondary" style={{ fontSize: 24 }}>
                                                                                    remove_circle
                                                                                </Icon>} />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </Fragment>
                                                            ));


                                                        const paging = (<Pagination Prefix="productsAvailable" TotalRecords={data.manufacturer.productsByProductlineCount}
                                                            PageSize={pageSizeAssigned} PageNumber={pageNumberAssigned} ShowPreviousNext={true} onPageChange={this.onPageChangeAssigned} ></Pagination>);
                                                        return (<Fragment><List dense className={classes.root}>
                                                            <Fragment>
                                                                <ListItem className={classes.selectAllListItem} >
                                                                    <ListItemText primary="Remove all" />
                                                                    <ListItemSecondaryAction>
                                                                        <Checkbox onChange={(e) => { this.UnAssignAll(e) }} color="blue"
                                                                            edge="start"
                                                                            checked={UnAssignedAll}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            inputProps={{ 'aria-labelledby': 'Remove all' }}
                                                                        />


                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                                <Divider />
                                                            </Fragment>
                                                            {listAssignedModels}
                                                        </List>
                                                            {paging}
                                                            <Typography variant="subtitle1" className={classes.total} gutterBottom><span className={classes.span}>Total Assigned Models:</span>{data.manufacturer.productsByProductlineCount}
                                                            </Typography>
                                                        </Fragment>);
                                                    }}
                                                </Query>

                                            </Paper>
                                            <Button variant="contained" color={activeRemovedButtonColor}
                                                {...isRemovedSelected !== true ? { disabled: true } : ''} onClick={(e) => this.UnAssignProductline()} className={classes.buttons}>Remove</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>);
                }}
            </Query>





        );
    }
}

export default withStyles(styles)(AssignProductLine);
